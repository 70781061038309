import {
  Autocomplete,
  Box,
  Button,
  Chip,
  Grid,
  MenuItem,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { createStoragePriceApi } from "../../../../components/api/StoragePriceApiService";
import { retrieveAllWarehouseApi } from "../../../../components/api/WarehouseApiService";
import { currencies } from "../../../../utils/menu-items";

const AddStoragePrice = (props) => {
  const navigate = useNavigate();
  const { rows, setRows, setAddView } = props;

  const [addWarehouseData, setAddWarehouseData] = useState({
    warehouse: null,
    warehouseId: null,
    year: null,
    january: null,
    february: null,
    march: null,
    april: null,
    may: null,
    june: null,
    july: null,
    august: null,
    semptember: null,
    october: null,
    november: null,
    december: null,
    month: 1,
  });
  const [warehouses, setWarehouses] = useState();
  function retrieveWarehouse() {
    retrieveAllWarehouseApi()
      .then((response) => {
        setWarehouses(response?.data);
      })
      .catch((error) => console.log(error.response?.data));
  }

  return (
    <Stack direction="column">
      <Typography variant="body1" mb={2}>
        Add New Storage Price
      </Typography>
      <Grid container xs={12} spacing="8px">
        <Grid item xs={12} sm={3}>
          <Typography variant="body2">Warehouse</Typography>
          <Autocomplete
            options={warehouses || []}
            getOptionLabel={(option) =>
              `ID: ${option?.id || ""}, Code: ${option?.code || ""}, Name: ${
                option?.name || ""
              }`
            }
            value={addWarehouseData.warehouse}
            onChange={(event, newValue) => {
              setAddWarehouseData({
                ...addWarehouseData,
                warehouseId: newValue?.id,
                warehouse: newValue,
              });
            }}
            onOpen={() => retrieveWarehouse()}
            isOptionEqualToValue={(option, value) => option.id === value.id}
            renderInput={(params) => (
              <TextField
                {...params}
                variant="outlined"
                size="small"
                fullWidth
              />
            )}
            renderTags={() => null}
          />
          {addWarehouseData?.warehouse && (
            <Box mt={2}>
              <Chip
                label={`ID: ${addWarehouseData?.warehouse?.id || ""}`}
                onDelete={() => {
                  setAddWarehouseData({
                    ...addWarehouseData,
                    warehouse: null,
                    warehouseId: null,
                  });
                }}
                style={{ marginBottom: "5px" }}
              />
              <br></br>
              <Chip
                label={`Code: ${addWarehouseData?.warehouse?.code || ""}`}
                style={{ marginBottom: "5px" }}
              />
              <br />
              <Chip
                label={`Name: ${addWarehouseData?.warehouse?.name || ""}`}
                style={{ marginBottom: "5px" }}
              />
              <br />
              <Chip
                label={`Contact: ${
                  addWarehouseData?.warehouse?.contactPerson?.contactPerson ||
                  ""
                }`}
                style={{ marginBottom: "5px" }}
              />
              <br />
              <Chip
                label={`Storage Pricing By ${
                  addWarehouseData?.warehouse?.storagePricingBy || ""
                }`}
                style={{ marginBottom: "5px" }}
              />
              <br />
            </Box>
          )}
        </Grid>
      
        <Grid item xs={12} sm={3}>
          <Typography variant="body2">Year</Typography>
          <TextField
            variant="outlined"
            size="small"
            fullWidth
            type="number"
            onChange={(e) =>
              setAddWarehouseData({
                ...addWarehouseData,
                year: e.target.value,
              })
            }
          />
        </Grid>
        <Grid item xs={12} sm={6}></Grid>

        <Grid item xs={12} sm={2}>
            <Typography variant="body2">Currency</Typography>
            <TextField
              variant="outlined"
              size="small"
              fullWidth
              value={addWarehouseData?.currency}
              id="outlined-select--currency"
              select
              onChange={(e) =>
                setAddWarehouseData({
                  ...addWarehouseData,
                  currency: e.target.value,
                })
              }
            >
              {currencies.map((option) => (
                <MenuItem key={option.value} value={option.value}>
                  {option.label}
                </MenuItem>
              ))}
            </TextField>
          </Grid>
          <Grid item xs={12} sm={10}></Grid>
        <Grid item xs={12} sm={2}>
          <Typography variant="body2">January</Typography>
          <TextField
            variant="outlined"
            size="small"
            fullWidth
            type="number"
            onChange={(e) =>
              setAddWarehouseData({
                ...addWarehouseData,
                january: e.target.value,
              })
            }
          />
        </Grid>
        <Grid item xs={12} sm={2}>
          <Typography variant="body2">February</Typography>
          <TextField
            variant="outlined"
            size="small"
            fullWidth
            type="number"
            onChange={(e) =>
              setAddWarehouseData({
                ...addWarehouseData,
                february: e.target.value,
              })
            }
          />
        </Grid>
        <Grid item xs={12} sm={2}>
          <Typography variant="body2">March</Typography>
          <TextField
            variant="outlined"
            size="small"
            fullWidth
            type="number"
            onChange={(e) =>
              setAddWarehouseData({
                ...addWarehouseData,
                march: e.target.value,
              })
            }
          />
        </Grid>
        <Grid item xs={12} sm={2}>
          <Typography variant="body2">April</Typography>
          <TextField
            variant="outlined"
            size="small"
            fullWidth
            type="number"
            onChange={(e) =>
              setAddWarehouseData({
                ...addWarehouseData,
                april: e.target.value,
              })
            }
          />
        </Grid>
        <Grid item xs={12} sm={2}>
          <Typography variant="body2">May</Typography>
          <TextField
            variant="outlined"
            size="small"
            fullWidth
            type="number"
            onChange={(e) =>
              setAddWarehouseData({
                ...addWarehouseData,
                may: e.target.value,
              })
            }
          />
        </Grid>
        <Grid item xs={12} sm={2}>
          <Typography variant="body2">June</Typography>
          <TextField
            variant="outlined"
            size="small"
            fullWidth
            type="number"
            onChange={(e) =>
              setAddWarehouseData({
                ...addWarehouseData,
                june: e.target.value,
              })
            }
          />
        </Grid>
        <Grid item xs={12} sm={2}>
          <Typography variant="body2">July</Typography>
          <TextField
            variant="outlined"
            size="small"
            fullWidth
            type="number"
            onChange={(e) =>
              setAddWarehouseData({
                ...addWarehouseData,
                july: e.target.value,
              })
            }
          />
        </Grid>
        <Grid item xs={12} sm={2}>
          <Typography variant="body2">August</Typography>
          <TextField
            variant="outlined"
            size="small"
            fullWidth
            type="number"
            onChange={(e) =>
              setAddWarehouseData({
                ...addWarehouseData,
                august: e.target.value,
              })
            }
          />
        </Grid>
        <Grid item xs={12} sm={2}>
          <Typography variant="body2">September</Typography>
          <TextField
            variant="outlined"
            size="small"
            fullWidth
            type="number"
            onChange={(e) =>
              setAddWarehouseData({
                ...addWarehouseData,
                september: e.target.value,
              })
            }
          />
        </Grid>
        <Grid item xs={12} sm={2}>
          <Typography variant="body2">October</Typography>
          <TextField
            variant="outlined"
            size="small"
            fullWidth
            type="number"
            onChange={(e) =>
              setAddWarehouseData({
                ...addWarehouseData,
                october: e.target.value,
              })
            }
          />
        </Grid>
        <Grid item xs={12} sm={2}>
          <Typography variant="body2">November</Typography>
          <TextField
            variant="outlined"
            size="small"
            fullWidth
            type="number"
            onChange={(e) =>
              setAddWarehouseData({
                ...addWarehouseData,
                november: e.target.value,
              })
            }
          />
        </Grid>
        <Grid item xs={12} sm={2}>
          <Typography variant="body2">December</Typography>
          <TextField
            variant="outlined"
            size="small"
            fullWidth
            type="number"
            onChange={(e) =>
              setAddWarehouseData({
                ...addWarehouseData,
                december: e.target.value,
              })
            }
          />
        </Grid>
      </Grid>
      <Stack direction="row" spacing={1} mt={1}>
        <Button variant="outlined" onClick={() => setAddView(false)}>
          Cancel
        </Button>
        <Button
          variant="contained"
          onClick={() => {
            setRows([addWarehouseData, ...rows]);
            setAddView(false);

            createStoragePriceApi(addWarehouseData)
              .then((response) => {
                window.location.reload();
                navigate("/storage-price");
              })
              .catch((error) => {
                console.log(error.response.data);
              });
          }}
        >
          Add
        </Button>
      </Stack>
    </Stack>
  );
};

export default AddStoragePrice;

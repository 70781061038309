import DeleteIcon from "@mui/icons-material/Delete";
import { Autocomplete, MenuItem, Stack } from "@mui/material";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Checkbox from "@mui/material/Checkbox";
import FormControlLabel from "@mui/material/FormControlLabel";
import Grid from "@mui/material/Grid";
import IconButton from "@mui/material/IconButton";
import Paper from "@mui/material/Paper";
import Switch from "@mui/material/Switch";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import TableSortLabel from "@mui/material/TableSortLabel";
import TextField from "@mui/material/TextField";
import Toolbar from "@mui/material/Toolbar";
import Tooltip from "@mui/material/Tooltip";
import Typography from "@mui/material/Typography";
import { alpha } from "@mui/material/styles";
import { visuallyHidden } from "@mui/utils";
import { format } from "date-fns";
import PropTypes from "prop-types";
import * as React from "react";
import { useEffect, useState } from "react";
import { retrieveAllCostsApi } from "../../../components/api/CostApiService";
import {
  retrieveAllWarehouseInventoryApi,
  retrieveFilteredWarehouseInventoryRecordsApi,
} from "../../../components/api/WarehouseInventoryApiService";
import { retrieveAllItemsApi } from "../../../components/api/ItemApiService";
import ExportExcel from "../../../utils/excelExport";
import AddWarehouse from "./AddWarehouseInventory";
import EditWarehouse from "./EditWarehouseInventory";
import {
  color,
  companies,
  incoterms,
  leafForm,
  offerStatus,
  origin,
  paymentTerm,
  recordStatus,
  sampleStatus,
  status,
  type,
  units,
} from "../../../utils/menu-items";
import HistoryButton from "./Dialogs/WarehouseInventoryHistoryButton";
import StorageCostButton from "./Dialogs/StorageCostButton";
import { ExpandMore } from "@mui/icons-material";
import ManualStorageCostButton from "./Dialogs/ManualStorageCostButton";
import { retrieveAllWarehouseApi } from "../../../components/api/WarehouseApiService";


function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort(array, comparator) {
  const stabilizedThis = array?.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) {
      return order;
    }
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

const summaryHeadCells = [
  { id: "warehouseId", disablePadding: true, label: "Warehouse ID" },
  { id: "warehouseName", disablePadding: true, label: "Warehouse Name" },
  { id: "itemId", disablePadding: true, label: "Item ID" },
  { id: "itemName", disablePadding: true, label: "Item Name" },
  { id: "totalQtyOnWayIn", disablePadding: true, label: "Total Qty On Way (Incoming)" },
  { id: "totalQtyOnWayOut", disablePadding: true, label: "Total Qty On Way (Outgoing)" },
  { id: "totalShippedQuantity", disablePadding: true, label: "Quantity" },
  { id: "totalStorageCost", disablePadding: true, label: "Total Storage Cost" },
];

const detailHeadCells = [
  {
    id: "editButton",
    disablePadding: true,
    label: "",
  },
  {
    id: "id",
    numeric: true,
    disablePadding: true,
    label: "ID",
  },
  {
    id: "type",
    numeric: false,
    disablePadding: true,
    label: "Type",
  },
  {
    id: "shippingInstruction",
    numeric: false,
    disablePadding: true,
    label: "SI",
  },
  {
    id: "referenceShippingInstruction",
    numeric: false,
    disablePadding: true,
    label: "Reference SI",
  },
  {
    id: "shippingInstructionType",
    numeric: false,
    disablePadding: true,
    label: "SI Type",
  },
  {
    id: "warehouseId",
    numeric: false,
    disablePadding: true,
    label: "Warehouse Id",
  },
  {
    id: "name",
    numeric: false,
    disablePadding: true,
    label: "Name",
  },
  {
    id: "itemId",
    disablePadding: true,
    label: "Item ID",
  },
  {
    id: "itemBuyingGrade",
    disablePadding: true,
    label: "Buying Grade",
  },
  {
    id: "itemOrigin",
    disablePadding: true,
    label: "Origin",
  },
  {
    id: "itemCrop",
    disablePadding: true,
    label: "Crop",
  },
  {
    id: "entryDate",
    disablePadding: true,
    label: "Entry Date",
  },
  {
    id: "exitDate",
    disablePadding: true,
    label: "Exit Date",
  },
  {
    id: "shippedAmountOnWay",
    numeric: true,
    disablePadding: true,
    label: "Qty On Way",
  },
  {
    id: "shippedAmount",
    numeric: true,
    disablePadding: true,
    label: "Quantity",
  },
  {
    id: "storagePricingBy",
    disablePadding: true,
    label: "Storage Pricing By",
  },
  {
    id: "actualFreightPrice",
    disablePadding: true,
    label: "Actual Freight Price",
  },
  {
    id: "actualInsuranceCost",
    disablePadding: true,
    label: "Actual Insurance Cost",
  },
  {
    id: "monthsSinceEntry",
    disablePadding: true,
    label: "Months Since Entry",
  },
 {
    id: "creationTime",
    numeric: false,
    disablePadding: true,
    label: "Creation Time",
  },
  {
    id: "createdBy",
    numeric: false,
    disablePadding: true,
    label: "Created By",
  },
  {
    id: "recordStatus",
    numeric: false,
    disablePadding: false,
    label: "Record Status",
  },
  {
    id: "detailsButton",
    disablePadding: true,
    label: "",
  },
  {
    id: "historyButton",
    disablePadding: true,
    label: "",
  },
];

function EnhancedTableHead(props) {
  const {
    onSelectAllClick,
    order,
    orderBy,
    numSelected,
    rowCount,
    onRequestSort,
    headCells,
  } = props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        <TableCell padding="checkbox">
          <Checkbox
            color="primary"
            indeterminate={numSelected > 0 && numSelected < rowCount}
            checked={rowCount > 0 && numSelected !== 0}
            onChange={onSelectAllClick}
            inputProps={{
              "aria-label": "select all desserts",
            }}
          />
        </TableCell>
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            align={"center"}
            padding={headCell.disablePadding ? "none" : "normal"}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : "asc"}
              onClick={createSortHandler(headCell.id)}
            >
              {headCell.label}
              {orderBy === headCell.id ? (
                <Box component="span" sx={visuallyHidden}>
                  {order === "desc" ? "sorted descending" : "sorted ascending"}
                </Box>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

EnhancedTableHead.propTypes = {
  numSelected: PropTypes.number.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  onSelectAllClick: PropTypes.func.isRequired,
  order: PropTypes.oneOf(["asc", "desc"]).isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
};

function EnhancedTableToolbar(props) {
  const { numSelected, showFilters, setShowFilters, rows, selected, setAddView,
    selectedData } = props;

  return (
    <Toolbar
      sx={{
        pl: { sm: 2 },
        pr: { xs: 1, sm: 1 },
        ...(numSelected > 0 && {
          bgcolor: (theme) =>
            alpha(
              theme.palette.primary.main,
              theme.palette.action.activatedOpacity,
            ),
        }),
        display: "flex",
        justifyContent: "space-between",
      }}
    >
      <Stack direction="row" spacing={1} alignItems={"center"}>
          <Typography
          sx={{ fontWeight: 600, fontSize: "20px" }}
            variant="h6"
            id="tableTitle"
            component="div"
            color="#1E2685"
          >
            Warehouse Inventory
          </Typography>

          <Typography></Typography>
          {numSelected > 0 ? (
          <Typography
          sx={{ fontWeight: 200, fontSize: "16px" }}
            color="#1E2685"
            variant="subtitle1"
            component="div"
          >
            {numSelected} selected
          </Typography>
        ) : (<Typography></Typography>)}
        
        {numSelected > 0 && (
          <Tooltip title="Delete">
            <IconButton>
              <DeleteIcon />
            </IconButton>
          </Tooltip>
        )}
      </Stack>
      <Stack direction={"row"} spacing={1} sx={{ mr: 2 }}>
        <ExportExcel excelData={selectedData} fileName={"WarehouseExport"} buttonLabel="Export Table"/>

        <Button disableElevation variant="outlined" size="small" onClick={() => setAddView(true)}>
          Add
        </Button>
        <Button
          variant="outlined"
          onClick={() => setShowFilters(!showFilters)}
          sx={{ minWidth: "130px" }}
          size="small"
        >
          {showFilters ? "Hide Filters" : "Show Filters"}
        </Button>
      </Stack>
    </Toolbar>
  );
}

EnhancedTableToolbar.propTypes = {
  numSelected: PropTypes.number.isRequired,
};

export default function WarehouseInventoryView() {
  const [order, setOrder] = useState("asc");
  const [orderBy, setOrderBy] = useState("calories");
  const [selected, setSelected] = useState([]);
  const [selectedData, setSelectedData] = useState([]);
  const [page, setPage] = useState(0);
  const [dense, setDense] = useState(true);
  const [rowsPerPage, setRowsPerPage] = useState(25);
  const [showFilters, setShowFilters] = useState(false);
  const [addView, setAddView] = useState(false);
  const [addViewStates, setAddViewStates] = useState(null);
  const [rows, setRows] = useState([]);
  const [items, setItems] = useState([]);
  const [warehouses, setWarehouses] = useState([]);
  const [costs, setCosts] = useState([]);
  const [resultShipped, setResultShipped] = useState(0);
  const [resultUnshipped, setResultUnshipped] = useState(0);

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const [filterWarehouse, setFilterWarehouse] = useState({
    id: 0,
    ownershipList: [],
    itemIdList: [],
    costIdList: [],
    storageList: [],
    warehouseIdList:[],
    warehouseTypeList: [],
    storageTypeList: [],
    paymentStatusList: [],
    originList: [],
    remark: "",
    recordStatusList: ["ACTIVE"],
  });

  const [componentsState, setComponentsState] = useState({
    item: {
      dropdownOpen: false,
      inputValue: "",
    },
    warehouse: {
      dropdownOpen: false,
      inputValue: "",
    },
  });

  const updateComponentState = (componentKey, newState) => {
    setComponentsState((prevState) => ({
      ...prevState,
      [componentKey]: {
        ...prevState[componentKey],
        ...newState,
      },
    }));
  };

  useEffect(() => retrieveWarehouseInventory(), []);
  useEffect(() => {
    function sumShipped(data) {
      const sum = data
        .map((item) => item?.shippedAmount)
        .reduce((acc, currentValue) => acc + currentValue, 0);
      return sum;
    }
    function sumUnshipped(data) {
      const sum = data
        .map((item) => item?.unshippedAmount)
        .reduce((acc, currentValue) => acc + currentValue, 0);
      return sum;
    }
    setResultShipped(sumShipped(rows));
    setResultUnshipped(sumUnshipped(rows));
  }, [rows]);

  function retrieveWarehouseInventory() {
    retrieveAllWarehouseInventoryApi()
      .then((response) => {
        setRows(response?.data);
      })
      .catch((error) => console.log(error.response?.data));
  }


  function retrieveWarehouse() {
    retrieveAllWarehouseApi()
      .then((response) => {
        setWarehouses(response?.data);
      })
      .catch((error) => console.log(error.response?.data));
  }


  function retrieveItems() {
    retrieveAllItemsApi()
      .then((response) => {
        setItems(response?.data);
      })
      .catch((error) => console.log(error.response?.data));
  }

  function retrieveCosts() {
    retrieveAllCostsApi()
      .then((response) => {
        setCosts(response?.data);
      })
      .catch((error) => console.log(error.response?.data));
  }

  const [editWarehouse, setEditWarehouse] = useState({
    visible: false,
    data: null,
    index: null,
  });

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelected = rows.map((n) => n.id);
      setSelected(newSelected);
      setSelectedData(rows);
      return;
    }else{
      setSelected([]);
      setSelectedData([]);
    }
  };

  const handleClick = (event, name, row) => {
    const selectedIndex = selected.indexOf(name);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1),
      );
    }

    const selectedDataIndex = selectedData.indexOf(row);
    let newSelectedData = [];

    if (selectedDataIndex === -1) {
      newSelectedData = newSelectedData.concat(selectedData, row);
    } else if (selectedDataIndex === 0) {
      newSelectedData = newSelectedData.concat(selectedData.slice(1));
    } else if (selectedDataIndex === selectedData.length - 1) {
      newSelectedData = newSelectedData.concat(selectedData.slice(0, -1));
    } else if (selectedDataIndex > 0) {
      newSelectedData = newSelectedData.concat(
        selectedData.slice(0, selectedDataIndex),
        selectedData.slice(selectedDataIndex + 1),
      );
    }
    setSelectedData(newSelectedData);

    setSelected(newSelected);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleChangeDense = (event) => {
    setDense(event.target.checked);
  };

  const visibleRows = React.useMemo(
    () =>
      stableSort(rows, getComparator(order, orderBy)).slice(
        page * rowsPerPage,
        page * rowsPerPage + rowsPerPage,
      ),
    [order, orderBy, page, rowsPerPage, rows, editWarehouse],
  );

  const groupedRows = visibleRows.reduce((acc, row) => {
    const key = `${row?.warehouse?.id}-${row?.item?.id}`;
    if (!acc[key]) {
      acc[key] = {
        warehouseId: row?.warehouse?.id,
        warehouseName: row?.warehouse?.name,
        itemId: row?.item?.id,
        buyingGrade: row?.item?.buyingGrade,
        totalShippedQuantity:  0,
        totalQtyOnWayIn: 0,
        totalQtyOnWayOut: 0,
        details: [],
      };
    }
    if (row?.type === "Incoming") {
      acc[key].totalShippedQuantity += row?.shippedAmount ?? 0;
      acc[key].totalQtyOnWayIn += (row?.shippingInstruction?.shipmentRequest?.shipmentQuantity - (row?.shippedAmount ?? 0)) ?? 0;
    } else if (row?.type === "Outgoing") {
      acc[key].totalShippedQuantity -= row?.shippedAmount ?? 0;
      acc[key].totalQtyOnWayOut += (row?.shippingInstruction?.shipmentRequest?.shipmentQuantity - (row?.shippedAmount ?? 0)) ?? 0;
    }

    acc[key].details.push(row);
    return acc;
  }, {});
  
  const [headCells, setHeadCells] = useState(summaryHeadCells);
  const [expandedRow, setExpandedRow] = useState(null);

  const handleRowClick = (warehouseId, itemId) => {
    const key = `${warehouseId}-${itemId}`;
    if (expandedRow === key) {
      setHeadCells(summaryHeadCells); 
      setExpandedRow(null);
    } else {
      setHeadCells(detailHeadCells); 
      setExpandedRow(key);
    }
  };

  const isSelected = (name) => selected.indexOf(name) !== -1;
  // Avoid a layout jump when reaching the last page with empty rows.
  const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - rows.length) : 0;


  return (
    <div>
      {addView ? (
        <AddWarehouse
          setAddViewStates={setAddViewStates}
          addViewStates={addViewStates}
          rows={rows}
          setRows={setRows}
          setAddView={setAddView}
        />
      ) : editWarehouse.visible ? (
        <EditWarehouse
          rows={rows}
          setRows={setRows}
          setAddView={setAddView}
          editWarehouse={editWarehouse}
          setEditWarehouse={setEditWarehouse}
        />
      ) : (
        <>
          {showFilters && (
            <Box
              component="form"
              sx={{
                "& .MuiTextField-root": { m: 1, width: "25ch" },
              }}
              noValidate
              autoComplete="off"
            >
              <Grid
                container
                spacing={1}
                justifyContent="flex-start"
                alignItems="center"
              >
               
               <Autocomplete
                  multiple
                  id="outlined-select-warehouse"
                  disableClearable
                  open={componentsState.warehouse.dropdownOpen}
                  onOpen={() =>
                    updateComponentState("warehouse", { dropdownOpen: true })
                  }
                  inputValue={componentsState.warehouse.inputValue}
                  onInputChange={(event, newInputValue, reason) => {
                    if (reason === "input") {
                      updateComponentState("warehouse", {
                        inputValue: newInputValue,
                      });
                    }
                  }}
                  options={[
                    ...warehouses.filter((warehouse) =>
                      filterWarehouse.warehouseIdList.includes(
                        warehouse.id,
                      ),
                    ),
                    ...warehouses.filter(
                      (warehouse) =>
                        !filterWarehouse.warehouseIdList.includes(
                          warehouse.id,
                        ),
                    ),
                  ]}
                  getOptionLabel={(option) =>
                    `${option?.id || ""} - ${option?.code || ""} - ${
                      option?.name || ""}`
                  }
                  value={warehouses.filter((warehouse) =>
                    filterWarehouse.warehouseIdList.includes(
                      warehouse.id,
                    ),
                  )}
                  onChange={(event, newValue) => {
                    setFilterWarehouse({
                      ...filterWarehouse,
                      warehouseIdList: newValue
                        .map((warehouse) => warehouse.id),
                    });
                  }}
                  renderOption={(props, option, { selected }) => {
                    return (
                      <li
                        {...props}
                        style={{
                          ...props.style,
                          backgroundColor: selected ? "lightblue" : undefined,
                          fontWeight: selected ? 700 : 400,
                        }}
                      >
                        {`${option?.id || ""} - ${option?.code || ""} - ${option?.name || ""}`}
                      </li>
                    );
                  }}
                  renderInput={(params) => {
                    const label =
                      filterWarehouse?.warehouseIdList.length > 0
                        ? `Warehouses Selected (${filterWarehouse?.warehouseIdList.length})`
                        : "Warehouse";

                    return (
                      <TextField
                        {...params}
                        label={label}
                        type="search"
                        value={componentsState.warehouse.inputValue}
                        onChange={(e) => {
                          updateComponentState("warehouse", {
                            inputValue: e.target.value,
                          });
                        }}
                        onMouseDown={() => retrieveWarehouse()}
                        InputProps={{
                          ...params.InputProps,
                          style: { height: 42 },
                        }}
                        onBlur={() =>
                          updateComponentState("warehouse", {
                            dropdownOpen: false,
                          })
                        }
                      />
                    );
                  }}
                  renderTags={() => null}
                />

               <Autocomplete
        multiple
        id="outlined-select-item"
        disableClearable
        open={componentsState.item.dropdownOpen}
        onOpen={() => updateComponentState("item", { dropdownOpen: true })}
        inputValue={componentsState.item.inputValue}
        onInputChange={(event, newInputValue, reason) => {
          if (reason === "input") {
            updateComponentState("item", { inputValue: newInputValue });
          }
        }}
        options={[
          ...items.filter((item) =>
            filterWarehouse.itemIdList.includes(item.id),
          ),
          ...items.filter(
            (item) => !filterWarehouse.itemIdList.includes(item.id),
          ),
        ]}
        getOptionLabel={(option) =>
          `${option?.id || ""} - ${option?.buyingGrade || ""} - ${
            option?.crop || ""
          }- ${option?.origin || ""}`
        }
        value={items.filter((item) =>
          filterWarehouse.itemIdList.includes(item.id),
        )}
        onChange={(event, newValue) => {
          setFilterWarehouse({
            ...filterWarehouse,
            itemIdList: newValue.map((item) => item.id),
          });
        }}
        renderOption={(props, option, { selected }) => (
          <li
            {...props}
            style={{
              ...props.style,
              backgroundColor: selected ? "lightblue" : undefined,
              fontWeight: selected ? 700 : 400,
            }}
          >
            {`${option?.id || ""} - ${option?.buyingGrade || ""} - ${
              option?.crop || ""
            } - ${option?.origin || ""}`}
          </li>
        )}
        renderInput={(params) => {
          const label =
            filterWarehouse?.itemIdList.length > 0
              ? `Items Selected (${filterWarehouse?.itemIdList.length})`
              : "Item";

          return (
            <TextField
              {...params}
              label={label}
              type="search"
              value={componentsState.item.inputValue}
              onChange={(e) =>
                updateComponentState("item", { inputValue: e.target.value })
              }
              onMouseDown={retrieveItems}
              InputProps={{
                ...params.InputProps,
                style: { height: 42 },
              }}
              onBlur={() =>
                updateComponentState("item", { dropdownOpen: false })
              }
            />
          );
        }}
        renderTags={() => null}
      />
                <TextField
                  id="outlined-number"
                  label="Quantity"
                  type="number"
                  onChange={(e) =>
                    setFilterWarehouse({
                      ...filterWarehouse,
                      shippedAmount: e.target.value,
                    })
                  }
                />
                
                <TextField
                  label="Record Status"
                  type="search"
                  id="outlined-select-record-status"
                  select
                  SelectProps={{
                    multiple: true,
                    value: filterWarehouse.recordStatusList || [],
                    onChange: (e) => {
                      setFilterWarehouse({
                        ...filterWarehouse,
                        recordStatusList: e.target.value,
                      });
                    },
                  }}
                >
                  {recordStatus.map((option) => (
                    <MenuItem key={option.value} value={option.value}>
                      {option.label}
                    </MenuItem>
                  ))}
                </TextField>
              </Grid>
              <br></br>
              <Grid
                container
                spacing={2}
                justifyContent="flex-start"
                alignItems="center"
              >
                <Grid item xs={3} sm={1}>
                  <Button
                    disableElevation
                    variant="contained"
                    fullWidth
                    onClick={() => {
                      retrieveFilteredWarehouseInventoryRecordsApi(filterWarehouse)
                        .then((response) => {
                          setRows(response?.data);
                        })
                        .catch((error) => {
                          console.log(error?.response?.data);
                        });
                    }}
                  >
                    Search
                  </Button>
                </Grid>
                <Grid item xs={3} sm={1}>
                  <Button
                    disableElevation
                    variant="outlined"
                    fullWidth
                    onClick={() => {
                      setFilterWarehouse({
                        id: 0,
                        itemIdList: [],
                        warehouseIdList: [],
                        warehouseList: [],
                        quantity: null,
                        statusList: ["ACTIVE"],
                      });
                    }}
                  >
                    Clear Filters
                  </Button>
                </Grid>
              </Grid>
            </Box>
          )}

          <Box sx={{ width: "100%", mt: 2 }}>
            <Paper sx={{ width: "100%", mb: 2 }}>
              <EnhancedTableToolbar
                numSelected={selected.length}
                showFilters={showFilters}
                setShowFilters={setShowFilters}
                rows={rows}
                setAddView={setAddView}
                selected={selected}
                selectedData={selectedData}
              />
             <TableContainer style={{ maxHeight: 700 }}>
                <Table
                  stickyHeader
                  aria-label="sticky table"
                  style={{ width: "100%", }}
                  sx={{
                    minWidth: 750,
                    '& .MuiTableCell-head': {
                      backgroundColor: '#20315d',
                      color: 'white',
                    },
                  }}
                  aria-labelledby="tableTitle"
                  size={dense ? "small" : "medium"}
                >
                  <EnhancedTableHead
                    numSelected={selected.length}
                    order={order}
                    orderBy={orderBy}
                    onSelectAllClick={handleSelectAllClick}
                    onRequestSort={handleRequestSort}
                    rowCount={Object.keys(groupedRows).length}
                    headCells = {headCells}
                  />
                 <TableBody>
      {Object.values(groupedRows).map((group, index) => {
        const isExpanded = expandedRow === `${group?.warehouseId}-${group?.itemId}`;

        return (
          <React.Fragment key={`${group?.warehouseId}-${group?.itemId}`}>
            {/* Summary Row */}
            <TableRow
              hover
              onClick={() => handleRowClick(group?.warehouseId, group?.itemId)}
              style={index % 2 ? { background: "#f0f0f0" } : { background: "white" }}
            >
               <TableCell padding="checkbox">
                <ExpandMore color="primary" />
              </TableCell>
              <TableCell align="center">{group?.warehouseId}</TableCell>
              <TableCell align="center">{group?.warehouseName}</TableCell>
              <TableCell align="center">{group?.itemId}</TableCell>
              <TableCell align="center">{group?.buyingGrade}</TableCell>
              <TableCell align="center">
                            {group?.totalQtyOnWayIn
                              ? `${new Intl.NumberFormat("de-DE", {
                                  style: "decimal",
                                  minimumFractionDigits: 2,
                                }).format(group?.totalQtyOnWayIn)}`
                              : ""}
              </TableCell>
              <TableCell align="center">
                            {group?.totalQtyOnWayOut
                              ? `${new Intl.NumberFormat("de-DE", {
                                  style: "decimal",
                                  minimumFractionDigits: 2,
                                }).format(group?.totalQtyOnWayOut)}`
                              : ""}
              </TableCell>
              <TableCell align="center">
                            {group?.totalShippedQuantity
                              ? `${new Intl.NumberFormat("de-DE", {
                                  style: "decimal",
                                  minimumFractionDigits: 2,
                                }).format(group?.totalShippedQuantity)}`
                              : ""}
              </TableCell>
              {/*<TableCell align="center">               
              <StorageCostButton rows={Array.isArray(group.details) ? group.details : []} />
              </TableCell>*/}
              <TableCell align="center">
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    gap: 1, 
                  }}
                >
                  <StorageCostButton rows={Array.isArray(group.details) ? group.details : []} />
                
                  <ManualStorageCostButton
                    rows={Array.isArray(group.details) ? group.details : []}
                    onCostCalculated={(cost) => console.log(`Manual Storage Cost: ${cost}`)} 
                  />
                </Box>
               </TableCell>
            </TableRow>
            
            {isExpanded && group.details.map((row, i) => {
                      const isItemSelected = isSelected(row.id);
                      const labelId = `enhanced-table-checkbox-${index}`;

                      return (
              <TableRow 
                   key={row.id} 
                   style={{ backgroundColor: "#e0e0e0" }}
                   hover
                   onClick={(event) => handleClick(event, row.id, row)}
                   role="checkbox"
                   aria-checked={isItemSelected}
                   tabIndex={-1}
                   selected={isItemSelected}
                   sx={{ cursor: "pointer" }}>
                <TableCell padding="checkbox">
                  <Checkbox
                    color="primary"
                    checked={isItemSelected}
                    inputProps={{
                      "aria-labelledby": `enhanced-table-checkbox-${index}`,
                    }}
                  />
                </TableCell>
                <TableCell padding="normal">
                  {/*<Button
                    onClick={(e) => {
                      setEditWarehouse({
                        visible: true,
                        data: row,
                        index: i,
                      });
                      e.preventDefault();
                    }}
                  >
                    Edit
                  </Button>*/}
                </TableCell>
                <TableCell align="center">{row?.id}</TableCell>
                          <TableCell align="center">{row?.type}</TableCell>
                          <TableCell align="center">{row?.shippingInstruction?.id}-{row?.shippingInstruction?.finalShippingInstructionNumber}</TableCell>
                          <TableCell align="center">{row?.shippingInstruction?.shipmentRequest?.referenceShippingInstructionNumber}</TableCell>
                          <TableCell align="center">{row?.shippingInstruction?.shipmentRequest?.shipmentRequestType}</TableCell>
                          <TableCell align="center">{row?.warehouse?.id}</TableCell>
                          <TableCell align="center">{row?.warehouse?.name}</TableCell>
                          <TableCell align="center">{row?.item?.id}</TableCell>
                          <TableCell align="center">{row?.item?.buyingGrade}</TableCell>
                          <TableCell align="center">{row?.item?.origin}</TableCell>
                          <TableCell align="center">{row?.item?.crop}</TableCell>
                          <TableCell align="center">
                            {row?.entryDate
                              ? format(
                                  new Date(row?.entryDate),
                                  "dd/MM/yyyy",
                                )
                              : ""}
                          </TableCell>
                          <TableCell align="center">
                            {row?.exitDate
                              ? format(
                                  new Date(row?.exitDate),
                                  "dd/MM/yyyy",
                                )
                              : ""}
                          </TableCell>
                          <TableCell align="center">
                            {row?.shippingInstruction?.shipmentRequest?.shipmentQuantity !== undefined
                              ? `${new Intl.NumberFormat("de-DE", {
                                  style: "decimal",
                                  minimumFractionDigits: 2,
                                }).format(row?.shippingInstruction?.shipmentRequest?.shipmentQuantity - (row?.shippedAmount ?? 0))} ${row?.shippingInstruction?.shipmentRequest?.shipmentQuantityUnit ?? ""}`
                              : ""}
                          </TableCell>
                          <TableCell align="center">
                          {row?.shippedAmount ? `${new Intl.NumberFormat("de-DE", {
                                  style: "decimal",
                                  minimumFractionDigits: 2,
                                }).format(row.shippedAmount)} ${row.shippedAmountUnit ?? ""}`
                              : ""}
                          </TableCell>
                          <TableCell align="center">{row?.storagePricingBy}</TableCell> 
                          <TableCell align="center">{row?.shippingInstruction?.actualFreightPrice
                              ? `${new Intl.NumberFormat("en-US", {
                                  style: "decimal",
                                  minimumFractionDigits: 2,
                                }).format(row?.shippingInstruction?.actualFreightPrice)} ${row.shippingInstruction.actualFreightCurrency || ""}` 
                              : ""}{" "}
                            </TableCell>
                            <TableCell align="center">{row?.shippingInstruction?.actualInsuranceCost
                              ? `${new Intl.NumberFormat("en-US", {
                                  style: "decimal",
                                  minimumFractionDigits: 2,
                                }).format(row?.shippingInstruction?.actualInsuranceCost)} ${row.shippingInstruction.actualInsuranceCurrency || ""}` 
                              : ""}{" "}
                            </TableCell>
                            <TableCell align="center"></TableCell>
                          <TableCell align="center">{row?.timestamp ? format(new Date(row.timestamp), 'dd/MM/yyyy HH:mm'): ''}</TableCell>
                          <TableCell align="center">{row?.creator}</TableCell>
                          <TableCell align="center">
                            {row?.recordStatus}
                          </TableCell>
                          <TableCell padding="normal">
                            {/*<DetailsButton warehouse={row?.name} itemId={row?.item?.id} />*/}
                          </TableCell>
                          <TableCell padding="normal">
                            <HistoryButton warehouse={row?.name} itemId={row?.item?.id} />
                          </TableCell>
                        </TableRow>
            )})}
          </React.Fragment>
        );
      })}
      {/* Empty rows to keep the table layout consistent */}
      {emptyRows > 0 && (
        <TableRow style={{ height: (dense ? 33 : 53) * emptyRows }}>
          <TableCell colSpan={6} />
        </TableRow>
      )}
    </TableBody>
                </Table>
              </TableContainer>
              <div style={{ marginTop: '20px', marginLeft: '20px' }}>
  <div style={{ display: 'flex', flexDirection: 'row', gap: '20px', alignItems: 'center' }}>
    <Typography variant="h6">
                Total Quantity: {resultShipped ? `${new Intl.NumberFormat("de-DE", {
                                  style: "decimal",
                                  minimumFractionDigits: 2,
                                }).format(resultShipped)}`
                              : ""}
              </Typography>
  </div>
</div>
              <TablePagination
                rowsPerPageOptions={[5, 10, 25, 50]}
                component="div"
                count={rows.length}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
              />
            </Paper>
            <FormControlLabel
              control={<Switch checked={dense} onChange={handleChangeDense} />}
              label="Dense padding"
            />
          </Box>
        </>
      )}
    </div>
  );
}

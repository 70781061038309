import { ReloadOutlined } from "@ant-design/icons";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import {
  Autocomplete,
  Box,
  Button,
  ButtonGroup,
  Chip,
  ClickAwayListener,
  Collapse,
  Grid,
  Grow,
  MenuItem,
  MenuList,
  Paper,
  Popper,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from "@mui/material";
import { format, parseISO } from "date-fns";
import React, { useEffect, useState } from "react";
import toast from "react-hot-toast";
import {
  createCostApi,
  eurUsdExchangeApi,
  retrieveAllCostsApi,
  retrieveCostByIdApi,
  updateCostApi,
} from "../../../components/api/CostApiService";
import {
  createItemApi,
  retrieveAllItemsApi,
  retrieveItemByIdApi,
  updateItemApi,
} from "../../../components/api/ItemApiService";
import {
  retrieveAllOffersApi,
  retrieveFilteredOffersApi,
} from "../../../components/api/OfferApiService";
import { retrieveQuantityByItemIdApi } from "../../../components/api/QuantityApiService";
import {
  createSampleApi,
  retrievePurposeFilteredSamplesApi,
  retrieveSampleByIdApi,
  updateSampleApi,
} from "../../../components/api/SampleApiService";
import {
  retrieveAllStakeholdersApi,
  retrieveStakeholderByIdApi,
} from "../../../components/api/StakeholderApiService";
import {
  color,
  companies,
  currencies,
  incoterms,
  leafForm,
  offerStatus,
  origin,
  packagingTypes,
  paymentTerm,
  portsOfDischarge,
  portsOfLoading,
  recordStatus,
  samplePurposes,
  sampleStatus,
  status,
  statusType,
  type,
  unit,
} from "../../../utils/menu-items";

const options = ["New", "Edit"];

const AddCustomerOfferView = (props) => {
  const {
    key,
    index,
    addOfferArray,
    setAddOfferArray,
    offer,
    newOfferGroupNumber,
  } = props;
  const [customer, setCustomer] = useState([]);
  const [item, setItem] = useState([]);

  useEffect(() => {
    setItem({
      ...item,
      moisture: 12.5,
    });
    setCost({
      ...cost,
      basePriceCurrency: "USD",
      freightCurrency: "USD",
      insuranceCurrency: "USD",
      storageCostCurrency: "USD",
      financeCostCurrency: "USD",
      othersCurrency: "USD",
      firstCommisionCurrency: "USD",
      secondCommisionCurrency: "USD",
      thirdCommisionCurrency: "USD",
      profitCurrency: "USD",
    });
    let dummyArr = [...addOfferArray];
    dummyArr[index] = {
      ...dummyArr[index],
      referenceVendorOfferId: addOfferArray[index]?.referenceVendorOfferId,
      offerYear:
        addOfferArray[0]?.offerYear || new Date().getFullYear().toString(),
      orderGroupNumber: addOfferArray[0]?.orderGroupNumber,
      company: addOfferArray[0]?.company,
      paymentTerm: addOfferArray[0]?.paymentTerm,
      incoterm: addOfferArray[0]?.incoterm,
      offerLink: addOfferArray[0]?.offerLink,
      remark: addOfferArray[0]?.remark,
      offerDate: addOfferArray[0]?.offerDate,
      validityDate: addOfferArray[0]?.validityDate,
      stakeholderId: addOfferArray[0]?.stakeholderId,
      stakeholder: retrieveCustomerById(addOfferArray[0]?.stakeholderId || 0),
    };
    setAddOfferArray([...dummyArr]);
  }, []);

  const portOfDischargeIncoterms = [
    "C&F",
    "CFR",
    "CIF",
    "CIP",
    "CPT",
    "DAP",
    "DDP",
    "DPU",
  ];
  const portOfLoadingIncoterms = ["EXW", "FAS", "FCA", "FOB"];
  const [items, setItems] = useState([]);
  const [customers, setCustomers] = useState([]);
  const [costs, setCosts] = useState([]);
  const [filteredSamples, setFilteredSamples] = useState([]);
  const currentYear = new Date().getFullYear().toString();
  const [selectedCost, setSelectedCost] = useState(null);
  const [selectedPaymentTerm, setSelectedPaymentTerm] = useState(
    addOfferArray[0]?.paymentTerm || "",
  );
  const [selectedPortOfDischarge, setSelectedPortOfDischarge] = useState(
    addOfferArray[0]?.portOfDischarge || "",
  );
  const [selectedPortOfLoading, setSelectedPortOfLoading] = useState(
    addOfferArray[0]?.portOfLoading || "",
  );
  const [selectedItem, setSelectedItem] = useState(
    addOfferArray[index]?.item || null,
  );
  const [selectedCustomer, setSelectedCustomer] = useState(
    addOfferArray[index]?.stakeholder || "",
  );
  const [selectedFirstSample, setSelectedFirstSample] = useState(null);
  const [selectedSecondSample, setSelectedSecondSample] = useState(null);

  const [isNewCostOpen, setIsNewCostOpen] = useState(false);
  const [isEditCostOpen, setIsEditCostOpen] = useState(false);
  const [cost, setCost] = useState([]);

  const [isNewItemOpen, setIsNewItemOpen] = useState(false);
  const [isEditItemOpen, setIsEditItemOpen] = useState(false);

  const [selectedOrigin, setSelectedOrigin] = useState("");

  const [selectedSalesOrigin, setSelectedSalesOrigin] = useState(
    addOfferArray[index]?.salesOrigin || null,
  );

  const [quantity, setQuantity] = useState([]);

  const [isNewSampleOpen, setIsNewSampleOpen] = useState(false);
  const [isEditSampleOpen, setIsEditSampleOpen] = useState(false);
  const [sample, setSample] = useState([]);
  const [stakeholders, setStakeholders] = useState([]);
  const [offers, setOffers] = useState([]);
  const [selectedOffer, setSelectedOffer] = useState(
    addOfferArray[index]?.selectedOffer || null,
  );

  const [open, setOpen] = useState(false);
  const [open2, setOpen2] = useState(false);
  const [open3, setOpen3] = useState(false);
  const [open4, setOpen4] = useState(false);
  const anchorRef = React.useRef(null);
  const anchorRef2 = React.useRef(null);
  const anchorRef3 = React.useRef(null);
  const anchorRef4 = React.useRef(null);
  const [selectedIndex, setSelectedIndex] = useState(0);
  const [selectedIndex2, setSelectedIndex2] = useState(0);
  const [selectedIndex3, setSelectedIndex3] = useState(0);
  const [selectedIndex4, setSelectedIndex4] = useState(0);

  const [isHistoryVisible, setIsHistoryVisible] = useState(false);
  const [filteredOffers, setFilteredOffers] = useState(null);

  const costFieldMap = {
    basePriceCurrency: "basePrice",
    freightCurrency: "freightCost",
    insuranceCurrency: "insuranceCost",
    storageCostCurrency: "storageCost",
    financeCostCurrency: "financeCost",
    othersCurrency: "others",
    firstCommisionCurrency: "firstCommision",
    secondCommisionCurrency: "secondCommision",
    thirdCommisionCurrency: "thirdCommision",
    profitCurrency: "profit",
  };

  const handleCurrencyExchange = async (field, amount) => {
    const costField = costFieldMap[field];
    const currentCurrency = cost[field];
    const targetCurrency = currentCurrency === "EUR" ? "USD" : "EUR";

    try {
      const response = await eurUsdExchangeApi(currentCurrency, amount);
      const updatedCost = response?.data;

      setCost((prevCost) => ({
        ...prevCost,
        [costField]: updatedCost,
        [field]: targetCurrency,
      }));
    } catch (error) {
      setCost((prevCost) => ({
        ...prevCost,
        [costField]: prevCost[costField],
        [field]: prevCost[field],
      }));
      console.error(error.response.data);
    }
  };

  const handleHistoryClick = () => {
    const today = new Date().toISOString().split("T")[0];
    const filterCustomerOffers = {
      itemIdList: [selectedItem?.id],
      offerType: "CUSTOMER",
      validityDate: today,
      recordStatusList: ["ACTIVE"],
    };

    const filterVendorOffers = {
      itemIdList: [selectedItem?.id],
      offerType: "VENDOR",
      validityDate: today,
      recordStatusList: ["ACTIVE"],
    };

    const fetchCustomerOffers = retrieveFilteredOffersApi(filterCustomerOffers);

    const fetchVendorOffers = retrieveFilteredOffersApi(filterVendorOffers);

    Promise.all([fetchCustomerOffers, fetchVendorOffers])
      .then(([customerResponse, vendorResponse]) => {
        const customerOffers = customerResponse?.data || [];
        const vendorOffers = vendorResponse?.data || [];
        const combinedOffers = [...customerOffers, ...vendorOffers];

        setFilteredOffers(combinedOffers);
        return combinedOffers;
      })
      .catch((error) => console.log(error.response?.data));
    setIsHistoryVisible((prevVisibility) => !prevVisibility);
  };

  const handleClick = () => {
    setItem([]);
    if (selectedIndex === 0) {
      setIsNewItemOpen(true);
      setIsEditItemOpen(false);
    } else {
      setSelectedOrigin("");
      setIsEditItemOpen(true);
      setIsNewItemOpen(false);
      retrieveItemById(addOfferArray[index]?.itemId);
    }
  };
  const handleClick2 = () => {
    setCost([]);
    if (selectedIndex2 === 0) {
      setCost({
        ...cost,
        basePrice: addOfferArray[index]?.buyingPrice,
        basePriceCurrency: addOfferArray[index]?.buyingPriceCurrency,
      });
      setIsEditCostOpen(false);
      setIsNewCostOpen(true);
    } else {
      setIsNewCostOpen(false);
      setIsEditCostOpen(true);
      retrieveCostById(addOfferArray[index]?.estimatedCostId);
    }
  };
  const handleClick3 = () => {
    if (selectedIndex3 === 0) {
      setIsNewSampleOpen(true);
      setIsEditSampleOpen(false);
      setSample([]);
    } else {
      setSample([]);
      setIsEditSampleOpen(true);
      setIsNewSampleOpen(false);
      retrieveSampleById(addOfferArray[index]?.firstSampleId);
    }
  };
  const handleClick4 = () => {
    if (selectedIndex4 === 0) {
      setIsNewSampleOpen(true);
      setIsEditSampleOpen(false);
      setSample([]);
    } else {
      setSample([]);
      setIsEditSampleOpen(true);
      setIsNewSampleOpen(false);
      retrieveSampleById(addOfferArray[index]?.secondSampleId);
    }
  };

  const handleMenuItemClick = (event, index) => {
    setSelectedIndex(index);
    setOpen(false);
  };
  const handleMenuItemClick2 = (event, index) => {
    setSelectedIndex2(index);
    setOpen2(false);
  };
  const handleMenuItemClick3 = (event, index) => {
    setSelectedIndex3(index);
    setOpen3(false);
  };
  const handleMenuItemClick4 = (event, index) => {
    setSelectedIndex4(index);
    setOpen4(false);
  };

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };
  const handleToggle2 = () => {
    setOpen2((prevOpen) => !prevOpen);
  };
  const handleToggle3 = () => {
    setOpen3((prevOpen) => !prevOpen);
  };
  const handleToggle4 = () => {
    setOpen4((prevOpen) => !prevOpen);
  };

  const handleClose = (event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }

    setOpen(false);
  };
  const handleClose2 = (event) => {
    if (anchorRef2.current && anchorRef2.current.contains(event.target)) {
      return;
    }

    setOpen2(false);
  };
  const handleClose3 = (event) => {
    if (anchorRef3.current && anchorRef3.current.contains(event.target)) {
      return;
    }

    setOpen3(false);
  };
  const handleClose4 = (event) => {
    if (anchorRef4.current && anchorRef4.current.contains(event.target)) {
      return;
    }

    setOpen4(false);
  };

  const calculateTotalCost = () => {
    const totalCost =
      (parseFloat(cost?.basePrice) || 0) +
      (parseFloat(cost?.freightCost) || 0) +
      (parseFloat(cost?.insuranceCost) || 0) +
      (parseFloat(cost?.storageCost) || 0) +
      (parseFloat(cost?.financeCost) || 0) +
      (parseFloat(cost?.others) || 0) +
      (parseFloat(cost?.firstCommision) || 0) +
      (parseFloat(cost?.secondCommision) || 0) +
      (parseFloat(cost?.thirdCommision) || 0);
    setCost({ ...cost, totalCost });
  };

  const calculateTotalCostWithProfit = () => {
    const totalOfferCost =
      (parseFloat(cost?.basePrice) || 0) +
      (parseFloat(cost?.freightCost) || 0) +
      (parseFloat(cost?.insuranceCost) || 0) +
      (parseFloat(cost?.storageCost) || 0) +
      (parseFloat(cost?.financeCost) || 0) +
      (parseFloat(cost?.others) || 0) +
      (parseFloat(cost?.firstCommision) || 0) +
      (parseFloat(cost?.secondCommision) || 0) +
      (parseFloat(cost?.thirdCommision) || 0) +
      (parseFloat(cost?.profit) || 0);
    setCost({ ...cost, totalOfferCost });
  };

  function retrieveSampleById(id) {
    retrieveSampleByIdApi(id)
      .then((response) => {
        setSample(response?.data);
      })
      .catch((error) => console.log(error.response?.data));
  }

  function retrieveStakeholders() {
    Promise.all([
      retrieveAllStakeholdersApi("VENDOR"),
      retrieveAllStakeholdersApi("CUSTOMER"),
    ])
      .then(([vendorResponse, customerResponse]) => {
        setStakeholders([...vendorResponse?.data, ...customerResponse?.data]);
      })
      .catch((error) => console.log(error.response?.data));
  }

  function retrieveItemById(id) {
    retrieveItemByIdApi(id)
      .then((response) => {
        setItem(response?.data);
      })
      .catch((error) => console.log(error.response?.data));
  }

  function retrieveItems() {
    retrieveAllItemsApi()
      .then((response) => {
        setItems(response?.data);
      })
      .catch((error) => console.log(error.response?.data));
  }
  function retrieveCostById(id) {
    retrieveCostByIdApi(id)
      .then((response) => {
        setCost(response?.data);
      })
      .catch((error) => console.log(error.response?.data));
  }
  function retrieveCustomers() {
    retrieveAllStakeholdersApi("CUSTOMER")
      .then((response) => {
        setCustomers(response?.data);
      })
      .catch((error) => console.log(error.response?.data));
  }
  function retrieveVendorOffers() {
    retrieveAllOffersApi("VENDOR")
      .then((response) => {
        setOffers(response?.data);
      })
      .catch((error) => console.log(error.response?.data));
  }

  function retrieveCustomerById(stakeholderId) {
    retrieveStakeholderByIdApi(stakeholderId)
      .then((response) => {
        setCustomer(response?.data);
        return response?.data;
      })
      .catch((error) => console.log(error.response?.data));
  }
  function retrieveCosts() {
    //localhost:3000/register
    retrieveAllCostsApi()
      .then((response) => {
        setCosts(response?.data);
      })
      .catch((error) => console.log(error.response?.data));
  }

  function retrieveQuantity(itemId) {
    retrieveQuantityByItemIdApi(itemId)
      .then((response) => {
        setQuantity(response?.data);
      })
      .catch((error) => console.log(error.response?.data));
  }

  function retrieveFilteredSamples(samplePurpose) {
    retrievePurposeFilteredSamplesApi(samplePurpose)
      .then((response) => {
        setFilteredSamples(response?.data);
      })
      .catch((error) => console.log(error.response?.data));
  }

  const handleShowHide = () => {
    let dummyArr = addOfferArray;
    dummyArr[index].show = !offer.show;
    setAddOfferArray([...dummyArr]);
  };

  const handleDelete = () => {
    let dummyArr = addOfferArray;
    dummyArr.splice(index, 1);
    setAddOfferArray([...dummyArr]);
  };

  return (
    <>
      <Grid
        container
        item
        xs={12}
        display={"flex"}
        justifyContent={"end"}
        gap={1}
      >
        <Button
          variant="outlined"
          size="small"
          sx={{ minWidth: "80px" }}
          onClick={() => handleShowHide()}
        >
          {offer.show ? "Hide" : "Show"}
        </Button>
        <Button
          variant="contained"
          size="small"
          sx={{ minWidth: "80px" }}
          onClick={() => handleDelete()}
        >
          Delete
        </Button>
      </Grid>
      <Collapse in={offer.show} key={key}>
        <Grid container xs={12} spacing="8px">
          <Grid item xs={2} sm={1}>
            <Typography variant="body2">Offer Year</Typography>
            <TextField
              variant="outlined"
              size="small"
              fullWidth
              defaultValue={addOfferArray[0]?.offerYear || currentYear}
              onChange={(e) => {
                let dummyArr = addOfferArray;
                dummyArr[index] = {
                  ...dummyArr[index],
                  offerYear: e.target.value,
                };
                setAddOfferArray([...dummyArr]);
              }}
            />
          </Grid>
          <Grid item xs={10} sm={1}>
            <Typography variant="body2">Offer Number</Typography>
            <TextField
              variant="outlined"
              size="small"
              fullWidth
              defaultValue={
                addOfferArray[0]?.offerGroupNumber || newOfferGroupNumber
              }
              onChange={(e) => {
                let dummyArr = addOfferArray;
                dummyArr[index] = {
                  ...dummyArr[index],
                  offerGroupNumber: e.target.value,
                };
                setAddOfferArray([...dummyArr]);
              }}
            />
          </Grid>

          <Grid item xs={12} sm={2}>
            <Typography variant="body2">Reference Vendor Offer</Typography>
            <Autocomplete
              options={offers}
              getOptionLabel={(option) => {
                const offerDate = option?.offerDate
                  ? format(parseISO(option.offerDate), "dd-MM-yyyy")
                  : "";
                return `Id: ${option?.id || ""}, Item: ${
                  option?.item?.id || ""
                } - ${option?.item?.buyingGrade || ""}, Date: ${offerDate}`;
              }}
              value={selectedOffer}
              onChange={(event, newValue) => {
                setSelectedOffer(newValue);
                setSelectedItem(newValue?.item);
                setSelectedSalesOrigin(newValue?.item?.origin);
                let dummyArr = addOfferArray;
                dummyArr[index] = {
                  ...dummyArr[index],
                  referenceVendorOfferId: newValue ? newValue.id : null,
                  itemId: newValue.item ? newValue.item.id : null,
                  itemStorage: newValue?.item?.storage || "",
                  salesGrade: newValue?.item?.buyingGrade || "",
                  salesOrigin: newValue?.item?.origin || "",
                  salesCrop: newValue?.item?.crop || "",
                  salesNicotine: newValue?.item?.nicotine || "",
                  salesSugar: newValue?.item?.sugar || "",
                  buyingPrice: newValue ? newValue.finalOfferPrice : null,
                };
                setAddOfferArray([...dummyArr]);
              }}
              onOpen={retrieveVendorOffers}
              isOptionEqualToValue={(option, value) => option.id === value.id}
              renderInput={(params) => (
                <TextField
                  {...params}
                  variant="outlined"
                  size="small"
                  fullWidth
                />
              )}
              renderTags={() => null}
            />
            {selectedOffer && (
              <Box mt={2}>
                <Chip
                  label={`Id: ${selectedOffer?.id || ""}`}
                  onDelete={() => setSelectedOffer(null)}
                  style={{ marginBottom: "5px" }}
                />
                <br></br>
                <Chip
                  label={`Item: ${selectedOffer?.item?.id || ""} - ${
                    selectedOffer?.item?.buyingGrade || ""
                  }`}
                  style={{ marginBottom: "5px" }}
                />
                <br></br>
                <Chip
                  label={`Date: ${
                    selectedOffer?.offerDate
                      ? format(parseISO(selectedOffer.offerDate), "dd-MM-yyyy")
                      : ""
                  }`}
                  style={{ marginBottom: "5px" }}
                />
                <br></br>
                <Chip
                  label={`Final Price: ${selectedOffer?.finalOfferPrice || ""}`}
                  style={{ marginBottom: "5px" }}
                />
                <br></br>
              </Box>
            )}
          </Grid>

          <Grid item xs={12} sm={2}>
            <Typography variant="body2">Item</Typography>
            <Grid container xs={12} spacing="8px">
              <Grid item xs={12} sm={8}>
                <Autocomplete
                  options={items}
                  getOptionLabel={(option) =>
                    `${option?.id || ""} - Buying Grade: ${
                      option?.buyingGrade || ""
                    } - ${option?.origin || ""} - ${option?.crop || ""}`
                  }
                  value={selectedItem || addOfferArray[index]?.item}
                  onChange={(event, newValue) => {
                    setSelectedItem(newValue);
                    let dummyArr = addOfferArray;
                    dummyArr[index] = {
                      ...dummyArr[index],
                      itemId: newValue ? newValue.id : null,
                      itemStorage: newValue?.storage || "",
                      salesGrade: newValue?.buyingGrade || "",
                      salesOrigin: newValue?.origin || "",
                      salesCrop: newValue?.crop || "",
                      salesNicotine: newValue?.nicotine || "",
                      salesSugar: newValue?.sugar || "",
                    };
                    setAddOfferArray([...dummyArr]);
                    retrieveQuantity(newValue?.id);
                  }}
                  onOpen={retrieveItems}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      variant="outlined"
                      size="small"
                      fullWidth
                    />
                  )}
                  renderTags={() => null}
                />
                {selectedItem && (
                  <Box mt={2}>
                    <Chip
                      label={`ID: ${selectedItem?.id || ""}`}
                      style={{ marginBottom: "5px" }}
                      onDelete={() => setSelectedItem(null)}
                    />
                    <br></br>
                    <Chip
                      label={`Origin: ${selectedItem?.origin || ""}`}
                      style={{ marginBottom: "5px" }}
                    />
                    <br></br>
                    <Chip
                      label={`Type: ${selectedItem?.type || ""}`}
                      style={{ marginBottom: "5px" }}
                    />
                    <br></br>
                    <Chip
                      label={`Buying Grade: ${selectedItem?.buyingGrade || ""}`}
                      style={{ marginBottom: "5px" }}
                    />
                    <br></br>
                    <Chip
                      label={`Crop: ${selectedItem?.crop || ""}`}
                      style={{ marginBottom: "5px" }}
                    />
                    <br></br>
                    <Chip
                      label={`Nicotine: ${selectedItem?.nicotine || ""}`}
                      style={{ marginBottom: "5px" }}
                    />
                    <br></br>
                    <Chip
                      label={`Sugar: ${selectedItem?.sugar || ""}`}
                      style={{ marginBottom: "5px" }}
                    />
                    <br></br>
                    <Chip
                      label={`Current Location: ${selectedItem?.storage || ""}`}
                      style={{ marginBottom: "5px" }}
                    />
                    <br></br>
                    <Chip
                      label={`Quantity: ${
                        quantity?.quantityRemainingInNetKg || ""
                      } KG`}
                      style={{ marginBottom: "5px" }}
                    />
                    <br></br>
                  </Box>
                )}
              </Grid>
              <Grid item xs={12} sm={4}>
                <ButtonGroup
                  variant="outlined"
                  ref={anchorRef}
                  aria-label="Button group with a nested menu"
                >
                  <Button onClick={handleClick}>
                    {options[selectedIndex]}
                  </Button>
                  <Button
                    size="small"
                    aria-controls={open ? "split-button-menu" : undefined}
                    aria-expanded={open ? "true" : undefined}
                    aria-label="select merge strategy"
                    aria-haspopup="menu"
                    onClick={handleToggle}
                    sx={{
                      width: "20px !important",
                      minWidth: "0px !important",
                    }}
                  >
                    <ArrowDropDownIcon />
                  </Button>
                </ButtonGroup>
                <Popper
                  sx={{
                    zIndex: 1,
                  }}
                  open={open}
                  anchorEl={anchorRef.current}
                  role={undefined}
                  transition
                  disablePortal
                >
                  {({ TransitionProps, placement }) => (
                    <Grow
                      {...TransitionProps}
                      style={{
                        transformOrigin:
                          placement === "bottom"
                            ? "center top"
                            : "center bottom",
                      }}
                    >
                      <Paper>
                        <ClickAwayListener onClickAway={handleClose}>
                          <MenuList id="split-button-menu" autoFocusItem>
                            {options.map((option, index) => (
                              <MenuItem
                                key={option}
                                disabled={index === 2}
                                selected={index === selectedIndex}
                                onClick={(event) =>
                                  handleMenuItemClick(event, index)
                                }
                              >
                                {option}
                              </MenuItem>
                            ))}
                          </MenuList>
                        </ClickAwayListener>
                      </Paper>
                    </Grow>
                  )}
                </Popper>
              </Grid>
            </Grid>
          </Grid>

          <Grid item xs={12} sm={2}>
            <Typography variant="body2">Item Sales Grade</Typography>
            <TextField
              variant="outlined"
              size="small"
              value={addOfferArray[index]?.salesGrade || ""}
              fullWidth
              onChange={(e) => {
                let dummyArr = addOfferArray;
                dummyArr[index] = {
                  ...dummyArr[index],
                  salesGrade: e.target.value,
                };
                setAddOfferArray([...dummyArr]);
              }}
            />
          </Grid>

          <Grid item xs={12} sm={2}>
            <Typography variant="body2"> Item Sales Origin</Typography>
            <Autocomplete
              options={origin}
              label={selectedSalesOrigin || ""}
              value={selectedSalesOrigin || ""}
              onChange={(event, newValue) => {
                const newTerm = newValue ? newValue.value : "";
                setSelectedSalesOrigin(newTerm);
                let dummyArr = addOfferArray;
                dummyArr[index] = {
                  ...dummyArr[index],
                  salesOrigin: newTerm,
                };
                setAddOfferArray([...dummyArr]);
              }}
              isOptionEqualToValue={(option, value) =>
                option.value === value.value
              }
              renderInput={(params) => (
                <TextField
                  {...params}
                  variant="outlined"
                  size="small"
                  label={item?.origin}
                  fullWidth
                />
              )}
              renderTags={() => null}
            />
            {selectedSalesOrigin && (
              <Box mt={2}>
                <Chip
                  label={
                    origin.find((term) => term.value === selectedSalesOrigin)
                      ?.label || ""
                  }
                  onDelete={() => setSelectedSalesOrigin("")}
                />
              </Box>
            )}
          </Grid>

          <Grid item xs={12} sm={2}>
            <Typography variant="body2">Item Sales Crop</Typography>
            <TextField
              variant="outlined"
              size="small"
              type="number"
              value={addOfferArray[index]?.salesCrop || ""}
              fullWidth
              onChange={(e) => {
                let dummyArr = addOfferArray;
                dummyArr[index] = {
                  ...dummyArr[index],
                  salesCrop: e.target.value,
                };
                setAddOfferArray([...dummyArr]);
              }}
            />
          </Grid>

          <Grid item xs={12} sm={2}>
            <Typography variant="body2">Item Sales Nicotine</Typography>
            <TextField
              variant="outlined"
              size="small"
              type="number"
              value={addOfferArray[index]?.salesNicotine || ""}
              fullWidth
              onChange={(e) => {
                let dummyArr = addOfferArray;
                dummyArr[index] = {
                  ...dummyArr[index],
                  salesNicotine: e.target.value,
                };
                setAddOfferArray([...dummyArr]);
              }}
            />
          </Grid>

          <Grid item xs={12} sm={2}>
            <Typography variant="body2">Item Sales Sugar</Typography>
            <TextField
              variant="outlined"
              size="small"
              type="number"
              value={addOfferArray[index]?.salesSugar || ""}
              fullWidth
              onChange={(e) => {
                let dummyArr = addOfferArray;
                dummyArr[index] = {
                  ...dummyArr[index],
                  salesSugar: e.target.value,
                };
                setAddOfferArray([...dummyArr]);
              }}
            />
          </Grid>

          <Grid item xs={12} sm={2}>
            <Typography variant="body2">Item Current Location</Typography>
            <TextField
              variant="outlined"
              size="small"
              value={addOfferArray[index]?.itemStorage || ""}
              fullWidth
              onChange={(e) => {
                let dummyArr = addOfferArray;
                dummyArr[index] = {
                  ...dummyArr[index],
                  itemStorage: e.target.value,
                };
                setAddOfferArray([...dummyArr]);
              }}
            />
          </Grid>

          <Grid item xs={12} sm={2}>
            <Typography variant="body2">Company</Typography>
            <TextField
              id="outlined-select-currency"
              select
              size="small"
              fullWidth
              defaultValue={addOfferArray[index]?.company}
              onChange={(e) => {
                let dummyArr = addOfferArray;
                dummyArr[index] = {
                  ...dummyArr[index],
                  company: e.target.value,
                };
                setAddOfferArray([...dummyArr]);
              }}
            >
              {companies.map((option) => (
                <MenuItem key={option.value} value={option.value}>
                  {option.label}
                </MenuItem>
              ))}
            </TextField>
          </Grid>

          <Grid item xs={12} sm={2}>
            <Typography variant="body2">SG Approval Status</Typography>
            <TextField
              variant="outlined"
              size="small"
              fullWidth
              select
              onChange={(e) => {
                let dummyArr = addOfferArray;
                dummyArr[index] = {
                  ...dummyArr[index],
                  approvalStatus: e.target.value,
                };
                setAddOfferArray([...dummyArr]);
              }}
            >
              {status.map((option) => (
                <MenuItem key={option.value} value={option.value}>
                  {option.label}
                </MenuItem>
              ))}
            </TextField>
          </Grid>

          <Grid item xs={12} sm={2}>
            <Typography variant="body2">Status</Typography>
            <TextField
              variant="outlined"
              size="small"
              fullWidth
              select
              onChange={(e) => {
                let dummyArr = addOfferArray;

                const newStatus = e.target.value;
                let newStatusDeadline = dummyArr[index]?.statusDeadline;
                if (newStatus !== "First Refusal" || newStatus !== "Optioned") {
                  newStatusDeadline = null;
                }
                dummyArr[index] = {
                  ...dummyArr[index],
                  status: newStatus,
                  statusDeadline: newStatusDeadline,
                };
                setAddOfferArray([...dummyArr]);
              }}
            >
              {offerStatus.map((option) => (
                <MenuItem key={option.value} value={option.value}>
                  {option.label}
                </MenuItem>
              ))}
            </TextField>
          </Grid>
          <Grid item xs={12} sm={2}>
            <Typography variant="body2">Status Deadline</Typography>
            <div
              style={{
                borderColor:
                  (addOfferArray[index]?.statusDeadline === null ||
                    addOfferArray[index]?.statusDeadline === "") &&
                  (addOfferArray[index]?.status === "First Refusal" ||
                    addOfferArray[index]?.status === "Optioned")
                    ? "red"
                    : "inherit",
                borderWidth:
                  (addOfferArray[index]?.statusDeadline === null ||
                    addOfferArray[index]?.statusDeadline === "") &&
                  (addOfferArray[index]?.status === "First Refusal" ||
                    addOfferArray[index]?.status === "Optioned")
                    ? "1px"
                    : "inherit",
                borderStyle:
                  (addOfferArray[index]?.statusDeadline === null ||
                    addOfferArray[index]?.statusDeadline === "") &&
                  (addOfferArray[index]?.status === "First Refusal" ||
                    addOfferArray[index]?.status === "Optioned")
                    ? "solid"
                    : "inherit",
              }}
            >
              <TextField
                size="small"
                fullWidth
                disabled={
                  addOfferArray[index]?.status !== "First Refusal" &&
                  addOfferArray[index]?.status !== "Optioned"
                }
                variant="outlined"
                id="date"
                type="date"
                inputProps={{ min: "1999-01-01", max: "2999-01-01" }}
                InputLabelProps={{
                  shrink: true,
                }}
                onChange={(e) => {
                  let dummyArr = addOfferArray;
                  dummyArr[index] = {
                    ...dummyArr[index],
                    statusDeadline: e.target.value,
                  };
                  setAddOfferArray([...dummyArr]);
                }}
              />
            </div>
          </Grid>
          <Grid item xs={12} sm={2}>
            <Typography variant="body2">Customer</Typography>
            <Autocomplete
              options={customers}
              getOptionLabel={(option) =>
                `${option?.id || ""} - ${option?.name || ""}`
              }
              value={selectedCustomer || customer}
              onChange={(event, newValue) => {
                setSelectedCustomer(newValue);

                let dummyArr = addOfferArray;
                dummyArr[index] = {
                  ...dummyArr[index],
                  stakeholderId: newValue ? newValue.id : null,
                };
                setAddOfferArray([...dummyArr]);
              }}
              onOpen={retrieveCustomers}
              isOptionEqualToValue={(option, value) => option.id === value.id}
              renderInput={(params) => (
                <TextField
                  {...params}
                  variant="outlined"
                  size="small"
                  fullWidth
                />
              )}
              renderTags={() => null}
            />
            {selectedCustomer && (
              <Box mt={2}>
                <Chip
                  label={`${selectedCustomer?.id || ""} - ${
                    selectedCustomer?.name || ""
                  }`}
                  onDelete={() => setSelectedCustomer(null)}
                />
              </Box>
            )}
          </Grid>

          <Grid item xs={12} sm={2}>
            <Typography variant="body2">Enter Buying Price Per Kg</Typography>
            <TextField
              type="number"
              variant="outlined"
              size="small"
              fullWidth
              value={addOfferArray[index]?.buyingPrice}
              onChange={(e) => {
                let dummyArr = addOfferArray;
                dummyArr[index] = {
                  ...dummyArr[index],
                  buyingPrice: e.target.value,
                };
                setCost({ ...cost, basePrice: e.target.value });
                setAddOfferArray([...dummyArr]);
              }}
            />
          </Grid>
          <Grid item xs={12} sm={2}>
            <Typography variant="body2">
              Buying Price Per Kg Currency
            </Typography>
            <TextField
              id="outlined-select-buying-price-currency"
              select
              size="small"
              fullWidth
              value={addOfferArray[index]?.buyingPriceCurrency}
              onChange={(e) => {
                let dummyArr = addOfferArray;
                dummyArr[index] = {
                  ...dummyArr[index],
                  buyingPriceCurrency: e.target.value,
                };
                setCost({ ...cost, basePriceCurrency: e.target.value });
                setAddOfferArray([...dummyArr]);
              }}
            >
              {currencies.map((option) => (
                <MenuItem key={option.value} value={option.value}>
                  {option.label}
                </MenuItem>
              ))}
            </TextField>
          </Grid>

          <Grid item xs={12} sm={2}>
            <Typography variant="body2"> Cost</Typography>
            <Grid container xs={12} spacing="8px">
              <Grid item xs={12} sm={8}>
                <Autocomplete
                  options={costs}
                  getOptionLabel={(option) =>
                    `Id: ${option?.id}, Remark: ${
                      option?.remark || ""
                    }, Total Unit Cost: ${option?.totalCost || ""}`
                  }
                  value={selectedCost}
                  onChange={(event, newValue) => {
                    setSelectedCost(newValue);

                    let dummyArr = addOfferArray;
                    dummyArr[index] = {
                      ...dummyArr[index],
                      estimatedCostId: newValue ? newValue.id : null,
                      firstOfferPrice: newValue
                        ? newValue.totalOfferCost
                        : null,
                      finalOfferPrice: newValue
                        ? newValue.totalOfferCost
                        : null,
                    };
                    setAddOfferArray([...dummyArr]);
                  }}
                  onOpen={retrieveCosts}
                  isOptionEqualToValue={(option, value) =>
                    option.id === value.id
                  }
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      variant="outlined"
                      size="small"
                      fullWidth
                    />
                  )}
                  renderTags={() => null}
                />
                {selectedCost && (
                  <Box mt={2}>
                    <Chip
                      label={`Id: ${selectedCost.id}, Remark: ${selectedCost.remark}, Total Unit Cost: ${selectedCost.totalCost}`}
                      onDelete={() => setSelectedCost(null)}
                    />
                  </Box>
                )}
              </Grid>

              <Grid item xs={12} sm={4}>
                <ButtonGroup
                  variant="outlined"
                  ref={anchorRef2}
                  aria-label="Button group with a nested menu"
                >
                  <Button onClick={handleClick2}>
                    {options[selectedIndex2]}
                  </Button>
                  <Button
                    size="small"
                    aria-controls={open2 ? "split-button-menu" : undefined}
                    aria-expanded={open2 ? "true" : undefined}
                    aria-label="select merge strategy"
                    aria-haspopup="menu"
                    onClick={handleToggle2}
                    sx={{
                      width: "20px !important",
                      minWidth: "0px !important",
                    }}
                  >
                    <ArrowDropDownIcon />
                  </Button>
                </ButtonGroup>
                <Popper
                  sx={{
                    zIndex: 1,
                  }}
                  open={open2}
                  anchorEl={anchorRef2.current}
                  role={undefined}
                  transition
                  disablePortal
                >
                  {({ TransitionProps, placement }) => (
                    <Grow
                      {...TransitionProps}
                      style={{
                        transformOrigin:
                          placement === "bottom"
                            ? "center top"
                            : "center bottom",
                      }}
                    >
                      <Paper>
                        <ClickAwayListener onClickAway={handleClose2}>
                          <MenuList id="split-button-menu" autoFocusItem>
                            {options.map((option, index) => (
                              <MenuItem
                                key={option}
                                disabled={index === 2}
                                selected={index === selectedIndex2}
                                onClick={(event) =>
                                  handleMenuItemClick2(event, index)
                                }
                              >
                                {option}
                              </MenuItem>
                            ))}
                          </MenuList>
                        </ClickAwayListener>
                      </Paper>
                    </Grow>
                  )}
                </Popper>
              </Grid>
            </Grid>
          </Grid>

          <Grid item xs={12} sm={2}>
            <Typography variant="body2">Total Calculated Cost</Typography>
            <TextField
              type="number"
              variant="outlined"
              size="small"
              value={selectedCost?.totalCost || ""}
              fullWidth
              disabled
            />
          </Grid>

          <Grid item xs={12} sm={2}>
            <Typography variant="body2">First Offer Price</Typography>
            <TextField
              type="number"
              variant="outlined"
              size="small"
              value={addOfferArray[index]?.firstOfferPrice || ""}
              fullWidth
              disabled
              onChange={(e) => {
                let dummyArr = addOfferArray;
                dummyArr[index] = {
                  ...dummyArr[index],
                  firstOfferPrice: e.target.value,
                };
                setAddOfferArray([...dummyArr]);
              }}
            />
          </Grid>

          <Grid item xs={12} sm={2}>
            <Typography variant="body2">First Offer Price Currency</Typography>
            <TextField
              id="outlined-select-first-offer-price-currency"
              select
              size="small"
              fullWidth
              defaultValue="USD"
              onChange={(e) => {
                let dummyArr = addOfferArray;
                dummyArr[index] = {
                  ...dummyArr[index],
                  firstOfferPriceCurrency: e.target.value,
                };
                setAddOfferArray([...dummyArr]);
              }}
            >
              {currencies.map((option) => (
                <MenuItem key={option.value} value={option.value}>
                  {option.label}
                </MenuItem>
              ))}
            </TextField>
          </Grid>

          <Grid item xs={12} sm={2}>
            <Typography variant="body2">Enter Final Offer Price</Typography>
            <TextField
              type="number"
              variant="outlined"
              size="small"
              fullWidth
              value={addOfferArray[index]?.finalOfferPrice || ""}
              onChange={(e) => {
                let dummyArr = addOfferArray;
                dummyArr[index] = {
                  ...dummyArr[index],
                  finalOfferPrice: e.target.value,
                };
                setAddOfferArray([...dummyArr]);
              }}
            />
          </Grid>
          <Grid item xs={12} sm={2}>
            <Typography variant="body2">Final Offer Price Currency</Typography>
            <TextField
              id="outlined-select-final-offer-price-currency"
              select
              size="small"
              fullWidth
              defaultValue="USD"
              onChange={(e) => {
                let dummyArr = addOfferArray;
                dummyArr[index] = {
                  ...dummyArr[index],
                  finalOfferPriceCurrency: e.target.value,
                };
                setAddOfferArray([...dummyArr]);
              }}
            >
              {currencies.map((option) => (
                <MenuItem key={option.value} value={option.value}>
                  {option.label}
                </MenuItem>
              ))}
            </TextField>
          </Grid>

          <Grid item xs={12} sm={2}>
            <Typography variant="body2"> Offer Sample</Typography>
            <Grid container xs={12} spacing="8px">
              <Grid item xs={12} sm={8}>
                <Autocomplete
                  options={filteredSamples}
                  getOptionLabel={(option) =>
                    `ID: ${option?.id || ""}, Remark: ${
                      option?.remark || ""
                    }, Buying Grade: ${option?.item?.buyingGrade || ""}`
                  }
                  value={selectedFirstSample}
                  onChange={(event, newValue) => {
                    setSelectedFirstSample(newValue);

                    let dummyArr = addOfferArray;
                    dummyArr[index] = {
                      ...dummyArr[index],
                      firstSampleId: newValue ? newValue.id : null,
                    };
                    setAddOfferArray([...dummyArr]);
                  }}
                  onOpen={() => retrieveFilteredSamples("Offer Sample")}
                  isOptionEqualToValue={(option, value) =>
                    option.id === value.id
                  }
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      variant="outlined"
                      size="small"
                      fullWidth
                    />
                  )}
                  renderTags={() => null}
                />
                {selectedFirstSample && (
                  <Box mt={2}>
                    <Chip
                      label={`ID: ${selectedFirstSample?.id || ""}, Remark: ${
                        selectedFirstSample?.remark || ""
                      }, Buying Grade: ${
                        selectedFirstSample?.item?.buyingGrade || ""
                      }`}
                      onDelete={() => setSelectedFirstSample(null)}
                    />
                  </Box>
                )}
              </Grid>

              <Grid item xs={12} sm={4}>
                <ButtonGroup
                  variant="outlined"
                  ref={anchorRef3}
                  aria-label="Button group with a nested menu"
                >
                  <Button onClick={handleClick3}>
                    {options[selectedIndex3]}
                  </Button>
                  <Button
                    size="small"
                    aria-controls={open3 ? "split-button-menu" : undefined}
                    aria-expanded={open3 ? "true" : undefined}
                    aria-label="select merge strategy"
                    aria-haspopup="menu"
                    onClick={handleToggle3}
                    sx={{
                      width: "20px !important",
                      minWidth: "0px !important",
                    }}
                  >
                    <ArrowDropDownIcon />
                  </Button>
                </ButtonGroup>
                <Popper
                  sx={{
                    zIndex: 1,
                  }}
                  open={open3}
                  anchorEl={anchorRef3.current}
                  role={undefined}
                  transition
                  disablePortal
                >
                  {({ TransitionProps, placement }) => (
                    <Grow
                      {...TransitionProps}
                      style={{
                        transformOrigin:
                          placement === "bottom"
                            ? "center top"
                            : "center bottom",
                      }}
                    >
                      <Paper>
                        <ClickAwayListener onClickAway={handleClose3}>
                          <MenuList id="split-button-menu" autoFocusItem>
                            {options.map((option, index) => (
                              <MenuItem
                                key={option}
                                disabled={index === 2}
                                selected={index === selectedIndex3}
                                onClick={(event) =>
                                  handleMenuItemClick3(event, index)
                                }
                              >
                                {option}
                              </MenuItem>
                            ))}
                          </MenuList>
                        </ClickAwayListener>
                      </Paper>
                    </Grow>
                  )}
                </Popper>
              </Grid>
            </Grid>
          </Grid>

          <Grid item xs={12} sm={2}>
            <Typography variant="body2"> Offer Sample Status</Typography>
            <TextField
              id="outlined-select-first-sample-status"
              select
              size="small"
              fullWidth
              onChange={(e) => {
                let dummyArr = addOfferArray;
                dummyArr[index] = {
                  ...dummyArr[index],
                  firstSampleStatus: e.target.value,
                };
                setAddOfferArray([...dummyArr]);
              }}
            >
              {sampleStatus.map((option) => (
                <MenuItem key={option.value} value={option.value}>
                  {option.label}
                </MenuItem>
              ))}
            </TextField>
          </Grid>

          <Grid item xs={12} sm={2}>
            <Typography variant="body2"> Trial Sample</Typography>
            <Grid container xs={12} spacing="8px">
              <Grid item xs={12} sm={8}>
                <Autocomplete
                  options={filteredSamples}
                  getOptionLabel={(option) =>
                    `ID: ${option?.id || ""}, Remark: ${
                      option?.remark || ""
                    }, Buying Grade: ${option?.item?.buyingGrade || ""}`
                  }
                  value={selectedSecondSample}
                  onChange={(event, newValue) => {
                    setSelectedSecondSample(newValue);

                    let dummyArr = addOfferArray;
                    dummyArr[index] = {
                      ...dummyArr[index],
                      secondSampleId: newValue ? newValue.id : null,
                    };
                    setAddOfferArray([...dummyArr]);
                  }}
                  onOpen={() => retrieveFilteredSamples("Trial Sample")}
                  isOptionEqualToValue={(option, value) =>
                    option.id === value.id
                  }
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      variant="outlined"
                      size="small"
                      fullWidth
                    />
                  )}
                  renderTags={() => null}
                />
                {selectedSecondSample && (
                  <Box mt={2}>
                    <Chip
                      label={`ID: ${selectedSecondSample?.id || ""}, Remark: ${
                        selectedSecondSample?.remark || ""
                      }, Buying Grade: ${
                        selectedSecondSample?.item?.buyingGrade || ""
                      }`}
                      onDelete={() => setSelectedSecondSample(null)}
                    />
                  </Box>
                )}
              </Grid>

              <Grid item xs={12} sm={4}>
                <ButtonGroup
                  variant="outlined"
                  ref={anchorRef4}
                  aria-label="Button group with a nested menu"
                >
                  <Button onClick={handleClick4}>
                    {options[selectedIndex4]}
                  </Button>
                  <Button
                    size="small"
                    aria-controls={open4 ? "split-button-menu" : undefined}
                    aria-expanded={open4 ? "true" : undefined}
                    aria-label="select merge strategy"
                    aria-haspopup="menu"
                    onClick={handleToggle4}
                    sx={{
                      width: "20px !important",
                      minWidth: "0px !important",
                    }}
                  >
                    <ArrowDropDownIcon />
                  </Button>
                </ButtonGroup>
                <Popper
                  sx={{
                    zIndex: 1,
                  }}
                  open={open4}
                  anchorEl={anchorRef4.current}
                  role={undefined}
                  transition
                  disablePortal
                >
                  {({ TransitionProps, placement }) => (
                    <Grow
                      {...TransitionProps}
                      style={{
                        transformOrigin:
                          placement === "bottom"
                            ? "center top"
                            : "center bottom",
                      }}
                    >
                      <Paper>
                        <ClickAwayListener onClickAway={handleClose4}>
                          <MenuList id="split-button-menu" autoFocusItem>
                            {options.map((option, index) => (
                              <MenuItem
                                key={option}
                                disabled={index === 2}
                                selected={index === selectedIndex4}
                                onClick={(event) =>
                                  handleMenuItemClick4(event, index)
                                }
                              >
                                {option}
                              </MenuItem>
                            ))}
                          </MenuList>
                        </ClickAwayListener>
                      </Paper>
                    </Grow>
                  )}
                </Popper>
              </Grid>
            </Grid>
          </Grid>

          <Grid item xs={12} sm={2}>
            <Typography variant="body2"> Trial Sample Status</Typography>
            <TextField
              id="outlined-select-first-sample-status"
              select
              size="small"
              fullWidth
              onChange={(e) => {
                let dummyArr = addOfferArray;
                dummyArr[index] = {
                  ...dummyArr[index],
                  secondSampleStatus: e.target.value,
                };
                setAddOfferArray([...dummyArr]);
              }}
            >
              {sampleStatus.map((option) => (
                <MenuItem key={option.value} value={option.value}>
                  {option.label}
                </MenuItem>
              ))}
            </TextField>
          </Grid>

          <Grid item xs={12} sm={2}>
            <Typography variant="body2"> Packaging</Typography>
            <TextField
              variant="outlined"
              size="small"
              fullWidth
              select
              onChange={(e) => {
                let dummyArr = addOfferArray;
                dummyArr[index] = {
                  ...dummyArr[index],
                  packaging: e.target.value,
                };
                setAddOfferArray([...dummyArr]);
              }}
            >
              {packagingTypes.map((option) => (
                <MenuItem key={option.value} value={option.value}>
                  {option.label}
                </MenuItem>
              ))}
            </TextField>
          </Grid>

          <Grid item xs={12} sm={2}>
            <Typography variant="body2"> Payment Term</Typography>
            <Autocomplete
              options={paymentTerm}
              //getOptionLabel={(option) => option ? option?.label : ''}
              value={selectedPaymentTerm}
              onChange={(event, newValue) => {
                const newTerm = newValue ? newValue.value : "";
                setSelectedPaymentTerm(newTerm);

                let dummyArr = addOfferArray;
                dummyArr[index] = {
                  ...dummyArr[index],
                  paymentTerm: newTerm,
                };
                setAddOfferArray([...dummyArr]);
              }}
              isOptionEqualToValue={(option, value) =>
                option.value === value.value
              }
              renderInput={(params) => (
                <TextField
                  {...params}
                  variant="outlined"
                  size="small"
                  fullWidth
                />
              )}
              renderTags={() => null}
            />
            {selectedPaymentTerm && (
              <Box mt={2}>
                <Chip
                  label={
                    paymentTerm.find(
                      (term) => term.value === selectedPaymentTerm,
                    )?.label || ""
                  }
                  onDelete={() => setSelectedPaymentTerm("")}
                />
              </Box>
            )}
          </Grid>

          <Grid item xs={12} sm={2}>
            <Typography variant="body2"> Incoterm</Typography>
            <TextField
              id="outlined-select-incoterm"
              select
              variant="outlined"
              size="small"
              fullWidth
              value={addOfferArray[index]?.incoterm}
              onChange={(e) => {
                let dummyArr = addOfferArray;
                dummyArr[index] = {
                  ...dummyArr[index],
                  incoterm: e.target.value,
                };
                setAddOfferArray([...dummyArr]);
              }}
            >
              {incoterms.map((option) => (
                <MenuItem key={option.value} value={option.value}>
                  {option.label}
                </MenuItem>
              ))}
            </TextField>
          </Grid>

          <Grid item xs={12} sm={2}>
            <Typography variant="body2">Port Of Discharge</Typography>
            <Autocomplete
              options={portsOfDischarge}
              value={selectedPortOfDischarge}
              onChange={(event, newValue) => {
                const newTerm = newValue ? newValue.value : "";
                setSelectedPortOfDischarge(newTerm);

                let dummyArr = addOfferArray;
                dummyArr[index] = {
                  ...dummyArr[index],
                  portOfDischarge: newTerm,
                };
                setAddOfferArray([...dummyArr]);
              }}
              isOptionEqualToValue={(option, value) =>
                option.value === value.value
              }
              renderInput={(params) => (
                <TextField
                  {...params}
                  variant="outlined"
                  size="small"
                  fullWidth
                  style={{
                    border:
                      portOfDischargeIncoterms.includes(
                        addOfferArray[index]?.incoterm,
                      ) && addOfferArray[index]?.portOfDischarge == null
                        ? "3px solid red"
                        : "none",
                    borderRadius:
                      portOfDischargeIncoterms.includes(
                        addOfferArray[index]?.incoterm,
                      ) && addOfferArray[index]?.portOfDischarge == null
                        ? "5px"
                        : "none",
                  }}
                />
              )}
              renderTags={() => null}
            />
            {selectedPortOfDischarge && (
              <Box mt={2}>
                <Chip
                  label={
                    portsOfDischarge.find(
                      (term) => term.value === selectedPortOfDischarge,
                    )?.label || ""
                  }
                  onDelete={() => setSelectedPortOfDischarge("")}
                />
              </Box>
            )}
          </Grid>

          <Grid item xs={12} sm={2}>
            <Typography variant="body2">Port Of Loading</Typography>
            <Autocomplete
              options={portsOfLoading}
              value={selectedPortOfLoading}
              onChange={(event, newValue) => {
                const newTerm = newValue ? newValue.value : "";
                setSelectedPortOfLoading(newTerm);

                let dummyArr = addOfferArray;
                dummyArr[index] = {
                  ...dummyArr[index],
                  portOfLoading: newTerm,
                };
                setAddOfferArray([...dummyArr]);
              }}
              isOptionEqualToValue={(option, value) =>
                option.value === value.value
              }
              renderInput={(params) => (
                <TextField
                  {...params}
                  variant="outlined"
                  size="small"
                  fullWidth
                  style={{
                    border:
                      portOfLoadingIncoterms.includes(
                        addOfferArray[index]?.incoterm,
                      ) && addOfferArray[index]?.portOfLoading == null
                        ? "3px solid red"
                        : "none",
                    borderRadius:
                      portOfLoadingIncoterms.includes(
                        addOfferArray[index]?.incoterm,
                      ) && addOfferArray[index]?.portOfLoading == null
                        ? "5px"
                        : "none",
                  }}
                />
              )}
              renderTags={() => null}
            />
            {selectedPortOfLoading && (
              <Box mt={2}>
                <Chip
                  label={
                    portsOfLoading.find(
                      (term) => term.value === selectedPortOfLoading,
                    )?.label || ""
                  }
                  onDelete={() => setSelectedPortOfLoading("")}
                />
              </Box>
            )}
          </Grid>

          <Grid item xs={12} sm={2}>
            <Typography variant="body2">Enter Number Of Cartons</Typography>
            <TextField
              type="number"
              variant="outlined"
              size="small"
              fullWidth
              value={addOfferArray[index]?.numberOfCases}
              onChange={(e) => {
                let dummyArr = addOfferArray;
                dummyArr[index] = {
                  ...dummyArr[index],
                  numberOfCases: e.target.value,
                  volume:
                    e.target.value * addOfferArray[index]?.netWeightPerCase,
                };
                setAddOfferArray([...dummyArr]);
              }}
            />
          </Grid>

          <Grid item xs={12} sm={2}>
            <Typography variant="body2">Enter Net Weight Per Carton</Typography>
            <TextField
              type="number"
              variant="outlined"
              size="small"
              fullWidth
              value={addOfferArray[index]?.netWeightPerCase}
              onChange={(e) => {
                let dummyArr = addOfferArray;
                dummyArr[index] = {
                  ...dummyArr[index],
                  netWeightPerCase: e.target.value,
                  volume: e.target.value * addOfferArray[index]?.numberOfCases,
                };
                setAddOfferArray([...dummyArr]);
              }}
            />
          </Grid>

          <Grid item xs={12} sm={2}>
            <Typography variant="body2">Enter Volume</Typography>
            <TextField
              type="number"
              variant="outlined"
              size="small"
              value={addOfferArray[index]?.volume}
              fullWidth
              onChange={(e) => {
                let dummyArr = addOfferArray;
                dummyArr[index] = {
                  ...dummyArr[index],
                  volume: e.target.value,
                };
                setAddOfferArray([...dummyArr]);
              }}
            />
          </Grid>

          <Grid item xs={12} sm={2}>
            <Typography variant="body2"> Volume Unit</Typography>
            <TextField
              id="outlined-select-first-sample-status"
              select
              size="small"
              defaultValue={"KG"}
              fullWidth
              onChange={(e) => {
                let dummyArr = addOfferArray;
                dummyArr[index] = {
                  ...dummyArr[index],
                  volumeUnit: e.target.value,
                };
                setAddOfferArray([...dummyArr]);
              }}
            >
              {unit.map((option) => (
                <MenuItem key={option.value} value={option.value}>
                  {option.label}
                </MenuItem>
              ))}
            </TextField>
          </Grid>

          <Grid item xs={12} sm={2}>
            <Typography variant="body2"> Offer Date</Typography>
            <TextField
              size="small"
              fullWidth
              variant="outlined"
              id="date"
              type="date"
              defaultValue={addOfferArray[0]?.offerDate}
              inputProps={{ min: "1999-01-01", max: "2999-01-01" }}
              InputLabelProps={{
                shrink: true,
              }}
              onKeyDown={(e) => e.preventDefault()}
              onChange={(e) => {
                let dummyArr = addOfferArray;
                dummyArr[index] = {
                  ...dummyArr[index],
                  offerDate: e.target.value,
                };
                setAddOfferArray([...dummyArr]);
              }}
            />
          </Grid>

          <Grid item xs={12} sm={2}>
            <Typography variant="body2"> Validity Date</Typography>
            <TextField
              size="small"
              fullWidth
              variant="outlined"
              id="date"
              type="date"
              defaultValue={addOfferArray[0]?.validityDate}
              inputProps={{ min: "1999-01-01", max: "2999-01-01" }}
              InputLabelProps={{
                shrink: true,
              }}
              onKeyDown={(e) => e.preventDefault()}
              onChange={(e) => {
                let dummyArr = addOfferArray;
                dummyArr[index] = {
                  ...dummyArr[index],
                  validityDate: e.target.value,
                };
                setAddOfferArray([...dummyArr]);
              }}
            />
          </Grid>

          <Grid item xs={12} sm={2}>
            <Typography variant="body2">Enter Offer Link</Typography>
            <TextField
              variant="outlined"
              size="small"
              fullWidth
              defaultValue={addOfferArray[0]?.offerLink}
              onChange={(e) => {
                let dummyArr = addOfferArray;
                dummyArr[index] = {
                  ...dummyArr[index],
                  offerLink: e.target.value,
                };
                setAddOfferArray([...dummyArr]);
              }}
            />
          </Grid>

          <Grid item xs={12} sm={2}>
            <Typography variant="body2">Enter Remark</Typography>
            <TextField
              variant="outlined"
              size="small"
              fullWidth
              defaultValue={addOfferArray[0]?.remark}
              onChange={(e) => {
                let dummyArr = addOfferArray;
                dummyArr[index] = {
                  ...dummyArr[index],
                  remark: e.target.value,
                };
                setAddOfferArray([...dummyArr]);
              }}
            />
          </Grid>

          <Grid item xs={12} sm={2}>
            <Typography variant="body2">Enter Comment</Typography>
            <TextField
              variant="outlined"
              size="small"
              fullWidth
              onChange={(e) => {
                let dummyArr = addOfferArray;
                dummyArr[index] = {
                  ...dummyArr[index],
                  comment: e.target.value,
                };
                setAddOfferArray([...dummyArr]);
              }}
            />
          </Grid>

          <Grid item xs={12} sm={2}>
            <Typography variant="body2"> Document Status</Typography>
            <TextField
              id="outlined-document-status"
              select
              size="small"
              fullWidth
              onChange={(e) => {
                let dummyArr = addOfferArray;
                dummyArr[index] = {
                  ...dummyArr[index],
                  documentStatus: e.target.value,
                };
                setAddOfferArray([...dummyArr]);
              }}
            >
              {statusType.map((option) => (
                <MenuItem key={option.value} value={option.value}>
                  {option.label}
                </MenuItem>
              ))}
            </TextField>
          </Grid>

          <Grid item xs={12} sm={2}>
            <Typography variant="body2"> Record Status</Typography>
            <TextField
              id="outlined-select-first-sample-status"
              select
              size="small"
              label={"ACTIVE"}
              fullWidth
              onChange={(e) => {
                let dummyArr = addOfferArray;
                dummyArr[index] = {
                  ...dummyArr[index],
                  recordStatus: e.target.value,
                };
                setAddOfferArray([...dummyArr]);
              }}
            >
              {recordStatus.map((option) => (
                <MenuItem key={option.value} value={option.value}>
                  {option.label}
                </MenuItem>
              ))}
            </TextField>
          </Grid>
        </Grid>

        <Grid item xs={12} sm={5.5}>
          <Typography
            variant="body2"
            onClick={handleHistoryClick}
            style={{
              cursor: "pointer",
              fontSize: "14px",
              color: "blue",
              textDecoration: "underline",
              marginTop: "15px",
            }}
          >
           Valid Offers For Item {selectedItem?.id}
          </Typography>
        </Grid>
        {isHistoryVisible && (
          <Grid item xs={12} sm={12}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell align="center">Offer ID</TableCell>
                  <TableCell align="center">Item</TableCell>
                  <TableCell align="center">Type</TableCell>
                  <TableCell align="center">Status</TableCell>
                  <TableCell align="center">Status Deadline</TableCell>
                  <TableCell align="center">Business Partner</TableCell>
                  <TableCell align="center">Offer Date</TableCell>
                  <TableCell align="center">Validity Date</TableCell>
                  <TableCell align="center">Volume</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {filteredOffers?.map((request, index) => (
                  <TableRow key={index}>
                    <TableCell align="center">{request?.id}</TableCell>
                    <TableCell align="center">
                      {request?.item?.id}-{request?.item?.buyingGrade}
                    </TableCell>
                    <TableCell align="center">{request?.offerType}</TableCell>
                    <TableCell align="center">{request?.status}</TableCell>
                    <TableCell align="center">
                      {request?.statusDeadline
                        ? format(
                            parseISO(request?.statusDeadline),
                            "dd-MMM-yyyy",
                          )
                        : ""}
                    </TableCell>
                    <TableCell align="center">
                      {request?.stakeholder?.name}
                    </TableCell>
                    <TableCell align="center">
                      {request?.offerDate
                        ? format(parseISO(request?.offerDate), "dd-MMM-yyyy")
                        : ""}
                    </TableCell>
                    <TableCell align="center">
                      {request?.validityDate
                        ? format(parseISO(request?.validityDate), "dd-MMM-yyyy")
                        : ""}
                    </TableCell>
                    <TableCell align="center">
                      {request?.volume
                        ? `${request?.volume} ${request?.volumeUnit || ""}`
                        : ""}
                    </TableCell>
                  </TableRow>
                ))}
                <TableRow>
                  <TableCell></TableCell>
                  <TableCell></TableCell>
                  <TableCell></TableCell>
                  <TableCell></TableCell>
                  <TableCell></TableCell>
                  <TableCell></TableCell>
                  <TableCell></TableCell>
                  <TableCell align="center">
                    <strong>Total Vendor Offer Quantity:</strong>
                  </TableCell>
                  <TableCell align="center">
                    <strong>
                      {filteredOffers
                        ?.filter((offer) => offer?.offerType === "VENDOR")
                        .reduce(
                          (total, offer) => total + (offer?.volume || 0),
                          0,
                        )}
                    </strong>
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell></TableCell>
                  <TableCell></TableCell>
                  <TableCell></TableCell>
                  <TableCell></TableCell>
                  <TableCell></TableCell>
                  <TableCell></TableCell>
                  <TableCell></TableCell>
                  <TableCell align="center">
                    <strong>Total Customer Offer Quantity:</strong>
                  </TableCell>
                  <TableCell align="center">
                    <strong>
                      {filteredOffers
                        ?.filter((offer) => offer?.offerType === "CUSTOMER")
                        .reduce(
                          (total, offer) => total + (offer?.volume || 0),
                          0,
                        )}
                    </strong>
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell></TableCell>
                  <TableCell></TableCell>
                  <TableCell></TableCell>
                  <TableCell></TableCell>
                  <TableCell></TableCell>
                  <TableCell></TableCell>
                  <TableCell></TableCell>
                  <TableCell align="center">
                    <strong>Remaining Offerable Quantity:</strong>
                  </TableCell>
                  <TableCell align="center">
                    <strong>
                      {filteredOffers
                        ?.filter((offer) => offer?.offerType === "VENDOR")
                        .reduce(
                          (total, offer) => total + (offer?.volume || 0),
                          0,
                        ) -
                        filteredOffers
                          ?.filter((offer) => offer?.offerType === "CUSTOMER")
                          .reduce(
                            (total, offer) => total + (offer?.volume || 0),
                            0,
                          )}
                    </strong>
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </Grid>
        )}
        {isNewCostOpen && (
          <>
            {" "}
            <Grid item xs={12} style={{ position: "relative" }}>
              <Typography
                variant="h3"
                align="center"
                style={{
                  color: "#777f87",
                  position: "absolute",
                  top: "10px",
                  left: "50%",
                  transform: "translateX(-50%)",
                  backgroundColor: "#fafafb",
                  padding: "0 10px",
                }}
              >
                New Cost Details
              </Typography>
            </Grid>
            <Grid
              container
              spacing="12px"
              style={{
                border: "1px solid #dbd9d9",
                borderRadius: "20px",
                marginTop: "30px",
                padding: "20px",
                boxShadow: "2px 4px 8px rgba(0, 0, 0, 0.2)",
              }}
            >
              <Grid item xs={12} sm={2}>
                <Typography variant="body2">Buying Price Per Kg</Typography>
                <TextField
                  disabled
                  type="number"
                  variant="outlined"
                  size="small"
                  fullWidth
                  value={cost?.basePrice}
                />
              </Grid>
              <Grid item xs={12} sm={1}>
                <Typography variant="body2">Buying Price Currency</Typography>
                <TextField
                  disabled
                  variant="outlined"
                  size="small"
                  fullWidth
                  label={cost?.basePriceCurrency || ""}
                  value={cost?.basePriceCurrency || ""}
                />
              </Grid>
              <Grid item xs={12} sm={1}>
                <Typography variant="body2">Exchange</Typography>
                <Button
                  variant="outlined"
                  disabled={cost?.basePriceCurrency === "USD"}
                  onClick={() =>
                    handleCurrencyExchange(
                      "basePriceCurrency",
                      cost?.basePrice || 0.0,
                    )
                  }
                >
                  $
                </Button>
                <Button
                  variant="outlined"
                  disabled={cost?.basePriceCurrency === "EUR"}
                  onClick={() =>
                    handleCurrencyExchange(
                      "basePriceCurrency",
                      cost?.basePrice || 0.0,
                    )
                  }
                >
                  €
                </Button>
              </Grid>
              <Grid item xs={12} sm={2}>
                <Typography variant="body2">Price Per Container</Typography>
                <TextField
                  type="number"
                  variant="outlined"
                  size="small"
                  fullWidth
                  value={cost?.pricePerContainer}
                  onChange={(e) =>
                    setCost({
                      ...cost,
                      pricePerContainer: e.target.value,
                      freightCost: (
                        e.target.value / cost?.kgPerContainer
                      ).toFixed(2),
                    })
                  }
                />
              </Grid>
              <Grid item xs={12} sm={2}>
                <Typography variant="body2">Kg Per Container</Typography>
                <TextField
                  type="number"
                  variant="outlined"
                  size="small"
                  fullWidth
                  value={cost?.kgPerContainer}
                  onChange={(e) =>
                    setCost({
                      ...cost,
                      kgPerContainer: e.target.value,
                      freightCost: (
                        cost?.pricePerContainer / e.target.value
                      ).toFixed(2),
                    })
                  }
                />
              </Grid>

              <Grid item xs={12} sm={2}>
                <Typography variant="body2">Freight Costs Per Kg</Typography>
                <TextField
                  type="number"
                  variant="outlined"
                  size="small"
                  fullWidth
                  value={cost?.freightCost}
                  onChange={(e) =>
                    setCost({ ...cost, freightCost: e.target.value })
                  }
                />
              </Grid>

              <Grid item xs={12} sm={1}>
                <Typography variant="body2">Freight Cost Currency</Typography>
                <TextField
                  variant="outlined"
                  size="small"
                  fullWidth
                  id="outlined-select-status"
                  select
                  label={cost?.freightCurrency}
                  value={cost?.freightCurrency}
                  onChange={(e) =>
                    setCost({ ...cost, freightCurrency: e.target.value })
                  }
                >
                  {currencies.map((option) => (
                    <MenuItem key={option.value} value={option.value}>
                      {option.label}
                    </MenuItem>
                  ))}
                </TextField>
              </Grid>
              <Grid item xs={12} sm={1}>
                <Typography variant="body2">Exchange</Typography>
                <Button
                  variant="outlined"
                  disabled={cost?.freightCurrency === "USD"}
                  onClick={() =>
                    handleCurrencyExchange(
                      "freightCurrency",
                      cost?.freightCost || 0.0,
                    )
                  }
                >
                  $
                </Button>
                <Button
                  variant="outlined"
                  disabled={cost?.freightCurrency === "EUR"}
                  onClick={() =>
                    handleCurrencyExchange(
                      "freightCurrency",
                      cost?.freightCost || 0.0,
                    )
                  }
                >
                  €
                </Button>
              </Grid>
              <Grid item xs={12} sm={2}>
                <Typography variant="body2">Freight Offer By</Typography>
                <TextField
                  variant="outlined"
                  size="small"
                  fullWidth
                  onChange={(e) =>
                    setCost({ ...cost, freightOfferBy: e.target.value })
                  }
                />
              </Grid>
              <Grid item xs={12} sm={2}>
                <Typography variant="body2">Feight Offer Date</Typography>
                <TextField
                  size="small"
                  fullWidth
                  variant="outlined"
                  id="date"
                  type="date"
                  inputProps={{ min: "1999-01-01", max: "2999-01-01" }}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  onKeyDown={(e) => e.preventDefault()}
                  onChange={(e) =>
                    setCost({ ...cost, freightOfferDate: e.target.value })
                  }
                />
              </Grid>

              <Grid item xs={12} sm={2}>
                <Typography variant="body2">Insurance Costs Per Kg</Typography>
                <TextField
                  type="number"
                  variant="outlined"
                  size="small"
                  fullWidth
                  value={cost?.insuranceCost}
                  onChange={(e) =>
                    setCost({ ...cost, insuranceCost: e.target.value })
                  }
                />
              </Grid>

              <Grid item xs={12} sm={1}>
                <Typography variant="body2">Insurance Cost Currency</Typography>
                <TextField
                  variant="outlined"
                  size="small"
                  fullWidth
                  id="outlined-select-status"
                  select
                  label={cost?.insuranceCurrency}
                  value={cost?.insuranceCurrency}
                  onChange={(e) =>
                    setCost({ ...cost, insuranceCurrency: e.target.value })
                  }
                >
                  {currencies.map((option) => (
                    <MenuItem key={option.value} value={option.value}>
                      {option.label}
                    </MenuItem>
                  ))}
                </TextField>
              </Grid>
              <Grid item xs={12} sm={1}>
                <Typography variant="body2">Exchange</Typography>
                <Button
                  variant="outlined"
                  disabled={cost?.insuranceCurrency === "USD"}
                  onClick={() =>
                    handleCurrencyExchange(
                      "insuranceCurrency",
                      cost?.insuranceCost || 0.0,
                    )
                  }
                >
                  $
                </Button>
                <Button
                  variant="outlined"
                  disabled={cost?.insuranceCurrency === "EUR"}
                  onClick={() =>
                    handleCurrencyExchange(
                      "insuranceCurrency",
                      cost?.insuranceCost || 0.0,
                    )
                  }
                >
                  €
                </Button>
              </Grid>
              <Grid item xs={12} sm={2}>
                <Typography variant="body2">Storage Cost Per Kg</Typography>
                <TextField
                  type="number"
                  variant="outlined"
                  size="small"
                  fullWidth
                  value={cost?.storageCost}
                  onChange={(e) =>
                    setCost({ ...cost, storageCost: e.target.value })
                  }
                />
              </Grid>

              <Grid item xs={12} sm={1}>
                <Typography variant="body2">Storage Cost Currency</Typography>
                <TextField
                  variant="outlined"
                  size="small"
                  fullWidth
                  id="outlined-select-status"
                  select
                  label={cost?.storageCostCurrency}
                  value={cost?.storageCostCurrency}
                  onChange={(e) =>
                    setCost({ ...cost, storageCostCurrency: e.target.value })
                  }
                >
                  {currencies.map((option) => (
                    <MenuItem key={option.value} value={option.value}>
                      {option.label}
                    </MenuItem>
                  ))}
                </TextField>
              </Grid>
              <Grid item xs={12} sm={1}>
                <Typography variant="body2">Exchange</Typography>
                <Button
                  variant="outlined"
                  disabled={cost?.storageCostCurrency === "USD"}
                  onClick={() =>
                    handleCurrencyExchange(
                      "storageCostCurrency",
                      cost?.storageCost || 0.0,
                    )
                  }
                >
                  $
                </Button>
                <Button
                  variant="outlined"
                  disabled={cost?.storageCostCurrency === "EUR"}
                  onClick={() =>
                    handleCurrencyExchange(
                      "storageCostCurrency",
                      cost?.storageCost || 0.0,
                    )
                  }
                >
                  €
                </Button>
              </Grid>
              <Grid item xs={12} sm={2}>
                <Typography variant="body2">Finance Cost Per Kg</Typography>
                <TextField
                  type="number"
                  variant="outlined"
                  size="small"
                  fullWidth
                  value={cost?.financeCost}
                  onChange={(e) =>
                    setCost({ ...cost, financeCost: e.target.value })
                  }
                />
              </Grid>

              <Grid item xs={12} sm={1}>
                <Typography variant="body2">Finance Cost Currency</Typography>
                <TextField
                  variant="outlined"
                  size="small"
                  fullWidth
                  id="outlined-select-status"
                  select
                  label={cost?.financeCostCurrency}
                  value={cost?.financeCostCurrency}
                  onChange={(e) =>
                    setCost({ ...cost, financeCostCurrency: e.target.value })
                  }
                >
                  {currencies.map((option) => (
                    <MenuItem key={option.value} value={option.value}>
                      {option.label}
                    </MenuItem>
                  ))}
                </TextField>
              </Grid>
              <Grid item xs={12} sm={1}>
                <Typography variant="body2">Exchange</Typography>
                <Button
                  variant="outlined"
                  disabled={cost?.financeCostCurrency === "USD"}
                  onClick={() =>
                    handleCurrencyExchange(
                      "financeCostCurrency",
                      cost?.financeCost || 0.0,
                    )
                  }
                >
                  $
                </Button>
                <Button
                  variant="outlined"
                  disabled={cost?.financeCostCurrency === "EUR"}
                  onClick={() =>
                    handleCurrencyExchange(
                      "financeCostCurrency",
                      cost?.financeCost || 0.0,
                    )
                  }
                >
                  €
                </Button>
              </Grid>
              <Grid item xs={12} sm={2}>
                <Typography variant="body2">Other Costs Per Kg</Typography>
                <TextField
                  type="number"
                  variant="outlined"
                  size="small"
                  value={cost?.others}
                  fullWidth
                  onChange={(e) => setCost({ ...cost, others: e.target.value })}
                />
              </Grid>

              <Grid item xs={12} sm={1}>
                <Typography variant="body2">Other Cost Currency</Typography>
                <TextField
                  variant="outlined"
                  size="small"
                  fullWidth
                  id="outlined-select-status"
                  select
                  label={cost?.othersCurrency}
                  value={cost?.othersCurrency}
                  onChange={(e) =>
                    setCost({ ...cost, othersCurrency: e.target.value })
                  }
                >
                  {currencies.map((option) => (
                    <MenuItem key={option.value} value={option.value}>
                      {option.label}
                    </MenuItem>
                  ))}
                </TextField>
              </Grid>
              <Grid item xs={12} sm={1}>
                <Typography variant="body2">Exchange</Typography>
                <Button
                  variant="outlined"
                  disabled={cost?.othersCurrency === "USD"}
                  onClick={() =>
                    handleCurrencyExchange(
                      "othersCurrency",
                      cost?.others || 0.0,
                    )
                  }
                >
                  $
                </Button>
                <Button
                  variant="outlined"
                  disabled={cost?.othersCurrency === "EUR"}
                  onClick={() =>
                    handleCurrencyExchange(
                      "othersCurrency",
                      cost?.others || 0.0,
                    )
                  }
                >
                  €
                </Button>
              </Grid>
              <Grid item xs={12} sm={2}>
                <Typography variant="body2">Other Costs Remark</Typography>
                <TextField
                  variant="outlined"
                  size="small"
                  fullWidth
                  onChange={(e) =>
                    setCost({ ...cost, othersRemark: e.target.value })
                  }
                />
              </Grid>

              <Grid item xs={12} sm={2}>
                <Typography variant="body2">First Commision Per Kg</Typography>
                <TextField
                  type="number"
                  variant="outlined"
                  size="small"
                  fullWidth
                  value={cost?.firstCommision}
                  onChange={(e) =>
                    setCost({ ...cost, firstCommision: e.target.value })
                  }
                />
              </Grid>

              <Grid item xs={12} sm={1}>
                <Typography variant="body2">
                  First Commision Currency
                </Typography>
                <TextField
                  variant="outlined"
                  size="small"
                  fullWidth
                  id="outlined-select-status"
                  select
                  label={cost?.firstCommisionCurrency}
                  value={cost?.firstCommisionCurrency}
                  onChange={(e) =>
                    setCost({ ...cost, firstCommisionCurrency: e.target.value })
                  }
                >
                  {currencies.map((option) => (
                    <MenuItem key={option.value} value={option.value}>
                      {option.label}
                    </MenuItem>
                  ))}
                </TextField>
              </Grid>
              <Grid item xs={12} sm={1}>
                <Typography variant="body2">Exchange</Typography>
                <Button
                  variant="outlined"
                  disabled={cost?.firstCommisionCurrency === "USD"}
                  onClick={() =>
                    handleCurrencyExchange(
                      "firstCommisionCurrency",
                      cost?.firstCommision || 0.0,
                    )
                  }
                >
                  $
                </Button>
                <Button
                  variant="outlined"
                  disabled={cost?.firstCommisionCurrency === "EUR"}
                  onClick={() =>
                    handleCurrencyExchange(
                      "firstCommisionCurrency",
                      cost?.firstCommision || 0.0,
                    )
                  }
                >
                  €
                </Button>
              </Grid>
              <Grid item xs={12} sm={2}>
                <Typography variant="body2">Second Commision Per Kg</Typography>
                <TextField
                  type="number"
                  variant="outlined"
                  size="small"
                  fullWidth
                  value={cost?.secondCommision}
                  onChange={(e) =>
                    setCost({ ...cost, secondCommision: e.target.value })
                  }
                />
              </Grid>

              <Grid item xs={12} sm={1}>
                <Typography variant="body2">
                  Second Commision Currency
                </Typography>
                <TextField
                  variant="outlined"
                  size="small"
                  fullWidth
                  id="outlined-select-status"
                  select
                  label={cost?.secondCommisionCurrency}
                  value={cost?.secondCommisionCurrency}
                  onChange={(e) =>
                    setCost({
                      ...cost,
                      secondCommisionCurrency: e.target.value,
                    })
                  }
                >
                  {currencies.map((option) => (
                    <MenuItem key={option.value} value={option.value}>
                      {option.label}
                    </MenuItem>
                  ))}
                </TextField>
              </Grid>
              <Grid item xs={12} sm={1}>
                <Typography variant="body2">Exchange</Typography>
                <Button
                  variant="outlined"
                  disabled={cost?.secondCommisionCurrency === "USD"}
                  onClick={() =>
                    handleCurrencyExchange(
                      "secondCommisionCurrency",
                      cost?.secondCommision || 0.0,
                    )
                  }
                >
                  $
                </Button>
                <Button
                  variant="outlined"
                  disabled={cost?.secondCommisionCurrency === "EUR"}
                  onClick={() =>
                    handleCurrencyExchange(
                      "secondCommisionCurrency",
                      cost?.secondCommision || 0.0,
                    )
                  }
                >
                  €
                </Button>
              </Grid>
              <Grid item xs={12} sm={2}>
                <Typography variant="body2">Third Commision Per Kg</Typography>
                <TextField
                  type="number"
                  variant="outlined"
                  size="small"
                  fullWidth
                  value={cost?.thirdCommision}
                  onChange={(e) =>
                    setCost({ ...cost, thirdCommision: e.target.value })
                  }
                />
              </Grid>

              <Grid item xs={12} sm={1}>
                <Typography variant="body2">
                  Third Commision Currency
                </Typography>
                <TextField
                  variant="outlined"
                  size="small"
                  fullWidth
                  id="outlined-select-status"
                  select
                  label={cost?.thirdCommisionCurrency}
                  value={cost?.thirdCommisionCurrency}
                  onChange={(e) =>
                    setCost({ ...cost, thirdCommisionCurrency: e.target.value })
                  }
                >
                  {currencies.map((option) => (
                    <MenuItem key={option.value} value={option.value}>
                      {option.label}
                    </MenuItem>
                  ))}
                </TextField>
              </Grid>
              <Grid item xs={12} sm={1}>
                <Typography variant="body2">Exchange</Typography>
                <Button
                  variant="outlined"
                  disabled={cost?.thirdCommisionCurrency === "USD"}
                  onClick={() =>
                    handleCurrencyExchange(
                      "thirdCommisionCurrency",
                      cost?.thirdCommision || 0.0,
                    )
                  }
                >
                  $
                </Button>
                <Button
                  variant="outlined"
                  disabled={cost?.thirdCommisionCurrency === "EUR"}
                  onClick={() =>
                    handleCurrencyExchange(
                      "thirdCommisionCurrency",
                      cost?.thirdCommision || 0.0,
                    )
                  }
                >
                  €
                </Button>
              </Grid>
              <Grid item xs={12} sm={2}>
                <Typography variant="body2">
                  Total Unit Cost Before Profit
                </Typography>
                <Grid container spacing={1} alignItems="center">
                  <Grid Grid item xs={10} sm={9}>
                    <TextField
                      type="number"
                      variant="outlined"
                      size="small"
                      fullWidth
                      value={cost?.totalCost || ""}
                      onChange={(e) => {
                        setCost({ ...cost, totalCost: e.target.value });
                      }}
                    />
                  </Grid>
                  <Grid item xs={1} sm={1}>
                    <Button
                      style={{ padding: 0, color: "red" }}
                      onClick={calculateTotalCost}
                    >
                      <ReloadOutlined style={{ fontSize: "16px" }} />
                    </Button>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={12} sm={2}>
                <Typography variant="body2">Profit Per Kg</Typography>
                <TextField
                  type="number"
                  variant="outlined"
                  size="small"
                  fullWidth
                  value={cost?.profit}
                  onChange={(e) =>
                    setCost({
                      ...cost,
                      profit: e.target.value,
                    })
                  }
                />
              </Grid>

              <Grid item xs={12} sm={1}>
                <Typography variant="body2">Profit Currency</Typography>
                <TextField
                  variant="outlined"
                  size="small"
                  fullWidth
                  id="outlined-select-profit-currency"
                  select
                  label={cost?.profitCurrency}
                  value={cost?.profitCurrency}
                  onChange={(e) =>
                    setCost({
                      ...cost,
                      profitCurrency: e.target.value,
                    })
                  }
                >
                  {currencies.map((option) => (
                    <MenuItem key={option.value} value={option.value}>
                      {option.label}
                    </MenuItem>
                  ))}
                </TextField>
              </Grid>
              <Grid item xs={12} sm={1}>
                <Typography variant="body2">Exchange</Typography>
                <Button
                  variant="outlined"
                  disabled={cost?.profitCurrency === "USD"}
                  onClick={() =>
                    handleCurrencyExchange(
                      "profitCurrency",
                      cost?.profit || 0.0,
                    )
                  }
                >
                  $
                </Button>
                <Button
                  variant="outlined"
                  disabled={cost?.profitCurrency === "EUR"}
                  onClick={() =>
                    handleCurrencyExchange(
                      "profitCurrency",
                      cost?.profit || 0.0,
                    )
                  }
                >
                  €
                </Button>
              </Grid>
              <Grid item xs={12} sm={2}>
                <Typography variant="body2">
                  Total Unit Cost After Profit
                </Typography>
                <Grid container spacing={1} alignItems="center">
                  <Grid Grid item xs={10} sm={9}>
                    <TextField
                      type="number"
                      variant="outlined"
                      size="small"
                      fullWidth
                      value={cost?.totalOfferCost || ""}
                      onChange={(e) => {
                        setCost({ ...cost, totalOfferCost: e.target.value });
                      }}
                    />
                  </Grid>
                  <Grid item xs={1} sm={1}>
                    <Button
                      style={{ padding: 0, color: "red" }}
                      onClick={calculateTotalCostWithProfit}
                    >
                      <ReloadOutlined style={{ fontSize: "16px" }} />
                    </Button>
                  </Grid>
                </Grid>
              </Grid>

              <Grid item xs={12} sm={2}>
                <Typography variant="body2">Cost Remark</Typography>
                <TextField
                  variant="outlined"
                  size="small"
                  fullWidth
                  onChange={(e) => setCost({ ...cost, remark: e.target.value })}
                />
              </Grid>

              <Grid item xs={12} sm={2}>
                <Typography variant="body2">Cost Record Status</Typography>
                <TextField
                  id="outlined-select-status"
                  select
                  variant="outlined"
                  size="small"
                  fullWidth
                  onChange={(e) => setCost({ ...cost, status: e.target.value })}
                >
                  {recordStatus.map((option) => (
                    <MenuItem key={option.value} value={option.value}>
                      {option.label}
                    </MenuItem>
                  ))}
                </TextField>
              </Grid>

              <Grid item xs={12} sm={2}>
                <Button
                  variant="outlined"
                  size="small"
                  sx={{ ml: 1.5, mt: 4, mr: 1 }}
                  onClick={() => {
                    setIsNewCostOpen(false);
                    setCost([]);
                  }}
                >
                  Cancel
                </Button>
                <Button
                  variant="contained"
                  size="small"
                  sx={{ mt: 4 }}
                  onClick={() => {
                    createCostApi(cost)
                      .then((response) => {
                        toast.success("Cost added successfully.");
                      })
                      .catch((error) => {
                        console.log(error.response.data);
                        toast.error("Something Went Wrong.");
                      });
                    setIsNewCostOpen(false);
                    setCost([]);
                  }}
                >
                  Create Cost
                </Button>
              </Grid>
            </Grid>{" "}
          </>
        )}

        {isNewItemOpen && (
          <>
            {" "}
            <Grid item xs={12} style={{ position: "relative" }}>
              <Typography
                variant="h3"
                align="center"
                style={{
                  color: "#777f87",
                  position: "absolute",
                  top: "10px",
                  left: "50%",
                  transform: "translateX(-50%)",
                  backgroundColor: "#fafafb",
                  padding: "0 10px",
                }}
              >
                New Item Details
              </Typography>
            </Grid>
            <Grid
              container
              spacing="12px"
              style={{
                border: "1px solid #dbd9d9",
                borderRadius: "20px",
                marginTop: "30px",
                padding: "20px",
                boxShadow: "2px 4px 8px rgba(0, 0, 0, 0.2)",
              }}
            >
              <Grid container xs={12} spacing="8px">
                <Grid item xs={12} sm={2}>
                  <Typography variant="body2">Enter Tobacco Code</Typography>
                  <TextField
                    variant="outlined"
                    size="small"
                    fullWidth
                    onChange={(e) =>
                      setItem({
                        ...item,
                        tobaccoCode: e.target.value,
                      })
                    }
                  />
                </Grid>
                <Grid item xs={12} sm={2}>
                  <Typography variant="body2">Sales Grade</Typography>
                  <TextField
                    variant="outlined"
                    size="small"
                    fullWidth
                    onChange={(e) =>
                      setItem({
                        ...item,
                        salesGrade: e.target.value,
                      })
                    }
                  />
                </Grid>
                <Grid item xs={12} sm={2}>
                  <Typography variant="body2">Buying Grade</Typography>
                  <TextField
                    variant="outlined"
                    size="small"
                    fullWidth
                    onChange={(e) =>
                      setItem({
                        ...item,
                        buyingGrade: e.target.value,
                      })
                    }
                  />
                </Grid>
                <Grid item xs={12} sm={2}>
                  <Typography variant="body2">Internal Grade</Typography>
                  <TextField
                    variant="outlined"
                    size="small"
                    fullWidth
                    onChange={(e) =>
                      setItem({
                        ...item,
                        internalGrade: e.target.value,
                      })
                    }
                  />
                </Grid>

                <Grid item xs={12} sm={2}>
                  <Typography variant="body2"> Origin</Typography>
                  <Autocomplete
                    options={origin}
                    value={selectedOrigin}
                    onChange={(event, newValue) => {
                      const newTerm = newValue ? newValue.value : "";
                      setSelectedOrigin(newTerm);
                      setItem({
                        ...item,
                        origin: newTerm,
                      });
                    }}
                    isOptionEqualToValue={(option, value) =>
                      option.value === value.value
                    }
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        variant="outlined"
                        size="small"
                        fullWidth
                      />
                    )}
                    renderTags={() => null}
                  />
                  {selectedOrigin && (
                    <Box mt={2}>
                      <Chip
                        label={
                          origin.find((term) => term.value === selectedOrigin)
                            ?.label || ""
                        }
                        onDelete={() => setSelectedOrigin("")}
                      />
                    </Box>
                  )}
                </Grid>

                {selectedOrigin === "China" && (
                  <Grid item xs={12} sm={2}>
                    <Typography variant="body2">Province</Typography>
                    <TextField
                      variant="outlined"
                      size="small"
                      fullWidth
                      onChange={(e) =>
                        setItem({
                          ...item,
                          province: e.target.value,
                        })
                      }
                    />
                  </Grid>
                )}
                <Grid item xs={12} sm={2}>
                  <Typography variant="body2"> Type</Typography>
                  <TextField
                    variant="outlined"
                    size="small"
                    fullWidth
                    id="outlined-select-type"
                    select
                    onChange={(e) =>
                      setItem({
                        ...item,
                        type: e.target.value,
                      })
                    }
                  >
                    {type.map((option) => (
                      <MenuItem key={option.value} value={option.value}>
                        {option.label}
                      </MenuItem>
                    ))}
                  </TextField>
                </Grid>
                <Grid item xs={12} sm={2}>
                  <Typography variant="body2"> Leaf Form</Typography>
                  <TextField
                    variant="outlined"
                    size="small"
                    fullWidth
                    id="outlined-select-leaf-form"
                    select
                    onChange={(e) =>
                      setItem({ ...item, leafForm: e.target.value })
                    }
                  >
                    {leafForm.map((option) => (
                      <MenuItem key={option.value} value={option.value}>
                        {option.label}
                      </MenuItem>
                    ))}
                  </TextField>
                </Grid>
                <Grid item xs={12} sm={2}>
                  <Typography variant="body2"> Color</Typography>
                  <TextField
                    variant="outlined"
                    size="small"
                    fullWidth
                    id="outlined-select-color"
                    select
                    onChange={(e) =>
                      setItem({ ...item, color: e.target.value })
                    }
                  >
                    {color.map((option) => (
                      <MenuItem key={option.value} value={option.value}>
                        {option.label}
                      </MenuItem>
                    ))}
                  </TextField>
                </Grid>
                <Grid item xs={12} sm={2}>
                  <Typography variant="body2">Enter Crop Year</Typography>
                  <TextField
                    size="small"
                    fullWidth
                    onChange={(e) =>
                      setItem({
                        ...item,
                        crop: e.target.value,
                      })
                    }
                  />
                </Grid>
                <Grid item xs={12} sm={2}>
                  <Typography variant="body2">Current Location</Typography>
                  <TextField
                    variant="outlined"
                    size="small"
                    fullWidth
                    id="outlined-select-storage"
                    value={item?.storage}
                    onChange={(e) =>
                      setItem({
                        ...item,
                        storage: e.target.value,
                      })
                    }
                  />
                </Grid>
                <Grid item xs={12} sm={2}>
                  <Typography variant="body2">Nicotine</Typography>
                  <TextField
                    size="small"
                    type="number"
                    fullWidth
                    onChange={(e) =>
                      setItem({
                        ...item,
                        nicotine: e.target.value,
                      })
                    }
                  />
                </Grid>

                <Grid item xs={12} sm={2}>
                  <Typography variant="body2">Sugar</Typography>
                  <TextField
                    size="small"
                    type="number"
                    fullWidth
                    variant="outlined"
                    onChange={(e) =>
                      setItem({
                        ...item,
                        sugar: e.target.value,
                      })
                    }
                  />
                </Grid>

                <Grid item xs={12} sm={2}>
                  <Typography variant="body2">Moisture</Typography>
                  <TextField
                    size="small"
                    type="number"
                    fullWidth
                    variant="outlined"
                    value={item?.moisture}
                    onChange={(e) =>
                      setItem({
                        ...item,
                        moisture: e.target.value,
                      })
                    }
                  />
                </Grid>

                <Grid item xs={12} sm={2}>
                  <Typography variant="body2">Status</Typography>
                  <TextField
                    variant="outlined"
                    size="small"
                    fullWidth
                    id="outlined-select-status"
                    select
                    onChange={(e) =>
                      setItem({
                        ...item,
                        status: e.target.value,
                      })
                    }
                  >
                    {recordStatus.map((option) => (
                      <MenuItem key={option.value} value={option.value}>
                        {option.label}
                      </MenuItem>
                    ))}
                  </TextField>
                </Grid>
              </Grid>

              <Grid item xs={12} sm={2}>
                <Button
                  variant="outlined"
                  size="small"
                  sx={{ ml: 1.5, mt: 4, mr: 1 }}
                  onClick={() => {
                    setIsNewItemOpen(false);
                    setItem([]);
                    setSelectedOrigin("");
                  }}
                >
                  Cancel
                </Button>
                <Button
                  variant="contained"
                  size="small"
                  sx={{ mt: 4 }}
                  onClick={() => {
                    createItemApi(item)
                      .then((response) => {
                        toast.success("Item added successfully.");
                      })
                      .catch((error) => {
                        console.log(error.response.data);
                        toast.error("Something Went Wrong.");
                      });
                    setIsNewItemOpen(false);
                    setItem([]);
                    setSelectedOrigin("");
                  }}
                >
                  Create Item
                </Button>
              </Grid>
            </Grid>{" "}
          </>
        )}

        {isEditCostOpen && (
          <>
            {" "}
            <Grid item xs={12} style={{ position: "relative" }}>
              <Typography
                variant="h3"
                align="center"
                style={{
                  color: "#777f87",
                  position: "absolute",
                  top: "10px",
                  left: "50%",
                  transform: "translateX(-50%)",
                  backgroundColor: "#fafafb",
                  padding: "0 10px",
                }}
              >
                Edit Cost {cost?.id} Details
              </Typography>
            </Grid>
            <Grid
              container
              spacing="12px"
              style={{
                border: "1px solid #dbd9d9",
                borderRadius: "20px",
                marginTop: "30px",
                padding: "20px",
                boxShadow: "2px 4px 8px rgba(0, 0, 0, 0.2)",
              }}
            >
              <Grid item xs={12} sm={2}>
                <Typography variant="body2">Buying Price Per Kg</Typography>
                <TextField
                  disabled
                  type="number"
                  variant="outlined"
                  size="small"
                  fullWidth
                  value={cost?.basePrice}
                />
              </Grid>
              <Grid item xs={12} sm={1}>
                <Typography variant="body2">
                  Buying Price Per Kg Currency
                </Typography>
                <TextField
                  disabled
                  variant="outlined"
                  size="small"
                  fullWidth
                  label={cost?.basePriceCurrency || ""}
                  value={cost?.basePriceCurrency || ""}
                />
              </Grid>
              <Grid item xs={12} sm={1}>
                <Typography variant="body2">Exchange</Typography>
                <Button
                  variant="outlined"
                  disabled={cost?.basePriceCurrency === "USD"}
                  onClick={() =>
                    handleCurrencyExchange(
                      "basePriceCurrency",
                      cost?.basePrice || 0.0,
                    )
                  }
                >
                  $
                </Button>
                <Button
                  variant="outlined"
                  disabled={cost?.basePriceCurrency === "EUR"}
                  onClick={() =>
                    handleCurrencyExchange(
                      "basePriceCurrency",
                      cost?.basePrice || 0.0,
                    )
                  }
                >
                  €
                </Button>
              </Grid>

              <Grid item xs={12} sm={2}>
                <Typography variant="body2">Price Per Container</Typography>
                <TextField
                  type="number"
                  variant="outlined"
                  size="small"
                  fullWidth
                  value={cost?.pricePerContainer}
                  onChange={(e) =>
                    setCost({
                      ...cost,
                      pricePerContainer: e.target.value,
                      freightCost: (
                        e.target.value / cost?.kgPerContainer
                      ).toFixed(2),
                    })
                  }
                />
              </Grid>
              <Grid item xs={12} sm={2}>
                <Typography variant="body2">Kg Per Container</Typography>
                <TextField
                  type="number"
                  variant="outlined"
                  size="small"
                  fullWidth
                  value={cost?.kgPerContainer}
                  onChange={(e) =>
                    setCost({
                      ...cost,
                      kgPerContainer: e.target.value,
                      freightCost: (
                        cost?.pricePerContainer / e.target.value
                      ).toFixed(2),
                    })
                  }
                />
              </Grid>

              <Grid item xs={12} sm={2}>
                <Typography variant="body2">Freight Costs Per Kg</Typography>
                <TextField
                  type="number"
                  variant="outlined"
                  size="small"
                  value={cost?.freightCost || ""}
                  fullWidth
                  onChange={(e) =>
                    setCost({ ...cost, freightCost: e.target.value })
                  }
                />
              </Grid>

              <Grid item xs={12} sm={1}>
                <Typography variant="body2">Freight Cost Currency</Typography>
                <TextField
                  variant="outlined"
                  size="small"
                  fullWidth
                  label={cost?.freightCurrency || ""}
                  value={cost?.freightCurrency || ""}
                  id="outlined-select-status"
                  select
                  onChange={(e) =>
                    setCost({ ...cost, freightCurrency: e.target.value })
                  }
                >
                  {currencies.map((option) => (
                    <MenuItem key={option.value} value={option.value}>
                      {option.label}
                    </MenuItem>
                  ))}
                </TextField>
              </Grid>
              <Grid item xs={12} sm={1}>
                <Typography variant="body2">Exchange</Typography>
                <Button
                  variant="outlined"
                  disabled={cost?.freightCurrency === "USD"}
                  onClick={() =>
                    handleCurrencyExchange(
                      "freightCurrency",
                      cost?.freightCost || 0.0,
                    )
                  }
                >
                  $
                </Button>
                <Button
                  variant="outlined"
                  disabled={cost?.freightCurrency === "EUR"}
                  onClick={() =>
                    handleCurrencyExchange(
                      "freightCurrency",
                      cost?.freightCost || 0.0,
                    )
                  }
                >
                  €
                </Button>
              </Grid>
              <Grid item xs={12} sm={2}>
                <Typography variant="body2">Freight Offer By</Typography>
                <TextField
                  variant="outlined"
                  size="small"
                  fullWidth
                  value={cost?.freightOfferBy || ""}
                  onChange={(e) =>
                    setCost({ ...cost, freightOfferBy: e.target.value })
                  }
                />
              </Grid>
              <Grid item xs={12} sm={2}>
                <Typography variant="body2">Feight Offer Date</Typography>
                <TextField
                  size="small"
                  fullWidth
                  variant="outlined"
                  value={
                    cost.freightOfferDate
                      ? format(parseISO(cost.freightOfferDate), "yyyy-MM-dd")
                      : "" || ""
                  }
                  id="date"
                  type="date"
                  inputProps={{ min: "1999-01-01", max: "2999-01-01" }}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  onKeyDown={(e) => e.preventDefault()}
                  onChange={(e) =>
                    setCost({ ...cost, freightOfferDate: e.target.value })
                  }
                />
              </Grid>

              <Grid item xs={12} sm={2}>
                <Typography variant="body2">Insurance Costs Per Kg</Typography>
                <TextField
                  type="number"
                  variant="outlined"
                  size="small"
                  fullWidth
                  value={cost?.insuranceCost || ""}
                  onChange={(e) =>
                    setCost({ ...cost, insuranceCost: e.target.value })
                  }
                />
              </Grid>

              <Grid item xs={12} sm={1}>
                <Typography variant="body2">Insurance Cost Currency</Typography>
                <TextField
                  variant="outlined"
                  size="small"
                  fullWidth
                  label={cost?.insuranceCurrency || ""}
                  value={cost?.insuranceCurrency || ""}
                  id="outlined-select-status"
                  select
                  onChange={(e) =>
                    setCost({ ...cost, insuranceCurrency: e.target.value })
                  }
                >
                  {currencies.map((option) => (
                    <MenuItem key={option.value} value={option.value}>
                      {option.label}
                    </MenuItem>
                  ))}
                </TextField>
              </Grid>
              <Grid item xs={12} sm={1}>
                <Typography variant="body2">Exchange</Typography>
                <Button
                  variant="outlined"
                  disabled={cost?.insuranceCurrency === "USD"}
                  onClick={() =>
                    handleCurrencyExchange(
                      "insuranceCurrency",
                      cost?.insuranceCost || 0.0,
                    )
                  }
                >
                  $
                </Button>
                <Button
                  variant="outlined"
                  disabled={cost?.insuranceCurrency === "EUR"}
                  onClick={() =>
                    handleCurrencyExchange(
                      "insuranceCurrency",
                      cost?.insuranceCost || 0.0,
                    )
                  }
                >
                  €
                </Button>
              </Grid>
              <Grid item xs={12} sm={2}>
                <Typography variant="body2">Storage Cost Per Kg</Typography>
                <TextField
                  type="number"
                  variant="outlined"
                  size="small"
                  value={cost?.storageCost || ""}
                  fullWidth
                  onChange={(e) =>
                    setCost({ ...cost, storageCost: e.target.value })
                  }
                />
              </Grid>

              <Grid item xs={12} sm={1}>
                <Typography variant="body2">Storage Cost Currency</Typography>
                <TextField
                  variant="outlined"
                  size="small"
                  fullWidth
                  label={cost?.storageCostCurrency || ""}
                  value={cost?.storageCostCurrency || ""}
                  id="outlined-select-status"
                  select
                  onChange={(e) =>
                    setCost({ ...cost, storageCostCurrency: e.target.value })
                  }
                >
                  {currencies.map((option) => (
                    <MenuItem key={option.value} value={option.value}>
                      {option.label}
                    </MenuItem>
                  ))}
                </TextField>
              </Grid>
              <Grid item xs={12} sm={1}>
                <Typography variant="body2">Exchange</Typography>
                <Button
                  variant="outlined"
                  disabled={cost?.storageCostCurrency === "USD"}
                  onClick={() =>
                    handleCurrencyExchange(
                      "storageCostCurrency",
                      cost?.storageCost || 0.0,
                    )
                  }
                >
                  $
                </Button>
                <Button
                  variant="outlined"
                  disabled={cost?.storageCostCurrency === "EUR"}
                  onClick={() =>
                    handleCurrencyExchange(
                      "storageCostCurrency",
                      cost?.storageCost || 0.0,
                    )
                  }
                >
                  €
                </Button>
              </Grid>
              <Grid item xs={12} sm={2}>
                <Typography variant="body2">Finance Cost Per Kg</Typography>
                <TextField
                  type="number"
                  variant="outlined"
                  size="small"
                  value={cost?.financeCost || ""}
                  fullWidth
                  onChange={(e) =>
                    setCost({ ...cost, financeCost: e.target.value })
                  }
                />
              </Grid>

              <Grid item xs={12} sm={1}>
                <Typography variant="body2">Finance Cost Currency</Typography>
                <TextField
                  variant="outlined"
                  size="small"
                  fullWidth
                  label={cost?.financeCostCurrency || ""}
                  value={cost?.financeCostCurrency || ""}
                  id="outlined-select-status"
                  select
                  onChange={(e) =>
                    setCost({ ...cost, financeCostCurrency: e.target.value })
                  }
                >
                  {currencies.map((option) => (
                    <MenuItem key={option.value} value={option.value}>
                      {option.label}
                    </MenuItem>
                  ))}
                </TextField>
              </Grid>
              <Grid item xs={12} sm={1}>
                <Typography variant="body2">Exchange</Typography>
                <Button
                  variant="outlined"
                  disabled={cost?.financeCostCurrency === "USD"}
                  onClick={() =>
                    handleCurrencyExchange(
                      "financeCostCurrency",
                      cost?.financeCost || 0.0,
                    )
                  }
                >
                  $
                </Button>
                <Button
                  variant="outlined"
                  disabled={cost?.financeCostCurrency === "EUR"}
                  onClick={() =>
                    handleCurrencyExchange(
                      "financeCostCurrency",
                      cost?.financeCost || 0.0,
                    )
                  }
                >
                  €
                </Button>
              </Grid>
              <Grid item xs={12} sm={2}>
                <Typography variant="body2">Other Costs Per Kg</Typography>
                <TextField
                  type="number"
                  variant="outlined"
                  size="small"
                  value={cost?.others || ""}
                  fullWidth
                  onChange={(e) => setCost({ ...cost, others: e.target.value })}
                />
              </Grid>

              <Grid item xs={12} sm={1}>
                <Typography variant="body2">Other Cost Currency</Typography>
                <TextField
                  variant="outlined"
                  size="small"
                  fullWidth
                  label={cost?.othersCurrency || ""}
                  value={cost?.othersCurrency || ""}
                  id="outlined-select-status"
                  select
                  onChange={(e) =>
                    setCost({ ...cost, othersCurrency: e.target.value })
                  }
                >
                  {currencies.map((option) => (
                    <MenuItem key={option.value} value={option.value}>
                      {option.label}
                    </MenuItem>
                  ))}
                </TextField>
              </Grid>
              <Grid item xs={12} sm={1}>
                <Typography variant="body2">Exchange</Typography>
                <Button
                  variant="outlined"
                  disabled={cost?.othersCurrency === "USD"}
                  onClick={() =>
                    handleCurrencyExchange(
                      "othersCurrency",
                      cost?.others || 0.0,
                    )
                  }
                >
                  $
                </Button>
                <Button
                  variant="outlined"
                  disabled={cost?.othersCurrency === "EUR"}
                  onClick={() =>
                    handleCurrencyExchange(
                      "othersCurrency",
                      cost?.others || 0.0,
                    )
                  }
                >
                  €
                </Button>
              </Grid>
              <Grid item xs={12} sm={2}>
                <Typography variant="body2">Other Costs Remark</Typography>
                <TextField
                  variant="outlined"
                  size="small"
                  value={cost?.othersRemark || ""}
                  fullWidth
                  onChange={(e) =>
                    setCost({ ...cost, othersRemark: e.target.value })
                  }
                />
              </Grid>

              <Grid item xs={12} sm={2}>
                <Typography variant="body2">First Commision Per Kg</Typography>
                <TextField
                  type="number"
                  variant="outlined"
                  size="small"
                  value={cost?.firstCommision || ""}
                  fullWidth
                  onChange={(e) =>
                    setCost({ ...cost, firstCommision: e.target.value })
                  }
                />
              </Grid>

              <Grid item xs={12} sm={1}>
                <Typography variant="body2">
                  First Commision Currency
                </Typography>
                <TextField
                  variant="outlined"
                  size="small"
                  fullWidth
                  label={cost?.firstCommisionCurrency || ""}
                  value={cost?.firstCommisionCurrency || ""}
                  id="outlined-select-status"
                  select
                  onChange={(e) =>
                    setCost({ ...cost, firstCommisionCurrency: e.target.value })
                  }
                >
                  {currencies.map((option) => (
                    <MenuItem key={option.value} value={option.value}>
                      {option.label}
                    </MenuItem>
                  ))}
                </TextField>
              </Grid>
              <Grid item xs={12} sm={1}>
                <Typography variant="body2">Exchange</Typography>
                <Button
                  variant="outlined"
                  disabled={cost?.firstCommisionCurrency === "USD"}
                  onClick={() =>
                    handleCurrencyExchange(
                      "firstCommisionCurrency",
                      cost?.firstCommision || 0.0,
                    )
                  }
                >
                  $
                </Button>
                <Button
                  variant="outlined"
                  disabled={cost?.firstCommisionCurrency === "EUR"}
                  onClick={() =>
                    handleCurrencyExchange(
                      "firstCommisionCurrency",
                      cost?.firstCommision || 0.0,
                    )
                  }
                >
                  €
                </Button>
              </Grid>
              <Grid item xs={12} sm={2}>
                <Typography variant="body2">Second Commision Per Kg</Typography>
                <TextField
                  type="number"
                  variant="outlined"
                  size="small"
                  value={cost?.secondCommision || ""}
                  fullWidth
                  onChange={(e) =>
                    setCost({ ...cost, secondCommision: e.target.value })
                  }
                />
              </Grid>

              <Grid item xs={12} sm={1}>
                <Typography variant="body2">
                  Second Commision Currency
                </Typography>
                <TextField
                  variant="outlined"
                  size="small"
                  fullWidth
                  label={cost?.secondCommisionCurrency || ""}
                  value={cost?.secondCommisionCurrency || ""}
                  id="outlined-select-status"
                  select
                  onChange={(e) =>
                    setCost({
                      ...cost,
                      secondCommisionCurrency: e.target.value,
                    })
                  }
                >
                  {currencies.map((option) => (
                    <MenuItem key={option.value} value={option.value}>
                      {option.label}
                    </MenuItem>
                  ))}
                </TextField>
              </Grid>
              <Grid item xs={12} sm={1}>
                <Typography variant="body2">Exchange</Typography>
                <Button
                  variant="outlined"
                  disabled={cost?.secondCommisionCurrency === "USD"}
                  onClick={() =>
                    handleCurrencyExchange(
                      "secondCommisionCurrency",
                      cost?.secondCommision || 0.0,
                    )
                  }
                >
                  $
                </Button>
                <Button
                  variant="outlined"
                  disabled={cost?.secondCommisionCurrency === "EUR"}
                  onClick={() =>
                    handleCurrencyExchange(
                      "secondCommisionCurrency",
                      cost?.secondCommision || 0.0,
                    )
                  }
                >
                  €
                </Button>
              </Grid>
              <Grid item xs={12} sm={2}>
                <Typography variant="body2">Third Commision Per Kg</Typography>
                <TextField
                  type="number"
                  variant="outlined"
                  size="small"
                  value={cost?.thirdCommision || ""}
                  fullWidth
                  onChange={(e) =>
                    setCost({ ...cost, thirdCommision: e.target.value })
                  }
                />
              </Grid>

              <Grid item xs={12} sm={1}>
                <Typography variant="body2">
                  Third Commision Currency
                </Typography>
                <TextField
                  variant="outlined"
                  size="small"
                  fullWidth
                  label={cost?.thirdCommisionCurrency || ""}
                  value={cost?.thirdCommisionCurrency || ""}
                  id="outlined-select-status"
                  select
                  onChange={(e) =>
                    setCost({ ...cost, thirdCommisionCurrency: e.target.value })
                  }
                >
                  {currencies.map((option) => (
                    <MenuItem key={option.value} value={option.value}>
                      {option.label}
                    </MenuItem>
                  ))}
                </TextField>
              </Grid>
              <Grid item xs={12} sm={1}>
                <Typography variant="body2">Exchange</Typography>
                <Button
                  variant="outlined"
                  disabled={cost?.thirdCommisionCurrency === "USD"}
                  onClick={() =>
                    handleCurrencyExchange(
                      "thirdCommisionCurrency",
                      cost?.thirdCommision || 0.0,
                    )
                  }
                >
                  $
                </Button>
                <Button
                  variant="outlined"
                  disabled={cost?.thirdCommisionCurrency === "EUR"}
                  onClick={() =>
                    handleCurrencyExchange(
                      "thirdCommisionCurrency",
                      cost?.thirdCommision || 0.0,
                    )
                  }
                >
                  €
                </Button>
              </Grid>
              <Grid item xs={12} sm={2}>
                <Typography variant="body2">
                  Total Unit Cost Before Profit
                </Typography>
                <Grid container spacing={1} alignItems="center">
                  <Grid Grid item xs={10} sm={9}>
                    <TextField
                      type="number"
                      variant="outlined"
                      size="small"
                      value={cost?.totalCost || ""}
                      fullWidth
                      onChange={(e) => {
                        setCost({ ...cost, totalCost: e.target.value });
                      }}
                    />
                  </Grid>
                  <Grid item xs={1} sm={1}>
                    <Button
                      style={{ padding: 0, color: "red" }}
                      onClick={calculateTotalCost}
                    >
                      <ReloadOutlined style={{ fontSize: "16px" }} />
                    </Button>
                  </Grid>
                </Grid>
              </Grid>

              <Grid item xs={12} sm={2}>
                <Typography variant="body2">Profit Per Kg</Typography>
                <TextField
                  type="number"
                  variant="outlined"
                  size="small"
                  fullWidth
                  value={cost?.profit || ""}
                  onChange={(e) =>
                    setCost({
                      ...cost,
                      profit: e.target.value,
                    })
                  }
                />
              </Grid>

              <Grid item xs={12} sm={1}>
                <Typography variant="body2">Profit Currency</Typography>
                <TextField
                  variant="outlined"
                  size="small"
                  fullWidth
                  id="outlined-select-profit-currency"
                  select
                  label={cost?.profitCurrency || ""}
                  value={cost?.profitCurrency || ""}
                  onChange={(e) =>
                    setCost({
                      ...cost,
                      profitCurrency: e.target.value,
                    })
                  }
                >
                  {currencies.map((option) => (
                    <MenuItem key={option.value} value={option.value}>
                      {option.label}
                    </MenuItem>
                  ))}
                </TextField>
              </Grid>
              <Grid item xs={12} sm={1}>
                <Typography variant="body2">Exchange</Typography>
                <Button
                  variant="outlined"
                  disabled={cost?.profitCurrency === "USD"}
                  onClick={() =>
                    handleCurrencyExchange(
                      "profitCurrency",
                      cost?.profit || 0.0,
                    )
                  }
                >
                  $
                </Button>
                <Button
                  variant="outlined"
                  disabled={cost?.profitCurrency === "EUR"}
                  onClick={() =>
                    handleCurrencyExchange(
                      "profitCurrency",
                      cost?.profit || 0.0,
                    )
                  }
                >
                  €
                </Button>
              </Grid>
              <Grid item xs={12} sm={2}>
                <Typography variant="body2">
                  Total Unit Cost After Profit
                </Typography>
                <Grid container spacing={1} alignItems="center">
                  <Grid Grid item xs={10} sm={9}>
                    <TextField
                      type="number"
                      variant="outlined"
                      size="small"
                      value={cost?.totalOfferCost || ""}
                      fullWidth
                      onChange={(e) => {
                        setCost({ ...cost, totalOfferCost: e.target.value });
                      }}
                    />
                  </Grid>
                  <Grid item xs={1} sm={1}>
                    <Button
                      style={{ padding: 0, color: "red" }}
                      onClick={calculateTotalCostWithProfit}
                    >
                      <ReloadOutlined style={{ fontSize: "16px" }} />
                    </Button>
                  </Grid>
                </Grid>
              </Grid>

              <Grid item xs={12} sm={2}>
                <Typography variant="body2">Cost Remark</Typography>
                <TextField
                  variant="outlined"
                  size="small"
                  value={cost?.remark || ""}
                  fullWidth
                  onChange={(e) => setCost({ ...cost, remark: e.target.value })}
                />
              </Grid>

              <Grid item xs={12} sm={2}>
                <Typography variant="body2">Cost Record Status</Typography>
                <TextField
                  id="outlined-select-status"
                  select
                  variant="outlined"
                  size="small"
                  label={cost?.status}
                  fullWidth
                  onChange={(e) => setCost({ ...cost, status: e.target.value })}
                >
                  {recordStatus.map((option) => (
                    <MenuItem key={option.value} value={option.value}>
                      {option.label}
                    </MenuItem>
                  ))}
                </TextField>
              </Grid>

              <Grid item xs={12} sm={2}>
                <Button
                  variant="outlined"
                  size="small"
                  sx={{ ml: 1.5, mt: 4, mr: 1 }}
                  onClick={() => {
                    setIsEditCostOpen(false);
                    setCost([]);
                  }}
                >
                  Cancel
                </Button>
                <Button
                  variant="contained"
                  size="small"
                  sx={{ mt: 4 }}
                  onClick={() => {
                    updateCostApi(cost.id, cost)
                      .then((response) => {
                        toast.success("Cost edited successfully.");
                        setCost([]);
                      })
                      .catch((error) => {
                        toast.error("Something went wrong.");
                        console.log(error.response.data);
                      });
                    setIsEditCostOpen(false);
                    setCost([]);
                  }}
                >
                  Save Cost
                </Button>
              </Grid>
            </Grid>{" "}
          </>
        )}

        {isEditItemOpen && (
          <>
            {" "}
            <Grid item xs={12} style={{ position: "relative" }}>
              <Typography
                variant="h3"
                align="center"
                style={{
                  color: "#777f87",
                  position: "absolute",
                  top: "10px",
                  left: "50%",
                  transform: "translateX(-50%)",
                  backgroundColor: "#fafafb",
                  padding: "0 10px",
                }}
              >
                Edit Item {item?.id} Details
              </Typography>
            </Grid>
            <Grid
              container
              spacing="12px"
              style={{
                border: "1px solid #dbd9d9",
                borderRadius: "20px",
                marginTop: "30px",
                padding: "20px",
                boxShadow: "2px 4px 8px rgba(0, 0, 0, 0.2)",
              }}
            >
              <Grid container xs={12} spacing="8px">
                <Grid item xs={12} sm={2}>
                  <Typography variant="body2">Enter Tobacco Code</Typography>
                  <TextField
                    variant="outlined"
                    size="small"
                    fullWidth
                    value={item?.tobaccoCode || ""}
                    onChange={(e) =>
                      setItem({
                        ...item,
                        tobaccoCode: e.target.value,
                      })
                    }
                  />
                </Grid>
                <Grid item xs={12} sm={2}>
                  <Typography variant="body2">Enter Sales Grade</Typography>
                  <TextField
                    variant="outlined"
                    size="small"
                    fullWidth
                    value={item?.salesGrade || ""}
                    onChange={(e) =>
                      setItem({
                        ...item,
                        salesGrade: e.target.value,
                      })
                    }
                  />
                </Grid>
                <Grid item xs={12} sm={2}>
                  <Typography variant="body2">Enter Buying Grade</Typography>
                  <TextField
                    variant="outlined"
                    size="small"
                    fullWidth
                    value={item?.buyingGrade || ""}
                    onChange={(e) =>
                      setItem({
                        ...item,
                        buyingGrade: e.target.value,
                      })
                    }
                  />
                </Grid>
                <Grid item xs={12} sm={2}>
                  <Typography variant="body2">Internal Grade</Typography>
                  <TextField
                    variant="outlined"
                    size="small"
                    fullWidth
                    value={item?.internalGrade || ""}
                    onChange={(e) =>
                      setItem({
                        ...item,
                        internalGrade: e.target.value,
                      })
                    }
                  />
                </Grid>

                <Grid item xs={12} sm={2}>
                  <Typography variant="body2"> Origin</Typography>
                  <Autocomplete
                    options={origin}
                    value={selectedOrigin || ""}
                    onChange={(event, newValue) => {
                      const newTerm = newValue ? newValue.value : "";
                      setSelectedOrigin(newTerm);
                      setItem({
                        ...item,
                        origin: newTerm,
                      });
                    }}
                    isOptionEqualToValue={(option, value) =>
                      option.value === value.value
                    }
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        variant="outlined"
                        size="small"
                        label={item?.origin}
                        fullWidth
                      />
                    )}
                    renderTags={() => null}
                  />
                  {selectedOrigin && (
                    <Box mt={2}>
                      <Chip
                        label={
                          origin.find((term) => term.value === selectedOrigin)
                            ?.label || ""
                        }
                        onDelete={() => setSelectedOrigin("")}
                      />
                    </Box>
                  )}
                </Grid>

                {(selectedOrigin === "China" || item?.origin === "China") && (
                  <Grid item xs={12} sm={2}>
                    <Typography variant="body2">Province</Typography>
                    <TextField
                      variant="outlined"
                      size="small"
                      fullWidth
                      value={item?.province || ""}
                      onChange={(e) =>
                        setItem({
                          ...item,
                          province: e.target.value,
                        })
                      }
                    />
                  </Grid>
                )}
                <Grid item xs={12} sm={2}>
                  <Typography variant="body2"> Type</Typography>
                  <TextField
                    variant="outlined"
                    size="small"
                    fullWidth
                    label={item?.type || ""}
                    id="outlined-select-type"
                    select
                    onChange={(e) =>
                      setItem({
                        ...item,
                        type: e.target.value,
                      })
                    }
                  >
                    {type.map((option) => (
                      <MenuItem key={option.value} value={option.value}>
                        {option.label}
                      </MenuItem>
                    ))}
                  </TextField>
                </Grid>
                <Grid item xs={12} sm={2}>
                  <Typography variant="body2"> Leaf Form</Typography>
                  <TextField
                    variant="outlined"
                    size="small"
                    fullWidth
                    label={item?.leafForm || ""}
                    id="outlined-select-leaf-form"
                    select
                    onChange={(e) =>
                      setItem({ ...item, leafForm: e.target.value })
                    }
                  >
                    {leafForm.map((option) => (
                      <MenuItem key={option.value} value={option.value}>
                        {option.label}
                      </MenuItem>
                    ))}
                  </TextField>
                </Grid>
                <Grid item xs={12} sm={2}>
                  <Typography variant="body2"> Color</Typography>
                  <TextField
                    variant="outlined"
                    size="small"
                    fullWidth
                    label={item?.color || ""}
                    id="outlined-select-color"
                    select
                    onChange={(e) =>
                      setItem({ ...item, color: e.target.value })
                    }
                  >
                    {color.map((option) => (
                      <MenuItem key={option.value} value={option.value}>
                        {option.label}
                      </MenuItem>
                    ))}
                  </TextField>
                </Grid>
                <Grid item xs={12} sm={2}>
                  <Typography variant="body2">Enter Crop Year</Typography>
                  <TextField
                    size="small"
                    fullWidth
                    value={item?.crop || ""}
                    onChange={(e) =>
                      setItem({
                        ...item,
                        crop: e.target.value,
                      })
                    }
                  />
                </Grid>
                <Grid item xs={12} sm={2}>
                  <Typography variant="body2">Current Location</Typography>
                  <TextField
                    variant="outlined"
                    size="small"
                    fullWidth
                    id="outlined-select-storage"
                    value={item?.storage || ""}
                    onChange={(e) =>
                      setItem({
                        ...item,
                        storage: e.target.value,
                      })
                    }
                  />
                </Grid>
                <Grid item xs={12} sm={2}>
                  <Typography variant="body2">Nicotine</Typography>
                  <TextField
                    size="small"
                    type="number"
                    fullWidth
                    value={item?.nicotine || ""}
                    onChange={(e) =>
                      setItem({
                        ...item,
                        nicotine: e.target.value,
                      })
                    }
                  />
                </Grid>

                <Grid item xs={12} sm={2}>
                  <Typography variant="body2">Sugar</Typography>
                  <TextField
                    size="small"
                    type="number"
                    fullWidth
                    value={item?.sugar || ""}
                    variant="outlined"
                    onChange={(e) =>
                      setItem({
                        ...item,
                        sugar: e.target.value,
                      })
                    }
                  />
                </Grid>

                <Grid item xs={12} sm={2}>
                  <Typography variant="body2">Moisture</Typography>
                  <TextField
                    size="small"
                    type="number"
                    fullWidth
                    value={item?.moisture || ""}
                    variant="outlined"
                    onChange={(e) =>
                      setItem({
                        ...item,
                        moisture: e.target.value,
                      })
                    }
                  />
                </Grid>

                <Grid item xs={12} sm={2}>
                  <Typography variant="body2">Status</Typography>
                  <TextField
                    variant="outlined"
                    size="small"
                    fullWidth
                    label={item?.status}
                    id="outlined-select-status"
                    select
                    onChange={(e) =>
                      setItem({
                        ...item,
                        status: e.target.value,
                      })
                    }
                  >
                    {recordStatus.map((option) => (
                      <MenuItem key={option.value} value={option.value}>
                        {option.label}
                      </MenuItem>
                    ))}
                  </TextField>
                </Grid>
              </Grid>

              <Grid item xs={12} sm={2}>
                <Button
                  variant="outlined"
                  size="small"
                  sx={{ ml: 1.5, mt: 4, mr: 1 }}
                  onClick={() => {
                    setIsEditItemOpen(false);
                    setItem([]);
                    setSelectedOrigin("");
                  }}
                >
                  Cancel
                </Button>
                <Button
                  variant="contained"
                  size="small"
                  sx={{ mt: 4 }}
                  onClick={() => {
                    updateItemApi(item.id, item)
                      .then((response) => {
                        toast.success("Item edited successfully.");
                      })
                      .catch((error) => {
                        toast.error("Something went wrong.");
                        console.log(error.response.data);
                      });
                    setIsEditItemOpen(false);
                    setItem([]);
                    setSelectedOrigin("");
                  }}
                >
                  Save Item
                </Button>
              </Grid>
            </Grid>{" "}
          </>
        )}

        {isNewSampleOpen && (
          <>
            {" "}
            <Grid item xs={12} style={{ position: "relative" }}>
              <Typography
                variant="h3"
                align="center"
                style={{
                  color: "#777f87",
                  position: "absolute",
                  top: "10px",
                  left: "50%",
                  transform: "translateX(-50%)",
                  backgroundColor: "#fafafb",
                  padding: "0 10px",
                }}
              >
                New Sample Details
              </Typography>
            </Grid>
            <Grid
              container
              spacing="12px"
              style={{
                border: "1px solid #dbd9d9",
                borderRadius: "20px",
                marginTop: "30px",
                padding: "20px",
                boxShadow: "2px 4px 8px rgba(0, 0, 0, 0.2)",
              }}
            >
              <Grid container xs={12} spacing="8px">
                <Grid item xs={12} sm={2}>
                  <Typography variant="body2">Sample Purpose</Typography>
                  <TextField
                    id="outlined-select-status"
                    select
                    variant="outlined"
                    size="small"
                    fullWidth
                    onChange={(e) =>
                      setSample({
                        ...sample,
                        purpose: e.target.value,
                      })
                    }
                  >
                    {samplePurposes.map((option) => (
                      <MenuItem key={option.value} value={option.value}>
                        {option.label}
                      </MenuItem>
                    ))}
                  </TextField>
                </Grid>
                <Grid item xs={12} sm={2}>
                  <Typography variant="body2">Item</Typography>
                  <Autocomplete
                    options={items}
                    getOptionLabel={(option) =>
                      `ID: ${option?.id || ""} - Buying Grade: ${
                        option.buyingGrade || ""
                      }  - ${option?.origin || ""} - Crop: ${option?.crop || ""}`
                    }
                    value={sample?.itemId}
                    onChange={(event, newValue) => {
                      setSample({
                        ...sample,
                        itemId: newValue ? newValue.id : null,
                      });
                    }}
                    onOpen={retrieveItems}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        variant="outlined"
                        size="small"
                        fullWidth
                      />
                    )}
                    renderTags={() => null}
                  />
                  {sample?.itemId && (
                    <Box mt={2}>
                      <Chip
                        label={`ID: ${
                          items.find((item) => item.id === sample?.itemId)
                            ?.id || ""
                        }`}
                        style={{ marginBottom: "5px" }}
                        onDelete={() =>
                          setSample({
                            ...sample,
                            itemId: null,
                          })
                        }
                      />
                      <br />
                      <Chip
                        label={`Buying Grade: ${
                          items.find((item) => item.id === sample?.itemId)
                            ?.buyingGrade || ""
                        }`}
                        style={{ marginBottom: "5px" }}
                      />
                      <br />
                      <Chip
                        label={`Crop: ${
                          items.find((item) => item.id === sample?.itemId)
                            ?.crop || ""
                        }`}
                        style={{ marginBottom: "5px" }}
                      />
                      <br />
                    </Box>
                  )}
                </Grid>

                <Grid item xs={12} sm={2}>
                  <Typography variant="body2">Sample Image Link</Typography>
                  <TextField
                    variant="outlined"
                    size="small"
                    fullWidth
                    onChange={(e) =>
                      setSample({
                        ...sample,
                        image: e.target.value,
                      })
                    }
                  />
                </Grid>
                <Grid item xs={12} sm={2}>
                  <Typography variant="body2">Sender</Typography>
                  <Autocomplete
                    options={stakeholders}
                    getOptionLabel={(option) =>
                      `${option.id || ""} - ${option.type || ""} - ${
                        option.name || ""
                      }`
                    }
                    value={sample?.senderId}
                    onChange={(event, newValue) => {
                      setSample({
                        ...sample,
                        senderId: newValue ? newValue.id : null,
                      });
                    }}
                    onOpen={retrieveStakeholders}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        variant="outlined"
                        size="small"
                        fullWidth
                      />
                    )}
                    renderTags={() => null}
                  />
                  {sample?.senderId && (
                    <Box mt={2}>
                      <Chip
                        label={`ID: ${
                          stakeholders.find(
                            (stakeholder) =>
                              stakeholder.id === sample?.senderId,
                          )?.id || ""
                        }`}
                        style={{ marginBottom: "5px" }}
                        onDelete={() =>
                          setSample({
                            ...sample,
                            senderId: null,
                          })
                        }
                      />
                      <br />
                      <Chip
                        label={`Type: ${
                          stakeholders.find(
                            (stakeholder) =>
                              stakeholder.id === sample?.senderId,
                          )?.type || ""
                        }`}
                        style={{ marginBottom: "5px" }}
                      />
                      <br />
                      <Chip
                        label={`Name: ${
                          stakeholders.find(
                            (stakeholder) =>
                              stakeholder.id === sample?.senderId,
                          )?.name || ""
                        }`}
                        style={{ marginBottom: "5px" }}
                      />
                      <br />
                    </Box>
                  )}
                </Grid>

                <Grid item xs={12} sm={2}>
                  <Typography variant="body2">Receiver</Typography>
                  <Autocomplete
                    options={stakeholders}
                    getOptionLabel={(option) =>
                      `${option.id || ""} - ${option.type || ""} - ${
                        option.name || ""
                      }`
                    }
                    value={sample?.receiverId}
                    onChange={(event, newValue) => {
                      setSample({
                        ...sample,
                        receiverId: newValue ? newValue.id : null,
                      });
                    }}
                    onOpen={retrieveStakeholders}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        variant="outlined"
                        size="small"
                        fullWidth
                      />
                    )}
                    renderTags={() => null}
                  />
                  {sample?.receiverId && (
                    <Box mt={2}>
                      <Chip
                        label={`ID: ${
                          stakeholders.find(
                            (stakeholder) =>
                              stakeholder.id === sample?.receiverId,
                          )?.id || ""
                        }`}
                        style={{ marginBottom: "5px" }}
                        onDelete={() =>
                          setSample({
                            ...sample,
                            receiverId: null,
                          })
                        }
                      />
                      <br />
                      <Chip
                        label={`Type: ${
                          stakeholders.find(
                            (stakeholder) =>
                              stakeholder.id === sample?.receiverId,
                          )?.type || ""
                        }`}
                        style={{ marginBottom: "5px" }}
                      />
                      <br />
                      <Chip
                        label={`Name: ${
                          stakeholders.find(
                            (stakeholder) =>
                              stakeholder.id === sample?.receiverId,
                          )?.name || ""
                        }`}
                        style={{ marginBottom: "5px" }}
                      />
                      <br />
                    </Box>
                  )}
                </Grid>

                <Grid item xs={12} sm={2}>
                  <Typography variant="body2">Enter Sales Grade</Typography>
                  <TextField
                    variant="outlined"
                    size="small"
                    fullWidth
                    onChange={(e) =>
                      setSample({
                        ...sample,
                        salesGrade: e.target.value,
                      })
                    }
                  />
                </Grid>
                <Grid item xs={12} sm={2}>
                  <Typography variant="body2">Enter Sales Nicotine</Typography>
                  <TextField
                    variant="outlined"
                    size="small"
                    type="number"
                    fullWidth
                    onChange={(e) =>
                      setSample({
                        ...sample,
                        salesNicotine: e.target.value,
                      })
                    }
                  />
                </Grid>
                <Grid item xs={12} sm={2}>
                  <Typography variant="body2">Enter Sales Sugar</Typography>
                  <TextField
                    variant="outlined"
                    size="small"
                    type="number"
                    fullWidth
                    onChange={(e) =>
                      setSample({
                        ...sample,
                        salesSugar: e.target.value,
                      })
                    }
                  />
                </Grid>

                <Grid item xs={12} sm={2}>
                  <Typography variant="body2">Enter Sales Moisture</Typography>
                  <TextField
                    variant="outlined"
                    size="small"
                    type="number"
                    fullWidth
                    onChange={(e) =>
                      setSample({
                        ...sample,
                        salesMoisture: e.target.value,
                      })
                    }
                  />
                </Grid>
                <Grid item xs={12} sm={2}>
                  <Typography variant="body2">Enter Sales Origin</Typography>
                  <TextField
                    variant="outlined"
                    size="small"
                    fullWidth
                    onChange={(e) =>
                      setSample({
                        ...sample,
                        salesOrigin: e.target.value,
                      })
                    }
                  />
                </Grid>
                <Grid item xs={12} sm={2}>
                  <Typography variant="body2">Enter Sales Crop</Typography>
                  <TextField
                    variant="outlined"
                    size="small"
                    fullWidth
                    onChange={(e) =>
                      setSample({
                        ...sample,
                        salesCrop: e.target.value,
                      })
                    }
                  />
                </Grid>

                <Grid item xs={12} sm={2}>
                  <Typography variant="body2">
                    Enter Date Of Delivery
                  </Typography>
                  <TextField
                    size="small"
                    fullWidth
                    variant="outlined"
                    id="date"
                    type="date"
                    inputProps={{ min: "1999-01-01", max: "2999-01-01" }}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    onChange={(e) =>
                      setSample({
                        ...sample,
                        deliveryDate: e.target.value,
                      })
                    }
                  />
                </Grid>
                <Grid item xs={12} sm={2}>
                  <Typography variant="body2">Enter Date Of Receive</Typography>
                  <TextField
                    size="small"
                    fullWidth
                    variant="outlined"
                    id="date"
                    type="date"
                    inputProps={{ min: "1999-01-01", max: "2999-01-01" }}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    onChange={(e) =>
                      setSample({
                        ...sample,
                        receiveDate: e.target.value,
                      })
                    }
                  />
                </Grid>
                <Grid item xs={12} sm={2}>
                  <Typography variant="body2">
                    Enter Courier Information
                  </Typography>
                  <TextField
                    variant="outlined"
                    size="small"
                    fullWidth
                    onChange={(e) =>
                      setSample({
                        ...sample,
                        courier: e.target.value,
                      })
                    }
                  />
                </Grid>
                <Grid item xs={12} sm={2}>
                  <Typography variant="body2">Enter AWB Number</Typography>
                  <TextField
                    variant="outlined"
                    size="small"
                    fullWidth
                    onChange={(e) =>
                      setSample({
                        ...sample,
                        awbNumber: e.target.value,
                      })
                    }
                  />
                </Grid>
                <Grid item xs={12} sm={2}>
                  <Typography variant="body2">Enter Remark</Typography>
                  <TextField
                    variant="outlined"
                    size="small"
                    fullWidth
                    onChange={(e) =>
                      setSample({
                        ...sample,
                        remark: e.target.value,
                      })
                    }
                  />
                </Grid>
                <Grid item xs={12} sm={2}>
                  <Typography variant="body2">
                    Enter Customer Feedback
                  </Typography>
                  <TextField
                    variant="outlined"
                    size="small"
                    fullWidth
                    onChange={(e) =>
                      setSample({
                        ...sample,
                        customerFeedback: e.target.value,
                      })
                    }
                  />
                </Grid>
                <Grid item xs={12} sm={2}>
                  <Typography variant="body2">Is Sample Approved</Typography>
                  <TextField
                    variant="outlined"
                    size="small"
                    fullWidth
                    onChange={(e) =>
                      setSample({
                        ...sample,
                        approved: e.target.value,
                      })
                    }
                  />
                </Grid>
                <Grid item xs={12} sm={2}>
                  <Typography variant="body2">
                    Enter SG Approval Status
                  </Typography>
                  <TextField
                    variant="outlined"
                    size="small"
                    fullWidth
                    onChange={(e) =>
                      setSample({
                        ...sample,
                        approvalStatus: e.target.value,
                      })
                    }
                  />
                </Grid>
                <Grid item xs={12} sm={2}>
                  <Typography variant="body2">Sample Paid By</Typography>
                  <TextField
                    variant="outlined"
                    size="small"
                    fullWidth
                    onChange={(e) =>
                      setSample({
                        ...sample,
                        paidBy: e.target.value,
                      })
                    }
                  />
                </Grid>
                <Grid item xs={12} sm={2}>
                  <Typography variant="body2">Status</Typography>
                  <TextField
                    id="outlined-select-status"
                    select
                    variant="outlined"
                    size="small"
                    fullWidth
                    onChange={(e) =>
                      setSample({
                        ...sample,
                        status: e.target.value,
                      })
                    }
                  >
                    {recordStatus.map((option) => (
                      <MenuItem key={option.value} value={option.value}>
                        {option.label}
                      </MenuItem>
                    ))}
                  </TextField>
                </Grid>
              </Grid>

              <Grid item xs={12} sm={2}>
                <Button
                  variant="outlined"
                  size="small"
                  sx={{ ml: 1.5, mt: 4, mr: 1 }}
                  onClick={() => {
                    setIsNewSampleOpen(false);
                    setSample([]);
                    setSelectedOrigin("");
                  }}
                >
                  Cancel
                </Button>
                <Button
                  variant="contained"
                  size="small"
                  sx={{ mt: 4 }}
                  onClick={() => {
                    createSampleApi(sample)
                      .then((response) => {
                        toast.success("Sample added successfully.");
                      })
                      .catch((error) => {
                        console.log(error.response.data);
                        toast.error("Something Went Wrong.");
                      });
                    setIsNewSampleOpen(false);
                    setSample([]);
                    setSelectedOrigin("");
                  }}
                >
                  Create Sample
                </Button>
              </Grid>
            </Grid>{" "}
          </>
        )}
        {isEditSampleOpen && (
          <>
            {" "}
            <Grid item xs={12} style={{ position: "relative" }}>
              <Typography
                variant="h3"
                align="center"
                style={{
                  color: "#777f87",
                  position: "absolute",
                  top: "10px",
                  left: "50%",
                  transform: "translateX(-50%)",
                  backgroundColor: "#fafafb",
                  padding: "0 10px",
                }}
              >
                Edit Sample {sample?.id} Details
              </Typography>
            </Grid>
            <Grid
              container
              spacing="12px"
              style={{
                border: "1px solid #dbd9d9",
                borderRadius: "20px",
                marginTop: "30px",
                padding: "20px",
                boxShadow: "2px 4px 8px rgba(0, 0, 0, 0.2)",
              }}
            >
              <Grid container xs={12} spacing="8px">
                <Grid item xs={12} sm={2}>
                  <Typography variant="body2"> Sample Purpose</Typography>
                  <TextField
                    variant="outlined"
                    size="small"
                    fullWidth
                    id="outlined-select-purpose"
                    select
                    value={sample?.purpose || ""}
                    onChange={(e) =>
                      setSample({
                        ...sample,
                        purpose: e.target.value,
                      })
                    }
                  >
                    {samplePurposes.map((option) => (
                      <MenuItem key={option.value} value={option.value}>
                        {option.label}
                      </MenuItem>
                    ))}
                  </TextField>
                </Grid>

                <Grid item xs={12} sm={2}>
                  <Typography variant="body2">Item</Typography>
                  <Autocomplete
                    options={items}
                    getOptionLabel={(option) =>
                      `ID: ${option.id || ""} - Buying Grade: ${
                        option.buyingGrade || ""
                      } - ${option?.origin || ""} - Crop: ${option.crop || ""}`
                    }
                    value={sample?.item?.id}
                    onChange={(event, newValue) => {
                      setSample({
                        ...sample,
                        item: {
                          ...sample?.item,
                          id: newValue ? newValue.id : null,
                        },
                      });
                    }}
                    onOpen={retrieveItems}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        variant="outlined"
                        size="small"
                        fullWidth
                        label={
                          (sample?.item?.id || "") +
                          " " +
                          (sample?.item?.buyingGrade || "")
                        }
                      />
                    )}
                    renderTags={() => null}
                  />
                  {sample?.item?.id && (
                    <Box mt={2}>
                      <Chip
                        label={`ID: ${
                          items.find((item) => item.id === sample?.item.id)
                            ?.id || ""
                        }`}
                        style={{ marginBottom: "5px" }}
                        onDelete={() =>
                          setSample({
                            ...sample,
                            item: {
                              ...sample?.item,
                              id: null,
                            },
                          })
                        }
                      />
                      <br />
                      <Chip
                        label={`Buying Grade: ${
                          items.find((item) => item.id === sample?.item.id)
                            ?.buyingGrade || ""
                        }`}
                        style={{ marginBottom: "5px" }}
                      />
                      <br />
                      <Chip
                        label={`Crop: ${
                          items.find((item) => item.id === sample?.item.id)
                            ?.crop || ""
                        }`}
                        style={{ marginBottom: "5px" }}
                      />
                      <br />
                    </Box>
                  )}
                </Grid>

                <Grid item xs={12} sm={2}>
                  <Typography variant="body2">Image URL</Typography>
                  <TextField
                    variant="outlined"
                    size="small"
                    fullWidth
                    value={sample?.image || ""}
                    onChange={(e) =>
                      setSample({
                        ...sample,
                        image: e.target.value,
                      })
                    }
                  />
                </Grid>

                <Grid item xs={12} sm={2}>
                  <Typography variant="body2">Sender</Typography>
                  <Autocomplete
                    options={stakeholders}
                    getOptionLabel={(option) =>
                      `${option.id || ""} - ${option.type || ""} - ${
                        option.name || ""
                      }`
                    }
                    value={sample?.sender?.id}
                    onChange={(event, newValue) => {
                      setSample({
                        ...sample,
                        sender: {
                          ...sample?.sender,
                          id: newValue ? newValue.id : null,
                        },
                      });
                    }}
                    onOpen={retrieveStakeholders}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        variant="outlined"
                        size="small"
                        fullWidth
                        label={
                          (sample?.sender?.id || "") +
                          " " +
                          (sample?.sender?.name || "")
                        }
                      />
                    )}
                    renderTags={() => null}
                  />
                  {sample?.sender?.id && (
                    <Box mt={2}>
                      <Chip
                        label={`ID: ${
                          stakeholders.find(
                            (stakeholder) =>
                              stakeholder.id === sample?.sender.id,
                          )?.id || ""
                        }`}
                        style={{ marginBottom: "5px" }}
                        onDelete={() =>
                          setSample({
                            ...sample,
                            sender: {
                              ...sample?.sender,
                              id: null,
                            },
                          })
                        }
                      />
                      <br />
                      <Chip
                        label={`Type: ${
                          stakeholders.find(
                            (stakeholder) =>
                              stakeholder.id === sample?.sender.id,
                          )?.type || ""
                        }`}
                        style={{ marginBottom: "5px" }}
                      />
                      <br />
                      <Chip
                        label={`Name: ${
                          stakeholders.find(
                            (stakeholder) =>
                              stakeholder.id === sample?.sender.id,
                          )?.name || ""
                        }`}
                        style={{ marginBottom: "5px" }}
                      />
                      <br />
                    </Box>
                  )}
                </Grid>

                <Grid item xs={12} sm={2}>
                  <Typography variant="body2">Receiver</Typography>
                  <Autocomplete
                    options={stakeholders}
                    getOptionLabel={(option) =>
                      `${option.id || ""} - ${option.type || ""} - ${
                        option.name || ""
                      }`
                    }
                    value={sample?.receiver?.id}
                    onChange={(event, newValue) => {
                      setSample({
                        ...sample,
                        receiver: {
                          ...sample?.receiver,
                          id: newValue ? newValue.id : null,
                        },
                      });
                    }}
                    onOpen={retrieveStakeholders}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        variant="outlined"
                        size="small"
                        fullWidth
                        label={
                          (sample?.receiver?.id || "") +
                          " " +
                          (sample?.receiver?.name || "")
                        }
                      />
                    )}
                    renderTags={() => null}
                  />
                  {sample?.receiver?.id && (
                    <Box mt={2}>
                      <Chip
                        label={`ID: ${
                          stakeholders.find(
                            (stakeholder) =>
                              stakeholder.id === sample?.receiver.id,
                          )?.id || ""
                        }`}
                        style={{ marginBottom: "5px" }}
                        onDelete={() =>
                          setSample({
                            ...sample,
                            receiver: {
                              ...sample?.receiver,
                              id: null,
                            },
                          })
                        }
                      />
                      <br />
                      <Chip
                        label={`Type: ${
                          stakeholders.find(
                            (stakeholder) =>
                              stakeholder.id === sample?.receiver.id,
                          )?.type || ""
                        }`}
                        style={{ marginBottom: "5px" }}
                      />
                      <br />
                      <Chip
                        label={`Name: ${
                          stakeholders.find(
                            (stakeholder) =>
                              stakeholder.id === sample?.receiver.id,
                          )?.name || ""
                        }`}
                        style={{ marginBottom: "5px" }}
                      />
                      <br />
                    </Box>
                  )}
                </Grid>

                <Grid item xs={12} sm={2}>
                  <Typography variant="body2">Sales Grade</Typography>
                  <TextField
                    variant="outlined"
                    size="small"
                    fullWidth
                    value={sample?.salesGrade || ""}
                    onChange={(e) =>
                      setSample({
                        ...sample,
                        salesGrade: e.target.value,
                      })
                    }
                  />
                </Grid>
                <Grid item xs={12} sm={2}>
                  <Typography variant="body2">Sales Nicotine</Typography>
                  <TextField
                    variant="outlined"
                    size="small"
                    type="number"
                    fullWidth
                    value={sample?.salesNicotine || ""}
                    onChange={(e) =>
                      setSample({
                        ...sample,
                        salesNicotine: e.target.value,
                      })
                    }
                  />
                </Grid>
                <Grid item xs={12} sm={2}>
                  <Typography variant="body2">Sales Sugar</Typography>
                  <TextField
                    variant="outlined"
                    size="small"
                    fullWidth
                    type="number"
                    value={sample?.salesSugar || ""}
                    onChange={(e) =>
                      setSample({
                        ...sample,
                        salesSugar: e.target.value,
                      })
                    }
                  />
                </Grid>

                <Grid item xs={12} sm={2}>
                  <Typography variant="body2">Sales Moisture</Typography>
                  <TextField
                    variant="outlined"
                    size="small"
                    fullWidth
                    type="number"
                    value={sample?.salesMoisture || ""}
                    onChange={(e) =>
                      setSample({
                        ...sample,
                        salesMoisture: e.target.value,
                      })
                    }
                  />
                </Grid>

                <Grid item xs={12} sm={2}>
                  <Typography variant="body2">Sales Origin</Typography>
                  <TextField
                    variant="outlined"
                    size="small"
                    fullWidth
                    value={sample?.salesOrigin || ""}
                    onChange={(e) =>
                      setSample({
                        ...sample,
                        salesOrigin: e.target.value,
                      })
                    }
                  />
                </Grid>
                <Grid item xs={12} sm={2}>
                  <Typography variant="body2">Sales Crop</Typography>
                  <TextField
                    variant="outlined"
                    size="small"
                    type="number"
                    fullWidth
                    value={sample?.salesCrop || ""}
                    onChange={(e) =>
                      setSample({
                        ...sample,
                        salesCrop: e.target.value,
                      })
                    }
                  />
                </Grid>
                <Grid item xs={12} sm={2}>
                  <Typography variant="body2">Date Of Delivery</Typography>
                  <TextField
                    id="date"
                    type="date"
                    inputProps={{ min: "1999-01-01", max: "2999-01-01" }}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    variant="outlined"
                    size="small"
                    fullWidth
                    value={
                      sample?.deliveryDate
                        ? format(parseISO(sample?.deliveryDate), "yyyy-MM-dd")
                        : ""
                    }
                    onChange={(e) =>
                      setSample({
                        ...sample,
                        deliveryDate: e.target.value
                          ? e.target.value + "T00:00:00.000+00:00"
                          : null,
                      })
                    }
                  />
                </Grid>
                <Grid item xs={12} sm={2}>
                  <Typography variant="body2">Date Of Receive</Typography>
                  <TextField
                    id="date"
                    type="date"
                    inputProps={{ min: "1999-01-01", max: "2999-01-01" }}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    variant="outlined"
                    size="small"
                    fullWidth
                    value={
                      sample?.receiveDate
                        ? format(parseISO(sample?.receiveDate), "yyyy-MM-dd")
                        : ""
                    }
                    onChange={(e) =>
                      setSample({
                        ...sample,
                        receiveDate: e.target.value
                          ? e.target.value + "T00:00:00.000+00:00"
                          : null,
                      })
                    }
                  />
                </Grid>
                <Grid item xs={12} sm={2}>
                  <Typography variant="body2">Courier</Typography>
                  <TextField
                    variant="outlined"
                    size="small"
                    fullWidth
                    value={sample?.courier || ""}
                    onChange={(e) =>
                      setSample({
                        ...sample,
                        courier: e.target.value,
                      })
                    }
                  />
                </Grid>
                <Grid item xs={12} sm={2}>
                  <Typography variant="body2">AWB Number</Typography>
                  <TextField
                    variant="outlined"
                    size="small"
                    fullWidth
                    value={sample?.awbNumber || ""}
                    onChange={(e) =>
                      setSample({
                        ...sample,
                        awbNumber: e.target.value,
                      })
                    }
                  />
                </Grid>
                <Grid item xs={12} sm={2}>
                  <Typography variant="body2">Remark</Typography>
                  <TextField
                    variant="outlined"
                    size="small"
                    fullWidth
                    value={sample?.remark || ""}
                    onChange={(e) =>
                      setSample({
                        ...sample,
                        remark: e.target.value,
                      })
                    }
                  />
                </Grid>
                <Grid item xs={12} sm={2}>
                  <Typography variant="body2">Customer Feedback</Typography>
                  <TextField
                    variant="outlined"
                    size="small"
                    fullWidth
                    value={sample?.customerFeedback || ""}
                    onChange={(e) =>
                      setSample({
                        ...sample,
                        customerFeedback: e.target.value,
                      })
                    }
                  />
                </Grid>
                <Grid item xs={12} sm={2}>
                  <Typography variant="body2">Approved</Typography>
                  <TextField
                    variant="outlined"
                    size="small"
                    fullWidth
                    value={sample?.approved || ""}
                    onChange={(e) =>
                      setSample({
                        ...sample,
                        approved: e.target.value,
                      })
                    }
                  />
                </Grid>
                <Grid item xs={12} sm={2}>
                  <Typography variant="body2">Approval Details</Typography>
                  <TextField
                    variant="outlined"
                    size="small"
                    fullWidth
                    value={sample?.approvalDetails || ""}
                    onChange={(e) =>
                      setSample({
                        ...sample,
                        approvalDetails: e.target.value,
                      })
                    }
                  />
                </Grid>
                <Grid item xs={12} sm={2}>
                  <Typography variant="body2">Paid By</Typography>
                  <TextField
                    variant="outlined"
                    size="small"
                    fullWidth
                    value={sample?.paidBy || ""}
                    onChange={(e) =>
                      setSample({
                        ...sample,
                        paidBy: e.target.value,
                      })
                    }
                  />
                </Grid>
                <Grid item xs={12} sm={2}>
                  <Typography variant="body2"> Status</Typography>
                  <TextField
                    variant="outlined"
                    size="small"
                    fullWidth
                    id="outlined-select-status"
                    select
                    value={sample?.status || ""}
                    onChange={(e) =>
                      setSample({
                        ...sample,
                        status: e.target.value,
                      })
                    }
                  >
                    {recordStatus.map((option) => (
                      <MenuItem key={option.value} value={option.value}>
                        {option.label}
                      </MenuItem>
                    ))}
                  </TextField>
                </Grid>
              </Grid>

              <Grid item xs={12} sm={2}>
                <Button
                  variant="outlined"
                  size="small"
                  sx={{ ml: 1.5, mt: 4, mr: 1 }}
                  onClick={() => {
                    setIsEditSampleOpen(false);
                    setSample([]);
                    setSelectedOrigin("");
                  }}
                >
                  Cancel
                </Button>
                <Button
                  variant="contained"
                  size="small"
                  sx={{ mt: 4 }}
                  onClick={() => {
                    updateSampleApi(sample?.id, sample)
                      .then((response) => {
                        toast.success("Sample edited successfully.");
                      })
                      .catch((error) => {
                        toast.error("Something went wrong.");
                        console.log(error.response.data);
                      });
                    setIsEditSampleOpen(false);
                    setSample([]);
                    setSelectedOrigin("");
                  }}
                >
                  Save Sample
                </Button>
              </Grid>
            </Grid>
          </>
        )}
      </Collapse>
    </>
  );
};

export default AddCustomerOfferView;

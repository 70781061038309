import {
    AppstoreAddOutlined,
    AntDesignOutlined,
    BarcodeOutlined,
    BgColorsOutlined,
    FontSizeOutlined,
    DatabaseOutlined,
    FileTextOutlined,
    LoadingOutlined,
    UserOutlined,
    DollarOutlined,
    FormOutlined,
    SendOutlined,
    FileDoneOutlined,
  } from "@ant-design/icons";
  
  const icons = {
    FontSizeOutlined,
    BgColorsOutlined,
    BarcodeOutlined,
    AntDesignOutlined,
    LoadingOutlined,
    DatabaseOutlined,
    FileTextOutlined,
    AppstoreAddOutlined,
    UserOutlined,
    DollarOutlined,
    FormOutlined,
    SendOutlined,
    FileDoneOutlined,
  };
  
  const logistics = {
    id: "logistics",
    title: "Logistics",
    type: "accordion",
    children: [
      {
        id: "shipment-request",
        title: "Shipment Requests",
        type: "item",
        url: "/shipment-request-view",
        icon: icons.FileTextOutlined,
      },
      {
        id: "shipping-instruction",
        title: "Shipping Instructions",
        type: "item",
        url: "/shipping-instruction-view",
        icon: icons.FileTextOutlined,
      },
      {
        id: "shipping-instruction-costing",
        title: "SI Costing",
        type: "item",
        url: "/shipping-instruction-costing-view",
        icon: icons.DollarOutlined,
      },
    ],
  };
  
  export default logistics;
  
import {
  Autocomplete,
  Box,
  Button,
  Chip,
  Grid,
  MenuItem,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import { format, parseISO } from "date-fns";
import React, { useState } from "react";
import toast from "react-hot-toast";
import {
  retrieveAllOrdersApi,
  retrieveFilteredOrdersApi,
} from "../../../components/api/OrderApiService";
import { updateShipmentRequestApi } from "../../../components/api/ShipmentRequestApiService";
import { portsOfDischarge, portsOfLoading, recordStatus, shipmentRequestStatus, status, units, warehouses } from "../../../utils/menu-items";
import { retrieveUsersApi } from "../../../components/api/UserApiService";
import { retrieveFilteredShippingInstructionsApi } from "../../../components/api/ShippingInstructionApiService";
import { retrieveFilteredWarehouseRecordsApi } from "../../../components/api/WarehouseApiService";

const EditShipmentRequest = (props) => {
  const { editShipmentRequest, setEditShipmentRequest } = props;
  function retrieveOrders(orderType) {
    retrieveAllOrdersApi(orderType)
      .then((response) => {
        setOrders(response?.data);
      })
      .catch((error) => console.log(error.response?.data));
  }
  const [orders, setOrders] = useState([]);
  const [selectedPo, setSelectedPo] = useState(
    editShipmentRequest?.data?.purchaseOrder || null,
  );
  const [selectedSo, setSelectedSo] = useState(
    editShipmentRequest?.data?.salesOrder || null,
  );
  const { addViewStates } = props;
  const [users, setUsers] = useState([]);
  const [selectedUser, setSelectedUser] = useState( editShipmentRequest?.data?.assignedUser || null,);
  const [shippingInstructions, setShippingInstructions] = useState([]);
  const [selectedSi, setSelectedSi] = useState(null);

  const [warehouses, setWarehouses] = useState([]);
  const [selectedWarehouse, setSelectedWarehouse] = useState(null);
  const filterWarehouse = {
    recordStatusList: ["ACTIVE"],
  };

  function retrieveFilteredWarehouseRecords(filterWarehouse) {
    retrieveFilteredWarehouseRecordsApi(filterWarehouse)
      .then((response) => {
        setWarehouses(response?.data)
        return response?.data || [];
      })
      .catch((error) => console.log(error.response?.data));
  }

  const [filterShippingInstruction, setFilterShippingInstruction] = useState({
    id: 0,
    shipmentRequestIdList: [],
    freightOfferIdList: [],
    purchaseOrderIdList:[],
    salesOrderIdList:[],
    transportationContact: "",
    cartonNumber: "",
    finalShippingInstructionNumber:"",
    shippedByList: [],
    billOfLadingNumber: "",
    cmrNumber: "",
    shipperIdList: [],
    consigneeIdList: [],
    notifyIdList: [],
    pointOfLoadingList: [],
    pointOfUnloadingList: [],
    description: "",
    remark: "",
    statusList: ["Closed"],
    recordStatusList: ["ACTIVE"],
  });

  function retrieveFilteredShippingInstructions(filterShippingInstruction) {
    retrieveFilteredShippingInstructionsApi(filterShippingInstruction)
      .then((response) => {
        setShippingInstructions(response?.data)
        return response?.data || [];
      })
      .catch((error) => console.log(error.response?.data));
  }

  function retrieveFilteredOrders(orderDto) {
    retrieveFilteredOrdersApi(orderDto)
      .then((response) => {
        setOrders(response?.data);
        return response?.data || [];
      })
      .catch((error) => console.log(error.response?.data));
  }
  function retrieveUsers() {
    retrieveUsersApi()
      .then((response) => {
          setUsers(response?.data);
      })
      .catch((error) => console.log(error.response?.data));
  }

  const orderDto = {
    orderType: "",
    offerId: addViewStates?.offer?.id,
    offerIdList: [] || [],
    itemIdList: [],
    stakeholderIdList: [],
    costIdList: [],
    companyList: [],
    approvalStatusList: ["Approved"],
    orderStatusList: [],
    customerList: [],
    firstSampleIdList: [],
    secondSampleIdList: [],
    firstSampleStatusList: [],
    secondSampleStatusList: [],
    paymentTermList: [],
    incotermList: [],
    portOfLoadingList: [],
    portOfDischargeList: [],
    packagingList: [],
    volumeUnitList: [],
    firstPriceCurrencyList: [],
    finalPriceCurrencyList: [],
    documentStatusList: [],
    originList: [],
    typeList: [],
    leafFormList: [],
    colorList: [],
    storageList: [],
    remainingQuantity: 1,
    recordStatusList: ["ACTIVE"],
  };
  return (
    <>
      <Stack direcion="column">
        <Typography variant="body1" mb={2}>
          Edit Shipment Request
        </Typography>
        <Grid container item xs={12} spacing="8px">
          <Grid item xs={12} sm={2}>
            <Typography variant="body2">Select Purchase Order</Typography>
            <Autocomplete
              options={orders || []}
              getOptionLabel={(option) =>
                `ID: ${option?.id || ""}, SAP PO Number: ${
                  option?.purchaseOrderNumber || ""
                }, Vendor: ${option?.stakeholder?.name || ""}`
              }
              value={selectedPo}
              onChange={(event, newValue) => {
                setSelectedPo(newValue);
                setSelectedSi(null);
                setEditShipmentRequest({
                  ...editShipmentRequest,
                  data: {
                    ...editShipmentRequest.data,
                    purchaseOrder: newValue || {},
                  },
                });
              }}
              onOpen={() => {
                if (selectedSo) {
                  orderDto.orderType = "VENDOR";
                  orderDto.itemIdList = [selectedSo?.offer?.item?.id];
                  retrieveFilteredOrders(orderDto);
                } else {
                  retrieveOrders("VENDOR");
                }
              }}
              isOptionEqualToValue={(option, value) => option.id === value.id}
              renderInput={(params) => (
                <TextField
                  {...params}
                  variant="outlined"
                  size="small"
                  fullWidth
                  label={`${selectedPo?.id || ""}`}
                />
              )}
              renderTags={() => null}
            />
            {selectedPo && (
              <Box mt={2}>
                <Chip
                  label={`ID: ${selectedPo?.id || ""}`}
                  onDelete={() => {
                    setSelectedPo(null);
                    setEditShipmentRequest({
                      ...editShipmentRequest,
                      data: {
                        ...editShipmentRequest.data,
                        purchaseOrder: {},
                      },
                    });
                  }}
                  style={{ marginBottom: "5px" }}
                />{" "}
                <br></br>
                <Chip
                  label={`SAP PO Number: ${
                    selectedPo?.purchaseOrderNumber || ""
                  }`}
                  style={{ marginBottom: "5px" }}
                />
                <br></br>
                <Chip
                  label={`Quantity: ${selectedPo?.volume || ""} ${
                    selectedPo?.volumeUnit || ""
                  }`}
                  style={{ marginBottom: "5px" }}
                />
                <br></br>
                <Chip
                  label={`Supplier: ${
                    selectedPo?.offer?.stakeholder?.name || ""
                  }`}
                  style={{ marginBottom: "5px" }}
                />
                <br></br>
                <Chip
                  label={`Item ID: ${selectedPo?.offer?.item?.id || ""}`}
                  style={{ marginBottom: "5px" }}
                />
                <br></br>
              </Box>
            )}
          </Grid>

          {(editShipmentRequest?.data?.shipmentRequestType === "FWTC" ||
            editShipmentRequest?.data?.shipmentRequestType === "FWTIMP" ||
            editShipmentRequest?.data?.shipmentRequestType === "FWTW" ||
            editShipmentRequest?.data?.shipmentRequestType === "FWTINW") && (
            <Grid item xs={12} sm={2}>
              <Typography variant="body2">Select SI</Typography>
              <Autocomplete
                options={shippingInstructions || []}
                getOptionLabel={(option) =>
                  `ID: ${option?.id || ""}, SI Number: ${option?.finalShippingInstructionNumber || ""}, SR : ${option?.shipmentRequest?.shipmentRequestType || ""}, SR Number: ${option?.shipmentRequest?.id || ""}, Date: ${option?.stakeholder?.name || ""}, POD: ${option?.pointOfUnloading|| ""}`
                }
                value={selectedSi}
                onChange={(event, newValue) => {
                  setSelectedSi(newValue);
                  setEditShipmentRequest({
                    ...editShipmentRequest,
                    data: {
                      ...editShipmentRequest.data,
                      referenceShippingInstructionId: newValue ? newValue.id : "",
                      referenceShippingInstructionNumber: newValue ? newValue.finalShippingInstructionNumber : "",
                      warehouse: newValue ? newValue?.destination : "",
                    },
                  });
                }}
                onOpen={() => retrieveFilteredShippingInstructions(filterShippingInstruction)}
                isOptionEqualToValue={(option, value) => option.id === value.id}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    variant="outlined"
                    size="small"
                    fullWidth
                    label={`${editShipmentRequest?.data?.referenceShippingInstructionNumber || ""}`}
                  />
                )}
                renderTags={() => null}
              />
              {selectedSi && (
                <Box mt={2}>
                  <Chip
                    label={`ID: ${selectedSi?.id || ""}`}
                    onDelete={() => {
                      setSelectedSi(null);
                      setEditShipmentRequest({
                        ...editShipmentRequest,
                        data: {
                          ...editShipmentRequest.data,
                          referenceShippingInstructionNumber: "", 
                          referenceShippingInstructionId: "", 
                        },
                      });
                    }}
                    style={{ marginBottom: "5px" }}
                  />{" "}
                  <br></br>
                </Box>
              )}
            </Grid>
          )}

{(editShipmentRequest?.data?.shipmentRequestType === "FWTC" ||
            editShipmentRequest?.data?.shipmentRequestType === "FWTIMP" ||
            editShipmentRequest?.data?.shipmentRequestType === "FWTW" ||
            editShipmentRequest?.data?.shipmentRequestType === "FWTC" ||
            editShipmentRequest?.data?.shipmentRequestType === "FWTINW") && (editShipmentRequest?.data?.referenceShippingInstructionNumber ||selectedSi) && (
<Grid item xs={12} sm={2}>
            <Typography variant="body2">Warehouse</Typography>
            <TextField
              variant="outlined"
              size="small"
              fullWidth
              disabled
              id="outlined-select-pol"
              value={selectedSi?.destination}
            >
            </TextField>
          </Grid>
            )}

          {(editShipmentRequest?.data?.shipmentRequestType === "FWTC" ||
            editShipmentRequest?.data?.shipmentRequestType === "FWTIMP" ||
            editShipmentRequest?.data?.shipmentRequestType === "FVTC" ||
            editShipmentRequest?.data?.shipmentRequestType === "FVTIMP") && (
            <Grid item xs={12} sm={2}>
              <Typography variant="body2">Select Sales Order</Typography>
              <Autocomplete
                options={orders || []}
                getOptionLabel={(option) =>
                  `ID: ${option?.id || ""}, SAP SO Number: ${
                    option?.salesOrderNumber || ""
                  }, Customer: ${option?.offer?.stakeholder?.name || ""}`
                }
                value={selectedSo}
                onChange={(event, newValue) => {
                  setSelectedSo(newValue);
                  setEditShipmentRequest({
                    ...editShipmentRequest,
                    data: {
                      ...editShipmentRequest.data,
                      salesOrder: newValue || {},
                      destination: `${newValue?.incoterm || ""} ${
                        newValue?.portOfDischarge || ""
                      }`,
                      customerGradeMark: newValue
                        ? newValue?.offer?.salesGrade
                        : "",
                      productionTobaccoCode: newValue
                        ? newValue?.offer?.item?.tobaccoCode
                        : "",
                    },
                  });
                }}
                onOpen={() => {
                  if (selectedPo) {
                    orderDto.orderType = "CUSTOMER";
                    orderDto.itemIdList = [selectedPo?.offer?.item?.id];
                    retrieveFilteredOrders(orderDto);
                  } else {
                    retrieveOrders("CUSTOMER");
                  }
                }}
                isOptionEqualToValue={(option, value) => option.id === value.id}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    variant="outlined"
                    size="small"
                    fullWidth
                    label={`${selectedSo?.id || ""}`}
                  />
                )}
                renderTags={() => null}
              />
              {selectedSo && (
                <Box mt={2}>
                  <Chip
                    label={`ID: ${selectedSo?.id || ""}`}
                    onDelete={() => {
                      setSelectedSo(null);
                      setEditShipmentRequest({
                        ...editShipmentRequest,
                        data: {
                          ...editShipmentRequest.data,
                          salesOrder: {},
                        },
                      });
                    }}
                    style={{ marginBottom: "5px" }}
                  />{" "}
                  <br></br>
                  <Chip
                    label={`SAP SO Number: ${
                      selectedSo?.salesOrderNumber || ""
                    }`}
                    style={{ marginBottom: "5px" }}
                  />
                  <br></br>
                  <Chip
                    label={`Quantity: ${selectedSo?.volume || ""} ${
                      selectedSo?.volumeUnit || ""
                    }`}
                    style={{ marginBottom: "5px" }}
                  />
                  <br></br>
                  <Chip
                    label={`Customer: ${
                      selectedSo?.offer?.stakeholder?.name || ""
                    }`}
                    style={{ marginBottom: "5px" }}
                  />
                  <br></br>
                  <Chip
                       label={`Customer Grade Mark: ${selectedSo?.offer?.salesGrade || ""}`}
                       style={{ marginBottom: '5px' }}
                     /><br></br>
                  <Chip
                    label={`Item ID: ${selectedSo?.offer?.item?.id || ""}`}
                    style={{ marginBottom: "5px" }}
                  />
                  <br></br>
                </Box>
              )}
            </Grid>
          )}

          {(selectedSo || selectedPo) && (
            <Grid item xs={12} sm={2}>
              <Typography variant="body2">Item</Typography>
              <TextField
                disabled
                type="number"
                variant="outlined"
                size="small"
                fullWidth
                value={
                  selectedSo?.offer?.item?.id ||
                  selectedPo?.offer?.item?.id ||
                  ""
                }
              />
              {(selectedSo || selectedPo) && (
                <Box mt={2}>
                  <Chip
                    label={`ID: ${
                      selectedSo?.offer?.item?.id ||
                      selectedPo?.offer?.item?.id ||
                      ""
                    }`}
                    style={{ marginBottom: "5px" }}
                  />{" "}
                  <br></br>
                  <Chip
                    label={`Origin: ${
                      selectedSo?.offer?.item?.origin ||
                      selectedPo?.offer?.item?.origin ||
                      ""
                    }`}
                    style={{ marginBottom: "5px" }}
                  />
                  <br></br>
                  <Chip
                    label={`Type: ${
                      selectedSo?.offer?.item?.type ||
                      selectedPo?.offer?.item?.type ||
                      ""
                    }`}
                    style={{ marginBottom: "5px" }}
                  />
                  <br></br>
                  <Chip
                    label={`Buying Grade: ${
                      selectedSo?.offer?.item?.buyingGrade ||
                      selectedPo?.offer?.item?.buyingGrade ||
                      ""
                    }`}
                    style={{ marginBottom: "5px" }}
                  />
                  <br></br>
                  <Chip
                    label={`Sales Grade: ${
                      selectedSo?.offer?.item?.salesGrade ||
                      selectedPo?.offer?.item?.salesGrade ||
                      ""
                    }`}
                    style={{ marginBottom: "5px" }}
                  />
                  <br></br>
                  <Chip
                    label={`Crop: ${
                      selectedSo?.offer?.item?.crop ||
                      selectedPo?.offer?.item?.crop ||
                      ""
                    }`}
                    style={{ marginBottom: "5px" }}
                  />
                  <br></br>
                  <Chip
                    label={`Storage: ${
                      selectedSo?.offer?.item?.storage ||
                      selectedPo?.offer?.item?.storage ||
                      ""
                    }`}
                    style={{ marginBottom: "5px" }}
                  />
                  <br></br>
                </Box>
              )}
            </Grid>
          )}
           <Grid item xs={12} sm={2}>
            <Typography variant="body2">Customer Grade Mark</Typography>
            <TextField
              variant="outlined"
              size="small"
              fullWidth
              value={editShipmentRequest?.data?.customerGradeMark}
              onChange={(e) =>
                setEditShipmentRequest({
                  ...editShipmentRequest,
                  data: {
                    ...editShipmentRequest.data,
                    customerGradeMark: e.target.value,
                  },
                })
              }
            />
          </Grid>
             {((editShipmentRequest?.data?.shipmentRequestType==="FVTC") || (editShipmentRequest?.data?.shipmentRequestType==="FWTC")) && (
          <Grid item xs={12} sm={2}>
            <Typography variant="body2">Destination</Typography>
            <TextField
              variant="outlined"
              size="small"
              fullWidth
              value={editShipmentRequest?.data?.destination}
              onChange={(e) =>
                setEditShipmentRequest({
                  ...editShipmentRequest,
                  data: {
                    ...editShipmentRequest.data,
                    destination: e.target.value,
                  },
                })
              }
            />
          </Grid>
              )}
              
          {((editShipmentRequest?.data?.shipmentRequestType==="FVTIMP") || (editShipmentRequest?.data?.shipmentRequestType==="FVTINW") || (editShipmentRequest?.data?.shipmentRequestType==="FVTW")
           || (editShipmentRequest?.data?.shipmentRequestType==="FWTIMP") || (editShipmentRequest?.data?.shipmentRequestType==="FWTINW") || (editShipmentRequest?.data?.shipmentRequestType==="FWTW")) && (
          
            <Grid item xs={12} sm={2}>
            <Typography variant="body2">Destination</Typography>
            <Autocomplete
              options={warehouses || []}
              getOptionLabel={(option) =>
                `ID: ${option?.id || ""}, Code: ${option?.code || ""}, Name: ${option?.name || ""}`
              }
              value={selectedWarehouse}
              onChange={(event, newValue) => {
                setSelectedWarehouse(newValue);
                setEditShipmentRequest({
                  ...editShipmentRequest,
                  data: {
                    ...editShipmentRequest.data,
                    destination:newValue ? newValue?.name : "",
                  },
                })
              }}
              onOpen={() => retrieveFilteredWarehouseRecords(filterWarehouse)}
              isOptionEqualToValue={(option, value) => option.id === value.id}
              renderInput={(params) => (
                <TextField
                  {...params}
                  variant="outlined"
                  size="small"
                  fullWidth
                  label={`${editShipmentRequest?.data?.destination || ""}`}
                />
              )}
              renderTags={() => null}
            />
            {selectedWarehouse && (
              <Box mt={2}>
                <Chip
                  label={`ID: ${selectedWarehouse?.id || ""}`}
                  onDelete={() => {
                    setSelectedWarehouse(null);
                    setEditShipmentRequest({
                      ...editShipmentRequest,
                      data: {
                        ...editShipmentRequest.data,
                        destination: "",
                      },
                    })
                  }}
                  style={{ marginBottom: "5px" }}
                  />
                    <br></br>
                      <Chip
                        label={`Code: ${selectedWarehouse?.code || ""}`}
                        style={{ marginBottom: "5px" }}
                      />
                      <br />
                      <Chip
                        label={`Name: ${selectedWarehouse?.name || ""}`}
                        style={{ marginBottom: "5px" }}
                      />
                      <br />
                      <Chip
                        label={`Contact: ${selectedWarehouse?.contactPerson?.contactPerson || ""}`}
                        style={{ marginBottom: "5px" }}
                      />
                      <br />
                      <Chip
                        label={`Storage Pricing By ${selectedWarehouse?.storagePricingBy || ""}`}
                        style={{ marginBottom: "5px" }}
                      />
                      <br />
                </Box>
            )}
          </Grid>
          )}

          <Grid item xs={12} sm={2}>
            <Typography variant="body2">Port Of Loading</Typography>
            <TextField
              variant="outlined"
              size="small"
              fullWidth
              id="outlined-select-pol"
              select
              value={editShipmentRequest?.data?.portOfLoading}
              onChange={(e) =>
                setEditShipmentRequest({
                  ...editShipmentRequest,
                  data: {
                    ...editShipmentRequest.data,
                    portOfLoading: e.target.value,
                  },
                })
              }
            >
              {portsOfLoading.map((option) => (
                <MenuItem key={option.value} value={option.value}>
                  {option.label}
                </MenuItem>
              ))}
            </TextField>
          </Grid>

          <Grid item xs={12} sm={2}>
            <Typography variant="body2">Port Of Discharge</Typography>
            <TextField
              variant="outlined"
              size="small"
              fullWidth
              id="outlined-select-pol"
              select
              value={editShipmentRequest?.data?.portOfDischarge}
              onChange={(e) => {
                setEditShipmentRequest({
                  ...editShipmentRequest,
                  data: {
                    ...editShipmentRequest.data,
                    portOfDischarge: e.target.value,
                    ...( 
                      (editShipmentRequest?.data?.shipmentRequestType === "FVTC" || 
                       editShipmentRequest?.data?.shipmentRequestType === "FWTC") && 
                      { destination: editShipmentRequest?.data?.salesOrder?.incoterm +" "+ e.target.value }
                    ),
                  },
                });
              }}
            >
              {portsOfDischarge.map((option) => (
                <MenuItem key={option.value} value={option.value}>
                  {option.label}
                </MenuItem>
              ))}
            </TextField>
          </Grid>

          <Grid item xs={12} sm={2}>
            <Typography variant="body2">Number Of Cartons</Typography>
            <TextField
              type="number"
              variant="outlined"
              size="small"
              fullWidth
              value={editShipmentRequest?.data?.numberOfCases}
              onChange={(e) =>
                setEditShipmentRequest({
                  ...editShipmentRequest,
                  data: {
                    ...editShipmentRequest.data,
                    numberOfCases: e.target.value,
                    shipmentQuantity:
                      e.target.value *
                      editShipmentRequest?.data?.netWeightPerCase,
                  },
                })
              }
            />
          </Grid>
          <Grid item xs={12} sm={2}>
            <Typography variant="body2">Net Weight Per Carton</Typography>
            <TextField
              type="number"
              variant="outlined"
              size="small"
              fullWidth
              value={editShipmentRequest?.data?.netWeightPerCase}
              onChange={(e) =>
                setEditShipmentRequest({
                  ...editShipmentRequest,
                  data: {
                    ...editShipmentRequest.data,
                    netWeightPerCase: e.target.value,
                    shipmentQuantity:
                      e.target.value * editShipmentRequest?.data?.numberOfCases,
                  },
                })
              }
            />
          </Grid>
          <Grid item xs={12} sm={2}>
            <Typography variant="body2">Shipment Quantity</Typography>
            <TextField
              type="number"
              variant="outlined"
              size="small"
              fullWidth
              value={editShipmentRequest?.data?.shipmentQuantity}
              onChange={(e) =>
                setEditShipmentRequest({
                  ...editShipmentRequest,
                  data: {
                    ...editShipmentRequest.data,
                    shipmentQuantity: e.target.value,
                  },
                })
              }
            />
          </Grid>

          <Grid item xs={12} sm={2}>
            <Typography variant="body2">Shipment Quantity Unit</Typography>
            <TextField
              variant="outlined"
              size="small"
              fullWidth
              id="outlined-select-incoterm-price-unit"
              select
              value={editShipmentRequest?.data?.shipmentQuantityUnit}
              onChange={(e) =>
                setEditShipmentRequest({
                  ...editShipmentRequest,
                  data: {
                    ...editShipmentRequest.data,
                    shipmentQuantityUnit: e.target.value,
                  },
                })
              }
            >
              {units.map((option) => (
                <MenuItem key={option.value} value={option.value}>
                  {option.label}
                </MenuItem>
              ))}
            </TextField>
          </Grid>

          <Grid item xs={12} sm={2}>
            <Typography variant="body2">Cartons Per Container</Typography>
            <TextField
              type="number"
              variant="outlined"
              size="small"
              fullWidth
              value={editShipmentRequest?.data?.cartonsPerContainer}
              onChange={(e) =>
                setEditShipmentRequest({
                  ...editShipmentRequest,
                  data: {
                    ...editShipmentRequest.data,
                    cartonsPerContainer: e.target.value,
                  },
                })
              }
            />
          </Grid>

          <Grid item xs={12} sm={2}>
            <Typography variant="body2">Shipment ETD</Typography>
            <TextField
              id="date"
              type="date"
              inputProps={{ min: "1999-01-01", max: "2999-01-01" }}
              InputLabelProps={{
                shrink: true,
              }}
              onKeyDown={(e) => e.preventDefault()}
              variant="outlined"
              size="small"
              fullWidth
              value={
                editShipmentRequest?.data?.shipmentEstimatedTimeOfDeparture
                  ? format(
                      parseISO(
                        editShipmentRequest.data
                          .shipmentEstimatedTimeOfDeparture,
                      ),
                      "yyyy-MM-dd",
                    )
                  : ""
              }
              onChange={(e) =>
                setEditShipmentRequest({
                  ...editShipmentRequest,
                  data: {
                    ...editShipmentRequest.data,
                    shipmentEstimatedTimeOfDeparture: e.target.value
                      ? e.target.value + "T00:00:00.000+00:00"
                      : null,
                  },
                })
              }
            />
          </Grid>

          <Grid item xs={12} sm={2}>
            <Typography variant="body2">Approval Status</Typography>
            <TextField
              variant="outlined"
              size="small"
              fullWidth
              id="outlined-select-approval-status"
              select
              value={editShipmentRequest?.data?.approvalStatus}
              onChange={(e) => {
                const currentDate = new Date().toISOString().split('T')[0];
                if(e.target.value==="Approved"){
                  setEditShipmentRequest({
                    ...editShipmentRequest,
                    data: {
                      ...editShipmentRequest.data,
                      approvalStatus: e.target.value,
                      approvalDate: currentDate,
                    },
                  })
                }
                else if(e.target.value==="Not Approved"){
                  setEditShipmentRequest({
                    ...editShipmentRequest,
                    data: {
                      ...editShipmentRequest.data,
                      approvalStatus: e.target.value,
                      approvalDate: "",
                    },
                  })
                }
              }
              }
            >
              {status.map((option) => (
                <MenuItem key={option.value} value={option.value}>
                  {option.label}
                </MenuItem>
              ))}
            </TextField>
          </Grid>

          <Grid item xs={12} sm={2}>
            <Typography variant="body2">Approval Date</Typography>
            <TextField
              id="date"
              type="date"
              inputProps={{ min: "1999-01-01", max: "2999-01-01" }}
              InputLabelProps={{
                shrink: true,
              }}
              onKeyDown={(e) => e.preventDefault()}
              variant="outlined"
              size="small"
              fullWidth
              value={
                editShipmentRequest?.data?.approvalDate
                  ? format(
                      parseISO(editShipmentRequest.data.approvalDate),
                      "yyyy-MM-dd",
                    )
                  : ""
              }
              onChange={(e) =>
                setEditShipmentRequest({
                  ...editShipmentRequest,
                  data: {
                    ...editShipmentRequest.data,
                    approvalDate: e.target.value
                      ? e.target.value + "T00:00:00.000+00:00"
                      : null,
                  },
                })
              }
            />
          </Grid>

          <Grid item xs={12} sm={2}>
            <Typography variant="body2">Loading Remark</Typography>
            <TextField
              variant="outlined"
              size="small"
              fullWidth
              value={editShipmentRequest?.data?.loadingRemark}
              onChange={(e) =>
                setEditShipmentRequest({
                  ...editShipmentRequest,
                  data: {
                    ...editShipmentRequest.data,
                    loadingRemark: e.target.value,
                  },
                })
              }
            />
          </Grid>

          <Grid item xs={12} sm={2}>
            <Typography variant="body2">Remark</Typography>
            <TextField
              variant="outlined"
              size="small"
              fullWidth
              value={editShipmentRequest?.data?.remark}
              onChange={(e) =>
                setEditShipmentRequest({
                  ...editShipmentRequest,
                  data: {
                    ...editShipmentRequest.data,
                    remark: e.target.value,
                  },
                })
              }
            />
          </Grid>

          {(editShipmentRequest?.data?.assignedUser && <Grid item xs={12} sm={2}>
            <Typography variant="body2">Assigned To</Typography>
            <Autocomplete
              options={users || []}
              getOptionLabel={(option) =>
                `${
                  option?.name || ""
                }`
              }
              value={selectedUser}
              onChange={(event, newValue) => {
                setSelectedUser(newValue);
                setEditShipmentRequest({
                  ...editShipmentRequest,
                  data: {
                    ...editShipmentRequest.data,
                    assignedUser: newValue || {},
                  },
                });
              }}
              onOpen={() => {
                  retrieveUsers();
              }}
              isOptionEqualToValue={(option, value) => option.id === value.id}
              renderInput={(params) => (
                <TextField
                  {...params}
                  variant="outlined"
                  size="small"
                  fullWidth
                />
              )}
              renderTags={() => null}
            />
          </Grid>)}


          <Grid item xs={12} sm={2}>
            <Typography variant="body2">Status</Typography>
            <TextField
              variant="outlined"
              size="small"
              fullWidth
              id="outlined-select-status"
              select
              value={editShipmentRequest?.data?.status}
              onChange={(e) =>
                setEditShipmentRequest({
                  ...editShipmentRequest,
                  data: {
                    ...editShipmentRequest.data,
                    status: e.target.value,
                  },
                })
              }
            >
              {shipmentRequestStatus.map((option) => (
                <MenuItem key={option.value} value={option.value}>
                  {option.label}
                </MenuItem>
              ))}
            </TextField>
          </Grid>
          {/* 
          <Grid item xs={12} sm={2}>
            <Typography variant="body2">Payment Status</Typography>
            <TextField
              variant="outlined"
              size="small"
              fullWidth
              id="outlined-select-status"
              select
              value={editShipmentRequest?.data?.paymentStatus}
              onChange={(e) =>
                setEditShipmentRequest({
                  ...editShipmentRequest,
                  data: {
                    ...editShipmentRequest.data,
                    paymentStatus: e.target.value,
                  },
                })
              }
            >
              {status.map((option) => (
                <MenuItem key={option.value} value={option.value}>
                  {option.label}
                </MenuItem>
              ))}
            </TextField>
          </Grid>
          */}

          <Grid item xs={12} sm={2}>
            <Typography variant="body2">Record Status</Typography>
            <TextField
              variant="outlined"
              size="small"
              fullWidth
              id="outlined-select-status"
              select
              value={editShipmentRequest?.data?.recordStatus}
              onChange={(e) =>
                setEditShipmentRequest({
                  ...editShipmentRequest,
                  data: {
                    ...editShipmentRequest.data,
                    recordStatus: e.target.value,
                  },
                })
              }
            >
              {recordStatus.map((option) => (
                <MenuItem key={option.value} value={option.value}>
                  {option.label}
                </MenuItem>
              ))}
            </TextField>
          </Grid>
        </Grid>
        <Stack direction="row" spacing={1} mt={1}>
          <Button
            variant="outlined"
            onClick={() =>
              setEditShipmentRequest({
                visible: false,
                data: null,
                index: null,
              })
            }
          >
            Cancel
          </Button>
          <Button
            variant="contained"
            onClick={() => {
              updateShipmentRequestApi(
                editShipmentRequest.data.id,
                editShipmentRequest.data,
              )
                .then((response) => {
                  toast.success("ShipmentRequest edited successfully.");
                  window.location.reload();

                  setEditShipmentRequest({
                    visible: false,
                    data: null,
                    index: null,
                  });
                })
                .catch((error) => {
                  toast.error("Something went wrong.");
                  console.log(error.response.data);
                });
            }}
          >
            Save
          </Button>
          <Button
            variant="contained"
            color="error"
            onClick={() => {
              editShipmentRequest.data.recordStatus = "PASSIVE";
              updateShipmentRequestApi(
                editShipmentRequest.data.id,
                editShipmentRequest.data,
              )
                .then((response) => {
                  toast.success("ShipmentRequest deleted successfully.");
                  window.location.reload();
                  setEditShipmentRequest({
                    visible: false,
                    data: null,
                    index: null,
                  });
                })
                .catch((error) => {
                  toast.error("Something went wrong.");
                  console.log(error.response.data);
                });
            }}
          >
            Delete
          </Button>
        </Stack>
      </Stack>
    </>
  );
};

export default EditShipmentRequest;

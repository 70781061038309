import { apiClient } from "./ApiClient";

export const retrieveAllWarehouseApi = () =>
  apiClient.get(`/warehouse-view/warehouse`);

export const createWarehouseApi = (warehouse) =>
  apiClient.post(`/warehouse-view/add`, warehouse);

export const updateWarehouseApi = (warehouseId, warehouse) =>
  apiClient.put(`/warehouse-view/edit/${warehouseId}`, warehouse);

export const updateMultipleWarehouseRecordsApi = (warehouseList) =>
  apiClient.put(`/warehouse-view/edit/warehouse-record-list`, warehouseList);

export const retrieveFilteredWarehouseRecordsApi = (warehouse) =>
  apiClient.post(`/warehouse-view/warehouse/filter`, warehouse);

import DeleteIcon from "@mui/icons-material/Delete";
import {
  Autocomplete,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Select,
  Stack,
} from "@mui/material";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Checkbox from "@mui/material/Checkbox";
import FormControlLabel from "@mui/material/FormControlLabel";
import Grid from "@mui/material/Grid";
import IconButton from "@mui/material/IconButton";
import MenuItem from "@mui/material/MenuItem";
import Paper from "@mui/material/Paper";
import Switch from "@mui/material/Switch";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import TableSortLabel from "@mui/material/TableSortLabel";
import TextField from "@mui/material/TextField";
import Toolbar from "@mui/material/Toolbar";
import Tooltip from "@mui/material/Tooltip";
import Typography from "@mui/material/Typography";
import { alpha } from "@mui/material/styles";
import { visuallyHidden } from "@mui/utils";
import { format } from "date-fns";
import PropTypes from "prop-types";
import * as React from "react";
import { useEffect, useState } from "react";
import toast from "react-hot-toast";
import {
  retrieveAllOrdersApi,
  retrieveOrderByIdApi,
} from "../../../components/api/OrderApiService";
import {
  approveShipmentRequestApi,
  retrieveAllShipmentRequestsApi,
  retrieveFilteredShipmentRequestsApi,
} from "../../../components/api/ShipmentRequestApiService";
import { retrieveAllStakeholdersApi } from "../../../components/api/StakeholderApiService";
import ExportExcel from "../../../utils/excelExport";
import {
  recordStatus,
  shipmentRequestStatus,
  shipmentRequestTypes,
  status,
} from "../../../utils/menu-items";
import EditCustomerOrder from "../CustomerOrder/EditCustomerOrder";
import CreateSI from "../ShippingInstructionView/AddShippingInstruction";
import EditVendorOrder from "../VendorOrderView/EditVendorOrder";
import AddShipmentRequest from "./AddShipmentRequest";
import EditShipmentRequest from "./EditShipmentRequest";
import { retrieveUsersApi } from "../../../components/api/UserApiService";
import { retrieveAllWarehouseApi } from "../../../components/api/WarehouseApiService";

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort(array, comparator) {
  const stabilizedThis = array?.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) {
      return order;
    }
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

const headCells = [
  {
    id: "editButton",
    disablePadding: true,
    label: "",
  },
  {
    id: "approveButton",
    disablePadding: true,
    label: "",
  },
  {
    id: "createSIButton",
    disablePadding: true,
    label: "",
  },
  {
    id: "id",
    numeric: true,
    disablePadding: true,
    label: "ID",
  },
  {
    id: "assignedTo",
    numeric: true,
    disablePadding: true,
    label: "Assigned To",
  },
  {
    id: "shipmentRequestType",
    numeric: false,
    disablePadding: true,
    label: "Type",
  },
  {
    id: "shipmentQuantity",
    numeric: true,
    disablePadding: true,
    label: "Shipment Quantity",
  },
  {
    id: "shipmentRequestTo",
    numeric: false,
    disablePadding: true,
    label: "SR To",
  },
  {
    id: "purchaseOrder",
    numeric: false,
    disablePadding: true,
    label: "PO Id",
  },
  {
    id: "purchaseOrderNumber",
    numeric: false,
    disablePadding: true,
    label: "SAP PO Number",
  },
  {
    id: "purchaseOrderQuantity",
    disablePadding: true,
    label: "PO Quantity",
  },
  {
    id: "supplier",
    numeric: false,
    disablePadding: true,
    label: "Supplier",
  },
  {
    id: "itemId",
    numeric: false,
    disablePadding: true,
    label: "Item ID",
  },
  {
    id: "origin",
    numeric: false,
    disablePadding: true,
    label: "Origin",
  },
  {
    id: "type",
    numeric: false,
    disablePadding: false,
    label: "Type",
  },
  {
    id: "buyingGrade",
    numeric: false,
    disablePadding: true,
    label: "Buying Grade",
  },
  {
    id: "salesGrade",
    numeric: false,
    disablePadding: true,
    label: "Sales Grade",
  },
  {
    id: "crop",
    numeric: true,
    disablePadding: true,
    label: "Crop Year",
  },
  {
    id: "descriptionOfGoods",
    numeric: true,
    disablePadding: true,
    label: "Description Of Goods",
  },
  {
    id: "storage",
    numeric: true,
    disablePadding: true,
    label: "Current Location",
  },
  {
    id: "redrawnStatus",
    numeric: false,
    disablePadding: true,
    label: "Redrawn Status",
  },
  {
    id: "soId",
    numeric: true,
    disablePadding: true,
    label: "SO Id",
  },
  {
    id: "soNumber",
    numeric: true,
    disablePadding: true,
    label: "SAP SO Number",
  },
  {
    id: "customer",
    numeric: false,
    disablePadding: true,
    label: "Customer",
  },
  {
    id: "customerGradeMark",
    numeric: false,
    disablePadding: true,
    label: "Customer Grade Mark",
  },
  {
    id: "warehouse",
    numeric: false,
    disablePadding: true,
    label: "Warehouse",
  },
  {
    id: "destination",
    numeric: false,
    disablePadding: true,
    label: "Destination",
  },
  {
    id: "portOfLoading",
    numeric: false,
    disablePadding: true,
    label: "Port Of Loading",
  },
  {
    id: "portOfDischarge",
    numeric: false,
    disablePadding: true,
    label: "Port Of Discharge",
  },
  {
    id: "numberOfCases",
    numeric: true,
    disablePadding: true,
    label: "Number Of Cartons",
  },
  {
    id: "netWeightPerCase",
    numeric: true,
    disablePadding: true,
    label: "Net Weight Per Carton",
  },
  {
    id: "cartonsPerContainer",
    numeric: false,
    disablePadding: true,
    label: "Cartons Per Container",
  },
  {
    id: "storage",
    numeric: false,
    disablePadding: true,
    label: "Shipment ETD",
  },
  {
    id: "approvalStatus",
    numeric: false,
    disablePadding: true,
    label: "Approval Status",
  },
  {
    id: "approvalDate",
    numeric: false,
    disablePadding: true,
    label: "Approval Date",
  },
  {
    id: "status",
    numeric: false,
    disablePadding: true,
    label: "Status",
  },
  {
    id: "loadingRemark",
    numeric: false,
    disablePadding: true,
    label: "Loading Remark",
  },
  {
    id: "remark",
    numeric: false,
    disablePadding: true,
    label: "Remark",
  },
  {
    id: "referenceSi",
    numeric: false,
    disablePadding: true,
    label: "Reference SI",
  },
  {
    id: "creationTime",
    numeric: false,
    disablePadding: true,
    label: "Creation Time",
  },
  {
    id: "createdBy",
    numeric: false,
    disablePadding: true,
    label: "Created By",
  },
  {
    id: "recordStatus",
    numeric: false,
    disablePadding: false,
    label: "Record Status",
  },
];

function EnhancedTableHead(props) {
  const {
    onSelectAllClick,
    order,
    orderBy,
    numSelected,
    rowCount,
    onRequestSort,
  } = props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        <TableCell padding="checkbox">
          <Checkbox
            color="primary"
            indeterminate={numSelected > 0 && numSelected < rowCount}
            checked={rowCount > 0 && numSelected === rowCount}
            onChange={onSelectAllClick}
            inputProps={{
              "aria-label": "select all desserts",
            }}
          />
        </TableCell>
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            align={"center"}
            padding={headCell.disablePadding ? "none" : "normal"}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : "asc"}
              onClick={createSortHandler(headCell.id)}
            >
              {headCell.label}
              {orderBy === headCell.id ? (
                <Box component="span" sx={visuallyHidden}>
                  {order === "desc" ? "sorted descending" : "sorted ascending"}
                </Box>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

EnhancedTableHead.propTypes = {
  numSelected: PropTypes.number.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  onSelectAllClick: PropTypes.func.isRequired,
  order: PropTypes.oneOf(["asc", "desc"]).isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
};

function EnhancedTableToolbar(props) {
  const {
    numSelected,
    showFilters,
    setShowFilters,
    rows,
    setAddView,
    selected,
    selectedData,
  } = props;
  return (
    <Toolbar
      sx={{
        pl: { sm: 2 },
        pr: { xs: 1, sm: 1 },
        ...(numSelected > 0 && {
          bgcolor: (theme) =>
            alpha(
              theme.palette.primary.main,
              theme.palette.action.activatedOpacity,
            ),
        }),
        display: "flex",
        justifyContent: "space-between",
      }}
    >
      <Stack direction="row" spacing={1} alignItems={"center"}>
        <Typography
          sx={{ fontWeight: 600, fontSize: "20px" }}
          variant="h6"
          id="tableTitle"
          component="div"
          color="#1E2685"
        >
          Shipment Requests
        </Typography>

        <Typography></Typography>
        {numSelected > 0 ? (
          <Typography
            sx={{ fontWeight: 200, fontSize: "16px" }}
            color="#1E2685"
            variant="subtitle1"
            component="div"
          >
            {numSelected} selected
          </Typography>
        ) : (
          <Typography></Typography>
        )}

        {numSelected > 0 && (
          <Tooltip title="Delete">
            <IconButton>
              <DeleteIcon />
            </IconButton>
          </Tooltip>
        )}
      </Stack>
      <Stack spacing={1} direction="row" sx={{ mr: 2 }}>
        <ExportExcel
          excelData={selectedData}
          fileName={"ShipmentRequestExport"}
          buttonLabel="Export Table"
        />

        <Button
          disableElevation
          variant="outlined"
          size="small"
          onClick={() => setAddView(true)}
        >
          Add
        </Button>
        <Button
          variant="outlined"
          onClick={() => setShowFilters(!showFilters)}
          sx={{ minWidth: "130px", mr: 2 }}
          size="small"
        >
          {showFilters ? "Hide Filters" : "Show Filters"}
        </Button>
      </Stack>
    </Toolbar>
  );
}

EnhancedTableToolbar.propTypes = {
  numSelected: PropTypes.number.isRequired,
};

export default function ShipmentRequestView() {
  const [rows, setRows] = useState([]);
  useEffect(() => {
    retrieveShipmentRequests();
    retrieveUsers();
  }, []);
  const [order, setOrder] = useState("asc");
  const [orderBy, setOrderBy] = useState("calories");
  const [selected, setSelected] = useState([]);
  const [selectedData, setSelectedData] = useState([]);
  const [page, setPage] = useState(0);
  const [dense, setDense] = useState(true);
  const [rowsPerPage, setRowsPerPage] = useState(25);
  const [addView, setAddView] = useState(false);
  const [addViewStates, setAddViewStates] = useState(null);
  const [addViewStatesArray, setAddViewStatesArray] = useState([]);
  const [showFilters, setShowFilters] = useState(false);
  const [selectedOrder, setSelectedOrder] = useState([]);
  const [vendors, setVendors] = useState([]);
  const [customers, setCustomers] = useState([]);
  const [users, setUsers] = useState([]);
  const [isAssignUserDialogOpen, setIsAssignUserDialogOpen] = useState(false);
  const [selectedUser, setSelectedUser] = useState("");
  const [selectedRow, setSelectedRow] = useState(null);
  const [warehouses, setWarehouses] = useState([]);
  const [destinations, setDestinations] = useState([]);

  function retrieveWarehouse() {
    retrieveAllWarehouseApi()
      .then((response) => {
        setWarehouses(response?.data);
        setDestinations(response?.data);
      })
      .catch((error) => console.log(error.response?.data));
  }

  const openAssignUserDialog = (row) => {
    setSelectedRow(row);
    setIsAssignUserDialogOpen(true);
  };

  const closeAssignUserDialog = () => {
    setIsAssignUserDialogOpen(false);
    setSelectedRow(null);
    setSelectedUser("");
  };

  const handleAssignAndApprove = () => {
    if (selectedRow && selectedUser) {
      const updatedRow = { ...selectedRow, assignedUser: selectedUser };
      approveShipmentRequest(selectedRow?.id, updatedRow);
      closeAssignUserDialog();
    }
  };

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const [componentsState, setComponentsState] = useState({
    purchaseOrder: {
      dropdownOpen: false,
      inputValue: "",
    },
    salesOrder: {
      dropdownOpen: false,
      inputValue: "",
    },
    supplier: {
      dropdownOpen: false,
      inputValue: "",
    },
    customer: {
      dropdownOpen: false,
      inputValue: "",
    },
    destination: {
      dropdownOpen: false,
      inputValue: "",
    },
    warehouse: {
      dropdownOpen: false,
      inputValue: "",
    },
  });

  const updateComponentState = (componentKey, newState) => {
    setComponentsState((prevState) => ({
      ...prevState,
      [componentKey]: {
        ...prevState[componentKey],
        ...newState,
      },
    }));
  };

  const [purchaseOrders, setPurchaseOrders] = useState([]);
  const [salesOrders, setSalesOrders] = useState([]);

  const [filterShipmentRequest, setFilterShipmentRequest] = useState({
    id: 0,
    shipmentRequestTypeList: [],
    purchaseOrderIdList: [],
    salesOrderIdList: [],
    supplierIdList: [],
    itemIdList: [],
    customerIdList: [],
    assignedUserIdList: [],
    destination: "",
    approvalStatusList: [],
    paymentStatusList: [],
    portOfLoadingList: [],
    portOfDischargeList: [],
    loadingRemark: "",
    remark: "",
    statusList: [],
    recordStatusList: ["ACTIVE"],
  });

  const [editVendorOrder, setEditVendorOrder] = useState({
    visible: false,
    data: null,
    index: null,
  });

  const [editCustomerOrder, setEditCustomerOrder] = useState({
    visible: false,
    data: null,
    index: null,
  });

  function approveShipmentRequest(shipmentRequestId, shipmentRequest) {
    approveShipmentRequestApi(shipmentRequestId, shipmentRequest)
      .then((response) => {
        window.location.reload();
        toast.success("Shipment request approved successfully.");
      })
      .catch((error) => {
        toast.error("No Authorization");
        console.log(error.response.data);
      });
  }

  function retrieveShipmentRequests() {
    retrieveAllShipmentRequestsApi()
      .then((response) => {
        setRows(response?.data);
      })
      .catch((error) => console.log(error.response?.data));
  }

  function retrieveOrderById(id) {
    retrieveOrderByIdApi(id)
      .then((response) => {
        setSelectedOrder(response?.data);
      })
      .catch((error) => console.log(error.response?.data));
  }

  function retrieveOrders(type) {
    retrieveAllOrdersApi(type)
      .then((response) => {
        if (type === "VENDOR") {
          setPurchaseOrders(response?.data);
        } else if (type === "CUSTOMER") {
          setSalesOrders(response?.data);
        }
      })
      .catch((error) => console.log(error.response?.data));
  }

  function retrieveStakeholders(type) {
    retrieveAllStakeholdersApi(type)
      .then((response) => {
        if (type === "VENDOR") {
          setVendors(response?.data);
        } else if (type === "CUSTOMER") {
          setCustomers(response?.data);
        }
      })
      .catch((error) => console.log(error.response?.data));
  }

  function retrieveUsers() {
    retrieveUsersApi()
      .then((response) => {
        setUsers(response?.data);
      })
      .catch((error) => console.log(error.response?.data));
  }

  const [editShipmentRequest, setEditShipmentRequest] = useState({
    visible: false,
    data: null,
    index: null,
  });

  const [showSIPopup, setShowSIPopup] = useState({
    visible: false,
    data: null,
  });

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelected = rows.map((n) => n.id);
      setSelected(newSelected);
      setSelectedData(rows);
      return;
    } else {
      setSelected([]);
      setSelectedData([]);
    }
  };

  const handleClick = (event, name, row) => {
    const selectedIndex = selected.indexOf(name);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1),
      );
    }

    const selectedDataIndex = selectedData.indexOf(row);
    let newSelectedData = [];

    if (selectedDataIndex === -1) {
      newSelectedData = newSelectedData.concat(selectedData, row);
    } else if (selectedDataIndex === 0) {
      newSelectedData = newSelectedData.concat(selectedData.slice(1));
    } else if (selectedDataIndex === selectedData.length - 1) {
      newSelectedData = newSelectedData.concat(selectedData.slice(0, -1));
    } else if (selectedDataIndex > 0) {
      newSelectedData = newSelectedData.concat(
        selectedData.slice(0, selectedDataIndex),
        selectedData.slice(selectedDataIndex + 1),
      );
    }
    setSelectedData(newSelectedData);

    setSelected(newSelected);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleChangeDense = (event) => {
    setDense(event.target.checked);
  };

  const isSelected = (name) => selected.indexOf(name) !== -1;

  // Avoid a layout jump when reaching the last page with empty rows.
  const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - rows.length) : 0;

  const visibleRows = React.useMemo(
    () =>
      stableSort(rows, getComparator(order, orderBy)).slice(
        page * rowsPerPage,
        page * rowsPerPage + rowsPerPage,
      ),
    [order, orderBy, page, rowsPerPage, rows, editShipmentRequest],
  );

  return (
    <div>
      {addView ? (
        <AddShipmentRequest
          setAddViewStates={setAddViewStates}
          addViewStates={addViewStates}
          rows={rows}
          setRows={setRows}
          setAddView={setAddView}
        />
      ) : showSIPopup.visible ? (
        <CreateSI
          setAddViewStatesArray={setAddViewStatesArray}
          addViewStatesArray={addViewStatesArray}
          setAddViewStates={setAddViewStates}
          addViewStates={addViewStates}
          rows={rows}
          setRows={setRows}
          showSIPopup={showSIPopup}
          setAddView={setShowSIPopup}
        />
      ) : editVendorOrder.visible ? (
        <EditVendorOrder
          rows={rows}
          setRows={setRows}
          setAddView={setAddView}
          editVendorOrder={editVendorOrder}
          setEditVendorOrder={setEditVendorOrder}
        />
      ) : editCustomerOrder.visible ? (
        <EditCustomerOrder
          rows={rows}
          setRows={setRows}
          setAddView={setAddView}
          editCustomerOrder={editCustomerOrder}
          setEditCustomerOrder={setEditCustomerOrder}
        />
      ) : editShipmentRequest.visible ? (
        <EditShipmentRequest
          rows={rows}
          setRows={setRows}
          setAddView={setAddView}
          setAddViewStates={setAddViewStates}
          addViewStates={addViewStates}
          editShipmentRequest={editShipmentRequest}
          setEditShipmentRequest={setEditShipmentRequest}
        />
      ) : (
        <>
          {showFilters && (
            <Box
              component="form"
              sx={{
                "& .MuiTextField-root": { m: 1, width: "25ch" },
              }}
              noValidate
              autoComplete="off"
            >
              <Grid
                container
                spacing={1}
                justifyContent="flex-start"
                alignItems="center"
              >
                <TextField
                  type="search"
                  id="outlined-select-type"
                  select
                  fullWidth
                  label="Type"
                  SelectProps={{
                    multiple: true,
                    value: filterShipmentRequest.shipmentRequestTypeList || [],
                    onChange: (e) => {
                      setFilterShipmentRequest({
                        ...filterShipmentRequest,
                        shipmentRequestTypeList: e.target.value,
                      });
                    },
                  }}
                >
                  {shipmentRequestTypes.map((option) => (
                    <MenuItem key={option.value} value={option.value}>
                      {option.label}
                    </MenuItem>
                  ))}
                </TextField>

                <Autocomplete
                  multiple
                  id="outlined-select-po"
                  disableClearable
                  open={componentsState.purchaseOrder.dropdownOpen}
                  onOpen={() =>
                    updateComponentState("purchaseOrder", {
                      dropdownOpen: true,
                    })
                  }
                  inputValue={componentsState.purchaseOrder.inputValue}
                  onInputChange={(event, newInputValue, reason) => {
                    if (reason === "input") {
                      updateComponentState("purchaseOrder", {
                        inputValue: newInputValue,
                      });
                    }
                  }}
                  options={[
                    ...purchaseOrders.filter((purchaseOrder) =>
                      filterShipmentRequest.purchaseOrderIdList.includes(
                        purchaseOrder.id,
                      ),
                    ),
                    ...purchaseOrders.filter(
                      (purchaseOrder) =>
                        !filterShipmentRequest.purchaseOrderIdList.includes(
                          purchaseOrder.id,
                        ),
                    ),
                  ]}
                  getOptionLabel={(option) =>
                    `ID: ${option?.id || ""}, Item Buying Grade: ${
                      option?.offer?.item?.buyingGrade || ""
                    }, 
                       Origin: ${option?.offer?.item?.origin || ""}, Crop: ${
                      option?.offer?.item?.crop || ""
                    }`
                  }
                  value={purchaseOrders.filter((purchaseOrder) =>
                    filterShipmentRequest.purchaseOrderIdList.includes(
                      purchaseOrder.id,
                    ),
                  )}
                  onChange={(event, newValue) => {
                    setFilterShipmentRequest({
                      ...filterShipmentRequest,
                      purchaseOrderIdList: newValue
                        .map((purchaseOrder) => purchaseOrder.id)
                        .filter((id) => id !== null),
                    });
                  }}
                  renderOption={(props, option, { selected }) => (
                    <li
                      {...props}
                      style={{
                        ...props.style,
                        backgroundColor: selected ? "lightblue" : undefined,
                        fontWeight: selected ? 700 : 400,
                      }}
                    >
                      {`ID: ${option?.id || ""}, Item Buying Grade: ${
                        option?.offer?.item?.buyingGrade || ""
                      }, 
                       Origin: ${option?.offer?.item?.origin || ""}, Crop: ${
                        option?.offer?.item?.crop || ""
                      }`}
                    </li>
                  )}
                  renderInput={(params) => {
                    const label =
                      filterShipmentRequest?.purchaseOrderIdList.length > 0
                        ? `POs Selected (${filterShipmentRequest?.purchaseOrderIdList.length})`
                        : "PO";

                    return (
                      <TextField
                        {...params}
                        label={label}
                        type="search"
                        value={componentsState.purchaseOrder.inputValue}
                        onChange={(e) => {
                          updateComponentState("purchaseOrder", {
                            inputValue: e.target.value,
                          });
                        }}
                        onMouseDown={() => retrieveOrders("VENDOR")}
                        InputProps={{
                          ...params.InputProps,
                          style: { height: 42 },
                        }}
                        onBlur={() =>
                          updateComponentState("purchaseOrder", {
                            dropdownOpen: false,
                          })
                        }
                      />
                    );
                  }}
                  renderTags={() => null}
                />

                <Autocomplete
                  multiple
                  id="outlined-select-supplier"
                  disableClearable
                  open={componentsState.supplier.dropdownOpen}
                  onOpen={() =>
                    updateComponentState("supplier", { dropdownOpen: true })
                  }
                  inputValue={componentsState.supplier.inputValue}
                  onInputChange={(event, newInputValue, reason) => {
                    if (reason === "input") {
                      updateComponentState("supplier", {
                        inputValue: newInputValue,
                      });
                    }
                  }}
                  options={[
                    ...vendors.filter((option) =>
                      filterShipmentRequest.supplierIdList.includes(option.id),
                    ),
                    ...vendors.filter(
                      (option) =>
                        !filterShipmentRequest.supplierIdList.includes(
                          option.id,
                        ),
                    ),
                  ]}
                  getOptionLabel={(option) =>
                    `${option?.id || ""} - ${option?.name || ""}`
                  }
                  value={vendors.filter((option) =>
                    filterShipmentRequest.supplierIdList.includes(option.id),
                  )}
                  onChange={(event, newValue) => {
                    setFilterShipmentRequest({
                      ...filterShipmentRequest,
                      supplierIdList: newValue.map((option) => option.id),
                    });
                  }}
                  renderOption={(props, option, { selected }) => (
                    <li
                      {...props}
                      style={{
                        ...props.style,
                        backgroundColor: selected ? "lightblue" : undefined,
                        fontWeight: selected ? 700 : 400,
                      }}
                    >
                      {`${option?.id || ""} - ${option?.name || ""}`}
                    </li>
                  )}
                  renderInput={(params) => {
                    const label =
                      filterShipmentRequest?.supplierIdList.length > 0
                        ? `Vendors Selected (${filterShipmentRequest?.supplierIdList.length})`
                        : "Vendor";

                    return (
                      <TextField
                        {...params}
                        label={label}
                        type="search"
                        value={componentsState.supplier.inputValue}
                        onChange={(e) =>
                          updateComponentState("supplier", {
                            inputValue: e.target.value,
                          })
                        }
                        onMouseDown={() => retrieveStakeholders("VENDOR")}
                        InputProps={{
                          ...params.InputProps,
                          style: { height: 42 },
                        }}
                        onBlur={() =>
                          updateComponentState("supplier", {
                            dropdownOpen: false,
                          })
                        }
                      />
                    );
                  }}
                  renderTags={() => null}
                />

                <Autocomplete
                  multiple
                  id="outlined-select-so"
                  disableClearable
                  open={componentsState.salesOrder.dropdownOpen}
                  onOpen={() =>
                    updateComponentState("salesOrder", { dropdownOpen: true })
                  }
                  inputValue={componentsState.salesOrder.inputValue}
                  onInputChange={(event, newInputValue, reason) => {
                    if (reason === "input") {
                      updateComponentState("salesOrder", {
                        inputValue: newInputValue,
                      });
                    }
                  }}
                  options={[
                    ...salesOrders.filter((salesOrder) =>
                      filterShipmentRequest.salesOrderIdList.includes(
                        salesOrder.id,
                      ),
                    ),
                    ...salesOrders.filter(
                      (salesOrder) =>
                        !filterShipmentRequest.salesOrderIdList.includes(
                          salesOrder.id,
                        ),
                    ),
                  ]}
                  getOptionLabel={(option) =>
                    `ID: ${option?.id || ""}, Item Buying Grade: ${
                      option?.offer?.item?.buyingGrade || ""
                    }, 
                       Origin: ${option?.offer?.item?.origin || ""}, Crop: ${
                      option?.offer?.item?.crop || ""
                    }`
                  }
                  value={salesOrders.filter((salesOrder) =>
                    filterShipmentRequest.salesOrderIdList.includes(
                      salesOrder.id,
                    ),
                  )}
                  onChange={(event, newValue) => {
                    setFilterShipmentRequest({
                      ...filterShipmentRequest,
                      salesOrderIdList: newValue
                        .map((salesOrder) => salesOrder.id)
                        .filter((id) => id !== null),
                    });
                  }}
                  renderOption={(props, option, { selected }) => (
                    <li
                      {...props}
                      style={{
                        ...props.style,
                        backgroundColor: selected ? "lightblue" : undefined,
                        fontWeight: selected ? 700 : 400,
                      }}
                    >
                      {`ID: ${option?.id || ""}, Item Buying Grade: ${
                        option?.offer?.item?.buyingGrade || ""
                      }, 
                       Origin: ${option?.offer?.item?.origin || ""}, Crop: ${
                        option?.offer?.item?.crop || ""
                      }`}
                    </li>
                  )}
                  renderInput={(params) => {
                    const label =
                      filterShipmentRequest?.salesOrderIdList.length > 0
                        ? `SOs Selected (${filterShipmentRequest?.salesOrderIdList.length})`
                        : "SO";

                    return (
                      <TextField
                        {...params}
                        label={label}
                        type="search"
                        value={componentsState.salesOrder.inputValue}
                        onChange={(e) => {
                          updateComponentState("salesOrder", {
                            inputValue: e.target.value,
                          });
                        }}
                        onMouseDown={() => retrieveOrders("CUSTOMER")}
                        InputProps={{
                          ...params.InputProps,
                          style: { height: 42 },
                        }}
                        onBlur={() =>
                          updateComponentState("salesOrder", {
                            dropdownOpen: false,
                          })
                        }
                      />
                    );
                  }}
                  renderTags={() => null}
                />

                <Autocomplete
                  multiple
                  id="outlined-select-customer"
                  disableClearable
                  open={componentsState.customer.dropdownOpen}
                  onOpen={() =>
                    updateComponentState("customer", { dropdownOpen: true })
                  }
                  inputValue={componentsState.customer.inputValue}
                  onInputChange={(event, newInputValue, reason) => {
                    if (reason === "input") {
                      updateComponentState("customer", {
                        inputValue: newInputValue,
                      });
                    }
                  }}
                  options={[
                    ...customers.filter((option) =>
                      filterShipmentRequest.customerIdList.includes(option.id),
                    ),
                    ...customers.filter(
                      (option) =>
                        !filterShipmentRequest.customerIdList.includes(
                          option.id,
                        ),
                    ),
                  ]}
                  getOptionLabel={(option) =>
                    `${option?.id || ""} - ${option?.name || ""}`
                  }
                  value={customers.filter((option) =>
                    filterShipmentRequest.customerIdList.includes(option.id),
                  )}
                  onChange={(event, newValue) => {
                    setFilterShipmentRequest({
                      ...filterShipmentRequest,
                      customerIdList: newValue.map((option) => option.id),
                    });
                  }}
                  renderOption={(props, option, { selected }) => (
                    <li
                      {...props}
                      style={{
                        ...props.style,
                        backgroundColor: selected ? "lightblue" : undefined,
                        fontWeight: selected ? 700 : 400,
                      }}
                    >
                      {`${option?.id || ""} - ${option?.name || ""}`}
                    </li>
                  )}
                  renderInput={(params) => {
                    const label =
                      filterShipmentRequest?.customerIdList.length > 0
                        ? `Customers Selected (${filterShipmentRequest?.customerIdList.length})`
                        : "Customer";

                    return (
                      <TextField
                        {...params}
                        label={label}
                        type="search"
                        value={componentsState.customer.inputValue}
                        onChange={(e) =>
                          updateComponentState("customer", {
                            inputValue: e.target.value,
                          })
                        }
                        onMouseDown={() => retrieveStakeholders("CUSTOMER")}
                        InputProps={{
                          ...params.InputProps,
                          style: { height: 42 },
                        }}
                        onBlur={() =>
                          updateComponentState("customer", {
                            dropdownOpen: false,
                          })
                        }
                      />
                    );
                  }}
                  renderTags={() => null}
                />

                <TextField
                  label="Assigned User"
                  type="search"
                  id="outlined-select-assigned-user"
                  select
                  SelectProps={{
                    multiple: true,
                    value: filterShipmentRequest.assignedUserIdList || [],
                    onChange: (e) => {
                      setFilterShipmentRequest({
                        ...filterShipmentRequest,
                        assignedUserIdList: e.target.value,
                      });
                    },
                  }}
                >
                  {users.map((option) => (
                    <MenuItem key={option?.id} value={option?.id}>
                      {option?.name}
                    </MenuItem>
                  ))}
                </TextField>

                <Autocomplete
                  multiple
                  id="outlined-select-warehouse"
                  disableClearable
                  open={componentsState.warehouse.dropdownOpen}
                  onOpen={() => updateComponentState("warehouse", { dropdownOpen: true })}
                  inputValue={componentsState.warehouse.inputValue}
                  onInputChange={(event, newInputValue, reason) => {
                    if (reason === "input") {
                      updateComponentState("warehouse", { inputValue: newInputValue });
                    }
                  }}
                  options={warehouses}
                  getOptionLabel={(option) =>
                    `${option?.id || ""} - ${option?.code || ""} - ${
                      option?.name || ""}`
                  }
                  value={warehouses.filter((warehouse) =>
                    filterShipmentRequest?.warehouseList?.includes(warehouse.name),
                  )}
                  onChange={(event, newValue) => {
                    setFilterShipmentRequest({
                      ...filterShipmentRequest,
                      warehouseList: newValue.map((warehouse) => warehouse.name),
                    });
                  }}
                  renderOption={(props, option, { selected }) => (
                    <li
                      {...props}
                      style={{
                        ...props.style,
                        backgroundColor: selected ? "lightblue" : undefined,
                        fontWeight: selected ? 700 : 400,
                      }}
                    >
                      {`${option?.id || ""} - ${option?.code || ""} - ${
                      option?.name || ""}`}
                    </li>
                  )}
                  renderInput={(params) => {
                    const label =
                    filterShipmentRequest?.warehouseList?.length > 0
                        ? `Warehouses Selected (${filterShipmentRequest?.warehouseList?.length})`
                        : "Warehouse";
          
                    return (
                      <TextField
                        {...params}
                        label={label}
                        type="search"
                        value={componentsState.warehouse.inputValue}
                        onChange={(e) =>
                          updateComponentState("warehouse", { inputValue: e.target.value })
                        }
                        onMouseDown={retrieveWarehouse}
                        InputProps={{
                          ...params.InputProps,
                          style: { height: 42 },
                        }}
                        onBlur={() =>
                          updateComponentState("warehouse", { dropdownOpen: false })
                        }
                      />
                    );
                  }}
                  renderTags={() => null}
                />
                
                <Autocomplete
                  multiple
                  id="outlined-select-destination"
                  disableClearable
                  open={componentsState.destination.dropdownOpen}
                  onOpen={() => updateComponentState("destination", { dropdownOpen: true })}
                  inputValue={componentsState.destination.inputValue}
                  onInputChange={(event, newInputValue, reason) => {
                    if (reason === "input") {
                      updateComponentState("destination", { inputValue: newInputValue });
                    }
                  }}
                  options={destinations}
                  getOptionLabel={(option) =>
                    `${option?.id || ""} - ${option?.code || ""} - ${
                      option?.name || ""}`
                  }
                  value={destinations.filter((destination) =>
                    filterShipmentRequest?.destinationList?.includes(destination.name),
                  )}
                  onChange={(event, newValue) => {
                    setFilterShipmentRequest({
                      ...filterShipmentRequest,
                      destinationList: newValue.map((destination) => destination.name),
                    });
                  }}
                  renderOption={(props, option, { selected }) => (
                    <li
                      {...props}
                      style={{
                        ...props.style,
                        backgroundColor: selected ? "lightblue" : undefined,
                        fontWeight: selected ? 700 : 400,
                      }}
                    >
                      {`${option?.id || ""} - ${option?.code || ""} - ${
                      option?.name || ""}`}
                    </li>
                  )}
                  renderInput={(params) => {
                    const label =
                    filterShipmentRequest?.destinationList?.length > 0
                        ? `Destinations Selected (${filterShipmentRequest?.destinationList?.length})`
                        : "Destination";
          
                    return (
                      <TextField
                        {...params}
                        label={label}
                        type="search"
                        value={componentsState.destination.inputValue}
                        onChange={(e) =>
                          updateComponentState("destination", { inputValue: e.target.value })
                        }
                        onMouseDown={retrieveWarehouse}
                        InputProps={{
                          ...params.InputProps,
                          style: { height: 42 },
                        }}
                        onBlur={() =>
                          updateComponentState("destination", { dropdownOpen: false })
                        }
                      />
                    );
                  }}
                  renderTags={() => null}
                />

                <TextField
                  id="outlined-warehouse"
                  label="Warehouse"
                  value={filterShipmentRequest?.warehouse || ""}
                  onChange={(e) =>
                    setFilterShipmentRequest({
                      ...filterShipmentRequest,
                      warehouse: e.target.value,
                    })
                  }
                />

                <TextField
                  id="outlined-destination"
                  label="Destination"
                  value={filterShipmentRequest?.destination || ""}
                  onChange={(e) =>
                    setFilterShipmentRequest({
                      ...filterShipmentRequest,
                      destination: e.target.value,
                    })
                  }
                />

                <TextField
                  id="outlined-number-of-cases"
                  label="Number Of Cartons"
                  value={filterShipmentRequest?.numberOfCases || ""}
                  type="number"
                  onChange={(e) =>
                    setFilterShipmentRequest({
                      ...filterShipmentRequest,
                      numberOfCases: e.target.value,
                    })
                  }
                />

                <TextField
                  id="outlined-net-weight-per-case"
                  label="Net Weight Per Carton"
                  value={filterShipmentRequest?.netWeightPerCase || ""}
                  type="number"
                  onChange={(e) =>
                    setFilterShipmentRequest({
                      ...filterShipmentRequest,
                      netWeightPerCase: e.target.value,
                    })
                  }
                />
                <TextField
                  id="outlined-shipment-quantity"
                  label="Shipment Quantity"
                  value={filterShipmentRequest?.shipmentQuantity || ""}
                  type="number"
                  onChange={(e) =>
                    setFilterShipmentRequest({
                      ...filterShipmentRequest,
                      shipmentQuantity: e.target.value,
                    })
                  }
                />
                <TextField
                  id="outlined-cartons-per-container"
                  label="Cartons Per Container"
                  value={filterShipmentRequest?.cartonsPerContainer || ""}
                  type="number"
                  onChange={(e) =>
                    setFilterShipmentRequest({
                      ...filterShipmentRequest,
                      cartonsPerContainer: e.target.value,
                    })
                  }
                />
                <TextField
                  label="SG Approval Status"
                  type="search"
                  id="outlined-select-approval-status"
                  select
                  SelectProps={{
                    multiple: true,
                    value: filterShipmentRequest.approvalStatusList || [],
                    onChange: (e) => {
                      setFilterShipmentRequest({
                        ...filterShipmentRequest,
                        approvalStatusList: e.target.value,
                      });
                    },
                  }}
                >
                  {status.map((option) => (
                    <MenuItem key={option.value} value={option.value}>
                      {option.label}
                    </MenuItem>
                  ))}
                </TextField>
                <TextField
                  id="outlined-search"
                  label="Remark"
                  value={filterShipmentRequest?.remark || ""}
                  type="search"
                  onChange={(e) =>
                    setFilterShipmentRequest({
                      ...filterShipmentRequest,
                      remark: e.target.value,
                    })
                  }
                />

                <TextField
                  label="Status"
                  type="search"
                  id="outlined-select-status"
                  select
                  SelectProps={{
                    multiple: true,
                    value: filterShipmentRequest.statusList || [],
                    onChange: (e) => {
                      setFilterShipmentRequest({
                        ...filterShipmentRequest,
                        statusList: e.target.value,
                      });
                    },
                  }}
                >
                  {shipmentRequestStatus.map((option) => (
                    <MenuItem key={option.value} value={option.value}>
                      {option.label}
                    </MenuItem>
                  ))}
                </TextField>
              </Grid>
              <br></br>
              <Grid
                container
                spacing={2}
                justifyContent="flex-start"
                alignItems="center"
              >
                <Grid item xs={3} sm={1}>
                  <Button
                    disableElevation
                    variant="contained"
                    fullWidth
                    onClick={() => {
                      retrieveFilteredShipmentRequestsApi(filterShipmentRequest)
                        .then((response) => {
                          setRows(response?.data);
                        })
                        .catch((error) => {
                          console.log(error?.response?.data);
                        });
                    }}
                  >
                    Search
                  </Button>
                </Grid>
                <Grid item xs={3} sm={1}>
                  <Button
                    disableElevation
                    variant="outlined"
                    fullWidth
                    onClick={() => {
                      setFilterShipmentRequest({
                        id: 0,
                        shipmentRequestTypeList: [],
                        purchaseOrderIdList: [],
                        salesOrderIdList: [],
                        supplierIdList: [],
                        itemIdList: [],
                        customerIdList: [],
                        destination: "",
                        destinationList: [],
                        warehouseList: [],
                        approvalStatusList: [],
                        paymentStatusList: [],
                        portOfLoadingList: [],
                        portOfDischargeList: [],
                        loadingRemark: "",
                        remark: "",
                        netWeightPerCase: null,
                        numberOfCases: null,
                        shipmentQuantity: null,
                        cartonsPerContainer: null,
                        statusList: [],
                        recordStatusList: ["ACTIVE"],
                        shipmentEstimatedTimeOfDeparture: "",
                        approvalDate: "",
                      });
                    }}
                  >
                    Clear Filters
                  </Button>
                </Grid>
              </Grid>
            </Box>
          )}

          <Box sx={{ width: "100%", mt: 2 }}>
            <Paper sx={{ width: "100%", mb: 2 }}>
              <EnhancedTableToolbar
                numSelected={selected.length}
                showFilters={showFilters}
                setShowFilters={setShowFilters}
                rows={rows}
                setAddView={setAddView}
                selected={selected}
                selectedData={selectedData}
              />
              <TableContainer style={{ maxHeight: 700 }}>
                <Table
                  stickyHeader
                  aria-label="sticky table"
                  sx={{
                    minWidth: 750,
                    "& .MuiTableCell-root": {
                      borderRight: "1px solid rgba(224, 224, 224, 1)",
                    },
                    "& .MuiTableCell-head": {
                      backgroundColor: "#20315d",
                      color: "white",
                      borderRight: "1px solid rgba(49, 64, 128, 1)",
                    },
                  }}
                  aria-labelledby="tableTitle"
                  size={dense ? "small" : "medium"}
                  style={{ width: "max-content" }}
                >
                  <EnhancedTableHead
                    numSelected={selected.length}
                    order={order}
                    orderBy={orderBy}
                    onSelectAllClick={handleSelectAllClick}
                    onRequestSort={handleRequestSort}
                    rowCount={rows.length}
                  />
                  <TableBody>
                    {visibleRows.map((row, index) => {
                      const isItemSelected = isSelected(row.id);
                      const labelId = `enhanced-table-checkbox-${index}`;

                      return (
                        <TableRow
                          style={
                            isSelected(row.id)
                              ? { background: "#d7e7fc" }
                              : row.status === "SI Closed"
                              ? {
                                  background: "#898989",
                                  textDecoration: "line-through",
                                }
                              : row.status === "SI Created"
                              ? { background: "#bdb9b9" }
                              : row.approvalStatus === "Approved"
                              ? { background: "#C8E6C9" }
                              : row.approvalStatus === "Not Approved"
                              ? { background: "#FFCDD2" }
                              : row.approvalStatus === "Waiting"
                              ? { background: "#FFF9C4" }
                              : //: index % 2
                                //? { background: '#f0f0f0' }
                                { background: "#FFF9C4" }
                          }
                          hover
                          onClick={(event) => handleClick(event, row.id, row)}
                          role="checkbox"
                          aria-checked={isItemSelected}
                          tabIndex={-1}
                          key={row.id}
                          selected={isItemSelected}
                          sx={{ cursor: "pointer" }}
                        >
                          <TableCell padding="checkbox">
                            <Checkbox
                              color="primary"
                              checked={isItemSelected}
                              inputProps={{
                                "aria-labelledby": labelId,
                              }}
                            />
                          </TableCell>
                          <TableCell padding="normal">
                            <Button
                              onClick={(e) => {
                                e.preventDefault();
                                if (row?.status === "SI Created") {
                                  alert("An SI is connected to this SR.");
                                } else if (row?.status === "SI Closed") {
                                  alert(
                                    "An SI connected to this SR is closed. Editing is not allowed.",
                                  );
                                  return;
                                }
                                setEditShipmentRequest({
                                  visible: true,
                                  data: row,
                                  index: index,
                                });
                              }}
                            >
                              Edit
                            </Button>
                          </TableCell>
                          <TableCell padding="normal">
                            <Button
                              variant="outlined"
                              disabled={row?.approvalStatus === "Approved"}
                              onClick={() => openAssignUserDialog(row)}
                            >
                              Approve
                            </Button>
                          </TableCell>
                          <Dialog
                            open={isAssignUserDialogOpen}
                            onClose={closeAssignUserDialog}
                          >
                            <DialogTitle>
                              Assign Shipment Request to a User
                            </DialogTitle>
                            <DialogContent>
                              <Select
                                value={selectedUser}
                                onChange={(e) =>
                                  setSelectedUser(e.target.value)
                                }
                                displayEmpty
                                fullWidth
                              >
                                <MenuItem value="" disabled>
                                  Select a User
                                </MenuItem>
                                {users.map((user) => (
                                  <MenuItem key={user} value={user}>
                                    {user.name}
                                  </MenuItem>
                                ))}
                              </Select>
                            </DialogContent>
                            <DialogActions>
                              <Button onClick={closeAssignUserDialog}>
                                Cancel
                              </Button>
                              <Button
                                onClick={handleAssignAndApprove}
                                disabled={!selectedUser}
                              >
                                Approve
                              </Button>
                            </DialogActions>
                          </Dialog>
                          <TableCell padding="normal">
                            <Button
                              style={{ width: "max-content" }}
                              disabled={
                                row?.approvalStatus !== "Approved" ||
                                row?.status === "SI Created" ||
                                row?.status === "SI Closed"
                              }
                              onClick={() => {
                                if (
                                  localStorage.getItem("username") ===
                                  row?.assignedUser?.email
                                ) {
                                  setShowSIPopup({ data: row, visible: true });
                                } else {
                                  toast.error(
                                    "You are not the assigned user for this SI.",
                                  );
                                }
                              }}
                            >
                              Create SI
                            </Button>
                          </TableCell>
                          <TableCell align="center">{row?.id}</TableCell>
                          <TableCell align="center">
                            {row?.assignedUser?.name}
                          </TableCell>
                          <TableCell align="center">
                            {row?.shipmentRequestType}
                          </TableCell>
                          <TableCell align="center">
                            {row?.shipmentQuantity
                              ? `${new Intl.NumberFormat("de-DE", {
                                  style: "decimal",
                                  minimumFractionDigits: 2,
                                }).format(row.shipmentQuantity)} ${
                                  row?.shipmentQuantityUnit
                                }`
                              : ""}
                          </TableCell>
                          <TableCell align="center">
                            {row?.shipmentRequestTo}
                          </TableCell>
                          <TableCell align="center">
                            <Button
                              onClick={() => {
                                retrieveOrderById(row?.purchaseOrder?.id);
                              }}
                              onDoubleClick={() => {
                                setTimeout(() => {
                                  setEditVendorOrder({
                                    visible: true,
                                    data: selectedOrder,
                                    index: index,
                                  });
                                }, 1000);
                              }}
                            >
                              {row?.purchaseOrder?.id}
                            </Button>
                          </TableCell>
                          <TableCell align="center">
                            {row?.purchaseOrder?.purchaseOrderNumber}
                          </TableCell>
                          <TableCell align="center">
                            {row?.purchaseOrder?.volume
                              ? `${new Intl.NumberFormat("de-DE", {
                                  style: "decimal",
                                  minimumFractionDigits: 2,
                                }).format(row?.purchaseOrder?.volume)} ${
                                  row?.purchaseOrder?.volumeUnit
                                }`
                              : ""}
                          </TableCell>
                          <TableCell align="center">
                            {row?.purchaseOrder?.offer?.stakeholder?.name || ""}
                          </TableCell>
                          <TableCell align="center">
                            {row?.salesOrder?.offer?.item?.id ||
                              row?.purchaseOrder?.offer?.item?.id ||
                              ""}
                          </TableCell>
                          <TableCell align="center">
                            {row?.salesOrder?.offer?.item?.origin ||
                              row?.purchaseOrder?.offer?.item?.origin ||
                              ""}
                          </TableCell>
                          <TableCell align="center">
                            {row?.salesOrder?.offer?.item?.type ||
                              row?.purchaseOrder?.offer?.item?.type ||
                              ""}
                          </TableCell>
                          <TableCell align="center">
                            {row?.salesOrder?.offer?.item?.buyingGrade ||
                              row?.purchaseOrder?.offer?.item?.buyingGrade ||
                              ""}
                          </TableCell>
                          <TableCell align="center">
                            {row?.salesOrder?.offer?.item?.salesGrade ||
                              row?.purchaseOrder?.offer?.item?.salesGrade ||
                              ""}
                          </TableCell>
                          <TableCell align="center">
                            {row?.salesOrder?.offer?.item?.crop ||
                              row?.purchaseOrder?.offer?.item?.crop ||
                              ""}
                          </TableCell>
                          <TableCell align="center">
                            {row?.salesOrder?.descriptionOfGoods?.length >
                            50 ? (
                              <span
                                style={{
                                  display: "inline-block",
                                  maxWidth: "350px",
                                  whiteSpace: "nowrap",
                                  overflow: "hidden",
                                  textOverflow: "ellipsis",
                                  verticalAlign: "top",
                                }}
                                title={row?.salesOrder?.descriptionOfGoods}
                              >
                                {row?.salesOrder?.descriptionOfGoods}
                              </span>
                            ) : (
                              row?.salesOrder?.descriptionOfGoods
                            )}
                          </TableCell>
                          <TableCell align="center">
                            {row?.salesOrder?.offer?.item?.storage ||
                              row?.purchaseOrder?.offer?.item?.storage ||
                              ""}
                          </TableCell>
                          <TableCell align="center">
                            {row?.salesOrder?.secondSampleStatus || ""}
                          </TableCell>
                          <TableCell align="center">
                            <Button
                              onClick={() => {
                                retrieveOrderById(row?.salesOrder?.id);
                              }}
                              onDoubleClick={() => {
                                setTimeout(() => {
                                  setEditCustomerOrder({
                                    visible: true,
                                    data: selectedOrder,
                                    index: index,
                                  });
                                }, 1000);
                              }}
                            >
                              {row?.salesOrder?.id || ""}
                            </Button>
                          </TableCell>
                          <TableCell align="center">
                            {row?.salesOrder?.salesOrderNumber || ""}
                          </TableCell>
                          <TableCell align="center">
                            {row?.salesOrder?.offer?.stakeholder?.name || ""}
                          </TableCell>
                          <TableCell align="center">
                            {row?.customerGradeMark || ""}
                          </TableCell>
                          <TableCell align="center">
                            {row?.warehouse || ""}
                          </TableCell>
                          <TableCell align="center">
                            {row?.destination || ""}
                          </TableCell>
                          <TableCell align="center">
                            {row?.portOfLoading || ""}
                          </TableCell>
                          <TableCell align="center">
                            {row?.portOfDischarge || ""}
                          </TableCell>
                          <TableCell align="center">
                            {row?.numberOfCases || ""}
                          </TableCell>
                          <TableCell align="center">
                            {row?.netWeightPerCase || ""}
                          </TableCell>
                          <TableCell align="center">
                            {row?.cartonsPerContainer || ""}
                          </TableCell>
                          <TableCell align="center">
                            {row?.shipmentEstimatedTimeOfDeparture
                              ? format(
                                  new Date(
                                    row?.shipmentEstimatedTimeOfDeparture,
                                  ),
                                  "dd/MM/yyyy",
                                )
                              : ""}
                          </TableCell>
                          <TableCell align="center">
                            {row?.approvalStatus || ""}
                          </TableCell>
                          <TableCell align="center">
                            {row?.approvalDate
                              ? format(
                                  new Date(row?.approvalDate),
                                  "dd/MM/yyyy",
                                )
                              : ""}
                          </TableCell>
                          <TableCell align="center">{row?.status}</TableCell>
                          <TableCell align="center">
                            {row?.loadingRemark}
                          </TableCell>
                          <TableCell align="center">{row?.remark}</TableCell>

                          <TableCell align="center">
                          {row?.referenceShippingInstructionId}-{row?.referenceShippingInstructionNumber}
                          </TableCell>
                          <TableCell align="center">
                            {row?.timestamp
                              ? format(
                                  new Date(row.timestamp),
                                  "dd/MM/yyyy HH:mm",
                                )
                              : ""}
                          </TableCell>
                          <TableCell align="center">{row?.creator}</TableCell>
                          <TableCell align="center">
                            {row?.recordStatus}
                          </TableCell>
                        </TableRow>
                      );
                    })}
                    {emptyRows > 0 && (
                      <TableRow
                        style={{
                          height: (dense ? 33 : 53) * emptyRows,
                        }}
                      >
                        <TableCell colSpan={6} />
                      </TableRow>
                    )}
                  </TableBody>
                </Table>
              </TableContainer>
              <div style={{ marginTop: "20px", marginLeft: "20px" }}>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    gap: "20px",
                    alignItems: "center",
                  }}
                >
                  <div style={{ display: "flex", alignItems: "center" }}>
                    <span
                      style={{
                        background: "#c8e6c9",
                        padding: "4px 8px",
                        marginRight: "5px",
                        borderRadius: "8px",
                        display: "inline-block",
                      }}
                    >
                      Approved
                    </span>
                    <span>- Shipment request approved</span>
                  </div>
                  <div style={{ display: "flex", alignItems: "center" }}>
                    <span
                      style={{
                        background: "#FFCDD2",
                        padding: "4px 8px",
                        marginRight: "5px",
                        borderRadius: "8px",
                        display: "inline-block",
                      }}
                    >
                      Not Approved
                    </span>
                    <span>- Shipment request not approved</span>
                  </div>
                  <div style={{ display: "flex", alignItems: "center" }}>
                    <span
                      style={{
                        background: "#bdb9b9",
                        padding: "4px 8px",
                        marginRight: "5px",
                        borderRadius: "8px",
                        display: "inline-block",
                      }}
                    >
                      SI Created
                    </span>
                    <span>- SI created for shipment request</span>
                  </div>
                  <div style={{ display: "flex", alignItems: "center" }}>
                    <span
                      style={{
                        background: "#898989",
                        textDecoration: "line-through",
                        padding: "4px 8px",
                        marginRight: "5px",
                        borderRadius: "8px",
                        display: "inline-block",
                      }}
                    >
                      SI CLOSED
                    </span>
                    <span>- An SI is CLOSED for shipment request</span>
                  </div>
                </div>
              </div>
              <TablePagination
                rowsPerPageOptions={[5, 10, 25, 50]}
                component="div"
                count={rows.length}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
              />
            </Paper>
            <FormControlLabel
              control={<Switch checked={dense} onChange={handleChangeDense} />}
              label="Dense padding"
            />
          </Box>
        </>
      )}
    </div>
  );
}

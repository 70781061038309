import React, { useState } from 'react';
import { Button,
    TableCell,
    Modal,
    Typography,
    Box,
    Table,
    TableHead,
    TableBody,
    TableRow,
    TableCell as StyledTableCell,
    TableFooter,} from '@mui/material';
import { format, differenceInMonths, addMonths, startOfMonth } from 'date-fns';
import { retrieveFilteredStoragePricesApi } from '../../../../components/api/StoragePriceApiService';

const StorageCostButton = ({ rows }) => {
  const [storageCost, setStorageCost] = useState(null);
  const [history, setHistory] = useState([]); // To store history details
  const [open, setOpen] = useState(false); // Modal state

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const calculateStorageCost = async () => {
    console.log("rows", rows);
    if (!rows || rows.length === 0) return;
  
    const earliestEntryDate = rows
      .filter((row) => row.entryDate)
      .reduce((earliest, row) => {
        const entryDate = new Date(row.entryDate);
        return entryDate < earliest ? entryDate : earliest;
      }, new Date());
  
    console.log("earliestEntryDate", earliestEntryDate);
  
    const currentMonth = new Date();
    console.log("currentMonth", currentMonth);
  
    let totalStorageCost = 0;
    let remainingStock = 0;
  
    const monthNames = [
      "january",
      "february",
      "march",
      "april",
      "may",
      "june",
      "july",
      "august",
      "september",
      "october",
      "november",
      "december",
    ];
  
    let currentIterationMonth = new Date(earliestEntryDate);
    const historyDetails = [];

    while (
      currentIterationMonth.getFullYear() < currentMonth.getFullYear() ||
      (currentIterationMonth.getFullYear() === currentMonth.getFullYear() &&
        currentIterationMonth.getMonth() < currentMonth.getMonth())
    ) {
      // Sum incoming for the month (rows with entry date in the month)
      const incomingSum = rows
        .filter(
          (row) =>
            row?.type === "Incoming" &&
            row?.entryDate &&
            new Date(row.entryDate).getFullYear() === currentIterationMonth.getFullYear() &&
            new Date(row.entryDate).getMonth() === currentIterationMonth.getMonth()
        )
        .reduce((sum, row) => sum + (row?.shippedAmount || 0), 0);
  
      // Sum outgoing for the month (rows with exit date in the month)
      const outgoingSum = rows
        .filter(
          (row) =>
            row?.type === "Outgoing" &&
            row?.exitDate &&
            new Date(row.exitDate).getFullYear() === currentIterationMonth.getFullYear() &&
            new Date(row.exitDate).getMonth() === currentIterationMonth.getMonth()
        )
        .reduce((sum, row) => sum + (row?.shippedAmount || 0), 0);
  
      // Calculate net stock for the current month
      const netStockForMonth = remainingStock + incomingSum - outgoingSum;
  
      const filteredStorageCost = {
        id: 0,
        warehouseIdList: [rows[0]?.warehouse?.id],
        year: currentIterationMonth.getFullYear(),
        recordStatusList: ["ACTIVE"],
      };
  
      try {
        // Fetch storage prices for the month
        const apiResponse = await retrieveFilteredStoragePricesApi(filteredStorageCost);
        const apiResponseData = apiResponse?.data;
        const monthName = monthNames[currentIterationMonth.getMonth()];
        const priceForMonth = apiResponseData[0]?.[monthName] ?? 0;
  
        console.log(
          `Month: ${monthName}, Year: ${currentIterationMonth.getFullYear()}, Price: ${priceForMonth}, Incoming: ${incomingSum}, Outgoing: ${outgoingSum}, Remaining: ${remainingStock}`
        );
  
        const monthlyStorageCost = netStockForMonth * priceForMonth;
  
        console.log(
          `Month: ${monthName}, Year: ${currentIterationMonth.getFullYear()}, Monthly Cost: ${monthlyStorageCost}, Net Stock: ${netStockForMonth}`
        );
  
        totalStorageCost += monthlyStorageCost;
  
        remainingStock = netStockForMonth;
        historyDetails.push({
          month: monthName,
          year: currentIterationMonth.getFullYear(),
          priceForMonth,
          incomingSum,
          outgoingSum,
          netStockForMonth,
          monthlyStorageCost,
        });
      } catch (error) {
        console.error(
          `Error fetching storage prices for ${currentIterationMonth}:`,
          error
        );
      }
  
      currentIterationMonth.setMonth(currentIterationMonth.getMonth() + 1);
    }
  
    console.log(`Total Storage Cost: ${totalStorageCost}`);
    setHistory(historyDetails);
    setStorageCost(totalStorageCost);
  };

  return (
    <TableCell align="center">
  <Box
    sx={{
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      gap: 2,
      flexWrap: "nowrap",
    }}
  >
    <Button
      variant="contained"
      color="primary"
      onClick={calculateStorageCost}
    >
      Get Storage Cost
    </Button>
    {storageCost !== null && (
      <Typography variant="subtitle1" sx={{ margin: 0 }}>
        {`${new Intl.NumberFormat("en-US", {
          style: "decimal",
          minimumFractionDigits: 2,
        }).format(storageCost)} USD`}
      </Typography>
    )}
    {history.length > 0 && (
      <Button
        variant="outlined"
        color="secondary"
        onClick={handleOpen}
      >
        View History
      </Button>
    )}
  </Box>
  <Modal open={open} onClose={handleClose}>
    <Box
      sx={{
        position: "absolute",
        top: "50%",
        left: "50%",
        transform: "translate(-50%, -50%)",
        width: 600,
        maxHeight: "70vh",
        overflowY: "auto",
        bgcolor: "background.paper",
        boxShadow: 24,
        p: 4,
        borderRadius: 2,
      }}
    >
      <Typography variant="h6" component="h2" sx={{ marginBottom: 2 }}>
        Storage Cost History
      </Typography>
      <Table>
        <TableHead>
          <TableRow sx={{ backgroundColor: "#20315d" }}>
            <StyledTableCell sx={{ color: "#fff", fontWeight: "bold" }}>
              Month
            </StyledTableCell>
            <StyledTableCell sx={{ color: "#fff", fontWeight: "bold" }}>
              Year
            </StyledTableCell>
            <StyledTableCell sx={{ color: "#fff", fontWeight: "bold" }}>
              Price
            </StyledTableCell>
            <StyledTableCell sx={{ color: "#fff", fontWeight: "bold" }}>
              Incoming
            </StyledTableCell>
            <StyledTableCell sx={{ color: "#fff", fontWeight: "bold" }}>
              Outgoing
            </StyledTableCell>
            <StyledTableCell sx={{ color: "#fff", fontWeight: "bold" }}>
              Net Stock
            </StyledTableCell>
            <StyledTableCell sx={{ color: "#fff", fontWeight: "bold" }}>
              Monthly Cost
            </StyledTableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {history.map((record, index) => (
            <TableRow
              key={index}
              sx={{
                backgroundColor:
                  index % 2 === 0 ? "#f0f8ff" : "#ffffff", // Alternating row colors
                "&:hover": {
                  backgroundColor: "#e6f7ff", // Highlight on hover
                },
              }}
            >
              <StyledTableCell>{record.month}</StyledTableCell>
              <StyledTableCell>{record.year}</StyledTableCell>
              <StyledTableCell
                sx={{ fontWeight: "bold", textAlign: "center" }}
              >
                {`${new Intl.NumberFormat("en-US", {
                  style: "decimal",
                  minimumFractionDigits: 2,
                }).format(record.priceForMonth)} USD`}
              </StyledTableCell>
              <StyledTableCell>{record.incomingSum}</StyledTableCell>
              <StyledTableCell>{record.outgoingSum}</StyledTableCell>
              <StyledTableCell>{record.netStockForMonth}</StyledTableCell>
              <StyledTableCell
              sx={{ fontWeight: "bold", textAlign: "center" }}
            >
              {`${new Intl.NumberFormat("en-US", {
                style: "decimal",
                minimumFractionDigits: 2,
              }).format(record.monthlyStorageCost)} USD`}
            </StyledTableCell>
            </TableRow>
          ))}
        </TableBody>
        <TableFooter>
          <TableRow>
            <StyledTableCell
              colSpan={6}
              sx={{ fontWeight: "bold", textAlign: "right" }}
            >
              Total Cost:
            </StyledTableCell>
            <StyledTableCell
              sx={{ fontWeight: "bold", textAlign: "center" }}
            >
              {`${new Intl.NumberFormat("en-US", {
                style: "decimal",
                minimumFractionDigits: 2,
              }).format(
                history.reduce(
                  (sum, record) => sum + record.monthlyStorageCost,
                  0
                )
              )} USD`}
            </StyledTableCell>
          </TableRow>
        </TableFooter>
      </Table>
      <Button
        onClick={handleClose}
        variant="contained"
        color="secondary"
        sx={{ marginTop: 2 }}
      >
        Close
      </Button>
    </Box>
  </Modal>
</TableCell>
  );
};

export default StorageCostButton;
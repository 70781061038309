import DeleteIcon from "@mui/icons-material/Delete";
import { MenuItem, Stack } from "@mui/material";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Checkbox from "@mui/material/Checkbox";
import FormControlLabel from "@mui/material/FormControlLabel";
import Grid from "@mui/material/Grid";
import IconButton from "@mui/material/IconButton";
import Paper from "@mui/material/Paper";
import Switch from "@mui/material/Switch";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import TableSortLabel from "@mui/material/TableSortLabel";
import TextField from "@mui/material/TextField";
import Toolbar from "@mui/material/Toolbar";
import Tooltip from "@mui/material/Tooltip";
import Typography from "@mui/material/Typography";
import { alpha } from "@mui/material/styles";
import { visuallyHidden } from "@mui/utils";
import PropTypes from "prop-types";
import * as React from "react";
import { useEffect, useState } from "react";
import {
  retrieveAllItemsApi,
  retrieveFilteredItemsApi,
} from "../../../components/api/ItemApiService";
import ExportExcel from "../../../utils/excelExport";
import {
  leafForm,
  origin,
  recordStatus,
  type,
} from "../../../utils/menu-items";
import AddItem from "./AddItem";
import EditItem from "./EditItem";
import { format } from "date-fns";

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === "asc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort(array, comparator) {
  const stabilizedThis = array?.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) {
      return order;
    }
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

const headCells = [
  {
    id: "editButton",
    disablePadding: true,
    label: "",
  },
  {
    id: "id",
    numeric: true,
    disablePadding: true,
    label: "ID",
  },
  {
    id: "tobaccoCode",
    numeric: false,
    disablePadding: true,
    label: "Tobacco Code",
  },
  {
    id: "salesGrade",
    numeric: false,
    disablePadding: true,
    label: "Sales Grade",
  },
  {
    id: "buyingGrade",
    numeric: false,
    disablePadding: true,
    label: "Buying Grade",
  },
  {
    id: "internalGrade",
    numeric: false,
    disablePadding: true,
    label: "Internal Grade",
  },
  {
    id: "origin",
    numeric: false,
    disablePadding: false,
    label: "Origin",
  },
  {
    id: "province",
    numeric: false,
    disablePadding: false,
    label: "Province",
  },
  {
    id: "type",
    numeric: false,
    disablePadding: false,
    label: "Type",
  },
  {
    id: "leafForm",
    numeric: false,
    disablePadding: false,
    label: "Leaf Form",
  },
  {
    id: "color",
    numeric: false,
    disablePadding: false,
    label: "Color",
  },
  {
    id: "crop",
    numeric: true,
    disablePadding: true,
    label: "Crop",
  },
  {
    id: "storage",
    numeric: false,
    disablePadding: true,
    label: "Current Location",
  },
  {
    id: "nicotine",
    numeric: true,
    disablePadding: false,
    label: "Nicotine",
  },
  {
    id: "sugar",
    numeric: true,
    disablePadding: false,
    label: "Sugar",
  },
  {
    id: "moisture",
    numeric: true,
    disablePadding: false,
    label: "Moisture",
  },
  {
    id: "creationTime",
    numeric: false,
    disablePadding: true,
    label: "Creation Time",
  },
  {
    id: "createdBy",
    numeric: false,
    disablePadding: true,
    label: "Created By",
  },
  {
    id: "status",
    numeric: false,
    disablePadding: false,
    label: "Status",
  },
];

function EnhancedTableHead(props) {
  const {
    onSelectAllClick,
    order,
    orderBy,
    numSelected,
    rowCount,
    onRequestSort,
  } = props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        <TableCell padding="checkbox">
          <Checkbox
            color="primary"
            indeterminate={numSelected > 0 && numSelected < rowCount}
            checked={rowCount > 0 && numSelected === rowCount}
            onChange={onSelectAllClick}
            inputProps={{
              "aria-label": "select all desserts",
            }}
          />
        </TableCell>
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            align={"center"}
            padding={headCell.disablePadding ? "none" : "normal"}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : "asc"}
              onClick={createSortHandler(headCell.id)}
            >
              {headCell.label}
              {orderBy === headCell.id ? (
                <Box component="span" sx={visuallyHidden}>
                  {order === "desc" ? "sorted descending" : "sorted ascending"}
                </Box>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

EnhancedTableHead.propTypes = {
  numSelected: PropTypes.number.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  onSelectAllClick: PropTypes.func.isRequired,
  order: PropTypes.oneOf(["asc", "desc"]).isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
};

function EnhancedTableToolbar(props) {
  const { numSelected, showFilters, setShowFilters, rows, setAddView, selected,
    selectedData } = props;

  return (
    <Toolbar
      sx={{
        pl: { sm: 2 },
        pr: { xs: 1, sm: 1 },
        ...(numSelected > 0 && {
          bgcolor: (theme) =>
            alpha(
              theme.palette.primary.main,
              theme.palette.action.activatedOpacity,
            ),
        }),
        display: "flex",
        justifyContent: "space-between",
      }}
    >
    <Stack direction="row" spacing={1} alignItems={"center"}>

        <Typography
          sx={{ fontWeight: 600, fontSize: "20px" }}
          variant="h6"
          id="tableTitle"
          component="div"
          color="#1E2685"
        >
          Items
        </Typography>
 
        <Typography></Typography>
          {numSelected > 0 ? (
          <Typography
          sx={{ fontWeight: 200, fontSize: "16px" }}
            color="#1E2685"
            variant="subtitle1"
            component="div"
          >
            {numSelected} selected
          </Typography>
        ) : (<Typography></Typography>)}
        
      {numSelected > 0 && (
        <Tooltip title="Delete">
          <IconButton>
            <DeleteIcon />
          </IconButton>
        </Tooltip>
      )}
      </Stack>
      <Stack direction={"row"} spacing={1} sx={{ mr: 2 }}>
        <ExportExcel excelData={selectedData} fileName={"ItemExport"} buttonLabel="Export Table"/>

        <Button
          disableElevation
          variant="outlined"
          size="small"
          onClick={() => setAddView(true)}
        >
          Add
        </Button>
        <Button
          variant="outlined"
          onClick={() => setShowFilters(!showFilters)}
          sx={{ minWidth: "130px" }}
          size="small"
        >
          {showFilters ? "Hide Filters" : "Show Filters"}
        </Button>
      </Stack>
    </Toolbar>
  );
}

EnhancedTableToolbar.propTypes = {
  numSelected: PropTypes.number.isRequired,
};

export default function ItemView() {
  const [rows, setRows] = useState([]);
  const [filterItem, setFilterItem] = useState({
    id: 0,
    tobaccoCode: "",
    salesGrade: "",
    buyingGrade: "",
    customerGrade: "",
    internalGrade:"",
    originList: [],
    typeList: [],
    leafFormList: [],
    colorList: [],
    storage: "",
    storageList: [],
    statusList: ["ACTIVE"],
  });
  const [showFilters, setShowFilters] = useState(false);

  useEffect(() => retrieveItems(), []);

  function retrieveItems() {
    retrieveAllItemsApi()
      .then((response) => {
        setRows(response?.data);
      })
      .catch((error) => console.log(error.response?.data));
  }

  const [order, setOrder] = React.useState("asc");
  const [orderBy, setOrderBy] = React.useState("id");
  const [selected, setSelected] = React.useState([]);
  const [selectedData, setSelectedData] = useState([]);
  const [page, setPage] = React.useState(0);
  const [dense, setDense] = React.useState(true);
  const [rowsPerPage, setRowsPerPage] = React.useState(25);

  const [addView, setAddView] = useState(false);

  const [editItem, setEditItem] = useState({
    visible: false,
    data: null,
    index: null,
  });

  const [addViewStates, setAddViewStates] = useState(null);

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelected = rows.map((n) => n.id);
      setSelected(newSelected);
      setSelectedData(rows);
      return;
    }else{
      setSelected([]);
      setSelectedData([]);
    }
  };

  const handleClick = (event, name, row) => {
    const selectedIndex = selected.indexOf(name);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1),
      );
    }

    const selectedDataIndex = selectedData.indexOf(row);
    let newSelectedData = [];

    if (selectedDataIndex === -1) {
      newSelectedData = newSelectedData.concat(selectedData, row);
    } else if (selectedDataIndex === 0) {
      newSelectedData = newSelectedData.concat(selectedData.slice(1));
    } else if (selectedDataIndex === selectedData.length - 1) {
      newSelectedData = newSelectedData.concat(selectedData.slice(0, -1));
    } else if (selectedDataIndex > 0) {
      newSelectedData = newSelectedData.concat(
        selectedData.slice(0, selectedDataIndex),
        selectedData.slice(selectedDataIndex + 1),
      );
    }
    setSelectedData(newSelectedData);

    setSelected(newSelected);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleChangeDense = (event) => {
    setDense(event.target.checked);
  };

  const isSelected = (name) => selected.indexOf(name) !== -1;

  // Avoid a layout jump when reaching the last page with empty rows.
  const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - rows.length) : 0;

  const visibleRows = React.useMemo(
    () =>
      stableSort(rows, getComparator(order, orderBy)).slice(
        page * rowsPerPage,
        page * rowsPerPage + rowsPerPage,
      ),
    [order, orderBy, page, rowsPerPage, rows, editItem],
  );

  return (
    <div>
      {addView ? (
        <AddItem
          setAddViewStates={setAddViewStates}
          addViewStates={addViewStates}
          rows={rows}
          setRows={setRows}
          setAddView={setAddView}
        />
      ) : editItem.visible ? (
        <EditItem
          rows={rows}
          setRows={setRows}
          setAddView={setAddView}
          editItem={editItem}
          setEditItem={setEditItem}
        />
      ) : (
        <>
          {showFilters && (
            <Box
              component="form"
              sx={{
                "& .MuiTextField-root": { m: 1, width: "25ch" },
              }}
              noValidate
              autoComplete="off"
            >
              <Grid
                container
                spacing={1}
                justifyContent="flex-start"
                alignItems="center"
              >
                <TextField
                  id="outlined-search"
                  label="Tobacco Code"
                  value={filterItem?.tobaccoCode || ""}
                  type="search"
                  onChange={(e) =>
                    setFilterItem({
                      ...filterItem,
                      tobaccoCode: e.target.value,
                    })
                  }
                />
                <TextField
                  id="outlined-search"
                  label="Sales Grade"
                  value={filterItem?.salesGrade || ""}
                  type="search"
                  onChange={(e) =>
                    setFilterItem({
                      ...filterItem,
                      salesGrade: e.target.value,
                    })
                  }
                />
                <TextField
                  id="outlined-search"
                  label="Buying Grade"
                  value={filterItem?.buyingGrade || ""}
                  type="search"
                  onChange={(e) =>
                    setFilterItem({
                      ...filterItem,
                      buyingGrade: e.target.value,
                    })
                  }
                />
                <TextField
                  id="outlined-search"
                  label="Internal Grade"
                  value={filterItem?.internalGrade || ""}
                  type="search"
                  onChange={(e) =>
                    setFilterItem({
                      ...filterItem,
                      internalGrade: e.target.value,
                    })
                  }
                />
                <TextField
                  label="Origin"
                  type="search"
                  id="outlined-select-origin"
                  select
                  SelectProps={{
                    multiple: true,
                    value: filterItem.originList || [],
                    onChange: (e) => {
                      setFilterItem({
                        ...filterItem,
                        originList: e.target.value,
                      });
                    },
                  }}
                >
                  {origin.map((option) => (
                    <MenuItem key={option.value} value={option.value}>
                      {option.label}
                    </MenuItem>
                  ))}
                </TextField>

                <TextField
                  label="Type"
                  type="search"
                  id="outlined-select-type"
                  select
                  SelectProps={{
                    multiple: true,
                    value: filterItem.typeList || [],
                    onChange: (e) => {
                      setFilterItem({
                        ...filterItem,
                        typeList: e.target.value,
                      });
                    },
                  }}
                >
                  {type.map((option) => (
                    <MenuItem key={option.value} value={option.value}>
                      {option.label}
                    </MenuItem>
                  ))}
                </TextField>
                <TextField
                  label="Leaf Form"
                  type="search"
                  id="outlined-select-leaf-form"
                  select
                  SelectProps={{
                    multiple: true,
                    value: filterItem.leafFormList || [],
                    onChange: (e) => {
                      setFilterItem({
                        ...filterItem,
                        leafFormList: e.target.value,
                      });
                    },
                  }}
                >
                  {leafForm.map((option) => (
                    <MenuItem key={option.value} value={option.value}>
                      {option.label}
                    </MenuItem>
                  ))}
                </TextField>
                <TextField
                  id="outlined-search"
                  label="Crop Year"
                  value={filterItem?.crop || ""}
                  type="search"
                  onChange={(e) =>
                    setFilterItem({
                      ...filterItem,
                      crop: e.target.value,
                    })
                  }
                />
                <TextField
                  label="Current Location"
                  type="search"
                  id="outlined-search"
                  value={filterItem?.storage || ""}
                  onChange={(e) =>
                    setFilterItem({
                      ...filterItem,
                      storage: e.target.value,
                    })
                  }
                />
                <TextField
                  id="outlined-number"
                  label="Nicotine"
                  value={filterItem?.nicotine || ""}
                  type="number"
                  onChange={(e) =>
                    setFilterItem({
                      ...filterItem,
                      nicotine: e.target.value,
                    })
                  }
                />
                <TextField
                  id="outlined-number"
                  label="Sugar"
                  value={filterItem?.sugar || ""}
                  type="number"
                  onChange={(e) =>
                    setFilterItem({
                      ...filterItem,
                      sugar: e.target.value,
                    })
                  }
                />
                <TextField
                  id="outlined-number"
                  label="Moisture"
                  value={filterItem?.moisture || ""}
                  type="number"
                  onChange={(e) =>
                    setFilterItem({
                      ...filterItem,
                      moisture: e.target.value,
                    })
                  }
                />
                <TextField
                  label="Status"
                  type="search"
                  id="outlined-select-status"
                  select
                  SelectProps={{
                    multiple: true,
                    value: filterItem.statusList || [],
                    onChange: (e) => {
                      setFilterItem({
                        ...filterItem,
                        statusList: e.target.value,
                      });
                    },
                  }}
                >
                  {recordStatus.map((option) => (
                    <MenuItem key={option.value} value={option.value}>
                      {option.label}
                    </MenuItem>
                  ))}
                </TextField>
              </Grid>

              <Grid
                container
                spacing={2}
                justifyContent="flex-start"
                alignItems="center"
              >
                <Grid item xs={3} sm={1}>
                  <Button
                    disableElevation
                    variant="contained"
                    fullWidth
                    onClick={() => {
                      retrieveFilteredItemsApi(filterItem)
                        .then((response) => {
                          setRows(response?.data);
                        })
                        .catch((error) => {
                          console.log(error?.response?.data);
                        });
                    }}
                  >
                    Search
                  </Button>
                </Grid>
                <Grid item xs={3} sm={1}>
                  <Button
                    disableElevation
                    variant="outlined"
                    fullWidth
                    onClick={() => {
                      setFilterItem({
                        id: 0,
                        tobaccoCode: "",
                        salesGrade: "",
                        buyingGrade: "",
                        customerGrade: "",
                        internalGrade:"",
                        originList: [],
                        typeList: [],
                        leafFormList: [],
                        colorList: [],
                        crop: null,
                        storageList: [],
                        nicotine: null,
                        sugar: null,
                        moisture: null,
                        statusList: ["ACTIVE"],
                      });
                    }}
                  >
                    Clear Filters
                  </Button>
                </Grid>
              </Grid>
            </Box>
          )}

          <Box sx={{ width: "100%", mt: 2 }}>
            <Paper sx={{ width: "100%", mb: 2 }}>
              <EnhancedTableToolbar
                numSelected={selected.length}
                showFilters={showFilters}
                setShowFilters={setShowFilters}
                rows={rows}
                setAddView={setAddView}
                selected={selected}
                selectedData={selectedData}
              />
             <TableContainer style={{ maxHeight: 700 }}>
                <Table
                  stickyHeader
                  aria-label="sticky table"
                  sx={{
                    minWidth: 750,
                    '& .MuiTableCell-head': {
                      backgroundColor: '#20315d',
                      color: 'white',
                    },
                  }}
                  aria-labelledby="tableTitle"
                  size={dense ? "small" : "medium"}
                >
                  <EnhancedTableHead
                    numSelected={selected.length}
                    order={order}
                    orderBy={orderBy}
                    onSelectAllClick={handleSelectAllClick}
                    onRequestSort={handleRequestSort}
                    rowCount={rows.length}
                  />
                  <TableBody>
                    {visibleRows.map((row, index) => {
                      const isItemSelected = isSelected(row.id);
                      const labelId = `enhanced-table-checkbox-${index}`;

                      return (
                        <TableRow
                          style={
                            index % 2
                              ? { background: "#f0f0f0" }
                              : { background: "white" }
                          }
                          hover
                          onClick={(event) => handleClick(event, row.id, row)}
                          role="checkbox"
                          aria-checked={isItemSelected}
                          tabIndex={-1}
                          key={row.id}
                          selected={isItemSelected}
                          sx={{ cursor: "pointer" }}
                        >
                          <TableCell padding="checkbox">
                            <Checkbox
                              color="primary"
                              checked={isItemSelected}
                              inputProps={{
                                "aria-labelledby": labelId,
                              }}
                            />
                          </TableCell>
                          <TableCell padding="normal">
                            <Button
                              onClick={(e) => {
                                setEditItem({
                                  visible: true,
                                  data: row,
                                  index: index,
                                });
                                e.preventDefault();
                              }}
                            >
                              Edit
                            </Button>
                          </TableCell>
                          <TableCell align="center">{row?.id}</TableCell>
                          <TableCell align="center">
                            {row?.tobaccoCode}
                          </TableCell>
                          <TableCell align="center">{row?.salesGrade}</TableCell>
                          <TableCell align="center">
                            {row?.buyingGrade}
                          </TableCell>
                          <TableCell align="center">
                            {row?.internalGrade}
                          </TableCell>
                          <TableCell align="center">{row?.origin}</TableCell>
                          <TableCell align="center">{row?.province}</TableCell>
                          <TableCell align="center">{row?.type}</TableCell>
                          <TableCell align="center">{row?.leafForm}</TableCell>
                          <TableCell align="center">{row?.color}</TableCell>
                          <TableCell align="center">{row?.crop}</TableCell>
                          <TableCell align="center">{row?.storage}</TableCell>
                          <TableCell align="center">{row?.nicotine}</TableCell>
                          <TableCell align="center">{row?.sugar}</TableCell>
                          <TableCell align="center">{row?.moisture}</TableCell>
                          <TableCell align="center">{row?.timestamp ? format(new Date(row.timestamp), 'dd/MM/yyyy HH:mm'): ''}</TableCell>
                          <TableCell align="center">{row?.creator}</TableCell>
                          <TableCell align="center">{row?.status}</TableCell>
                        </TableRow>
                      );
                    })}
                    {emptyRows > 0 && (
                      <TableRow
                        style={{
                          height: (dense ? 33 : 53) * emptyRows,
                        }}
                      >
                        <TableCell colSpan={6} />
                      </TableRow>
                    )}
                  </TableBody>
                </Table>
              </TableContainer>
              <TablePagination
                rowsPerPageOptions={[5, 10, 25, 50]}
                component="div"
                count={rows.length}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
              />
            </Paper>
            <FormControlLabel
              control={<Switch checked={dense} onChange={handleChangeDense} />}
              label="Dense padding"
            />
          </Box>
        </>
      )}
    </div>
  );
}

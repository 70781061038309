import React, { useState } from 'react';
import { Button, Tooltip } from '@mui/material';
import toast from "react-hot-toast";
import { retrieveFilteredWarehouseRecordsApi, updateMultipleWarehouseRecordsApi } from '../../../../components/api/WarehouseApiService';
import { updateShippingInstructionApi } from '../../../../components/api/ShippingInstructionApiService';
import { updateMultipleOrdersApi } from '../../../../components/api/OrderApiService';
import { ClipLoader } from 'react-spinners';

function OpenShippingInstructionButton({ invoiceData, onSuccessfulOpen }) {

  const [warehouseRecord, setWarehouseRecord] = useState([]);
  const [destinationRecord, setDestinationRecord] = useState([]);
  const [closeDialogOpen, setOpenDialogOpen] = useState(false);
  const [loading, setLoading] = useState(false);

  const getDisabledReason = (invoiceData) => {
    if (invoiceData?.status !== "Closed") {
      return "SI cannot be opened because the status is not 'Closed'.";
    } 
    if (
      [
        "FVTW",
        "FVTIMP",
        "FVTINW",
        "FWTW",
        "FWTIMP",
        "FWTINW"
      ].includes(invoiceData?.shipmentRequest?.shipmentRequestType) &&
      invoiceData?.shipmentRequest?.storageQuantity != null &&
      invoiceData?.shipmentRequest?.shipmentQuantity != null &&
      Number(invoiceData?.shipmentRequest?.storageQuantity) !== Number(invoiceData?.shipmentRequest?.shipmentQuantity)
    ) {
      return "SI cannot be opened because it is referenced by another SI.";
    }
  
    return "";
  };

  const handleOpenClick = async () => {

    const updatedInvoiceData = {
        ...invoiceData,
           status: "Open",
           operation: "Opening",
           actualTimeOfArrival:null,
      };

      const updatedPurchaseOrder = {
        ...invoiceData?.shipmentRequest?.purchaseOrder,
        remainingQuantity: invoiceData?.shipmentRequest?.purchaseOrder ? ((invoiceData?.shipmentRequest?.purchaseOrder?.remainingQuantity || 0) + (invoiceData?.shipmentRequest?.shipmentQuantity || 0)) : invoiceData?.shipmentRequest?.purchaseOrder?.remainingQuantity,
        orderStatus: invoiceData?.shipmentRequest?.purchaseOrder ? (((invoiceData?.shipmentRequest?.purchaseOrder?.remainingQuantity || 0) + (invoiceData?.shipmentRequest?.shipmentQuantity || 0)) >= 0 ? "" : invoiceData?.shipmentRequest?.purchaseOrder?.status) : invoiceData?.shipmentRequest?.purchaseOrder?.status,
      };

      const updatedSalesOrder = {
        ...invoiceData?.shipmentRequest?.salesOrder,
        remainingQuantity: invoiceData?.shipmentRequest?.salesOrder ? ((invoiceData?.shipmentRequest?.salesOrder?.remainingQuantity || 0) + (invoiceData?.shipmentRequest?.shipmentQuantity || 0)) : invoiceData?.shipmentRequest?.salesOrder?.remainingQuantity,
        orderStatus: invoiceData?.shipmentRequest?.salesOrder ? (((invoiceData?.shipmentRequest?.salesOrder?.remainingQuantity || 0) + (invoiceData?.shipmentRequest?.shipmentQuantity || 0)) >= 0 ? "" : invoiceData?.shipmentRequest?.salesOrder?.status) : invoiceData?.shipmentRequest?.purchaseOrder?.status,
      };

      const updatedOrders = [updatedPurchaseOrder, updatedSalesOrder];
      const shipmentType = invoiceData?.shipmentRequest?.shipmentRequestType;

        const retrieveFilteredWarehouseRecord = {
          id: 0,
          warehouseList: [invoiceData?.warehouse],
          itemIdList: [
            invoiceData?.shipmentRequest?.purchaseOrder?.offer?.item?.id || 0,
            invoiceData?.shipmentRequest?.salesOrder?.offer?.item?.id || 0,
          ],
          recordStatusList: ["ACTIVE"],
        };

        const retrieveFilteredDestinationRecord = {
          id: 0,
          warehouseList: [invoiceData?.destination],
          itemIdList: [
            invoiceData?.shipmentRequest?.purchaseOrder?.offer?.item?.id || 0,
            invoiceData?.shipmentRequest?.salesOrder?.offer?.item?.id || 0,
          ],
          recordStatusList: ["ACTIVE"],
        };

        setLoading(true);

    try {

      const destinationResponse = await retrieveFilteredWarehouseRecordsApi(retrieveFilteredDestinationRecord);
      const destinationRecords = destinationResponse?.data || [];

      let updatedDestinationRecords = [];

      if (shipmentType === "FVTW" || shipmentType === "FWTW" ||
          shipmentType === "FVTIMP" || shipmentType === "FWTIMP" ||
          shipmentType === "FVTINW" || shipmentType === "FWTINW") {
        
        updatedDestinationRecords = destinationRecords.map((destinationRecord) => ({
          ...destinationRecord,
          shippedAmount: (destinationRecord?.shippedAmount || 0) -
            (invoiceData?.shipmentRequest?.shipmentQuantity || 0),
          unshippedAmount: (destinationRecord?.unshippedAmount || 0) +
            (invoiceData?.shipmentRequest?.shipmentQuantity || 0),
        }));

        setDestinationRecord(updatedDestinationRecords);
      }

      const warehouseResponse = await retrieveFilteredWarehouseRecordsApi(retrieveFilteredWarehouseRecord);
      const warehouseRecords = warehouseResponse?.data || [];

      let updatedWarehouseRecords = [];

      if (invoiceData?.shipmentRequest?.shipmentRequestType === "FWTW" || invoiceData?.shipmentRequest?.shipmentRequestType === "FWTIMP" ||
        invoiceData?.shipmentRequest?.shipmentRequestType === "FWTINW" || invoiceData?.shipmentRequest?.shipmentRequestType === "FWTC") {
        
        updatedWarehouseRecords = warehouseRecords.map((warehouseRecord) => ({
          ...warehouseRecord,
          shippedAmount: (warehouseRecord?.shippedAmount || 0) +
            (invoiceData?.shipmentRequest?.shipmentQuantity || 0),
          unshippedAmount: (warehouseRecord?.unshippedAmount || 0) -
            (invoiceData?.shipmentRequest?.shipmentQuantity || 0),
        }));

        setWarehouseRecord(updatedWarehouseRecords);
      }

      const updatedRecords = [...updatedWarehouseRecords, ...updatedDestinationRecords];

      await Promise.all([
        updateMultipleWarehouseRecordsApi(updatedRecords),
        updateShippingInstructionApi(updatedInvoiceData?.id, updatedInvoiceData),
        shipmentType === "FVTC"
          ? updateMultipleOrdersApi(updatedOrders)
          : shipmentType === "FVTW" || shipmentType === "FVTIMP" || shipmentType === "FVTINW"
          ? updateMultipleOrdersApi([updatedPurchaseOrder])
          : shipmentType === "FWTC"
          ? updateMultipleOrdersApi([updatedSalesOrder])
          : Promise.resolve(),
      ]);

      toast.success("All updates were successful.");
      setOpenDialogOpen(false);
      if (onSuccessfulOpen) onSuccessfulOpen();
    } catch (error) {
       console.error("An error occurred:", error);
       toast.error("Something went wrong during one of the updates.");
    }finally {
      setLoading(false);
    }
    };

  return (
    <>
    {loading ? (
      <ClipLoader color="#000000" loading={loading} size={35} />
    ) : (
      <Tooltip
      title={getDisabledReason(invoiceData)}
      componentsProps={{
        tooltip: {
          sx: {
            fontSize: '1rem', 
          },
        },
      }}
    >
         <span style={{ display: 'inline-block' }}>
      <Button
      disabled={
        invoiceData?.status !== "Closed" ||
        (
          [
            "FVTW",
            "FVTIMP",
            "FVTINW",
            "FWTW",
            "FWTIMP",
            "FWTINW"
          ].includes(invoiceData?.shipmentRequest?.shipmentRequestType) &&
          invoiceData?.shipmentRequest?.storageQuantity != null &&
          invoiceData?.shipmentRequest?.shipmentQuantity != null &&
          Number(invoiceData?.shipmentRequest?.storageQuantity) !== Number(invoiceData?.shipmentRequest?.shipmentQuantity)
        )
      }
        onClick={handleOpenClick}
        color="primary"
      >
        Open SI
      </Button>
      </span>
      </Tooltip>
    )}
  </>
  );
}

export default OpenShippingInstructionButton;

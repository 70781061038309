import { Grid, MenuItem, Stack, TextField, Typography, IconButton } from "@mui/material";
import Button from "@mui/material/Button";
import { format, parseISO } from "date-fns";
import ExcelJS from "exceljs";
import { saveAs } from "file-saver";
import React, { useEffect, useState } from "react";
import toast from "react-hot-toast";
import eurLogo from "../../../../assets/images/logo/sanEurLogoJpeg.jpeg";
import intlLogo from "../../../../assets/images/logo/sanIntlLogoJpeg.jpeg";
import { companies, titles } from "../../../../utils/menu-items";
import { updateMultipleOrdersApi } from "../../../../components/api/OrderApiService";
import { ButtonGroup } from '@mui/material';
import { Delete as DeleteIcon } from '@mui/icons-material';


const convertImageToBase64 = (url, callback) => {
  const xhr = new XMLHttpRequest();
  xhr.onload = function () {
    const reader = new FileReader();
    reader.onloadend = function () {
      callback(reader.result);
    };
    reader.readAsDataURL(xhr.response);
  };
  xhr.open("GET", url);
  xhr.responseType = "blob";
  xhr.send();
};

const createExcelWithLogoAndData = async (shipmentAdviceDetails) => {
  const workbook = new ExcelJS.Workbook();
  const worksheet = workbook.addWorksheet("My Sheet");
  const len = shipmentAdviceDetails?.items.length;
  const companyHeader =
    shipmentAdviceDetails?.company === "SGE"
      ? "SanGroup Europe Kft."
      : "SANGROUP INTERNATIONAL PTE.LTD.";
  const buyingAddressOne =
    shipmentAdviceDetails?.company === "SGE"
      ? "Stefania ut 101-103"
      : "11 Irving Place #09-02";
  const buyingAddressTwo =
    shipmentAdviceDetails?.company === "SGE"
      ? "H-1143 Budapest, Hungary"
      : "Tai Seng Point Singapore 369551";
  const buyingAddressThree =
    shipmentAdviceDetails?.company === "SGE"
      ? "Registration no: 01-09-400244 VAT no: Hu27842588"
      : "Registration no: 201717395N";

  const totalNumberOfCases = shipmentAdviceDetails?.items.reduce((total, item) => {
    return total + (item?.numberOfCases || 0);
  }, 0);

  const totalNetWeight = shipmentAdviceDetails?.items.reduce((total, item) => {
    return total + (item?.shipmentQuantity || 0);
  }, 0);

  const totalTotalAmount = shipmentAdviceDetails?.items.reduce((total, item) => {
    return total + (item?.totalAmount || 0);
  }, 0);

  const total20 = shipmentAdviceDetails?.items.reduce((total, item) => {
    return total + (item?.toBeShippedBy20 || 0);
  }, 0);
  const total40 = shipmentAdviceDetails?.items.reduce((total, item) => {
    return total + (item?.toBeShippedBy40 || 0);
  }, 0);
  const total40HC = shipmentAdviceDetails?.items.reduce((total, item) => {
    return total + (item?.toBeShippedBy40HC || 0);
  }, 0);
  const totalFtl = shipmentAdviceDetails?.items.reduce((total, item) => {
    return total + (item?.toBeShippedByFtl || 0);
  }, 0);

  var logo;
  // Convert and add the intlLogo
  if (shipmentAdviceDetails.company === "SGI") {
    logo = intlLogo;
  } else if (shipmentAdviceDetails.company === "SGE") {
    logo = eurLogo;
  } else {
    logo = intlLogo;
  }
  convertImageToBase64(logo, async (base64Image) => {
    const imageId = workbook.addImage({
      base64: base64Image,
      extension: "jpeg",
    });

    worksheet.addImage(imageId, {
      tl: { col: 1 + 0.1, row: 1 },
      br: { col: 3, row: 4 },
      editAs: 'oneCell',
      margins: { insetX: 10, insetY: 10 },
    });
    worksheet.pageSetup.orientation = 'landscape';
    worksheet.pageSetup.printArea = `A1:J${25+len}`;

    worksheet.pageSetup.fitToPage = true;
    worksheet.pageSetup.fitToWidth = 1;
    worksheet.pageSetup.fitToHeight = 0;

    const startRow = 3;
    const headerRowNumber = startRow + 21; 

    const headerFill = {
      type: "pattern",
      pattern: "solid",
      fgColor: { argb: "FF4F81BD" }, 
    };

    const dataForExcel = [
      [,,,"                            SHIPPING DETAILS",,," SHIPMENT ADVICE"],
      [,,,,,," SI Number: " + (shipmentAdviceDetails?.siNumber||""),,"Customer Ref: "+ (shipmentAdviceDetails?.customerReference||"")],
      [,,,,,," Date: "+ (shipmentAdviceDetails?.siDate
        ? format(
            parseISO(shipmentAdviceDetails.siDate),
            "dd/MMM/yyyy",
          )
        : "")],
      [,"To: "+ (shipmentAdviceDetails?.consignee?.name || "")],
      [],
      [,,,"           BOOKING DETAILS           "],
      [],
      [,"Booking No.",,shipmentAdviceDetails?.bookingNumber || "",,,,,"Shipment Quantity"],
      [,shipmentAdviceDetails?.billOfLadingNumber? "BL No." : shipmentAdviceDetails?.cmrNumber 
      ? "CMR No:" : "BL No.",,shipmentAdviceDetails?.billOfLadingNumber? shipmentAdviceDetails?.billOfLadingNumber : shipmentAdviceDetails?.cmrNumber 
      ? shipmentAdviceDetails?.cmrNumber :""],
      [,shipmentAdviceDetails?.billOfLadingDate ? "BL Date:" : shipmentAdviceDetails?.cmrDate ? "CMR Date:" : "BL Date:",,shipmentAdviceDetails?.billOfLadingDate ? 
        format(
          parseISO(shipmentAdviceDetails.billOfLadingDate),
          "dd/MMM/yyyy",
        )
        : shipmentAdviceDetails?.cmrDate ? 
        format(
        parseISO(shipmentAdviceDetails.cmrDate),
        "dd/MMM/yyyy",
      ) :"",,,,"Shipment Quantity"],
      [,,,,,,,total20|| "","20\""],
      [,,,,,,,total40|| "","40\""],
      [,"Shipping Line",,shipmentAdviceDetails?.shippingLine|| "",,,,total40HC|| "","40 HC"],
      [,"Vessel",,shipmentAdviceDetails?.vesselName|| "",,,,totalFtl|| "","FTL"],
      [,"Voyage No:",,shipmentAdviceDetails?.voyageNumber|| ""],
      [,"Port Of Loading:",,shipmentAdviceDetails?.portOfLoading|| "",,,,"ETD/ATD","ETA","ATA"],
      [,"Transshipment Port:",,shipmentAdviceDetails?.transshipmentPorts.join(", "),,,"1st Update:",shipmentAdviceDetails?.estimatedTimeOfDeparture? format(
        parseISO(shipmentAdviceDetails.estimatedTimeOfDeparture),
        "dd/MMM/yyyy",
      ): "", shipmentAdviceDetails?.estimatedTimeOfArrival? format(parseISO(shipmentAdviceDetails.estimatedTimeOfArrival),"dd/MMM/yyyy",)
  : "",shipmentAdviceDetails?.actualTimeOfArrival? format(
    parseISO(shipmentAdviceDetails.actualTimeOfArrival),
    "dd/MMM/yyyy",
  )
: ""],
      [,"Port Of Discharge",,shipmentAdviceDetails?.portOfDischarge|| "",,,"2nd Update:",shipmentAdviceDetails?.secondEstimatedTimeOfDeparture? format(
        parseISO(shipmentAdviceDetails.secondEstimatedTimeOfDeparture),
        "dd/MMM/yyyy",
      )
    : "",shipmentAdviceDetails?.secondEstimatedTimeOfArrival? format(
      parseISO(shipmentAdviceDetails.secondEstimatedTimeOfArrival),
      "dd/MMM/yyyy",
    )
  : ""],
      [,"Final Destination",,shipmentAdviceDetails?.finalDestination|| "",,,"3rd Update:",shipmentAdviceDetails?.thirdEstimatedTimeOfDeparture? format(
        parseISO(shipmentAdviceDetails.thirdEstimatedTimeOfDeparture),
        "dd/MMM/yyyy",
      )
    : "", shipmentAdviceDetails?.thirdEstimatedTimeOfArrival? format(
      parseISO(shipmentAdviceDetails.thirdEstimatedTimeOfArrival),
      "dd/MMM/yyyy",
    )
  : ""],
      [],
      [,,,,,,"     Shipment Details",,,,,,],
      [,
        ,
        "Grade",
        "Crop Year",
        "N Cartons",
        "Net Kg",
        "Gross Kg",
        "Description",
        "Carton Range",
        ,
      ],
      ...shipmentAdviceDetails?.items.map((item, index) => [
        ,,
        item?.buyingGrade, 
        item?.crop,
        item?.numberOfCases,
        item?.netKg ? new Intl.NumberFormat('de-DE', { style: 'decimal', minimumFractionDigits: 2 }).format(item?.netKg): '',
        item?.grossKg ? new Intl.NumberFormat('de-DE', { style: 'decimal', minimumFractionDigits: 2 }).format(item?.grossKg): '',
        item?.description,
        item?.cartonNumber,
      ]),
      [,,"Totals:",,
        totalNumberOfCases,
        totalNetWeight? new Intl.NumberFormat('de-DE', { style: 'decimal', minimumFractionDigits: 2 }).format(totalNetWeight)
        : '',
      ],
      [],
    ];

for (let i = 10; i <= 12; i++) { 
    const cellD = worksheet.getCell(`D${i}`);
    cellD.border = {
        left: { style: "hair"},
        top: { style: "hair"},
        bottom: { style: "hair"},
    };

    const cellE = worksheet.getCell(`E${i}`);
    cellE.border = {
        right: { style: "hair"},
        top: { style: "hair"},
        bottom: { style: "hair"},
    };
}

for (let i = 15; i <= 21; i++) { 
  const cellD = worksheet.getCell(`D${i}`);
  cellD.border = {
      left: { style: "hair"},
      top: { style: "hair"},
      bottom: { style: "hair"},
  };

  const cellE = worksheet.getCell(`E${i}`);
  cellE.border = {
      right: { style: "hair"},
      top: { style: "hair"},
      bottom: { style: "hair"},
  };
}

for (let i = 12; i <= 16; i++) {
    const cellH = worksheet.getCell(`H${i}`);
    cellH.border = {
        left: { style: "hair"},
        top: { style: "hair"},
        bottom: { style: "hair"},
    };

    const cellI = worksheet.getCell(`I${i}`);
    cellI.border = {
        right: { style: "hair"},
        top: { style: "hair"},
        left: { style: "hair"},
        bottom: { style: "hair"},
    };
}


for (let i = 3; i <= 3; i++) {
  const cellG = worksheet.getCell(`G${i}`);
  cellG.border = {
      bottom: { style: "hair"},
  };
  const cellH = worksheet.getCell(`H${i}`);
  cellH.border = {
      bottom: { style: "hair"},
  };
  const cellI = worksheet.getCell(`I${i}`);
  cellI.border = {
      bottom: { style: "hair"},
  };
  const cellJ = worksheet.getCell(`J${i}`);
  cellJ.border = {
      bottom: { style: "hair"},
  };
}

for (let i = 4; i <= 4; i++) {
  const cellG = worksheet.getCell(`G${i}`);
  cellG.border = {
      bottom: { style: "hair"},
  };
  const cellH = worksheet.getCell(`H${i}`);
  cellH.border = {
      bottom: { style: "hair"},
  };
  const cellI = worksheet.getCell(`I${i}`);
  cellI.border = {
      bottom: { style: "hair"},
  };
  const cellJ = worksheet.getCell(`J${i}`);
  cellJ.border = {
      bottom: { style: "hair"},
  };
}

for (let i = 1; i <= 5; i++) {
  const cellF = worksheet.getCell(`F${i}`);
  cellF.border = {
      right: { style: "hair"},
  };
}


for (let i = 5; i <= 5; i++) {
  const cellB = worksheet.getCell(`B${i}`);
  cellB.border = {
    bottom: { style: "hair"},
    left: { style: "hair"},
  };
  
  const cellC = worksheet.getCell(`C${i}`);
  cellC.border = {
    bottom: { style: "hair"},
  };
  
  const cellD = worksheet.getCell(`D${i}`);
  cellD.border = {
    bottom: { style: "hair"},
  };
  
  const cellE = worksheet.getCell(`E${i}`);
  cellE.border = {
    bottom: { style: "hair"},
  };
  
  const cellF = worksheet.getCell(`F${i}`);
  cellF.border = {
    bottom: { style: "hair"},
    right: { style: "hair"},
  };
  
  const cellG = worksheet.getCell(`G${i}`);
  cellG.border = {
    bottom: { style: "hair"},
  };
  
  const cellH = worksheet.getCell(`H${i}`);
  cellH.border = {
    bottom: { style: "hair"},
    right: { style: "hair"},
  };
  
  const cellI = worksheet.getCell(`I${i}`);
  cellI.border = {
    bottom: { style: "hair"},
  };
  
  const cellJ = worksheet.getCell(`J${i}`);
  cellJ.border = {
    bottom: { style: "hair"},
    right: { style: "hair"},
  };
}

for (let i = 25+len; i <= 25+len; i++) {
  const cellC = worksheet.getCell(`C${i}`);
  cellC.border = {
    bottom: { style: "hair"},
    left: { style: "hair"},
  };
  
  const cellD = worksheet.getCell(`D${i}`);
  cellD.border = {
    bottom: { style: "hair"},
  };
  
  const cellE = worksheet.getCell(`E${i}`);
  cellE.border = {
    bottom: { style: "hair"},
  };
  
  const cellF = worksheet.getCell(`F${i}`);
  cellF.border = {
    bottom: { style: "hair"},
  };
  
  const cellG = worksheet.getCell(`G${i}`);
  cellG.border = {
    bottom: { style: "hair"},
  };
  
  const cellH = worksheet.getCell(`H${i}`);
  cellH.border = {
    bottom: { style: "hair"},
  };
  
  const cellI = worksheet.getCell(`I${i}`);
  cellI.border = {
    bottom: { style: "hair"},
    right: { style: "hair"},
  };
}

for (let i = 1; i <= 1; i++) {
  const cellB = worksheet.getCell(`B${i}`);
  cellB.border = {
      top: { style: "hair"},
      left: { style: "hair"},
  };
  
  const cellC = worksheet.getCell(`C${i}`);
  cellC.border = {
      top: { style: "hair"},
  };
  
  const cellD = worksheet.getCell(`D${i}`);
  cellD.border = {
      top: { style: "hair"},
  };
  
  const cellE = worksheet.getCell(`E${i}`);
  cellE.border = {
      top: { style: "hair"},
  };
  
  const cellF = worksheet.getCell(`F${i}`);
  cellF.border = {
      top: { style: "hair"},
      right: { style: "hair"},
  };
  
  const cellG = worksheet.getCell(`G${i}`);
  cellG.border = {
      top: { style: "hair"},
  };
  
  const cellH = worksheet.getCell(`H${i}`);
  cellH.border = {
      top: { style: "hair"},
  };
  
  const cellI = worksheet.getCell(`I${i}`);
  cellI.border = {
      top: { style: "hair"},
  };
  
  const cellJ = worksheet.getCell(`J${i}`);
  cellJ.border = {
      top: { style: "hair"},
      right: { style: "hair"},
  };
  }

  for (let i = 4; i <= 5; i++) {
    const cellH = worksheet.getCell(`H${i}`);
    cellH.border = {
        right: { style: "hair"},
        bottom: { style: "hair"},
    };
  }

  for (let i = 1; i <= 5; i++) {
    const cellB = worksheet.getCell(`B${i}`);
    cellB.border = {
        left: { style: "hair"},
    };
    worksheet.getCell(`B${1}`).border = {
      left: { style: "hair"},
      top: { style: "hair"},
    }
    worksheet.getCell(`B${5}`).border = {
      left: { style: "hair"},
      bottom: { style: "hair"},
    }
  }

  for (let i = 1; i <= 5; i++) {
    const cellJ = worksheet.getCell(`J${i}`);
    cellJ.border = {
        right: { style: "hair"},
    };
    worksheet.getCell(`J${1}`).border = {
      right: { style: "hair"},
      top: { style: "hair"},
    }
    worksheet.getCell(`J${4}`).border = {
      right: { style: "hair"},
      top: { style: "hair"},
    }
    worksheet.getCell(`J${5}`).border = {
      right: { style: "hair"},
      bottom: { style: "hair"},
      top: { style: "hair"},
    }
  }


    dataForExcel.forEach((row, index) => {
      const rowIndex = startRow + index;
      const excelRow = worksheet.getRow(rowIndex);
      row.forEach((cell, cellIndex) => {
        const cellAddress = excelRow.getCell(cellIndex + 1);
        cellAddress.value = cell;
   
        if (rowIndex === headerRowNumber) {
          cellAddress.font = { bold: true, size: 8, };
          if (cellIndex === 6 ) {
            cellAddress.alignment = {
                vertical: "middle",
                wrapText: "true",
                horizontal:"center",
              };
              worksheet.getColumn(cellIndex-3).width = 15;
              worksheet.getColumn(cellIndex-1).width = 15;
              worksheet.getColumn(cellIndex).width = 15;
              worksheet.getColumn(cellIndex+1).width = 15;
              worksheet.getColumn(cellIndex+2).width = 30;
              worksheet.getColumn(cellIndex+3).width = 22;
          }else{
            cellAddress.alignment = {
                vertical: "middle",
                horizontal:"center",
              };
          }
          cellAddress.font = {
            size: 8,
            bold: true,
          };
          cellAddress.border = {
            top: { style: "hair" },
            bottom: { style: "hair" },
            left: { style: "hair" },
            right: { style: "hair" },
          };
        } else if (rowIndex === 3) {
            cellAddress.font = {
                bold: true,
                size: 11,
              };
          } else if (rowIndex === 4 || rowIndex === 5) {
            cellAddress.font = {
                size: 9,
              };
          }else if (rowIndex === 6) {
          cellAddress.font = {
            bold: true,
            size: 9,
          };
        } else if(rowIndex === 8) {
            cellAddress.font = {
              size: 10,
              underline: true,
              bold: true,
            }
        } else if(rowIndex === 10) {
            cellAddress.font = {
              size: 9,
            }
             if (cellIndex === 3) {
              cellAddress.font = {
                  size: 8,
                }
           }else if (cellIndex === 8) {
              cellAddress.font = {
                size: 9,
                underline: true,
                bold: true,
              }
              cellAddress.alignment = {
                vertical: "middle",
                horizontal:"center",
              }
            }
        } else if (rowIndex === 11) {
            if (cellIndex === 1) {
              cellAddress.font = {
                size: 9,
              };
            }else if (cellIndex === 3) {
              cellAddress.font = {
                  size: 8,
                }
           }else if (cellIndex === 7) {
                cellAddress.font = {
                    size: 9,
                    underline: true,
                    bold: true,
                  };
                  cellAddress.alignment = {
                    vertical: "middle",
                    horizontal:"center",
                  };
          }} else if (rowIndex === 12) {
            if (cellIndex === 1) {
              cellAddress.font = {
                size: 9,
              };
            }else if (cellIndex === 3) {
              cellAddress.font = {
                  size: 8,
                }
           }else if (cellIndex === 7) {
                cellAddress.font = {
                    size: 9,
                    underline: true,
                    bold: true,
                  };
                  cellAddress.alignment = {
                    vertical: "middle",
                    horizontal:"center",
                  };
          }}else if (rowIndex === 13) {
            if (cellIndex === 7) {
              cellAddress.font = {
                  size: 9,
                  underline: true,
                  bold: true,
                };
                cellAddress.alignment = {
                  vertical: "middle",
                  horizontal:"center",
                };
        } else if (cellIndex === 8) {
                cellAddress.font = {
                    size: 9,
                  };
                  const cell = worksheet.getRow(13).getCell(9);
                  cell.fill = {
                    type: "pattern",
                    pattern: "solid",
                    fgColor: { argb: "C0C0C0" },
                  };
                  cellAddress.alignment = {
                    vertical: "middle",
                    horizontal:"center",
                  };

          }} else if (rowIndex === 14) {
            if (cellIndex === 7) {
              cellAddress.font = {
                  size: 9,
                  underline: true,
                  bold: true,
                };
                cellAddress.alignment = {
                  vertical: "middle",
                  horizontal:"center",
                };
        } else if (cellIndex === 8) {
               cellAddress.font = {
                   size: 9,
                 };
                 const cell = worksheet.getRow(14).getCell(9);
                 cell.fill = {
                   type: "pattern",
                   pattern: "solid",
                   fgColor: { argb: "C0C0C0" },
                 };
                 cellAddress.alignment = {
                    vertical: "middle",
                    horizontal:"center",
                  };

         }}else if (rowIndex === 15) {
            cellAddress.font = {
                size: 9,
              };
              if (cellIndex === 3) {
                cellAddress.font = {
                    size: 8,
                  }
             }else if (cellIndex === 7) {
                cellAddress.font = {
                    size: 9,
                    underline: true,
                    bold: true,
                  };
                  cellAddress.alignment = {
                    vertical: "middle",
                    horizontal:"center",
                  };
          }else if (cellIndex === 8) {
                 const cell = worksheet.getRow(15).getCell(9);
                 cellAddress.font = {
                    size: 9,
                  };
                 cell.fill = {
                   type: "pattern",
                   pattern: "solid",
                   fgColor: { argb: "C0C0C0" },
                 };
                 cellAddress.alignment = {
                    vertical: "middle",
                    horizontal:"center",
                  };

         }} else if(rowIndex === 16) {
            cellAddress.font = {
              size: 9,
            }
            if (cellIndex === 3) {
              cellAddress.font = {
                  size: 8,
                }
           }else if (cellIndex === 7) {
            cellAddress.font = {
                size: 9,
                underline: true,
                bold: true,
              };
              cellAddress.alignment = {
                vertical: "middle",
                horizontal:"center",
              };
      }else if (cellIndex === 8) {
             const cell = worksheet.getRow(16).getCell(9);
             cellAddress.font = {
                size: 9,
              };
             cell.fill = {
               type: "pattern",
               pattern: "solid",
               fgColor: { argb: "C0C0C0" },
             };
             cellAddress.alignment = {
                vertical: "middle",
                horizontal:"center",
              };

     }
        }  else if(rowIndex === 17) {
            cellAddress.font = {
              size: 9,
            }
            if (cellIndex === 3) {
              cellAddress.font = {
                  size: 8,
                }
           }
        }  else if(rowIndex === 18) {
            if (cellIndex === 1) {
                cellAddress.font = {
                    size: 9,
                  }
             }else if (cellIndex === 3) {
              cellAddress.font = {
                  size: 8,
                }
           } else if (cellIndex === 7) {
                cellAddress.font = {
                    size: 11,
                    underline: true,
                    bold: true,
                  }
                  cellAddress.alignment = {
                    vertical: "middle",
                    horizontal:"center",
                  };
             }else if (cellIndex === 8 || cellIndex === 9) {
                cellAddress.font = {
                    size: 11,
                    underline: true,
                    bold: true,
                  }
                  cellAddress.alignment = {
                    vertical: "middle",
                    horizontal:"center",
                  };
             }
        }    else if(rowIndex === 19) {
            if (cellIndex === 1) {
                cellAddress.font = {
                    size: 9,
                  }
             }else if (cellIndex === 3) {
              cellAddress.font = {
                  size: 8,
                }
           }else if (cellIndex === 6) {
              cellAddress.font = {
                  size: 9,
                }
                cellAddress.alignment = {
                  vertical: "middle",
                  horizontal:"right",
                };
           } else if (cellIndex === 7) {
                cellAddress.font = {
                    size: 9,
                  }
                  cellAddress.alignment = {
                    vertical: "middle",
                    horizontal:"center",
                  };
             }else if (cellIndex === 8 || cellIndex === 9) {
                cellAddress.font = {
                    size: 9,
                  }
                  cellAddress.alignment = {
                    vertical: "middle",
                    horizontal:"center",
                  };
             }
            
        }  else if(rowIndex === 20) {
            if (cellIndex === 1) {
                cellAddress.font = {
                    size: 9,
                  }
             }else if (cellIndex === 3) {
              cellAddress.font = {
                  size: 8,
                }
           } else if (cellIndex === 6) {
              cellAddress.font = {
                  size: 9,
                }
                cellAddress.alignment = {
                  vertical: "middle",
                  horizontal:"right",
                };
           }else if (cellIndex === 7) {
                cellAddress.font = {
                    size: 9,
                  }
                  cellAddress.alignment = {
                    vertical: "middle",
                    horizontal:"center",
                  };
             }else if (cellIndex === 8 || cellIndex === 9) {
                cellAddress.font = {
                    size: 9,
                  }
                  cellAddress.alignment = {
                    vertical: "middle",
                    horizontal:"center",
                  };
             }
        }  else if(rowIndex === 21) {
            if (cellIndex === 1) {
                cellAddress.font = {
                    size: 9,
                  }
             }else if (cellIndex === 3) {
              cellAddress.font = {
                  size: 8,
                }
           }else if (cellIndex === 6) {
              cellAddress.font = {
                  size: 9,
                }
                cellAddress.alignment = {
                  vertical: "middle",
                  horizontal:"right",
                };
           }else if (cellIndex === 7) {
                cellAddress.font = {
                    size: 9,
                  }
                  cellAddress.alignment = {
                    vertical: "middle",
                    horizontal:"center",
                  };

             }else if (cellIndex === 8 || cellIndex === 9) {
                cellAddress.font = {
                    size: 9,
                  }
                  cellAddress.alignment = {
                    vertical: "middle",
                    horizontal:"center",
                  };
             }
        } else if(rowIndex === 23) {
            cellAddress.font = {
              size: 8,
              bold: true,
            }
        }else if(rowIndex === 9) {
            cellAddress.font = {
              size: 8,
              underline: true,
              bold: true,
            }
        } else if (rowIndex === 15) {
              cellAddress.font = {
                size: 8,
                bold: true,
              }; 
          }else if (rowIndex === 25 + len) {  
                cellAddress.font = {
                  size: 9,
                  bold: true,
              }
              if (cellIndex === 4 || cellIndex === 5) {
              cellAddress.alignment = {
                vertical: "middle",
                horizontal:"center",
              };
            }
          }
          else if (rowIndex > 24 && rowIndex <= 24 + len) {

          cellAddress.border = {
            top: { style: "hair", color: { argb: "6e6e6e" } },
            left: { style: "hair", color: { argb: "6e6e6e" } },
            bottom: { style: "hair", color: { argb: "6e6e6e" } },
            right: { style: "hair", color: { argb: "6e6e6e" } },
          };
          cellAddress.alignment = {
            wrapText: "true",
            vertical: "middle",
            horizontal: "center",
          };
          cellAddress.font = {
            size: 9,
          };
        }
      });
      excelRow.commit();
    });

    const buffer = await workbook.xlsx.writeBuffer();
    const blob = new Blob([buffer], {
      type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    });
    saveAs(blob, "OfferWithLogo.xlsx");
  });
};


const ExportShippingInstructionOrder = (props) => {
  const { setExportShippingDetailsPopup, exportShippingDetailsPopup } = props;
  const [entities, setEntities] = useState([]);
  const [items, setItems] = useState(
    exportShippingDetailsPopup && exportShippingDetailsPopup.data
      ? exportShippingDetailsPopup.data.map((shippingInstruction) => ({
          buyingGrade: shippingInstruction?.shipmentRequest?.purchaseOrder?.offer?.item?.buyingGrade || shippingInstruction?.shipmentRequest?.salesOrder?.item?.buyingGrade || "",
          crop: shippingInstruction?.shipmentRequest?.purchaseOrder?.offer?.item?.crop || shippingInstruction?.shipmentRequest?.salesOrder?.item?.crop || "",
          dateOfLoadingRemark: shippingInstruction?.dateOfLoadingRemark ||  "",
          numberOfCases: shippingInstruction?.shipmentRequest?.numberOfCases || "",
          netKg: shippingInstruction?.shipmentRequest?.shipmentQuantity || "",
          grossKg: shippingInstruction?.grossWeight || "",
          description: shippingInstruction?.description || "",
          cartonNumber: shippingInstruction?.cartonNumber || "",
          shipmentQuantity: shippingInstruction?.shipmentRequest?.shipmentQuantity || "",
          customerGradeMark: shippingInstruction?.shipmentRequest?.salesOrder?.item?.salesGrade || "",
          leafForm: shippingInstruction?.item?.leafForm || "",
          nicotine: shippingInstruction?.item?.nicotine || "",
          sugar: shippingInstruction?.item?.sugar || "",
          volume: shippingInstruction?.volume || "",
          price: shippingInstruction?.finalPrice || "",
          incoterm: shippingInstruction?.incoterm || "",
          finalPrice: shippingInstruction?.finalPrice || "",
          totalAmount: shippingInstruction?.totalAmount || "",
          toBeShippedBy20: shippingInstruction?.toBeShippedBy20 || "",
          toBeShippedBy40: shippingInstruction?.toBeShippedBy40 || "",
          toBeShippedBy40HC: shippingInstruction?.toBeShippedBy40HC || "",
          toBeShippedByFtl: shippingInstruction?.toBeShippedByFtl || "",
        }))
      : [],
  );
  
  const documentsRequiredList = exportShippingDetailsPopup?.data[0]?.shipmentRequest?.salesOrder?.offer?.stakeholder?.documentsRequiredList || [];

  const getDigit = (type) => {
    if (type === "HT" || type === "HD") {
      return 100;
    } else if (type === "ST" || type === "SD" || type === "B" || type === "SL" || type === "CT") {
      return 1000;
    } else {
      return 1000;
    }
  };

  useEffect(() => {
    setExportShippingDetailsPopup(prevState => ({
      ...prevState,
      data: prevState.data.map(item => ({
        ...item,
        shippingInstructionStatus: 'PO Created', 
      })),
    }));
  }, []);
  
  useEffect(() => {
    const initialEntities = documentsRequiredList.map(doc => ({
      name: doc,
      count1: 0,
      count2: 0,
    }));
    setEntities(initialEntities);
  }, [documentsRequiredList]);

  const updateStatusForAll = () => {
    updateMultipleOrdersApi(exportShippingDetailsPopup?.data)
                .then((response) => {
                  console.log(response?.data);
                  toast.success("Purchase shippingInstruction(s) updated successfully.");
                })
                .catch((error) => {
                  console.log(error.response?.data);
                  toast.error("Purchase shippingInstruction(s) could not be deleted.");
                });
  };
  const [shipmentAdviceDetails, setShipmentAdviceDetails] = useState({
    siNumber: (exportShippingDetailsPopup?.data[0]?.shippingInstructionType || "") + ((exportShippingDetailsPopup?.data[0]?.shippingInstructionYear%10)*getDigit(exportShippingDetailsPopup?.data[0]?.shippingInstructionType)+(exportShippingDetailsPopup?.data[0]?.shippingInstructionNumber) || "") || "",
    items: items,
    siDate: exportShippingDetailsPopup?.data[0]?.shippingInstructionDate,
    specialInstructionsList: exportShippingDetailsPopup?.data[0]?.shipmentRequest?.salesOrder?.offer?.stakeholder?.specialInstructionsList || "",
    documentsRequiredList: documentsRequiredList || "",
    customer: exportShippingDetailsPopup?.data[0]?.shipmentRequest?.salesOrder?.offer?.stakeholder || "",
    siTo: exportShippingDetailsPopup?.data[0]?.shipmentRequest?.shipmentRequestTo || "",
    toBeShippedBy: exportShippingDetailsPopup?.data[0]?.shippedBy || "",
    shipper: exportShippingDetailsPopup?.data[0]?.shipper || "",
    consignee: exportShippingDetailsPopup?.data[0]?.consignee || "",
    notifyParties: exportShippingDetailsPopup?.data[0]?.notifyParties || "",
    pointOfLoading: exportShippingDetailsPopup?.data[0]?.pointOfLoading || "",
    pointOfUnloading: exportShippingDetailsPopup?.data[0]?.pointOfUnloading || "",
    description: exportShippingDetailsPopup?.data[0]?.description || "",
    descriptionTwo: "",
    documentsRequired:"",
    transportationContact: exportShippingDetailsPopup?.data[0]?.transportationContact || "",
    company: exportShippingDetailsPopup?.data[0]?.shipmentRequest?.salesOrder?.company || exportShippingDetailsPopup?.data[0]?.shipmentRequest?.purchaseOrder?.company || "",
    revision: exportShippingDetailsPopup?.data[0]?.revision|| "",
    entities: documentsRequiredList.map(doc => ({
      name: doc,
      count1: 0,
      count2: 0,
    })),
    bookingNumber: exportShippingDetailsPopup?.data[0]?.bookingNumber || "",
    billOfLadingNumber: exportShippingDetailsPopup?.data[0]?.billOfLadingNumber || "",
    billOfLadingDate: exportShippingDetailsPopup?.data[0]?.billOfLadingDate,
    cmrNumber: exportShippingDetailsPopup?.data[0]?.cmrNumber || "",
    cmrDate: exportShippingDetailsPopup?.data[0]?.cmrDate,
    shippingLine: exportShippingDetailsPopup?.data[0]?.shippingLine || "",
    vesselName: exportShippingDetailsPopup?.data[0]?.vesselName || "",
    voyageNumber: exportShippingDetailsPopup?.data[0]?.voyageNumber || "",
    portOfLoading: exportShippingDetailsPopup?.data[0]?.pointOfLoading || "",
    transshipmentPorts: exportShippingDetailsPopup?.data[0]?.transshipmentPorts || "",
    portOfDischarge: exportShippingDetailsPopup?.data[0]?.pointOfUnloading || "",
    finalDestination: exportShippingDetailsPopup?.data[0]?.destination || "",

    shippingInstructionNumber: exportShippingDetailsPopup?.data[0]?.shippingInstructionNumber || "",
    shippingInstructionDate: exportShippingDetailsPopup?.data[0]?.shippingInstructionDate,

    toBeShippedBy20: exportShippingDetailsPopup?.data[0]?.toBeShippedBy20 || "",
    toBeShippedBy40: exportShippingDetailsPopup?.data[0]?.toBeShippedBy40 || "",
    toBeShippedBy40HC: exportShippingDetailsPopup?.data[0]?.toBeShippedBy40HC || "",

    actualTimeOfArrival: exportShippingDetailsPopup?.data[0]?.actualTimeOfArrival,

    estimatedTimeOfDeparture: exportShippingDetailsPopup?.data[0]?.estimatedTimeOfDeparture,
    secondEstimatedTimeOfDeparture: exportShippingDetailsPopup?.data[0]?.secondEstimatedTimeOfDeparture,
    thirdEstimatedTimeOfDeparture: exportShippingDetailsPopup?.data[0]?.thirdEstimatedTimeOfDeparture,

    estimatedTimeOfArrival: exportShippingDetailsPopup?.data[0]?.estimatedTimeOfArrival,
    secondEstimatedTimeOfArrival: exportShippingDetailsPopup?.data[0]?.secondEstimatedTimeOfArrival,
    thirdEstimatedTimeOfArrival: exportShippingDetailsPopup?.data[0]?.thirdEstimatedTimeOfArrival,

    customerReference: exportShippingDetailsPopup?.data[0]?.shipmentRequest?.salesOrder?.customerReference,
  });

  const [showDocumentCounter, setShowDocumentCounter] = useState(false);
  const [showSpecialInstructions, setShowSpecialInstructions] = useState(false);

  const handleToggleDocuments = () => {
    setShowDocumentCounter(!showDocumentCounter);
  };

  const handleToggleSpecialInstructions = () => {
    setShowSpecialInstructions(!showSpecialInstructions);
  };

  const handleNameChange = (index, event) => {
    const newEntities = [...entities];
    newEntities[index].name = event.target.value;
    setEntities(newEntities);
  };

  const incrementCount1 = (index) => {
    const newEntities = [...entities];
    newEntities[index].count1 += 1;
    setEntities(newEntities);
  };

  const decrementCount1 = (index) => {
    const newEntities = [...entities];
    if (newEntities[index].count1 > 0) {
      newEntities[index].count1 -= 1;
      setEntities(newEntities);
    }
  };

  const incrementCount2 = (index) => {
    const newEntities = [...entities];
    newEntities[index].count2 += 1;
    setEntities(newEntities);
  };

  const decrementCount2 = (index) => {
    const newEntities = [...entities];
    if (newEntities[index].count2 > 0) {
      newEntities[index].count2 -= 1;
      setEntities(newEntities);
    }
  };

  const addEntity = () => {
    const newEntities = [...entities, { name: '', count1: 0, count2: 0 }];
    setEntities(newEntities);
    setShipmentAdviceDetails((prevDetails) => ({
      ...prevDetails,
      entities: newEntities,
    }));
  };

  const deleteEntity = (index) => {
    const newEntities = entities.filter((_, idx) => idx !== index);
    setEntities(newEntities);
    setShipmentAdviceDetails((prevDetails) => ({
      ...prevDetails,
      entities: newEntities,
    }));
  };

  const [specialInstructions, setSpecialInstructions] = useState([]);

  useEffect(() => {
    if (shipmentAdviceDetails?.specialInstructionsList) {
      setSpecialInstructions(shipmentAdviceDetails.specialInstructionsList);
    }
  }, [shipmentAdviceDetails]);

  const handleInstructionChange = (index, event) => {
    const newInstructions = [...specialInstructions];
    newInstructions[index] = event.target.value;
    setSpecialInstructions(newInstructions);
  };

  const createNewList = () => {
    const newList = specialInstructions.filter(instruction => instruction.trim() !== '');
    if (shipmentAdviceDetails) {
      shipmentAdviceDetails.specialInstructionsList = newList;
    }
  };

  const handleRemoveInstruction = (index) => {
    const newInstructions = specialInstructions.filter((_, i) => i !== index);
    setSpecialInstructions(newInstructions);
  };

  const handleAddInstruction = () => {
    setSpecialInstructions([...specialInstructions, '']);
  };

  return (
    // Added return statement here
    <>
      <Stack direction="column">
        <Typography variant="body1" mb={2}>
          Selected Items
        </Typography>
        {exportShippingDetailsPopup &&
          exportShippingDetailsPopup.data &&
          exportShippingDetailsPopup.data.map((shippingInstruction, index) => (
            <Grid
            container
            key={shippingInstruction.id}
            spacing={2}
            style={{ border: '1px solid #a8aebd', borderRadius: '25px', marginBottom: '30px', paddingBottom:'15px'}}
          >
              <Grid item xs={12} sm={1}>
                <Typography variant="body2"> SI ID </Typography>
                <TextField
                  disabled
                  variant="outlined"
                  size="small"
                  fullWidth
                  value={shippingInstruction?.id || ""}
                />
              </Grid>
              <Grid item xs={12} sm={1}>
                <Typography variant="body2">Shipment Request</Typography>
                <TextField
                  disabled
                  variant="outlined"
                  size="small"
                  fullWidth
                  value={shippingInstruction?.shipmentRequest?.id || ""}
                />
              </Grid>
              <Grid item xs={12} sm={1.5}>
                <Typography variant="body2">Supplier Grade Mark</Typography>
                <TextField
                  disabled
                  variant="outlined"
                  size="small"
                  fullWidth
                  value={shippingInstruction?.shipmentRequest?.purchaseOrder?.offer?.item?.buyingGrade || shippingInstruction?.shipmentRequest?.salesOrder?.item?.buyingGrade || ""}
                />
              </Grid>
              <Grid item xs={12} sm={1}>
                <Typography variant="body2">Crop</Typography>
                <TextField
                  disabled
                  variant="outlined"
                  size="small"
                  fullWidth
                  value={shippingInstruction?.shipmentRequest?.purchaseOrder?.offer?.item?.crop || shippingInstruction?.shipmentRequest?.salesOrder?.item?.crop || ""}
                />
              </Grid>

              <Grid item xs={12} sm={1}>
                <Typography variant="body2"># Of Cartons</Typography>
                <TextField
                  disabled
                  variant="outlined"
                  size="small"
                  fullWidth
                  value={shippingInstruction?.shipmentRequest?.numberOfCases || ""}
                />
              </Grid>
              <Grid item xs={12} sm={1}>
                <Typography variant="body2">Net Weight/Carton</Typography>
                <TextField
                  disabled
                  variant="outlined"
                  size="small"
                  fullWidth
                  value={shippingInstruction?.shipmentRequest?.netWeightPerCase || ""}
                />
              </Grid>
              <Grid item xs={12} sm={1}>
                <Typography variant="body2">Total Net Weight</Typography>
                <TextField
                  disabled
                  variant="outlined"
                  size="small"
                  fullWidth
                  value={shippingInstruction?.shipmentRequest?.shipmentQuantity || ""}
                />
              </Grid>
              <Grid item xs={12} sm={1}>
                <Typography variant="body2">Gross Weight</Typography>
                <TextField
                  disabled
                  variant="outlined"
                  size="small"
                  fullWidth
                  value={shippingInstruction?.grossWeight || ""}
                />
              </Grid>
              <Grid item xs={12} sm={1}>
                <Typography variant="body2">Carton Range</Typography>
                <TextField
                  disabled
                  variant="outlined"
                  size="small"
                  fullWidth
                  value={
                    shippingInstruction?.cartonNumber
                  }
                />
              </Grid>
              <Grid item xs={12} sm={0.5}>
                <Typography variant="body2">20"</Typography>
                <TextField
                  disabled
                  variant="outlined"
                  size="small"
                  fullWidth
                  value={
                    shippingInstruction?.toBeShippedBy20
                  }
                />
              </Grid>
              <Grid item xs={12} sm={0.5}>
                <Typography variant="body2">40"</Typography>
                <TextField
                  disabled
                  variant="outlined"
                  size="small"
                  fullWidth
                  value={
                    shippingInstruction?.toBeShippedBy40
                  }
                />
              </Grid>
              <Grid item xs={12} sm={0.5}>
                <Typography variant="body2">40HC</Typography>
                <TextField
                  disabled
                  variant="outlined"
                  size="small"
                  fullWidth
                  value={
                    shippingInstruction?.toBeShippedBy40HC
                  }
                />
              </Grid>
              <Grid item xs={12} sm={0.5}>
                <Typography variant="body2">FTL</Typography>
                <TextField
                  disabled
                  variant="outlined"
                  size="small"
                  fullWidth
                  value={
                    shippingInstruction?.toBeShippedByFtl
                  }
                />
              </Grid>
              <Grid item xs={12} sm={5}>
                <Typography variant="body2">Description</Typography>
                <TextField
                  disabled
                  variant="outlined"
                  size="small"
                  fullWidth
                  value={shippingInstruction?.description|| ""}
                />
              </Grid>
            </Grid>
          ))}
        <Typography variant="body1" mb={2}>
          Shipment Advice Details
        </Typography>
        <Grid container spacing={2} marginBottom={5}>
          <Grid item xs={12} sm={2}>
            <Typography variant="body2">SI Number</Typography>
            <TextField
              variant="outlined"
              size="small"
              fullWidth
              value={shipmentAdviceDetails?.siNumber}
              onChange={(e) => {
                setShipmentAdviceDetails({
                  ...shipmentAdviceDetails,
                  siNumber: e.target.value,
                });
              }}
            />
          </Grid>

          <Grid item xs={12} sm={2}>
            <Typography variant="body2">SI Date</Typography>
            <TextField
              size="small"
              fullWidth
              variant="outlined"
              id="date"
              type="date"
              value={
                shipmentAdviceDetails?.siDate
                  ? format(
                      parseISO(shipmentAdviceDetails.siDate),
                      "yyyy-MM-dd",
                    )
                  : ""
              }
              inputProps={{ min: "1999-01-01", max: "2999-01-01" }}
              InputLabelProps={{
                shrink: true,
              }}
              onKeyDown={(e) => e.preventDefault()}
              onChange={(e) => {
                let value = e.target.value;
                let isoDate = "";
            
                // Check if the value is a valid date before trying to parse it.
                if (value && !isNaN(Date.parse(value))) {
                  isoDate = new Date(value).toISOString().split('T')[0];
                }
            
                setShipmentAdviceDetails({
                  ...shipmentAdviceDetails,
                  poDate: isoDate,
                });
              }}
            />
          </Grid>
          <Grid item xs={12} sm={1}>
            <Typography variant="body2">Company</Typography>
            <TextField
              variant="outlined"
              size="small"
              fullWidth
              id="outlined-select-title"
              select
              value={shipmentAdviceDetails?.company}
              onChange={(e) =>
                setShipmentAdviceDetails({
                  ...shipmentAdviceDetails,
                  company: e.target.value,
                })
              }
            >
              {companies.map((option) => (
                <MenuItem key={option.value} value={option.value}>
                  {option.label}
                </MenuItem>
              ))}
            </TextField>
          </Grid>

          <Grid item xs={12} sm={2}>
            <Typography variant="body2">Shipment Advice To</Typography>
            <TextField
              variant="outlined"
              size="small"
              fullWidth
              value={shipmentAdviceDetails?.consignee?.name}
              onChange={(e) => {
                setShipmentAdviceDetails({
                  ...shipmentAdviceDetails,
                  consignee: e.target.value,
                });
              }}
            />
          </Grid>
          </Grid>

          <Typography variant="body1" mb={2}>
          Booking Details
        </Typography>
<Grid container spacing={2} marginBottom={5}>
          <Grid item xs={12} sm={2}>
            <Typography variant="body2">Booking Number</Typography>
            <TextField
              variant="outlined"
              size="small"
              fullWidth
              value={shipmentAdviceDetails?.bookingNumber}
              onChange={(e) =>
                setShipmentAdviceDetails({
                  ...shipmentAdviceDetails,
                    bookingNumber: e.target.value,
                  },
                )
              }
            />
          </Grid>

          <Grid item xs={12} sm={2}>
            <Typography variant="body2">BL Number</Typography>
            <TextField
              disabled
              variant="outlined"
              size="small"
              fullWidth
              value={shipmentAdviceDetails?.billOfLadingNumber}
            />
          </Grid>

          <Grid item xs={12} sm={2}>
            <Typography variant="body2">BL Date</Typography>
            <TextField
              id="date"
              type="date"
              inputProps={{ min: "1999-01-01", max: "2999-01-01" }}
              InputLabelProps={{
                shrink: true,
              }}
              onKeyDown={(e) => e.preventDefault()}
              variant="outlined"
              size="small"
              fullWidth
              value={
                shipmentAdviceDetails?.billOfLadingDate
                  ? format(
                      parseISO(shipmentAdviceDetails.billOfLadingDate),
                      "yyyy-MM-dd",
                    )
                  : ""
              }
              onChange={(e) =>
                setShipmentAdviceDetails({
                  ...shipmentAdviceDetails,
                  billOfLadingDate: e.target.value,
                  },
                )
              }
            />
          </Grid>

          <Grid item xs={12} sm={2}>
            <Typography variant="body2">CMR Number</Typography>
            <TextField
              disabled
              variant="outlined"
              size="small"
              fullWidth
              value={shipmentAdviceDetails?.cmrNumber}
            />
          </Grid>

          <Grid item xs={12} sm={2}>
            <Typography variant="body2">CMR Date</Typography>
            <TextField
              id="date"
              type="date"
              inputProps={{ min: "1999-01-01", max: "2999-01-01" }}
              InputLabelProps={{
                shrink: true,
              }}
              onKeyDown={(e) => e.preventDefault()}
              variant="outlined"
              size="small"
              fullWidth
              value={
                shipmentAdviceDetails?.cmrDate
                  ? format(
                      parseISO(shipmentAdviceDetails.cmrDate),
                      "yyyy-MM-dd",
                    )
                  : ""
              }
              onChange={(e) =>
                setShipmentAdviceDetails({
                  ...shipmentAdviceDetails,
                  cmrDate: e.target.value,
                  },
                )
              }
            />
          </Grid>

          <Grid item xs={12} sm={2}>
            <Typography variant="body2">Shipping Line</Typography>
            <TextField
              disabled
              variant="outlined"
              size="small"
              fullWidth
              value={shipmentAdviceDetails?.shippingLine}
            />
          </Grid>

          <Grid item xs={12} sm={2}>
            <Typography variant="body2">Vessel</Typography>
            <TextField
              disabled
              variant="outlined"
              size="small"
              fullWidth
              value={shipmentAdviceDetails?.vesselName}
            />
          </Grid>
       
          <Grid item xs={12} sm={2}>
            <Typography variant="body2">Voyage Number</Typography>
            <TextField
              disabled
              variant="outlined"
              size="small"
              fullWidth
              value={shipmentAdviceDetails?.voyageNumber}
            />
          </Grid>

          <Grid item xs={12} sm={2}>
            <Typography variant="body2">Port Of Loading</Typography>
            <TextField
              disabled
              variant="outlined"
              size="small"
              fullWidth
              value={exportShippingDetailsPopup?.data[0]?.pointOfLoading || ""}
            />
          </Grid>

          <Grid item xs={12} sm={6}>
            <Typography variant="body2">Transshipment Ports</Typography>
            <TextField
              disabled
              variant="outlined"
              size="small"
              fullWidth
              value={exportShippingDetailsPopup?.data[0]?.transshipmentPorts.join(", ") || ""}
            />
          </Grid>

          <Grid item xs={12} sm={2}>
            <Typography variant="body2">Port Of Discharge</Typography>
            <TextField
              disabled
              variant="outlined"
              size="small"
              fullWidth
              value={exportShippingDetailsPopup?.data[0]?.pointOfUnloading || ""}
            />
          </Grid>

          <Grid item xs={12} sm={2}>
            <Typography variant="body2">
            Final Destination
            </Typography>
            <TextField
              variant="outlined"
              size="small"
              fullWidth
              disabled
              value={shipmentAdviceDetails?.finalDestination}
            />
          </Grid> 
        </Grid>
        <Typography variant="body1" mb={2}>
          Shipment Quantity
        </Typography>
<Grid container spacing={2} marginBottom={5}>
<Grid item xs={12} sm={1.33}>
            <Typography variant="body2">
            Total 20"
            </Typography>
            <TextField
              variant="outlined"
              size="small"
              fullWidth
              disabled
              value={shipmentAdviceDetails?.items.reduce((total, item) => {
                return total + (item?.toBeShippedBy20 || 0);
              }, 0)}
            />
          </Grid> 
          <Grid item xs={12} sm={1.33}>
            <Typography variant="body2">
            Total 40"
            </Typography>
            <TextField
              variant="outlined"
              size="small"
              fullWidth
              disabled
              value={shipmentAdviceDetails?.items.reduce((total, item) => {
                return total + (item?.toBeShippedBy40 || 0);
              }, 0)}
            />
          </Grid> 
          <Grid item xs={12} sm={1.33}>
            <Typography variant="body2">
            Total 40HC"
            </Typography>
            <TextField
              variant="outlined"
              size="small"
              fullWidth
              disabled
              value={shipmentAdviceDetails?.items.reduce((total, item) => {
                return total + (item?.toBeShippedBy40HC || 0);
              }, 0)}
            />
          </Grid> 
          <Grid item xs={12} sm={1.33}>
            <Typography variant="body2">
            Total FTL
            </Typography>
            <TextField
              variant="outlined"
              size="small"
              fullWidth
              disabled
              value={shipmentAdviceDetails?.items.reduce((total, item) => {
                return total + (item?.toBeShippedByFtl || 0);
              }, 0)}
            />
          </Grid> 
  </Grid>

  <Typography variant="body1" mb={2}>
          Time Details
        </Typography>
<Grid container spacing={2} marginBottom={5}>
<Grid item xs={12} sm={2}>
            <Typography variant="body2" style={{ visibility: 'hidden' }}>
            .
            </Typography>
            <TextField
              variant="outlined"
              size="small"
              fullWidth
              disabled
              value={"1st Update"}
            />
          </Grid> 
          <Grid item xs={12} sm={2}>
            <Typography variant="body2">ETD/ATD</Typography>
            <TextField
              id="date"
              type="date"
              disabled
              inputProps={{ min: "1999-01-01", max: "2999-01-01" }}
              InputLabelProps={{
                shrink: true,
              }}
              onKeyDown={(e) => e.preventDefault()}
              variant="outlined"
              size="small"
              fullWidth
              value={
                shipmentAdviceDetails?.estimatedTimeOfDeparture
                  ? format(
                      parseISO(shipmentAdviceDetails.estimatedTimeOfDeparture),
                      "yyyy-MM-dd",
                    )
                  : ""
              }
            />
          </Grid>
          <Grid item xs={12} sm={2}>
            <Typography variant="body2">ETA</Typography>
            <TextField
              id="date"
              type="date"
              disabled
              inputProps={{ min: "1999-01-01", max: "2999-01-01" }}
              InputLabelProps={{
                shrink: true,
              }}
              onKeyDown={(e) => e.preventDefault()}
              variant="outlined"
              size="small"
              fullWidth
              value={
                shipmentAdviceDetails?.estimatedTimeOfArrival
                  ? format(
                      parseISO(shipmentAdviceDetails.estimatedTimeOfArrival),
                      "yyyy-MM-dd",
                    )
                  : ""
              }
            />
          </Grid>
          <Grid item xs={12} sm={2}>
            <Typography variant="body2">ATA</Typography>
            <TextField
              id="date"
              type="date"
              disabled
              inputProps={{ min: "1999-01-01", max: "2999-01-01" }}
              InputLabelProps={{
                shrink: true,
              }}
              onKeyDown={(e) => e.preventDefault()}
              variant="outlined"
              size="small"
              fullWidth
              value={
                shipmentAdviceDetails?.actualTimeOfArrival
                  ? format(
                      parseISO(shipmentAdviceDetails.actualTimeOfArrival),
                      "yyyy-MM-dd",
                    )
                  : ""
              }
            />
          </Grid>
          <Grid item xs={12} sm={4}>
          </Grid> 
          <Grid item xs={12} sm={2}>
            <Typography variant="body2" style={{ visibility: 'hidden' }}>
            .
            </Typography>
            <TextField
              variant="outlined"
              size="small"
              fullWidth
              disabled
              value={"2nd Update"}
            />
          </Grid> 
          <Grid item xs={12} sm={2}>
            <Typography variant="body2">ETD/ATD</Typography>
            <TextField
              id="date"
              type="date"
              disabled
              inputProps={{ min: "1999-01-01", max: "2999-01-01" }}
              InputLabelProps={{
                shrink: true,
              }}
              onKeyDown={(e) => e.preventDefault()}
              variant="outlined"
              size="small"
              fullWidth
              value={
                shipmentAdviceDetails?.secondEstimatedTimeOfDeparture
                  ? format(
                      parseISO(shipmentAdviceDetails.secondEstimatedTimeOfDeparture),
                      "yyyy-MM-dd",
                    )
                  : ""
              }
            />
          </Grid>
          <Grid item xs={12} sm={2}>
            <Typography variant="body2">ETA</Typography>
            <TextField
              id="date"
              type="date"
              disabled
              inputProps={{ min: "1999-01-01", max: "2999-01-01" }}
              InputLabelProps={{
                shrink: true,
              }}
              onKeyDown={(e) => e.preventDefault()}
              variant="outlined"
              size="small"
              fullWidth
              value={
                shipmentAdviceDetails?.secondEstimatedTimeOfArrival
                  ? format(
                      parseISO(shipmentAdviceDetails.secondEstimatedTimeOfArrival),
                      "yyyy-MM-dd",
                    )
                  : ""
              }
            />
          </Grid>
          <Grid item xs={12} sm={6}>
          </Grid> 
          <Grid item xs={12} sm={2}>
            <Typography variant="body2" style={{ visibility: 'hidden' }}>
            .
            </Typography>
            <TextField
              variant="outlined"
              size="small"
              fullWidth
              disabled
              value={"3rd Update"}
            />
          </Grid> 
          <Grid item xs={12} sm={2}>
            <Typography variant="body2">ETD/ATD</Typography>
            <TextField
              id="date"
              type="date"
              disabled
              inputProps={{ min: "1999-01-01", max: "2999-01-01" }}
              InputLabelProps={{
                shrink: true,
              }}
              onKeyDown={(e) => e.preventDefault()}
              variant="outlined"
              size="small"
              fullWidth
              value={
                shipmentAdviceDetails?.thirdEstimatedTimeOfDeparture
                  ? format(
                      parseISO(shipmentAdviceDetails.thirdEstimatedTimeOfDeparture),
                      "yyyy-MM-dd",
                    )
                  : ""
              }
            />
          </Grid>
          <Grid item xs={12} sm={2}>
            <Typography variant="body2">ETA</Typography>
            <TextField
              id="date"
              type="date"
              disabled
              inputProps={{ min: "1999-01-01", max: "2999-01-01" }}
              InputLabelProps={{
                shrink: true,
              }}
              onKeyDown={(e) => e.preventDefault()}
              variant="outlined"
              size="small"
              fullWidth
              value={
                shipmentAdviceDetails?.thirdEstimatedTimeOfArrival
                  ? format(
                      parseISO(shipmentAdviceDetails.thirdEstimatedTimeOfArrival),
                      "yyyy-MM-dd",
                    )
                  : ""
              }
            />
          </Grid>
          <Grid item xs={12} sm={6}>
          </Grid> 

  </Grid>
        <Stack direction="row" spacing={1} mt={1}>
          <Button
            variant="outlined"
            onClick={() =>{
              setExportShippingDetailsPopup({
                data: null,
                visible: false,
              })
            }}
          >
            Cancel
          </Button>
          <Button
            variant="contained"
            style={{ whiteSpace: "nowrap" }}
            onClick={() => {createExcelWithLogoAndData(shipmentAdviceDetails)
            updateStatusForAll()}}
          >
            Create Shipping Details File
          </Button>
        </Stack>
      </Stack>
    </>
  );
};

export default ExportShippingInstructionOrder;

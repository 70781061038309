import { ReloadOutlined } from "@ant-design/icons";
import {
  Autocomplete,
  Box,
  Button,
  Chip,
  Grid,
  MenuItem,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import toast from "react-hot-toast";
import { useNavigate } from "react-router-dom";
import {
  createAddressApi,
  eurUsdExchangeApi,
} from "../../../components/api/AddressApiService";
import {
  addressTypes,
  countries,
  currencies,
  recordStatus,
} from "../../../utils/menu-items";
import { retrieveAllStakeholdersApi } from "../../../components/api/StakeholderApiService";

const AddAddress = (props) => {
  const navigate = useNavigate();
  const { setAddViewStates, addViewStates, rows, setRows, setAddView } = props;
  const [stakeholders, setStakeholders] = useState([]);
  const [selectedCountry, setSelectedCountry] = useState(
    addViewStates?.country || "",
  );
  const [selectedStakeholder, setSelectedStakeholder] = useState(
    addViewStates?.stakeholder || "",
  );
  function retrieveStakeholders() {
    Promise.all([
      retrieveAllStakeholdersApi("VENDOR"),
      retrieveAllStakeholdersApi("CUSTOMER"),
      retrieveAllStakeholdersApi("SHIPPER"),
      retrieveAllStakeholdersApi("CONSIGNEE"),
    ])
      .then(
        ([
          vendorResponse,
          customerResponse,
          shipperResponse,
          consigneeResponse,
        ]) => {
          setStakeholders([
            ...vendorResponse?.data,
            ...customerResponse?.data,
            ...shipperResponse?.data,
            ...consigneeResponse?.data,
          ]);
        },
      )
      .catch((error) => console.log(error.response?.data));
  }

  return (
    <>
      <Stack direcion="column">
        <Typography variant="body1" mb={2}>
          Create New Address
        </Typography>
        <Grid container xs={12} spacing="8px">
          <Grid item xs={12} sm={2}>
            <Typography variant="body2">Select Business Partner</Typography>
            <Autocomplete
              options={stakeholders}
              getOptionLabel={(option) =>
                `${option.id || ""} - ${option.type || ""} - ${
                  option.code || ""
                } - ${option.name || ""}`
              }
              value={selectedStakeholder}
              onChange={(event, newValue) => {
                setSelectedStakeholder(newValue);
                setAddViewStates({
                  ...addViewStates,
                  stakeholderId: newValue ? newValue.id : null,
                });
              }}
              onOpen={() => retrieveStakeholders()}
              renderInput={(params) => (
                <TextField
                  {...params}
                  variant="outlined"
                  size="small"
                  fullWidth
                />
              )}
              renderTags={() => null}
            />
            {selectedStakeholder && (
              <Box mt={2}>
                <Chip
                  label={`ID: ${selectedStakeholder?.id || ""}`}
                  style={{ marginBottom: "5px" }}
                  onDelete={() => setSelectedStakeholder(null)}
                />
                <br />
                <Chip
                  label={`Type: ${selectedStakeholder?.type || ""}`}
                  style={{ marginBottom: "5px" }}
                />
                <br />
                <Chip
                  label={`SAP Code: ${selectedStakeholder?.code || ""}`}
                  style={{ marginBottom: "5px" }}
                />
                <br />
                <Chip
                  label={`Name: ${selectedStakeholder?.name || ""}`}
                  style={{ marginBottom: "5px" }}
                />
                <br />
              </Box>
            )}
          </Grid>
          <Grid item xs={12} sm={2}>
            <Typography variant="body2">Address Type</Typography>
            <TextField
              id="outlined-select-status"
              select
              variant="outlined"
              size="small"
              fullWidth
              onChange={(e) =>
                setAddViewStates({
                  ...addViewStates,
                  type: e.target.value,
                })
              }
            >
              {addressTypes.map((option) => (
                <MenuItem key={option.value} value={option.value}>
                  {option.label}
                </MenuItem>
              ))}
            </TextField>
          </Grid>

          <Grid item xs={12} sm={2}>
            <Typography variant="body2">Street</Typography>
            <TextField
              variant="outlined"
              size="small"
              fullWidth
              value={addViewStates?.street}
              onChange={(e) =>
                setAddViewStates({
                  ...addViewStates,
                  street: e.target.value,
                })
              }
            />
          </Grid>

          <Grid item xs={12} sm={2}>
            <Typography variant="body2">Zip Code</Typography>
            <TextField
              variant="outlined"
              size="small"
              fullWidth
              value={addViewStates?.zipCode}
              onChange={(e) =>
                setAddViewStates({
                  ...addViewStates,
                  zipCode: e.target.value,
                })
              }
            />
          </Grid>

          <Grid item xs={12} sm={2}>
            <Typography variant="body2">City</Typography>
            <TextField
              variant="outlined"
              size="small"
              fullWidth
              value={addViewStates?.city}
              onChange={(e) =>
                setAddViewStates({
                  ...addViewStates,
                  city: e.target.value,
                })
              }
            />
          </Grid>
          <Grid item xs={12} sm={2}>
            <Typography variant="body2">County</Typography>
            <TextField
              variant="outlined"
              size="small"
              fullWidth
              value={addViewStates?.county}
              onChange={(e) =>
                setAddViewStates({
                  ...addViewStates,
                  county: e.target.value,
                })
              }
            />
          </Grid>

          <Grid item xs={12} sm={2}>
            <Typography variant="body2">Country</Typography>
            <Autocomplete
              options={countries}
              getOptionLabel={(option) => (option ? option.label : "")}
              value={selectedCountry}
              onChange={(event, newValue) => {
                setSelectedCountry(newValue);
                setAddViewStates({
                  ...addViewStates,
                  country: newValue ? newValue.label : "",
                });
              }}
              isOptionEqualToValue={(option, value) =>
                option.value === value.value
              }
              renderInput={(params) => (
                <TextField
                  {...params}
                  variant="outlined"
                  size="small"
                  fullWidth
                />
              )}
              renderTags={() => null}
            />
            {selectedCountry && (
              <Box mt={2}>
                <Chip
                  label={selectedCountry.label}
                  onDelete={() => {
                    setSelectedCountry(null);
                    setAddViewStates({
                      ...addViewStates,
                      country: "",
                    });
                  }}
                />
              </Box>
            )}
          </Grid>

          <Grid item xs={12} sm={2}>
            <Typography variant="body2">Remark</Typography>
            <TextField
              variant="outlined"
              size="small"
              fullWidth
              onChange={(e) =>
                setAddViewStates({
                  ...addViewStates,
                  remark: e.target.value,
                })
              }
            />
          </Grid>
          <Grid item xs={12} sm={2}>
            <Typography variant="body2">Status</Typography>
            <TextField
              variant="outlined"
              size="small"
              fullWidth
              id="outlined-select-status"
              select
              onChange={(e) =>
                setAddViewStates({
                  ...addViewStates,
                  recordStatus: e.target.value,
                })
              }
            >
              {recordStatus.map((option) => (
                <MenuItem key={option.value} value={option.value}>
                  {option.label}
                </MenuItem>
              ))}
            </TextField>
          </Grid>
        </Grid>
        <Stack direction="row" spacing={1} mt={1}>
          <Button variant="outlined" onClick={() => setAddView(false)}>
            Cancel
          </Button>
          <Button
            variant="contained"
            onClick={() => {
              const newId = Math.floor(Math.random() * 100000);
              setAddViewStates({ ...addViewStates, id: newId });
              setRows([addViewStates, ...rows]);
              setAddView(false);

              createAddressApi(addViewStates)
                .then((response) => {
                  toast.success("Address added successfully.");
                  window.location.reload();
                  navigate("/stakeholder-address-view");
                })
                .catch((error) => {
                  toast.error("Something went wrong.");
                  console.log(error.response.data);
                });
            }}
          >
            Add
          </Button>
        </Stack>
      </Stack>
    </>
  );
};

export default AddAddress;

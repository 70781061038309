export const headCells = [
  {
    id: "editButton",
    disablePadding: true,
    label: "",
  },
  {
    id: "id",
    numeric: true,
    disablePadding: true,
    label: "ID",
  },
  {
    id: "warehouseName",
    numeric: false,
    disablePadding: true,
    label: "Warehouse",
  },
  {
    id: "year",
    numeric: true,
    disablePadding: true,
    label: "Year",
  },
  {
    id: "currency",
    numeric: true,
    disablePadding: true,
    label: "Currency",
  },
  {
    id: "january",
    numeric: true,
    disablePadding: true,
    label: "Jan",
  },
  {
    id: "february",
    numeric: true,
    disablePadding: true,
    label: "Feb",
  },
  {
    id: "march",
    numeric: true,
    disablePadding: true,
    label: "Mar",
  },
  {
    id: "april",
    numeric: true,
    disablePadding: true,
    label: "Apr",
  },
  {
    id: "may",
    numeric: true,
    disablePadding: true,
    label: "May",
  },
  {
    id: "june",
    numeric: true,
    disablePadding: false,
    label: "Jun",
  },
  {
    id: "july",
    numeric: true,
    disablePadding: true,
    label: "Jul",
  },
  {
    id: "august",
    numeric: true,
    disablePadding: true,
    label: "Aug",
  },
  {
    id: "september",
    numeric: true,
    disablePadding: true,
    label: "Sep",
  },
  {
    id: "october",
    numeric: true,
    disablePadding: true,
    label: "Oct",
  },
  {
    id: "november",
    numeric: true,
    disablePadding: true,
    label: "Nov",
  },
  {
    id: "december",
    numeric: true,
    disablePadding: true,
    label: "Dec",
  },
];

export function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

export function getComparator(order, orderBy) {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

export function stableSort(array, comparator) {
  const stabilizedThis = array?.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) {
      return order;
    }
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

import React, { useState } from "react";
import { Button, Dialog, DialogTitle, DialogContent, Table, TableBody, TableCell, TableHead, TableRow, CircularProgress } from "@mui/material";
import { retrieveFilteredRecordsApi } from "../../../../components/api/TradeApiService";
import { format } from "date-fns";
import { retrieveFilteredWarehouseRecordsApi } from "../../../../components/api/WarehouseApiService";

const WarehouseInventoryButton = ({ warehouse, itemIdList }) => {
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [records, setRecords] = useState([]);

  const retrieveFilteredWarehouseInventory = {
    id: 0,
    warehouseList: [warehouse,],
    itemIdList: itemIdList,
    recordStatusList: ["ACTIVE"],
  };

  const handleOpen = async () => {
    setOpen(true);
    setLoading(true);
    try {
      const response = await retrieveFilteredWarehouseRecordsApi(retrieveFilteredWarehouseInventory);
      setRecords(response.data); 
    } catch (error) {
      console.error("Error fetching records:", error);
    } finally {
      setLoading(false);
    }
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <TableCell padding="normal">
      <Button 
        disableElevation
        fullWidth  
        onClick={handleOpen}>Warehouse Inventory</Button>

      <Dialog open={open} onClose={handleClose} maxWidth="lg" fullWidth>
        <DialogTitle>Warehouse Inventory Records</DialogTitle>
        <DialogContent>
          {loading ? (
            <CircularProgress />
          ) : (
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>Record ID</TableCell>
                  <TableCell>Name </TableCell>
                  <TableCell>Item ID</TableCell>
                  <TableCell>Buying Grade</TableCell>
                  <TableCell>Origin</TableCell>
                  <TableCell>Crop</TableCell>
                  <TableCell>Quantity</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {records.length > 0 ? (
                  records.map((record) => (
                    <TableRow key={record.id}>
                         <TableCell align="center">{record?.id}</TableCell>
                          <TableCell align="center">{record?.name}</TableCell>
                          <TableCell align="center">{record?.item?.id}</TableCell>
                          <TableCell align="center">{record?.item?.buyingGrade}</TableCell>
                          <TableCell align="center">{record?.item?.origin}</TableCell>
                          <TableCell align="center">{record?.item?.crop}</TableCell>
                          <TableCell align="center">
                          {record?.shippedAmount ? `${new Intl.NumberFormat("de-DE", {
                                  style: "decimal",
                                  minimumFractionDigits: 2,
                                }).format(record.shippedAmount)} ${record.shippedAmountUnit ?? ""}`
                              : ""}
                          </TableCell>
                    </TableRow>
                  ))
                ) : (
                  <TableRow>
                    <TableCell colSpan={3} align="center">
                      No records found
                    </TableCell>
                  </TableRow>
                )}
              </TableBody>
            </Table>
          )}
        </DialogContent>
      </Dialog>
    </TableCell>
  );
};

export default WarehouseInventoryButton;

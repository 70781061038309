import DeleteIcon from "@mui/icons-material/Delete";
import { Autocomplete, MenuItem, Stack } from "@mui/material";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Checkbox from "@mui/material/Checkbox";
import FormControlLabel from "@mui/material/FormControlLabel";
import Grid from "@mui/material/Grid";
import IconButton from "@mui/material/IconButton";
import Paper from "@mui/material/Paper";
import Switch from "@mui/material/Switch";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import TableSortLabel from "@mui/material/TableSortLabel";
import TextField from "@mui/material/TextField";
import Toolbar from "@mui/material/Toolbar";
import Tooltip from "@mui/material/Tooltip";
import Typography from "@mui/material/Typography";
import { alpha } from "@mui/material/styles";
import { visuallyHidden } from "@mui/utils";
import { format } from "date-fns";
import PropTypes from "prop-types";
import * as React from "react";
import { useEffect, useState } from "react";
import { retrieveAllItemsApi } from "../../../components/api/ItemApiService";
import { retrieveAllStakeholdersApi } from "../../../components/api/StakeholderApiService";
import {
  retrieveAllTradeRecordsApi,
  retrieveFilteredRecordsApi,
} from "../../../components/api/TradeApiService";
import ExportExcel from "../../../utils/excelExport";
import {
  color,
  companies,
  incoterms,
  leafForm,
  operationType,
  orderTypes,
  origin,
  recordStatus,
  status,
  type,
  units,
  warehouses,
} from "../../../utils/menu-items";
import AddTrade from "./AddTrade";
import EditTrade from "./EditTrade";
import { retrieveAllWarehouseApi } from "../../../components/api/WarehouseApiService";

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort(array, comparator) {
  const stabilizedThis = array?.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) {
      return order;
    }
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

const headCells = [
  {
    id: "editButton",
    disablePadding: true,
    label: "",
  },
  {
    id: "id",
    numeric: true,
    disablePadding: true,
    label: "ID",
  },
  {
    id: "operationType",
    numeric: false,
    disablePadding: true,
    label: "Operation",
  },
  {
    id: "operationInfo",
    numeric: false,
    disablePadding: true,
    label: "",
  },
  {
    id: "operationDate",
    numeric: false,
    disablePadding: true,
    label: "Issue Date",
  },
  {
    id: "poCompany",
    numeric: true,
    disablePadding: true,
    label: "PO Company",
  },
  {
    id: "soCompany",
    numeric: true,
    disablePadding: true,
    label: "SO Company",
  },
  {
    id: "itemId",
    numeric: true,
    disablePadding: true,
    label: "Item ID",
  },
  {
    id: "itemBuyingGrade",
    numeric: false,
    disablePadding: true,
    label: "Buying Grade",
  },
  {
    id: "itemSgiGrade",
    numeric: false,
    disablePadding: true,
    label: "Sales Grade",
  },
  {
    id: "crop",
    numeric: true,
    disablePadding: true,
    label: "Crop",
  },
  {
    id: "itemOrigin",
    numeric: false,
    disablePadding: true,
    label: "Origin",
  },
  {
    id: "warehouse",
    numeric: false,
    disablePadding: true,
    label: "Warehouse",
  },
  {
    id: "destination",
    numeric: false,
    disablePadding: true,
    label: "Destination",
  },
  {
    id: "poBusinessPartner",
    numeric: false,
    disablePadding: true,
    label: "PO Business Partner",
  },
  {
    id: "soBusinessPartner",
    numeric: false,
    disablePadding: true,
    label: "SO Business Partner",
  },
  {
    id: "portOfLoading",
    numeric: false,
    disablePadding: true,
    label: "Port Of Loading",
  },
  {
    id: "portOfDischarge",
    numeric: false,
    disablePadding: true,
    label: "Port Of Discharge",
  },
  {
    id: "numberOfCases",
    numeric: true,
    disablePadding: false,
    label: "Number Of Cartons",
  },
  {
    id: "caseNetWeight",
    numeric: true,
    disablePadding: false,
    label: "Net Weight Per Carton",
  },
  {
    id: "volume",
    numeric: true,
    disablePadding: false,
    label: "Volume",
  },
  {
    id: "po",
    numeric: false,
    disablePadding: false,
    label: "PO",
  },
  {
    id: "so",
    numeric: true,
    disablePadding: false,
    label: "SO",
  },
  {
    id: "shipmentRequest",
    numeric: true,
    disablePadding: false,
    label: "SR",
  },
  {
    id: "shippingInstructionId",
    numeric: true,
    disablePadding: false,
    label: "SI",
  },
  {
    id: "actualTimeOfArrival",
    numeric: true,
    disablePadding: false,
    label: "ATA",
  },
  {
    id: "remark",
    numeric: false,
    disablePadding: true,
    label: "Remark",
  },
  {
    id: "creationTime",
    numeric: false,
    disablePadding: true,
    label: "Creation Time",
  },
  {
    id: "createdBy",
    numeric: false,
    disablePadding: true,
    label: "Created By",
  },
  {
    id: "recordStatus",
    numeric: false,
    disablePadding: true,
    label: "Record Status",
  },
];

function EnhancedTableHead(props) {
  const {
    onSelectAllClick,
    order,
    orderBy,
    numSelected,
    rowCount,
    onRequestSort,
  } = props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        <TableCell padding="checkbox">
          <Checkbox
            color="primary"
            indeterminate={numSelected > 0 && numSelected < rowCount}
            checked={rowCount > 0 && numSelected === rowCount}
            onChange={onSelectAllClick}
            inputProps={{
              "aria-label": "select all desserts",
            }}
          />
        </TableCell>
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            align={"center"}
            padding={headCell.disablePadding ? "none" : "normal"}
            sortDirection={orderBy === headCell.id ? order : false}
            sx={
              headCell.id === "id"
                ? {
                    position: 'sticky',
                    left: 0,
                    zIndex: 3, 
                  }
                : headCell.id === "operationType"
                ? {
                    position: 'sticky',
                    left: 50,
                    zIndex: 3, 
                  }
                : headCell.id === "operationInfo"
                ? {
                    position: 'sticky',
                    left: 150,
                    zIndex: 3, 
                  }
                : null
            }
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : "asc"}
              onClick={createSortHandler(headCell.id)}
            >
              {headCell.label}
              {orderBy === headCell.id ? (
                <Box component="span" sx={visuallyHidden}>
                  {order === "desc" ? "sorted descending" : "sorted ascending"}
                </Box>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

EnhancedTableHead.propTypes = {
  numSelected: PropTypes.number.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  onSelectAllClick: PropTypes.func.isRequired,
  order: PropTypes.oneOf(["asc", "desc"]).isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
};

function EnhancedTableToolbar(props) {
  const { numSelected, showFilters, setShowFilters, selected,
    selectedData } = props;

  return (
    <Toolbar
      sx={{
        pl: { sm: 2 },
        pr: { xs: 1, sm: 1 },
        ...(numSelected > 0 && {
          bgcolor: (theme) =>
            alpha(
              theme.palette.primary.main,
              theme.palette.action.activatedOpacity,
            ),
        }),
        display: "flex",
        justifyContent: "space-between",
      }}
    >
      <Stack direction="row" spacing={1} alignItems={"center"}>
        <Typography
          sx={{ fontWeight: 600, fontSize: "20px" }}
          variant="h6"
          id="tableTitle"
          component="div"
          color="#1E2685"
        >
          Trade History
        </Typography>

         <Typography></Typography>
          {numSelected > 0 ? (
          <Typography
          sx={{ fontWeight: 200, fontSize: "16px" }}
            color="#1E2685"
            variant="subtitle1"
            component="div"
          >
            {numSelected} selected
          </Typography>
        ) : (<Typography></Typography>)}
          

      {numSelected > 0 && (
        <Tooltip title="Delete">
          <IconButton>
            <DeleteIcon />
          </IconButton>
        </Tooltip>
      )}
      </Stack>
      <Stack direction={"row"} spacing={1} sx={{ mr: 2 }}>
      <Button
        variant="outlined"
        onClick={() => setShowFilters(!showFilters)}
        sx={{ minWidth: "130px" }}
        size="small"
      >
        {showFilters ? "Hide Filters" : "Show Filters"}
      </Button>
      </Stack>
    </Toolbar>
  );
}

EnhancedTableToolbar.propTypes = {
  numSelected: PropTypes.number.isRequired,
};

export default function TradeView() {
  const [order, setOrder] = React.useState("asc");
  const [orderBy, setOrderBy] = React.useState("calories");
  const [selected, setSelected] = React.useState([]);
  const [selectedData, setSelectedData] = useState([]);
  const [page, setPage] = React.useState(0);
  const [dense, setDense] = React.useState(true);
  const [rowsPerPage, setRowsPerPage] = React.useState(25);
  const [addView, setAddView] = useState(false);
  const [addViewStates, setAddViewStates] = useState(null);
  const [rows, setRows] = useState([]);
  const [items, setItems] = useState([]);
  const [stakeholders, setStakeholders] = useState([]);
  const [showFilters, setShowFilters] = useState();
  const [result, setResult] = useState(0);
  const [resultCase, setResultCase] = useState(0);
  const [warehouses, setWarehouses] = useState([]);
  const [destinations, setDestinations] = useState([]);

  function retrieveWarehouse() {
    retrieveAllWarehouseApi()
      .then((response) => {
        setWarehouses(response?.data);
        setDestinations(response?.data);
      })
      .catch((error) => console.log(error.response?.data));
  }

  useEffect(() => {
    function subtract(data) {
      const purchaseSum = data
        .filter((item) => item.operationType === "PO")
        .map((item) => item?.order?.volume)
        .reduce((acc, currentValue) => acc + currentValue, 0);

      const saleSum = data
        .filter((item) => item.operationType === "SO")
        .map((item) => item?.order?.volume)
        .reduce((acc, currentValue) => acc + currentValue, 0);

      return purchaseSum - saleSum;
    }
    function subtractCase(data) {
      const purchaseSum = data
        .filter((item) => item.operationType === "PO")
        .map((item) => item?.order?.numberOfCases)
        .reduce((acc, currentValue) => acc + currentValue, 0);

      const saleSum = data
        .filter((item) => item.operationType === "SO")
        .map((item) => item?.order?.numberOfCases)
        .reduce((acc, currentValue) => acc + currentValue, 0);

      return purchaseSum - saleSum;
    }
    setResult(subtract(rows));
    setResultCase(subtractCase(rows));
  }, [rows]);

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const [filterTrade, setFilterTrade] = useState({
    id: 0,
    recordStatusList: ["ACTIVE"],
  });

  useEffect(() => retrieveTrades(), []);

  function retrieveTrades() {
    retrieveAllTradeRecordsApi()
      .then((response) => {
        setRows(response?.data);
      })
      .catch((error) => console.log(error.response?.data));
  }

  function retrieveItems() {
    retrieveAllItemsApi()
      .then((response) => {
        setItems(response?.data);
      })
      .catch((error) => console.log(error.response?.data));
  }

  function retrieveStakeholders() {
    Promise.all([
      retrieveAllStakeholdersApi("VENDOR"),
      retrieveAllStakeholdersApi("CUSTOMER")
    ])
      .then(([vendorResponse, customerResponse]) => {
        setStakeholders([
          ...vendorResponse?.data,
          ...customerResponse?.data
        ]);
      })
      .catch((error) => console.log(error.response?.data));
  }

  const [editTrade, setEditTrade] = useState({
    visible: false,
    data: null,
    index: null,
  });

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelected = rows.map((n) => n.id);
      setSelected(newSelected);
      setSelectedData(rows);
      return;
    }else{
      setSelected([]);
      setSelectedData([]);
    }
  };

  const handleClick = (event, name, row) => {
    const selectedIndex = selected.indexOf(name);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1),
      );
    }

    const selectedDataIndex = selectedData.indexOf(row);
    let newSelectedData = [];

    if (selectedDataIndex === -1) {
      newSelectedData = newSelectedData.concat(selectedData, row);
    } else if (selectedDataIndex === 0) {
      newSelectedData = newSelectedData.concat(selectedData.slice(1));
    } else if (selectedDataIndex === selectedData.length - 1) {
      newSelectedData = newSelectedData.concat(selectedData.slice(0, -1));
    } else if (selectedDataIndex > 0) {
      newSelectedData = newSelectedData.concat(
        selectedData.slice(0, selectedDataIndex),
        selectedData.slice(selectedDataIndex + 1),
      );
    }
    setSelectedData(newSelectedData);

    setSelected(newSelected);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleChangeDense = (event) => {
    setDense(event.target.checked);
  };

  const isSelected = (name) => selected.indexOf(name) !== -1;

  const [componentsState, setComponentsState] = useState({
    item: {
      dropdownOpen: false,
      inputValue: "",
    },    
    poStakeholder: {
      dropdownOpen: false,
      inputValue: "",
    },
    soStakeholder: {
      dropdownOpen: false,
      inputValue: "",
    },
    destination: {
      dropdownOpen: false,
      inputValue: "",
    },
    warehouse: {
      dropdownOpen: false,
      inputValue: "",
    },
  });

  const updateComponentState = (componentKey, newState) => {
    setComponentsState((prevState) => ({
      ...prevState,
      [componentKey]: {
        ...prevState[componentKey],
        ...newState,
      },
    }));
  };

  // Avoid a layout jump when reaching the last page with empty rows.
  const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - rows.length) : 0;

  const visibleRows = React.useMemo(
    () =>
      stableSort(rows, getComparator(order, orderBy)).slice(
        page * rowsPerPage,
        page * rowsPerPage + rowsPerPage,
      ),
    [order, orderBy, page, rowsPerPage, rows, editTrade],
  );

  return (
    <div>
      {addView ? (
        <AddTrade
          setAddViewStates={setAddViewStates}
          addViewStates={addViewStates}
          rows={rows}
          setRows={setRows}
          setAddView={setAddView}
        />
      ) : editTrade.visible ? (
        <EditTrade
          rows={rows}
          setRows={setRows}
          setAddView={setAddView}
          editTrade={editTrade}
          setEditTrade={setEditTrade}
        />
      ) : (
        <>
          {showFilters && (
            <Box
              component="form"
              sx={{
                "& .MuiTextField-root": { m: 1, width: "25ch" },
              }}
              noValidate
              autoComplete="off"
            >
              <Grid
                container
                spacing={1}
                justifyContent="flex-start"
                alignItems="center"
              >
                <TextField
                  label="Operation Type"
                  type="search"
                  id="outlined-select-operation-type"
                  select
                  SelectProps={{
                    multiple: true,
                    value: filterTrade.operationTypeList || [],
                    onChange: (e) => {
                      setFilterTrade({
                        ...filterTrade,
                        operationTypeList: e.target.value,
                      });
                    },
                  }}
                >
                  {operationType.map((option) => (
                    <MenuItem key={option.value} value={option.value}>
                      {option.label}
                    </MenuItem>
                  ))}
                </TextField>

                <TextField
                  id="date"
                  type="date"
                  label="Operation Date"
                  value={filterTrade?.operationDate || ""}
                  inputProps={{ min: "1999-01-01", max: "2999-01-01" }}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  onKeyDown={(e) => e.preventDefault()}
                  onChange={(e) =>
                    setFilterTrade({
                      ...filterTrade,
                      operationDate: e.target.value,
                    })
                  }
                />       

                <Autocomplete
                  multiple
                  id="outlined-select-item"
                  disableClearable
                  open={componentsState.item.dropdownOpen}
                  onOpen={() => updateComponentState("item", { dropdownOpen: true })}
                  inputValue={componentsState.item.inputValue}
                  onInputChange={(event, newInputValue, reason) => {
                    if (reason === "input") {
                      updateComponentState("item", { inputValue: newInputValue });
                    }
                  }}
                  options={items}
                  getOptionLabel={(option) =>
                    `${option?.id || ""} - ${option?.buyingGrade || ""} - ${
                      option?.crop || ""} - ${
                      option?.origin || ""
                    }`
                  }
                  value={items.filter((item) =>
                    filterTrade?.itemIdList?.includes(item.id),
                  )}
                  onChange={(event, newValue) => {
                    setFilterTrade({
                      ...filterTrade,
                      itemIdList: newValue.map((item) => item.id),
                    });
                  }}
                  renderOption={(props, option, { selected }) => (
                    <li
                      {...props}
                      style={{
                        ...props.style,
                        backgroundColor: selected ? "lightblue" : undefined,
                        fontWeight: selected ? 700 : 400,
                      }}
                    >
                      {`${option?.id || ""} - ${option?.buyingGrade || ""} - ${
                        option?.crop || ""} - ${option?.origin || ""}`}
                    </li>
                  )}
                  renderInput={(params) => {
                    const label =
                    filterTrade?.itemIdList?.length > 0
                        ? `Items Selected (${filterTrade?.itemIdList?.length})`
                        : "Item";
          
                    return (
                      <TextField
                        {...params}
                        label={label}
                        type="search"
                        value={componentsState.item.inputValue}
                        onChange={(e) =>
                          updateComponentState("item", { inputValue: e.target.value })
                        }
                        onMouseDown={retrieveItems}
                        InputProps={{
                          ...params.InputProps,
                          style: { height: 42 },
                        }}
                        onBlur={() =>
                          updateComponentState("item", { dropdownOpen: false })
                        }
                      />
                    );
                  }}
                  renderTags={() => null}
                />
                <TextField
                  label="PO Company"
                  type="search"
                  id="outlined-select-company"
                  select
                  SelectProps={{
                    multiple: true,
                    value: filterTrade.poCompanyList || [],
                    onChange: (e) => {
                      setFilterTrade({
                        ...filterTrade,
                        poCompanyList: e.target.value,
                      });
                    },
                  }}
                >
                  {companies.map((option) => (
                    <MenuItem key={option.value} value={option.value}>
                      {option.label}
                    </MenuItem>
                  ))}
                </TextField>

                <TextField
                  label="SO Company"
                  type="search"
                  id="outlined-select-company"
                  select
                  SelectProps={{
                    multiple: true,
                    value: filterTrade.soCompanyList || [],
                    onChange: (e) => {
                      setFilterTrade({
                        ...filterTrade,
                        soCompanyList: e.target.value,
                      });
                    },
                  }}
                >
                  {companies.map((option) => (
                    <MenuItem key={option.value} value={option.value}>
                      {option.label}
                    </MenuItem>
                  ))}
                </TextField>

                <Autocomplete
                  multiple
                  id="outlined-select-warehouse"
                  disableClearable
                  open={componentsState.warehouse.dropdownOpen}
                  onOpen={() => updateComponentState("warehouse", { dropdownOpen: true })}
                  inputValue={componentsState.warehouse.inputValue}
                  onInputChange={(event, newInputValue, reason) => {
                    if (reason === "input") {
                      updateComponentState("warehouse", { inputValue: newInputValue });
                    }
                  }}
                  options={warehouses}
                  getOptionLabel={(option) =>
                    `${option?.id || ""} - ${option?.code || ""} - ${
                      option?.name || ""}`
                  }
                  value={warehouses.filter((warehouse) =>
                    filterTrade?.warehouseList?.includes(warehouse.name),
                  )}
                  onChange={(event, newValue) => {
                    setFilterTrade({
                      ...filterTrade,
                      warehouseList: newValue.map((warehouse) => warehouse.name),
                    });
                  }}
                  renderOption={(props, option, { selected }) => (
                    <li
                      {...props}
                      style={{
                        ...props.style,
                        backgroundColor: selected ? "lightblue" : undefined,
                        fontWeight: selected ? 700 : 400,
                      }}
                    >
                      {`${option?.id || ""} - ${option?.code || ""} - ${
                      option?.name || ""}`}
                    </li>
                  )}
                  renderInput={(params) => {
                    const label =
                    filterTrade?.warehouseList?.length > 0
                        ? `Warehouses Selected (${filterTrade?.warehouseList?.length})`
                        : "Warehouse";
          
                    return (
                      <TextField
                        {...params}
                        label={label}
                        type="search"
                        value={componentsState.warehouse.inputValue}
                        onChange={(e) =>
                          updateComponentState("warehouse", { inputValue: e.target.value })
                        }
                        onMouseDown={retrieveWarehouse}
                        InputProps={{
                          ...params.InputProps,
                          style: { height: 42 },
                        }}
                        onBlur={() =>
                          updateComponentState("warehouse", { dropdownOpen: false })
                        }
                      />
                    );
                  }}
                  renderTags={() => null}
                />
                
                <Autocomplete
                  multiple
                  id="outlined-select-destination"
                  disableClearable
                  open={componentsState.destination.dropdownOpen}
                  onOpen={() => updateComponentState("destination", { dropdownOpen: true })}
                  inputValue={componentsState.destination.inputValue}
                  onInputChange={(event, newInputValue, reason) => {
                    if (reason === "input") {
                      updateComponentState("destination", { inputValue: newInputValue });
                    }
                  }}
                  options={destinations}
                  getOptionLabel={(option) =>
                    `${option?.id || ""} - ${option?.code || ""} - ${
                      option?.name || ""}`
                  }
                  value={destinations.filter((destination) =>
                    filterTrade?.destinationList?.includes(destination.name),
                  )}
                  onChange={(event, newValue) => {
                    setFilterTrade({
                      ...filterTrade,
                      destinationList: newValue.map((destination) => destination.name),
                    });
                  }}
                  renderOption={(props, option, { selected }) => (
                    <li
                      {...props}
                      style={{
                        ...props.style,
                        backgroundColor: selected ? "lightblue" : undefined,
                        fontWeight: selected ? 700 : 400,
                      }}
                    >
                      {`${option?.id || ""} - ${option?.code || ""} - ${
                      option?.name || ""}`}
                    </li>
                  )}
                  renderInput={(params) => {
                    const label =
                    filterTrade?.destinationList?.length > 0
                        ? `Destinations Selected (${filterTrade?.destinationList?.length})`
                        : "Destination";
          
                    return (
                      <TextField
                        {...params}
                        label={label}
                        type="search"
                        value={componentsState.destination.inputValue}
                        onChange={(e) =>
                          updateComponentState("destination", { inputValue: e.target.value })
                        }
                        onMouseDown={retrieveWarehouse}
                        InputProps={{
                          ...params.InputProps,
                          style: { height: 42 },
                        }}
                        onBlur={() =>
                          updateComponentState("destination", { dropdownOpen: false })
                        }
                      />
                    );
                  }}
                  renderTags={() => null}
                />
                
                <TextField
                  id="outlined-number"
                  label="PO Buisness Partner"
                  value={filterTrade?.destination || ""}
                  onChange={(e) =>
                    setFilterTrade({
                      ...filterTrade,
                      destination: e.target.value,
                    })
                  }
                />

                <TextField
                  id="outlined-number"
                  label="SO Business Partner"
                  value={filterTrade?.destination || ""}
                  onChange={(e) =>
                    setFilterTrade({
                      ...filterTrade,
                      destination: e.target.value,
                    })
                  }
                />

                <TextField
                  id="outlined-number"
                  label="Number Of Cartons"
                  value={filterTrade?.numberOfCases || ""}
                  type="number"
                  onChange={(e) =>
                    setFilterTrade({
                      ...filterTrade,
                      numberOfCases: e.target.value,
                    })
                  }
                />
                <TextField
                  id="outlined-number"
                  label="Net Weight Per Carton"
                  value={filterTrade?.netWeightPerCase || ""}
                  type="number"
                  onChange={(e) =>
                    setFilterTrade({
                      ...filterTrade,
                      netWeightPerCase: e.target.value,
                    })
                  }
                />

                <TextField
                  id="outlined-number"
                  value={filterTrade?.volume || ""}
                  label="Volume"
                  type="number"
                  onChange={(e) =>
                    setFilterTrade({
                      ...filterTrade,
                      volume: e.target.value,
                    })
                  }
                />
                <TextField
                  label="Volume Unit"
                  type="search"
                  id="outlined-select-volume-unit"
                  select
                  SelectProps={{
                    multiple: true,
                    value: filterTrade.volumeUnitList || [],
                    onChange: (e) => {
                      setFilterTrade({
                        ...filterTrade,
                        volumeUnitList: e.target.value,
                      });
                    },
                  }}
                >
                  {units.map((option) => (
                    <MenuItem key={option.value} value={option.value}>
                      {option.label}
                    </MenuItem>
                  ))}
                </TextField>

                <TextField
                  id="outlined-number"
                  label="First Price"
                  value={filterTrade?.firstOfferPrice || ""}
                  type="number"
                  onChange={(e) =>
                    setFilterTrade({
                      ...filterTrade,
                      firstOfferPrice: e.target.value,
                    })
                  }
                />

                <TextField
                  id="outlined-number"
                  label="Final Price"
                  value={filterTrade?.finalPrice || ""}
                  type="number"
                  onChange={(e) =>
                    setFilterTrade({
                      ...filterTrade,
                      finalPrice: e.target.value,
                    })
                  }
                />
            
                <TextField
                  id="outlined-number"
                  label="SAP PO Number"
                  value={filterTrade?.purchaseOrderNumber || ""}
                  type="search"
                  onChange={(e) =>
                    setFilterTrade({
                      ...filterTrade,
                      purchaseOrderNumber: e.target.value,
                    })
                  }
                />
                <TextField
                  id="outlined-number"
                  label="SAP SO Number"
                  value={filterTrade?.salesOrderNumber || ""}
                  type="search"
                  onChange={(e) =>
                    setFilterTrade({
                      ...filterTrade,
                      salesOrderNumber: e.target.value,
                    })
                  }
                />
                <TextField
                  id="outlined-number"
                  label="SI Id"
                  value={filterTrade?.shippingInstructionId || ""}
                  type="number"
                  onChange={(e) =>
                    setFilterTrade({
                      ...filterTrade,
                      shippingInstructionId: e.target.value,
                    })
                  }
                />
            
                <TextField
                  label="Record Status"
                  type="search"
                  id="outlined-select-record-status"
                  select
                  SelectProps={{
                    multiple: true,
                    value: filterTrade.recordStatusList || [],
                    onChange: (e) => {
                      setFilterTrade({
                        ...filterTrade,
                        recordStatusList: e.target.value,
                      });
                    },
                  }}
                >
                  {recordStatus.map((option) => (
                    <MenuItem key={option.value} value={option.value}>
                      {option.label}
                    </MenuItem>
                  ))}
                </TextField>
              </Grid>
              <br></br>
              <Grid
                container
                spacing={2}
                justifyContent="flex-start"
                alignItems="center"
              >
                <Grid item xs={3} sm={1}>
                  <Button
                    disableElevation
                    variant="contained"
                    fullWidth
                    onClick={() => {
                      retrieveFilteredRecordsApi(filterTrade)
                        .then((response) => {
                          setRows(response?.data);
                        })
                        .catch((error) => {
                          console.log(error?.response?.data);
                        });
                    }}
                  >
                    Search
                  </Button>
                </Grid>
                <Grid item xs={3} sm={1}>
                  <Button
                    disableElevation
                    variant="outlined"
                    fullWidth
                    size="small"
                    onClick={() => {
                      setFilterTrade({
                        id: 0,
                        operationTypeList: [],
                        statusList: [],
                        orderTypeList: [],
                        offerIdList: [],
                        companyList: [],
                        approvalStatusList: [],
                        orderStatusList: [],
                        itemIdList: [],
                        stakeholderIdList: [],
                        operationDate: null,
                        statusDeadline: null,
                        invoiceDate: null,
                        billOfLadingDate: null,
                        shipmentDate: null,
                        customerList: [],
                        firstSampleId: "",
                        secondSampleId: "",
                        costId: "",
                        warehouse: "",
                        storage: "",
                        firstSampleDate: null,
                        secondSampleDate: null,
                        firstSampleStatusList: [],
                        secondSampleStatusList: [],
                        paymentTerm: "",
                        paymentTermList: [],
                        incotermList: [],
                        packagingList: [],
                        orderDate: null,
                        validityDate: null,
                        volumeUnitList: [],
                        remark: "",
                        documentStatusList: [],
                        tobaccoCode: "",
                        salesGrade: "",
                        buyingGrade: "",
                        customerGrade: "",
                        internalGrade:"",
                        crop: null,
                        nicotine: null,
                        sugar: null,
                        volume: null,
                        numberOfCases: null,
                        netWeightPerCase: null,
                        firstOfferPrice: null,
                        finalPrice: null,
                        totalAmount: null,
                        purchaseOrderNumber: null,
                        salesOrderNumber: null,
                        shippingInstructionId: null,
                        billOfLadingNumber: null,
                        quantityRemainingInNetCase: null,
                        quantityRemainingInNetKg: null,
                        originList: [],
                        typeList: [],
                        leafFormList: [],
                        colorList: [],
                        storageList: [],
                        warehouseIdList: [],
                        destinationList:[],
                        recordStatusList: ["ACTIVE"],
                      });
                    }}
                  >
                    Clear Filters
                  </Button>
                </Grid>
                <Grid item xs={3} sm="auto">
                  <Button
                    disableElevation
                    variant="outlined"
                    fullWidth
                    onClick={() => setAddView(true)}
                  >
                    Add
                  </Button>
                </Grid>
                <Grid item xs="auto">
                  <ExportExcel excelData={selectedData} fileName={"HistoryExport"} buttonLabel="Export Table"/>
                </Grid>
              </Grid>
              <br></br>
            </Box>
          )}

          <Box sx={{ width: "100%" }}>
            <Paper sx={{ width: "100%", mb: 2 }}>
              <EnhancedTableToolbar
                numSelected={selected.length}
                showFilters={showFilters}
                setShowFilters={setShowFilters}
                selected={selected}
                selectedData={selectedData}
              />
              <TableContainer style={{ maxHeight: 700 }}>
                <Table
                  stickyHeader
                  aria-label="sticky table"
                  striped="columns"
                  style={{ width: "max-content" }}
                  sx={{
                    minWidth: 750,
                    "& .MuiTableCell-root": {
                      borderRight: "1px solid rgba(224, 224, 224, 1)",
                    },
                    "& .MuiTableCell-head": {
                      backgroundColor: "#20315d",
                      color: "white",
                      borderRight: "1px solid rgba(49, 64, 128, 1)",
                    },
                  }}
                  aria-labelledby="tableTitle"
                  size={dense ? "small" : "medium"}
                >
                  <EnhancedTableHead
                    numSelected={selected.length}
                    order={order}
                    orderBy={orderBy}
                    onSelectAllClick={handleSelectAllClick}
                    onRequestSort={handleRequestSort}
                    rowCount={rows.length}
                  />
                  <TableBody>
                    {visibleRows.map((row, index) => {
                      const isItemSelected = isSelected(row.id);
                      const labelId = `enhanced-table-checkbox-${index}`;

                      return (
                        <TableRow
                          style={
                            isSelected(row.id) ? { background: "#d7e7fc" }
                              :index % 2
                              ? { background: "#f0f0f0" }
                              : { background: "white" }
                          }
                          hover
                          onClick={(event) => handleClick(event, row.id, row)}
                          role="checkbox"
                          aria-checked={isItemSelected}
                          tabIndex={-1}
                          key={row.id}
                          selected={isItemSelected}
                          sx={{ cursor: "pointer" }}
                        >
                          <TableCell padding="checkbox">
                            <Checkbox
                              color="primary"
                              checked={isItemSelected}
                              inputProps={{
                                "aria-labelledby": labelId,
                              }}
                            />
                          </TableCell>
                          <TableCell padding="normal">
                            <Button
                              onClick={(e) => {
                                setEditTrade({
                                  visible: true,
                                  data: row,
                                  index: index,
                                });
                                e.preventDefault();
                              }}
                            >
                              Edit
                            </Button>
                          </TableCell>
                          <TableCell align="center" sx={{ position: 'sticky', left: 0, background: 'inherit', zIndex: 1 }}>{row?.id}</TableCell>
                          <TableCell align="center" sx={{ position: 'sticky', left: 50, background: 'inherit', zIndex: 1 }}>
                          {row?.operationType}
                          </TableCell>
                          <TableCell
                            align="center"
                            sx={{ position: 'sticky', left: 150, background: 'inherit', zIndex: 1 }}
                          >
                            {row?.shippingInstruction &&
                              `${row?.shippingInstruction?.shipmentRequest?.shipmentRequestType} ${row?.shippingInstruction?.finalShippingInstructionNumber || ""}` ||
                              (row?.operationType === "PO" && row?.order?.purchaseOrderNumber) ||
                              (row?.operationType === "SO" && row?.order?.salesOrderNumber) ||
                              (row?.operationType === "SR" && row?.shipmentRequest?.shipmentRequestType) ||
                              ""
                            }
                          </TableCell>
                          <TableCell align="center">
                            {(row?.shippingInstruction?.shippingInstructionDate
                              ? format(
                                  new Date(row?.shippingInstruction?.shippingInstructionDate),
                                  "dd/MM/yyyy",
                                )
                              : "") || (row?.shipmentRequest?.timestamp
                                ? format(
                                    new Date(row?.shipmentRequest?.timestamp),
                                    "dd/MM/yyyy",
                                  )
                                : "") || (row?.order?.operationDate
                                  ? format(
                                      new Date(row?.order?.operationDate),
                                      "dd/MM/yyyy",
                                    )
                                  : "")}
                          </TableCell>
                          <TableCell align="center">
                            {row?.shippingInstruction?.shipmentRequest?.purchaseOrder?.company  ||
                            row?.shipmentRequest?.purchaseOrder?.company ||
                            ((row?.operationType==="PO" || row?.operationType==="PO CANCELLATION") && row?.order?.company)}
                           </TableCell>
                          <TableCell align="center">
                            {row?.shippingInstruction?.shipmentRequest?.salesOrder?.company ||
                            row?.shipmentRequest?.salesOrder?.company ||
                            ((row?.operationType==="SO" || row?.operationType==="SO CANCELLATION") && row?.order?.company)}
                          </TableCell>
                          <TableCell align="center">
                            {row?.shippingInstruction?.shipmentRequest?.purchaseOrder?.offer?.item?.id || row?.shippingInstruction?.shipmentRequest?.salesOrder?.offer?.item?.id ||
                            row?.shipmentRequest?.purchaseOrder?.offer?.item?.id || row?.shipmentRequest?.salesOrder?.offer?.item?.id ||
                            row?.order?.offer?.item?.id}
                          </TableCell>
                          <TableCell align="center">
                            {row?.shippingInstruction?.shipmentRequest?.purchaseOrder?.offer?.item?.buyingGrade || row?.shippingInstruction?.shipmentRequest?.salesOrder?.offer?.item?.buyingGrade ||
                            row?.shipmentRequest?.purchaseOrder?.offer?.item?.buyingGrade || row?.shipmentRequest?.salesOrder?.offer?.item?.buyingGrade ||
                            row?.order?.offer?.item?.buyingGrade || row?.order?.offer?.item?.buyingGrade}
                          </TableCell>
                          <TableCell align="center">
                            {row?.shippingInstruction?.shipmentRequest?.purchaseOrder?.offer?.salesGrade || row?.shippingInstruction?.shipmentRequest?.salesOrder?.offer?.salesGrade ||
                            row?.shipmentRequest?.purchaseOrder?.offer?.salesGrade || row?.shipmentRequest?.salesOrder?.offer?.salesGrade ||
                            row?.order?.offer?.salesGrade || row?.order?.offer?.salesGrade}
                          </TableCell>
                          <TableCell align="center">
                            {row?.shippingInstruction?.shipmentRequest?.purchaseOrder?.offer?.item?.crop || row?.shippingInstruction?.shipmentRequest?.salesOrder?.offer?.item?.crop ||
                            row?.shipmentRequest?.purchaseOrder?.offer?.item?.crop || row?.shipmentRequest?.salesOrder?.offer?.item?.crop ||
                            row?.order?.offer?.item?.crop || row?.order?.offer?.item?.crop}
                          </TableCell>
                          <TableCell align="center">
                            {row?.shippingInstruction?.shipmentRequest?.purchaseOrder?.offer?.item?.origin || row?.shippingInstruction?.shipmentRequest?.salesOrder?.offer?.item?.origin ||
                            row?.shipmentRequest?.purchaseOrder?.offer?.item?.origin || row?.shipmentRequest?.salesOrder?.offer?.item?.origin ||
                            row?.order?.offer?.item?.origin || row?.order?.offer?.item?.origin}
                          </TableCell>
                          <TableCell align="center">
                            {row?.shippingInstruction?.warehouse || row?.shipmentRequest?.warehouse}
                          </TableCell>
                          <TableCell align="center">
                            {row?.shippingInstruction?.destination || row?.shipmentRequest?.destination}
                          </TableCell>
                          <TableCell align="center">
                            {row?.shippingInstruction?.shipmentRequest?.purchaseOrder?.offer?.stakeholder?.name ||
                            row?.shipmentRequest?.purchaseOrder?.offer?.stakeholder?.name ||
                            (row?.operationType==="PO" && row?.order?.offer?.stakeholder?.name)}
                          </TableCell>
                          <TableCell align="center">
                            {row?.shippingInstruction?.shipmentRequest?.salesOrder?.offer?.stakeholder?.name ||
                            row?.shipmentRequest?.salesOrder?.offer?.stakeholder?.name ||
                            (row?.operationType==="SO" && row?.order?.offer?.stakeholder?.name)}
                          </TableCell>
                          <TableCell align="center">
                            {row?.shippingInstruction?.pointOfLoading || row?.shipmentRequest?.portOfLoading ||
                            row?.order?.portOfLoading}
                          </TableCell>
                          <TableCell align="center">
                            {row?.shippingInstruction?.pointOfUnloading || row?.shipmentRequest?.portOfDischarge ||
                            row?.order?.portOfDischarge}
                          </TableCell>
                          <TableCell align="center">
                          {row?.shippingInstruction?.shipmentRequest?.numberOfCases || row?.shipmentRequest?.numberOfCases ||
                            row?.order?.numberOfCases}
                          </TableCell>
                          <TableCell align="center">
                          {row?.shippingInstruction?.shipmentRequest?.netWeightPerCase || row?.shipmentRequest?.netWeightPerCase ||
                            row?.order?.netWeightPerCase}
                          </TableCell>
                          <TableCell align="center">
                            {row?.shippingInstruction?.shipmentRequest?.shipmentQuantity ? `${new Intl.NumberFormat('de-DE', { style: 'decimal', minimumFractionDigits: 2 }).format(row?.shippingInstruction?.shipmentRequest?.shipmentQuantity)} ${row?.shippingInstruction?.shipmentRequest?.shipmentQuantityUnit}`: '' || 
                            row?.shipmentRequest?.shipmentQuantity ? `${new Intl.NumberFormat('de-DE', { style: 'decimal', minimumFractionDigits: 2 }).format(row?.shipmentRequest?.shipmentQuantity)} ${row?.shipmentRequest?.shipmentQuantityUnit}`: '' ||
                            row?.order?.volume ? `${new Intl.NumberFormat('de-DE', { style: 'decimal', minimumFractionDigits: 2 }).format(row?.order?.volume)} ${row?.order?.volumeUnit}`: ''}
                            </TableCell>
                          <TableCell align="center">{row?.shippingInstruction?.shipmentRequest?.purchaseOrder?.id || row?.shipmentRequest?.purchaseOrder?.id || ((row?.operationType==="PO" || row?.operationType==="PO CANCELLATION") && row?.order?.id)}</TableCell>
                          <TableCell align="center">{row?.shippingInstruction?.shipmentRequest?.salesOrder?.id || row?.shipmentRequest?.salesOrder?.id || ((row?.operationType==="SO" || row?.operationType==="SO CANCELLATION") && row?.order?.id)}</TableCell>
                          <TableCell align="center">{row?.shippingInstruction?.shipmentRequest?.id || row?.shipmentRequest?.id}</TableCell>
                          <TableCell align="center">{row?.shippingInstruction?.id}</TableCell>
                          <TableCell align="center">{row?.shippingInstruction?.actualTimeOfArrival ? format(new Date(row?.shippingInstruction?.actualTimeOfArrival), 'dd-MMM-yyyy'): ''}</TableCell>
                          <TableCell align="center">{row?.remark}</TableCell>
                          <TableCell align="center">{row?.timestamp ? format(new Date(row.timestamp), 'dd/MM/yyyy HH:mm'): ''}</TableCell>
                          <TableCell align="center">{row?.creator}</TableCell>
                          <TableCell align="center">
                            {row?.recordStatus}
                          </TableCell>
                        </TableRow>
                      );
                    })}
                    {emptyRows > 0 && (
                      <TableRow
                        style={{
                          height: (dense ? 33 : 53) * emptyRows,
                        }}
                      >
                        <TableCell colSpan={6} />
                      </TableRow>
                    )}
                  </TableBody>
                </Table>
              </TableContainer>
              <TablePagination
                rowsPerPageOptions={[5, 10, 25, 50]}
                component="div"
                count={rows.length}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
              />
            </Paper>
            <Stack direction="row" spacing={1} alignItems={"center"}>
              <FormControlLabel
                control={
                  <Switch checked={dense} onChange={handleChangeDense} />
                }
                label="Dense padding"
              />
              <Typography variant="subtitle2">
                Quantity remaining in net kg: {result}
              </Typography>
              <Typography variant="subtitle2">
                Quantity remaining in net case: {resultCase}
              </Typography>
            </Stack>
          </Box>
        </>
      )}
    </div>
  );
}

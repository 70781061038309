import {
  Autocomplete,
  Box,
  Button,
  Chip,
  Grid,
  MenuItem,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { retrieveAllStakeholdersApi } from "../../../components/api/StakeholderApiService";
import { createWarehouseApi } from "../../../components/api/WarehouseApiService";
import { recordStatus, storagePricingBy } from "../../../utils/menu-items";
import toast from "react-hot-toast";

const AddWarehouse = (props) => {
  const navigate = useNavigate();
  const { setAddViewStates, addViewStates, rows, setRows, setAddView } = props;
  const [vendors, setVendors] = useState([]);
  const [selectedVendor, setSelectedVendor] = useState(null);
  const [vendor, setVendor] = useState([]);

  function retrieveVendors() {
    retrieveAllStakeholdersApi("VENDOR")
      .then((response) => {
        setVendors(response?.data);
      })
      .catch((error) => console.log(error.response?.data));
  }

  useEffect(
    () =>
      setAddViewStates({
        ...addViewStates,
        shippedAmountUnit: "KG",
        unshippedAmountUnit: "KG",
      }),
    [],
  );

  return (
    <>
      <Stack direcion="column">
        <Typography variant="body1" mb={2}>
          Create New Warehouse Inventory Record
        </Typography>
        <Grid container xs={12} spacing="8px">
          <Grid item xs={12} sm={2}>
            <Typography variant="body2">Name</Typography>
            <TextField
              variant="outlined"
              size="small"
              fullWidth
              onChange={(e) =>
                setAddViewStates({
                  ...addViewStates,
                  name: e.target.value,
                })
              }
            />
          </Grid>
          <Grid item xs={12} sm={2}>
            <Typography variant="body2">Code</Typography>
            <TextField
              variant="outlined"
              size="small"
              fullWidth
              onChange={(e) =>
                setAddViewStates({
                  ...addViewStates,
                  code: e.target.value,
                })
              }
            />
          </Grid>

          <Grid item xs={12} sm={2}>
            <Typography variant="body2">Contact Person</Typography>
            <Autocomplete
              options={vendors}
              getOptionLabel={(option) =>
                `${option?.id || ""} - ${option?.name || ""} - ${
                  option?.contactPerson || ""
                }`
              }
              value={selectedVendor || vendor}
              onChange={(event, newValue) => {
                setSelectedVendor(newValue);
                setAddViewStates({
                  ...addViewStates,
                  contactPersonId: newValue ? newValue.id : null,
                });
              }}
              onOpen={retrieveVendors}
              isOptionEqualToValue={(option, value) => option.id === value.id}
              renderInput={(params) => (
                <TextField
                  {...params}
                  variant="outlined"
                  size="small"
                  fullWidth
                />
              )}
              renderTags={() => null}
            />
            {selectedVendor && (
              <Box mt={2}>
                <Chip
                  label={`${selectedVendor?.id || ""} - ${
                    selectedVendor?.name || ""
                  }`}
                  onDelete={() => setSelectedVendor(null)}
                  style={{ marginBottom: "5px" }}
                />
                <br></br>
                {selectedVendor?.contactPerson && (
                  <>
                    <Chip
                      label={`${selectedVendor.contactPerson}`}
                      style={{ marginBottom: "5px" }}
                    />
                    <br />
                  </>
                )}
              </Box>
            )}
          </Grid>

          <Grid item xs={12} sm={2}>
            <Typography variant="body2">Storage Pricing By</Typography>
            <TextField
              id="outlined-select-storage-pricing-by"
              select
              variant="outlined"
              size="small"
              fullWidth
              onChange={(e) =>
                setAddViewStates({
                  ...addViewStates,
                  storagePricingBy: e.target.value,
                })
              }
            >
              {storagePricingBy.map((option) => (
                <MenuItem key={option.value} value={option.value}>
                  {option.label}
                </MenuItem>
              ))}
            </TextField>
          </Grid>

          <Grid item xs={12} sm={2}>
            <Typography variant="body2">Record Status</Typography>
            <TextField
              id="outlined-select-status"
              select
              variant="outlined"
              size="small"
              fullWidth
              label={"ACTIVE"}
              onChange={(e) =>
                setAddViewStates({
                  ...addViewStates,
                  recordStatus: e.target.value,
                })
              }
            >
              {recordStatus.map((option) => (
                <MenuItem key={option.value} value={option.value}>
                  {option.label}
                </MenuItem>
              ))}
            </TextField>
          </Grid>
        </Grid>
        <Stack direction="row" spacing={1} mt={1}>
          <Button variant="outlined" onClick={() => setAddView(false)}>
            Cancel
          </Button>
          <Button
            variant="contained"
            onClick={() => {
              const newId = Math.floor(Math.random() * 100000);
              setAddViewStates({ ...addViewStates, id: newId });
              setRows([addViewStates, ...rows]);

              createWarehouseApi(addViewStates)
                .then((response) => {
                  toast.success("Warehouse created succesfully.");
                  setAddView(false);
                  window.location.reload();
                  navigate("/warehouse-view");
                })
                .catch((error) => {
                  toast.error(error?.response?.data?.message);
                  console.log(error.response.data);
                });
            }}
          >
            Add
          </Button>
        </Stack>
      </Stack>
    </>
  );
};

export default AddWarehouse;

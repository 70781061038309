import React, { useState } from "react";
import { Button, Dialog, DialogTitle, DialogContent, Table, TableBody, TableCell, TableHead, TableRow, CircularProgress } from "@mui/material";
import { retrieveFilteredRecordsApi } from "../../../../components/api/TradeApiService";
import { format } from "date-fns";

const WarehouseInventoryHistoryButton = ({ warehouse, itemId }) => {
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [records, setRecords] = useState([]);

  const retrieveFilteredHistory = {
    id: 0,
    destinationList:[warehouse],
    warehouseList: [warehouse],
    itemIdList: [itemId],
    recordStatusList: ["ACTIVE"],
  };

  const handleOpen = async () => {
    setOpen(true);
    setLoading(true);
    try {
      const response = await retrieveFilteredRecordsApi(retrieveFilteredHistory);
      setRecords(response.data); 
    } catch (error) {
      console.error("Error fetching records:", error);
    } finally {
      setLoading(false);
    }
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <TableCell padding="normal">
      <Button onClick={handleOpen}>History</Button>

      <Dialog open={open} onClose={handleClose} maxWidth="lg" fullWidth>
        <DialogTitle>History Records</DialogTitle>
        <DialogContent>
          {loading ? (
            <CircularProgress />
          ) : (
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>Record ID</TableCell>
                  <TableCell>Operation </TableCell>
                  <TableCell></TableCell>
                  <TableCell>Issue Date</TableCell>
                  <TableCell>Item ID</TableCell>
                  <TableCell>Buying Grade</TableCell>
                  <TableCell>Sales Grade</TableCell>
                  <TableCell>Crop</TableCell>
                  <TableCell>Origin</TableCell>
                  <TableCell>Warehouse</TableCell>
                  <TableCell>Destination</TableCell>
                  <TableCell>PO BP</TableCell>
                  <TableCell>SO BP</TableCell>
                  <TableCell>Port Of Loading</TableCell>
                  <TableCell>Port Of Discharge</TableCell>
                  <TableCell>Number Of Cartons</TableCell>
                  <TableCell>Net Weight Per Carton</TableCell>
                  <TableCell>Volume</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {records.length > 0 ? (
                  records.map((record) => (
                    <TableRow key={record.id}>
                      <TableCell align="center">{record?.id}</TableCell>
                      <TableCell align="center">
                          {record?.operationType}
                          </TableCell>
                          <TableCell
                            align="center"
                          >
                            {record?.shippingInstruction &&
                              `${record?.shippingInstruction?.shipmentRequest?.shipmentRequestType} ${record?.shippingInstruction?.finalShippingInstructionNumber || ""}` ||
                              (record?.operationType === "PO" && record?.order?.purchaseOrderNumber) ||
                              (record?.operationType === "SO" && record?.order?.salesOrderNumber) ||
                              (record?.operationType === "SR" && record?.shipmentRequest?.shipmentRequestType) ||
                              ""
                            }
                          </TableCell>
                          <TableCell align="center">
                            {(record?.shippingInstruction?.shippingInstructionDate
                              ? format(
                                  new Date(record?.shippingInstruction?.shippingInstructionDate),
                                  "dd/MM/yyyy",
                                )
                              : "") || (record?.shipmentRequest?.timestamp
                                ? format(
                                    new Date(record?.shipmentRequest?.timestamp),
                                    "dd/MM/yyyy",
                                  )
                                : "") || (record?.order?.operationDate
                                  ? format(
                                      new Date(record?.order?.operationDate),
                                      "dd/MM/yyyy",
                                    )
                                  : "")}
                          </TableCell>
                          <TableCell align="center">
                            {record?.shippingInstruction?.shipmentRequest?.purchaseOrder?.offer?.item?.id || record?.shippingInstruction?.shipmentRequest?.salesOrder?.offer?.item?.id ||
                            record?.shipmentRequest?.purchaseOrder?.offer?.item?.id || record?.shipmentRequest?.salesOrder?.offer?.item?.id ||
                            record?.order?.offer?.item?.id}
                          </TableCell>
                          <TableCell align="center">
                            {record?.shippingInstruction?.shipmentRequest?.purchaseOrder?.offer?.item?.buyingGrade || record?.shippingInstruction?.shipmentRequest?.salesOrder?.offer?.item?.buyingGrade ||
                            record?.shipmentRequest?.purchaseOrder?.offer?.item?.buyingGrade || record?.shipmentRequest?.salesOrder?.offer?.item?.buyingGrade ||
                            record?.order?.offer?.item?.buyingGrade || record?.order?.offer?.item?.buyingGrade}
                          </TableCell>
                          <TableCell align="center">
                          {record?.shippingInstruction?.shipmentRequest?.purchaseOrder?.offer?.salesGrade || record?.shippingInstruction?.shipmentRequest?.salesOrder?.offer?.salesGrade ||
                            record?.shipmentRequest?.purchaseOrder?.offer?.salesGrade || record?.shipmentRequest?.salesOrder?.offer?.salesGrade ||
                            record?.order?.offer?.salesGrade || record?.order?.offer?.salesGrade}
                          </TableCell>
                          <TableCell align="center">
                            {record?.shippingInstruction?.shipmentRequest?.purchaseOrder?.offer?.item?.crop || record?.shippingInstruction?.shipmentRequest?.salesOrder?.offer?.item?.crop ||
                            record?.shipmentRequest?.purchaseOrder?.offer?.item?.crop || record?.shipmentRequest?.salesOrder?.offer?.item?.crop ||
                            record?.order?.offer?.item?.crop || record?.order?.offer?.item?.crop}
                          </TableCell>
                          <TableCell align="center">
                            {record?.shippingInstruction?.shipmentRequest?.purchaseOrder?.offer?.item?.origin || record?.shippingInstruction?.shipmentRequest?.salesOrder?.offer?.item?.origin ||
                            record?.shipmentRequest?.purchaseOrder?.offer?.item?.origin || record?.shipmentRequest?.salesOrder?.offer?.item?.origin ||
                            record?.order?.offer?.item?.origin || record?.order?.offer?.item?.origin}
                          </TableCell>
                          <TableCell align="center">
                            {record?.shippingInstruction?.warehouse || record?.shipmentRequest?.warehouse}
                          </TableCell>
                          <TableCell align="center">
                            {record?.shippingInstruction?.destination || record?.shipmentRequest?.destination}
                          </TableCell>
                          <TableCell align="center">
                            {record?.shippingInstruction?.shipmentRequest?.purchaseOrder?.offer?.stakeholder?.name ||
                            record?.shipmentRequest?.purchaseOrder?.offer?.stakeholder?.name ||
                            (record?.operationType==="PO" && record?.order?.offer?.stakeholder?.name)}
                          </TableCell>
                          <TableCell align="center">
                            {record?.shippingInstruction?.shipmentRequest?.salesOrder?.offer?.stakeholder?.name ||
                            record?.shipmentRequest?.salesOrder?.offer?.stakeholder?.name ||
                            (record?.operationType==="SO" && record?.order?.offer?.stakeholder?.name)}
                          </TableCell>
                          <TableCell align="center">
                            {record?.shippingInstruction?.pointOfLoading || record?.shipmentRequest?.portOfLoading ||
                            record?.order?.portOfLoading}
                          </TableCell>
                          <TableCell align="center">
                            {record?.shippingInstruction?.pointOfUnloading || record?.shipmentRequest?.portOfDischarge ||
                            record?.order?.portOfDischarge}
                          </TableCell>
                          <TableCell align="center">
                          {record?.shippingInstruction?.shipmentRequest?.numberOfCases || record?.shipmentRequest?.numberOfCases ||
                            record?.order?.numberOfCases}
                          </TableCell>
                          <TableCell align="center">
                          {record?.shippingInstruction?.shipmentRequest?.netWeightPerCase || record?.shipmentRequest?.netWeightPerCase ||
                            record?.order?.netWeightPerCase}
                          </TableCell>
                          <TableCell align="center">
                            {record?.shippingInstruction?.shipmentRequest?.shipmentQuantity ? `${new Intl.NumberFormat('de-DE', { style: 'decimal', minimumFractionDigits: 2 }).format(record?.shippingInstruction?.shipmentRequest?.shipmentQuantity)} ${record?.shippingInstruction?.shipmentRequest?.shipmentQuantityUnit}`: '' || 
                            record?.shipmentRequest?.shipmentQuantity ? `${new Intl.NumberFormat('de-DE', { style: 'decimal', minimumFractionDigits: 2 }).format(record?.shipmentRequest?.shipmentQuantity)} ${record?.shipmentRequest?.shipmentQuantityUnit}`: '' ||
                            record?.order?.volume ? `${new Intl.NumberFormat('de-DE', { style: 'decimal', minimumFractionDigits: 2 }).format(record?.order?.volume)} ${record?.order?.volumeUnit}`: ''}
                            </TableCell>
                    </TableRow>
                  ))
                ) : (
                  <TableRow>
                    <TableCell colSpan={3} align="center">
                      No records found
                    </TableCell>
                  </TableRow>
                )}
              </TableBody>
            </Table>
          )}
        </DialogContent>
      </Dialog>
    </TableCell>
  );
};

export default WarehouseInventoryHistoryButton;

import {
  Autocomplete,
  Button,
  Chip,
  Grid,
  MenuItem,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import Box from "@mui/material/Box";
import { format, parseISO } from "date-fns";
import React, { useState } from "react";
import toast from "react-hot-toast";
import { retrieveAllOffersApi } from "../../../components/api/OfferApiService";
import { updateOrderApi } from "../../../components/api/OrderApiService";
import { retrievePurposeFilteredSamplesApi } from "../../../components/api/SampleApiService";
import {
  currencies,
  incoterms,
  packagingTypes,
  portsOfDischarge,
  portsOfLoading,
  paymentTerm,
  recordStatus,
  sampleStatus,
  status,
  units,
  companies,
} from "../../../utils/menu-items";

const EditVendorOrder = (props) => {
  const { editVendorOrder, setEditVendorOrder } = props;

  function retrieveOffers() {
    retrieveAllOffersApi("VENDOR")
      .then((response) => {
        setOffers(response?.data);
      })
      .catch((error) => console.log(error.response?.data));
  }

  function retrieveFilteredSamples(samplePurpose) {
    retrievePurposeFilteredSamplesApi(samplePurpose)
      .then((response) => {
        setFilteredSamples(response?.data);
      })
      .catch((error) => console.log(error.response?.data));
  }
  const [offers, setOffers] = useState([]);
  const [filteredSamples, setFilteredSamples] = useState([]);
  const [selectedFirstSample, setSelectedFirstSample] = useState(
    editVendorOrder?.data?.firstSample || null,
  );

  const initialPaymentTerm = paymentTerm.find(
    (term) => term.value === editVendorOrder?.data?.paymentTerm,
  );
  const [selectedPaymentTerm, setSelectedPaymentTerm] = useState(
    initialPaymentTerm || null,
  );
  const [selectedOffer, setSelectedOffer] = useState(
    editVendorOrder?.data?.offer || null,
  );
  const initialPortOfDischarge = portsOfDischarge.find(
    (term) => term.value === editVendorOrder?.data?.portOfDischarge,
  );
  const [selectedPortOfDischarge, setSelectedPortOfDischarge] = useState(
    initialPortOfDischarge || null,
  );

  const initialPortOfLoading = portsOfLoading.find(
    (term) => term.value === editVendorOrder?.data?.portOfLoading,
  );
  const [selectedPortOfLoading, setSelectedPortOfLoading] = useState(
    initialPortOfLoading || null,
  );
  const [previousVolume, setPreviousVolume] = useState(editVendorOrder?.data?.volume || 0);
  const [oldRemainingQuantity, setOldRemainingQuantity] = useState(editVendorOrder?.data?.remainingQuantity || 0);

  return (
    <>
      <Stack direcion="column">
        <Typography variant="body1" mb={2}>
          Edit Purchase Order: {editVendorOrder?.data?.id}{editVendorOrder?.data?.approvalStatus ==="Approved" && <Typography variant="h5" color="error" sx={{ marginTop: '10px' }}>Approved orders can only be edited by managers.</Typography>}
        </Typography>
        <Grid container item xs={12} spacing="8px">
          <Grid item xs={2} sm={1}>
            <Typography variant="body2">Order Year</Typography>
            <TextField
              variant="outlined"
              size="small"
              fullWidth
              value={editVendorOrder?.data?.orderYear}
              onChange={(e) =>
                setEditVendorOrder({
                  ...editVendorOrder,
                  data: {
                    ...editVendorOrder.data,
                    orderYear: e.target.value,
                  },
                })
              }
            />
          </Grid>
          <Grid item xs={10} sm={1}>
            <Typography variant="body2">Order Number</Typography>
            <TextField
              variant="outlined"
              size="small"
              fullWidth
              value={editVendorOrder?.data?.orderGroupNumber}
              onChange={(e) =>
                setEditVendorOrder({
                  ...editVendorOrder,
                  data: {
                    ...editVendorOrder.data,
                    orderGroupNumber: e.target.value,
                  },
                })
              }
            />
          </Grid>
          <Grid item xs={12} sm={2}>
            <Typography variant="body2">SG Approval Status</Typography>
            <TextField
              variant="outlined"
              size="small"
              fullWidth
              select
              value={editVendorOrder?.data?.approvalStatus}
              onChange={(e) =>
                setEditVendorOrder({
                  ...editVendorOrder,
                  data: {
                    ...editVendorOrder.data,
                    approvalStatus: e.target.value,
                  },
                })
              }
            >
              {status.map((option) => (
                <MenuItem key={option.value} value={option.value}>
                  {option.label}
                </MenuItem>
              ))}
            </TextField>
          </Grid>

          <Grid item xs={12} sm={2}>
            <Typography variant="body2">Buying Company</Typography>
            <TextField
              variant="outlined"
              size="small"
              fullWidth
              select
              value={editVendorOrder?.data?.company}
              onChange={(e) =>
                setEditVendorOrder({
                  ...editVendorOrder,
                  data: {
                    ...editVendorOrder.data,
                    company: e.target.value,
                  },
                })
              }
            >
              {companies.map((option) => (
                <MenuItem key={option.value} value={option.value}>
                  {option.label}
                </MenuItem>
              ))}
            </TextField>
          </Grid>

          <Grid item xs={12} sm={2}>
            <Typography variant="body2">Order Status</Typography>
            <TextField
              variant="outlined"
              size="small"
              fullWidth
              select
              value={editVendorOrder?.data?.orderStatus}
              onChange={(e) =>
                setEditVendorOrder({
                  ...editVendorOrder,
                  data: {
                    ...editVendorOrder.data,
                    orderStatus: e.target.value,
                  },
                })
              }
            >
              {status.map((option) => (
                <MenuItem key={option.value} value={option.value}>
                  {option.label}
                </MenuItem>
              ))}
            </TextField>
          </Grid>
          {/* <Grid item xs={12} sm={2}>
            <Typography variant="body2">Status</Typography>
            <TextField
              variant="outlined"
              size="small"
              fullWidth
              select
              value={editVendorOrder?.data?.orderStatus}
              onChange={(e) =>
                setEditVendorOrder({
                  ...editVendorOrder,
                  data: {
                    ...editVendorOrder.data,
                    orderStatus: e.target.value,
                  },
                })
              }
            >
              {status.map((option) => (
                <MenuItem key={option.value} value={option.value}>
                  {option.label}
                </MenuItem>
              ))}
            </TextField>
          </Grid>

          <Grid item xs={12} sm={2}>
            <Typography variant="body2">Status Deadline</Typography>
            <TextField
              id="date"
              type="date"
              inputProps={{ min: "1999-01-01", max: "2999-01-01" }}
              InputLabelProps={{
                shrink: true,
              }}
              variant="outlined"
              size="small"
              fullWidth
              value={
                editVendorOrder?.data?.statusDeadline
                  ? format(
                      parseISO(editVendorOrder.data.statusDeadline),
                      "yyyy-MM-dd",
                    )
                  : ""
              }
              onChange={(e) =>
                setEditVendorOrder({
                  ...editVendorOrder,
                  data: {
                    ...editVendorOrder.data,
                    statusDeadline: e.target.value
                      ? e.target.value + "T00:00:00.000+00:00"
                      : null,
                  },
                })
              }
            />
          </Grid>
          */}

          <Grid item xs={12} sm={2}>
            <Typography variant="body2">Redrawn Sample</Typography>
            <Autocomplete
              options={filteredSamples}
              getOptionLabel={(option) =>
                `ID: ${option?.id || ""}, Remark: ${
                  option?.remark || ""
                }, Buying Grade: ${option?.item?.buyingGrade || ""}`
              }
              value={selectedFirstSample}
              onChange={(event, newValue) => {
                setSelectedFirstSample(newValue);
                setEditVendorOrder({
                  ...editVendorOrder,
                  data: {
                    ...editVendorOrder.data,
                    firstSample: newValue || {},
                  },
                });
              }}
              onOpen={() => retrieveFilteredSamples("Redrawn Sample")}
              isOptionEqualToValue={(option, value) => option.id === value.id}
              renderInput={(params) => (
                <TextField
                  {...params}
                  variant="outlined"
                  size="small"
                  fullWidth
                  label={`ID: ${selectedFirstSample?.id || ""}, Remark: ${
                    selectedFirstSample?.remark || ""
                  }, Buying Grade: ${
                    selectedFirstSample?.item?.buyingGrade || ""
                  }`}
                />
              )}
              renderTags={() => null}
            />
            {selectedFirstSample && (
              <Box mt={2}>
                <Chip
                  label={`ID: ${selectedFirstSample?.id || ""}, Remark: ${
                    selectedFirstSample?.remark || ""
                  }, Buying Grade: ${
                    selectedFirstSample?.item?.buyingGrade || ""
                  }`}
                  onDelete={() => {
                    setSelectedFirstSample(null);
                    setEditVendorOrder({
                      ...editVendorOrder,
                      data: {
                        ...editVendorOrder.data,
                        firstSample: {},
                      },
                    });
                  }}
                />
              </Box>
            )}
          </Grid>

          <Grid item xs={12} sm={2}>
            <Typography variant="body2">Redrawn Sample Status</Typography>
            <TextField
              variant="outlined"
              size="small"
              fullWidth
              select
              value={editVendorOrder?.data?.firstSampleStatus}
              onChange={(e) =>
                setEditVendorOrder({
                  ...editVendorOrder,
                  data: {
                    ...editVendorOrder.data,
                    firstSampleStatus: e.target.value,
                  },
                })
              }
            >
              {sampleStatus.map((option) => (
                <MenuItem key={option.value} value={option.value}>
                  {option.label}
                </MenuItem>
              ))}
            </TextField>
          </Grid>

          <Grid item xs={12} sm={2}>
            <Typography variant="body2">Volume</Typography>
            <TextField
              type="number"
              variant="outlined"
              size="small"
              fullWidth
              value={editVendorOrder?.data?.volume}
              onChange={(e) => {
                const newVolume = e.target.value;
                const remainingQuantity = 
                  oldRemainingQuantity + (newVolume - previousVolume);
                setEditVendorOrder({
                  ...editVendorOrder,
                  data: {
                    ...editVendorOrder.data,
                    volume: newVolume,
                    totalAmount:
                    e.target.value *
                    editVendorOrder?.data?.finalPrice,
                    remainingQuantity: remainingQuantity,
                  },
                });
              }}
            />
          </Grid>
          <Grid item xs={12} sm={2}>
            <Typography variant="body2">Volume Unit</Typography>
            <TextField
              variant="outlined"
              size="small"
              fullWidth
              select
              defaultValue={"KG"}
              onChange={(e) =>
                setEditVendorOrder({
                  ...editVendorOrder,
                  data: {
                    ...editVendorOrder.data,
                    volumeUnit: e.target.value,
                  },
                })
              }
            >
              {units.map((option) => (
                <MenuItem key={option.value} value={option.value}>
                  {option.label}
                </MenuItem>
              ))}
            </TextField>
          </Grid>
          <Grid item xs={12} sm={2}>
            <Typography variant="body2">Packaging Type</Typography>
            <TextField
              variant="outlined"
              size="small"
              fullWidth
              select
              value={editVendorOrder?.data?.packaging}
              onChange={(e) =>
                setEditVendorOrder({
                  ...editVendorOrder,
                  data: {
                    ...editVendorOrder.data,
                    packaging: e.target.value,
                  },
                })
              }
            >
              {packagingTypes.map((option) => (
                <MenuItem key={option.value} value={option.value}>
                  {option.label}
                </MenuItem>
              ))}
            </TextField>
          </Grid>
          <Grid item xs={12} sm={2}>
            <Typography variant="body2">Net Weight Per Carton</Typography>
            <TextField
              type="number"
              variant="outlined"
              size="small"
              fullWidth
              value={editVendorOrder?.data?.netWeightPerCase}
              onChange={(e) => {
                const newVolume = e.target.value * editVendorOrder?.data?.numberOfCases;
                const remainingQuantity = 
                  oldRemainingQuantity + (newVolume - previousVolume);

                setEditVendorOrder({
                  ...editVendorOrder,
                  data: {
                    ...editVendorOrder.data,
                    netWeightPerCase: e.target.value,
                    totalAmount:
                      e.target.value *
                      editVendorOrder?.data?.numberOfCases *
                      editVendorOrder?.data?.finalPrice,
                    volume: newVolume,
                    remainingQuantity: remainingQuantity,
                  },
                })
              }}
            />
          </Grid>
          <Grid item xs={12} sm={2}>
            <Typography variant="body2">Number Of Cartons</Typography>
            <TextField
              type="number"
              variant="outlined"
              size="small"
              fullWidth
              value={editVendorOrder?.data?.numberOfCases}
              onChange={(e) => { 
                const newVolume = e.target.value * editVendorOrder?.data?.netWeightPerCase;
                const remainingQuantity = 
                  oldRemainingQuantity + (newVolume - previousVolume);
                  
                setEditVendorOrder({
                  ...editVendorOrder,
                  data: {
                    ...editVendorOrder.data,
                    numberOfCases: e.target.value,
                    totalAmount:
                      e.target.value *
                      editVendorOrder?.data?.netWeightPerCase *
                      editVendorOrder?.data?.finalPrice,
                      volume: newVolume,
                      remainingQuantity: remainingQuantity,
                  },
                })
              }}
            />
          </Grid>
         {/* <Grid item xs={12} sm={2}>
            <Typography variant="body2">Quantity</Typography>
            <TextField
              type="number"
              variant="outlined"
              size="small"
              fullWidth
              value={editVendorOrder?.data?.quantity}
              onChange={(e) =>
                setEditVendorOrder({
                  ...editVendorOrder,
                  data: {
                    ...editVendorOrder.data,
                    quantity: e.target.value,
                  },
                })
              }
            />
          </Grid>*/}
          <Grid item xs={12} sm={2}>
            <Typography variant="body2">Final Price</Typography>
            <TextField
              type="number"
              variant="outlined"
              size="small"
              fullWidth
              value={editVendorOrder?.data?.finalPrice}
              onChange={(e) =>
                setEditVendorOrder({
                  ...editVendorOrder,
                  data: {
                    ...editVendorOrder.data,
                    finalPrice: e.target.value,
                    totalAmount:
                      e.target.value *
                      editVendorOrder?.data?.volume
                  },
                })
              }
            />
          </Grid>
          <Grid item xs={12} sm={2}>
            <Typography variant="body2">Final Price Currency</Typography>
            <TextField
              variant="outlined"
              size="small"
              fullWidth
              select
              value={editVendorOrder?.data?.finalPriceCurrency}
              onChange={(e) =>
                setEditVendorOrder({
                  ...editVendorOrder,
                  data: {
                    ...editVendorOrder.data,
                    finalPriceCurrency: e.target.value,
                  },
                })
              }
            >
              {currencies.map((option) => (
                <MenuItem key={option.value} value={option.value}>
                  {option.label}
                </MenuItem>
              ))}
            </TextField>
          </Grid>
          <Grid item xs={12} sm={2}>
            <Typography variant="body2">Total Amount</Typography>
            <TextField
              type="numeric"
              variant="outlined"
              size="small"
              fullWidth
              value={editVendorOrder?.data?.totalAmount}
              onChange={(e) =>
                setEditVendorOrder({
                  ...editVendorOrder,
                  data: {
                    ...editVendorOrder.data,
                    totalAmount: e.target.value,
                  },
                })
              }
            />
          </Grid>
          {/*<Grid item xs={12} sm={2}>
            <Typography variant="body2">Cost</Typography>
            <Autocomplete
              options={costs}
              getOptionLabel={(option) =>
                `Remark: ${option?.remark || ""}, Total Cost: ${
                  option?.totalCost || ""
                }`
              }
              value={selectedCost}
              onChange={(event, newValue) => {
                setSelectedCost(newValue);
                setEditVendorOrder({
                  ...editVendorOrder,
                  data: {
                    ...editVendorOrder.data,
                    cost: newValue || {},
                  },
                });
              }}
              onOpen={retrieveCosts}
              isOptionEqualToValue={(option, value) => option.id === value.id}
              renderInput={(params) => (
                <TextField
                  {...params}
                  variant="outlined"
                  size="small"
                  fullWidth
                  label={`Remark: ${selectedCost?.remark || ""}, Total Cost: ${
                    selectedCost?.totalCost || ""
                  }`}
                />
              )}
              renderTags={() => null}
            />
            {selectedCost && (
              <Box mt={2}>
                <Chip
                  label={`Remark: ${selectedCost?.remark || ""}, Total Cost: ${
                    selectedCost?.totalCost || ""
                  }`}
                  onDelete={() => {
                    setSelectedCost(null);
                    setEditVendorOrder({
                      ...editVendorOrder,
                      data: {
                        ...editVendorOrder.data,
                        cost: {},
                      },
                    });
                  }}
                />
              </Box>
            )}
          </Grid>*/}
          <Grid item xs={12} sm={2}>
            <Typography variant="body2">Contract Value Date</Typography>
            <TextField
              id="date"
              type="date"
              inputProps={{ min: "1999-01-01", max: "2999-01-01" }}
              InputLabelProps={{
                shrink: true,
              }}
              onKeyDown={(e) => e.preventDefault()}
              variant="outlined"
              size="small"
              fullWidth
              value={
                editVendorOrder?.data?.contractValueDate
                  ? format(
                      parseISO(editVendorOrder.data.contractValueDate),
                      "yyyy-MM-dd",
                    )
                  : ""
              }
              onChange={(e) =>
                setEditVendorOrder({
                  ...editVendorOrder,
                  data: {
                    ...editVendorOrder.data,
                    contractValueDate: e.target.value
                      ? e.target.value + "T00:00:00.000+00:00"
                      : null,
                  },
                })
              }
            />
          </Grid>
          <Grid item xs={12} sm={2}>
            <Typography variant="body2">Incoterm</Typography>
            <TextField
              variant="outlined"
              size="small"
              fullWidth
              select
              value={editVendorOrder?.data?.incoterm}
              onChange={(e) =>
                setEditVendorOrder({
                  ...editVendorOrder,
                  data: {
                    ...editVendorOrder.data,
                    incoterm: e.target.value,
                  },
                })
              }
            >
              {incoterms.map((option) => (
                <MenuItem key={option.value} value={option.value}>
                  {option.label}
                </MenuItem>
              ))}
            </TextField>
          </Grid>

          <Grid item xs={12} sm={2}>
            <Typography variant="body2">Port Of Discharge</Typography>
            <Autocomplete
              options={portsOfDischarge}
              getOptionLabel={(option) => option?.label || ""}
              value={selectedPortOfDischarge}
              onChange={(event, newValue) => {
                setSelectedPortOfDischarge(newValue);
                setEditVendorOrder({
                  ...editVendorOrder,
                  data: {
                    ...editVendorOrder.data,
                    portOfDischarge: newValue?.value || "",
                  },
                });
              }}
              isOptionEqualToValue={(option, value) =>
                option?.value === value?.value
              }
              renderInput={(params) => (
                <TextField
                  {...params}
                  variant="outlined"
                  size="small"
                  fullWidth
                />
              )}
              renderTags={() => null}
            />
            {selectedPortOfDischarge && (
              <Box mt={2}>
                <Chip
                  label={selectedPortOfDischarge?.label}
                  onDelete={() => {
                    setSelectedPortOfDischarge(null);
                    setEditVendorOrder({
                      ...editVendorOrder,
                      data: {
                        ...editVendorOrder.data,
                        portOfDischarge: "",
                      },
                    });
                  }}
                />
              </Box>
            )}
          </Grid>

          <Grid item xs={12} sm={2}>
            <Typography variant="body2">Port Of Loading</Typography>
            <Autocomplete
              options={portsOfLoading}
              getOptionLabel={(option) => option?.label || ""}
              value={selectedPortOfLoading}
              onChange={(event, newValue) => {
                setSelectedPortOfLoading(newValue);
                setEditVendorOrder({
                  ...editVendorOrder,
                  data: {
                    ...editVendorOrder.data,
                    portOfLoading: newValue?.value || "",
                  },
                });
              }}
              isOptionEqualToValue={(option, value) =>
                option?.value === value?.value
              }
              renderInput={(params) => (
                <TextField
                  {...params}
                  variant="outlined"
                  size="small"
                  fullWidth
                />
              )}
              renderTags={() => null}
            />
            {selectedPortOfLoading && (
              <Box mt={2}>
                <Chip
                  label={selectedPortOfLoading?.label}
                  onDelete={() => {
                    setSelectedPortOfLoading(null);
                    setEditVendorOrder({
                      ...editVendorOrder,
                      data: {
                        ...editVendorOrder.data,
                        portOfLoading: "",
                      },
                    });
                  }}
                />
              </Box>
            )}
          </Grid>

          <Grid item xs={12} sm={2}>
            <Typography variant="body2">Payment Term</Typography>
            <Autocomplete
              options={paymentTerm}
              getOptionLabel={(option) => option?.label || ""}
              value={selectedPaymentTerm}
              onChange={(event, newValue) => {
                setSelectedPaymentTerm(newValue);
                setEditVendorOrder({
                  ...editVendorOrder,
                  data: {
                    ...editVendorOrder.data,
                    paymentTerm: newValue?.value || "",
                  },
                });
              }}
              isOptionEqualToValue={(option, value) =>
                option?.value === value?.value
              }
              renderInput={(params) => (
                <TextField
                  {...params}
                  variant="outlined"
                  size="small"
                  fullWidth
                />
              )}
              renderTags={() => null}
            />
            {selectedPaymentTerm && (
              <Box mt={2}>
                <Chip
                  label={selectedPaymentTerm?.label}
                  onDelete={() => {
                    setSelectedPaymentTerm(null);
                    setEditVendorOrder({
                      ...editVendorOrder,
                      data: {
                        ...editVendorOrder.data,
                        paymentTerm: "",
                      },
                    });
                  }}
                />
              </Box>
            )}
          </Grid>
          <Grid item xs={12} sm={2}>
            <Typography variant="body2">Issue Date</Typography>
            <TextField
              id="date"
              type="date"
              inputProps={{ min: "1999-01-01", max: "2999-01-01" }}
              InputLabelProps={{
                shrink: true,
              }}
              onKeyDown={(e) => e.preventDefault()}
              variant="outlined"
              size="small"
              fullWidth
              value={
                editVendorOrder?.data?.operationDate
                  ? format(
                      parseISO(editVendorOrder.data.operationDate),
                      "yyyy-MM-dd",
                    )
                  : ""
              }
              onChange={(e) =>
                setEditVendorOrder({
                  ...editVendorOrder,
                  data: {
                    ...editVendorOrder.data,
                    operationDate: e.target.value
                      ? e.target.value + "T00:00:00.000+00:00"
                      : null,
                  },
                })
              }
            />
          </Grid>

          <Grid item xs={12} sm={2}>
            <Typography variant="body2">ETD</Typography>
            <TextField
              id="date"
              type="date"
              inputProps={{ min: "1999-01-01", max: "2999-01-01" }}
              InputLabelProps={{
                shrink: true,
              }}
              onKeyDown={(e) => e.preventDefault()}
              variant="outlined"
              size="small"
              fullWidth
              value={
                editVendorOrder?.data?.estimatedTimeOfDeparture
                  ? format(
                      parseISO(editVendorOrder.data.estimatedTimeOfDeparture),
                      "yyyy-MM-dd",
                    )
                  : ""
              }
              onChange={(e) =>
                setEditVendorOrder({
                  ...editVendorOrder,
                  data: {
                    ...editVendorOrder.data,
                    estimatedTimeOfDeparture: e.target.value
                      ? e.target.value + "T00:00:00.000+00:00"
                      : null,
                  },
                })
              }
            />
          </Grid>

          <Grid item xs={12} sm={2}>
            <Typography variant="body2">ETA</Typography>
            <TextField
              id="date"
              type="date"
              inputProps={{ min: "1999-01-01", max: "2999-01-01" }}
              InputLabelProps={{
                shrink: true,
              }}
              onKeyDown={(e) => e.preventDefault()}
              variant="outlined"
              size="small"
              fullWidth
              value={
                editVendorOrder?.data?.estimatedTimeOfArrival
                  ? format(
                      parseISO(editVendorOrder.data.estimatedTimeOfArrival),
                      "yyyy-MM-dd",
                    )
                  : ""
              }
              onChange={(e) =>
                setEditVendorOrder({
                  ...editVendorOrder,
                  data: {
                    ...editVendorOrder.data,
                    estimatedTimeOfArrival: e.target.value
                      ? e.target.value + "T00:00:00.000+00:00"
                      : null,
                  },
                })
              }
            />
          </Grid>
          <Grid item xs={12} sm={2}>
            <Typography variant="body2">Shipment Schedule</Typography>
            <TextField
              variant="outlined"
              size="small"
              fullWidth
              value={editVendorOrder?.data?.shipmentSchedule}
              onChange={(e) =>
                setEditVendorOrder({
                  ...editVendorOrder,
                  data: {
                    ...editVendorOrder.data,
                    shipmentSchedule: e.target.value,
                  },
                })
              }
            />
          </Grid>
          <Grid item xs={12} sm={2}>
            <Typography variant="body2">Remark</Typography>
            <TextField
              variant="outlined"
              size="small"
              fullWidth
              value={editVendorOrder?.data?.remark}
              onChange={(e) =>
                setEditVendorOrder({
                  ...editVendorOrder,
                  data: {
                    ...editVendorOrder.data,
                    remark: e.target.value,
                  },
                })
              }
            />
          </Grid>
          <Grid item xs={12} sm={2}>
            <Typography variant="body2">Comment</Typography>
            <TextField
              variant="outlined"
              size="small"
              fullWidth
              value={editVendorOrder?.data?.comment}
              onChange={(e) =>
                setEditVendorOrder({
                  ...editVendorOrder,
                  data: {
                    ...editVendorOrder.data,
                    comment: e.target.value,
                  },
                })
              }
            />
          </Grid>
          <Grid item xs={12} sm={2}>
            <Typography variant="body2">Offer</Typography>
            <Autocomplete
              options={offers}
              getOptionLabel={(option) =>
                `Id: ${option?.id || ""}, Number: ${option?.offerYear || ""}-${
                  option?.offerGroupNumber || ""
                }`
              }
              value={selectedOffer}
              onChange={(event, newValue) => {
                setSelectedOffer(newValue);
                setEditVendorOrder({
                  ...editVendorOrder,
                  data: {
                    ...editVendorOrder.data,
                    offer: newValue || {},
                  },
                });
              }}
              onOpen={retrieveOffers}
              isOptionEqualToValue={(option, value) => option.id === value.id}
              renderInput={(params) => (
                <TextField
                  {...params}
                  variant="outlined"
                  size="small"
                  fullWidth
                  label={`Id: ${selectedOffer?.id || ""}, Number: ${
                    selectedOffer?.offerYear || ""
                  }-${selectedOffer?.offerGroupNumber || ""}`}
                />
              )}
              renderTags={() => null}
            />
            {selectedOffer && (
              <Box mt={2}>
                <Chip
                  label={`Id: ${selectedOffer?.id || ""}, Number: ${
                    selectedOffer?.offerYear || ""
                  }-${selectedOffer?.offerGroupNumber || ""}`}
                  onDelete={() => {
                    setSelectedOffer(null);
                    setEditVendorOrder({
                      ...editVendorOrder,
                      data: {
                        ...editVendorOrder.data,
                        offer: {},
                      },
                    });
                  }}
                  style={{ marginBottom: "5px" }}
                />{" "}
                <br></br>
                <Chip
                  label={`Item Buying Grade: ${
                    selectedOffer?.item?.buyingGrade || ""
                  }`}
                  style={{ marginBottom: "5px" }}
                />
                <br></br>
                <Chip
                  label={`Item Origin: ${selectedOffer?.item?.origin || ""}`}
                  style={{ marginBottom: "5px" }}
                />
                <br></br>
                <Chip
                  label={`Item Crop: ${selectedOffer?.item?.crop || ""}`}
                  style={{ marginBottom: "5px" }}
                />
                <br></br>
                <Chip
                  label={`Item Sugar: ${selectedOffer?.item?.sugar || ""}`}
                  style={{ marginBottom: "5px" }}
                />
                <br></br>
                <Chip
                  label={`Item Moisture: ${
                    selectedOffer?.item?.moisture || ""
                  }`}
                  style={{ marginBottom: "5px" }}
                />
                <br></br>
                <Chip
                  label={`Item Nicotine: ${
                    selectedOffer?.item?.nicotine || ""
                  }`}
                  style={{ marginBottom: "5px" }}
                />
                <br></br>
              </Box>
            )}
          </Grid>
          <Grid item xs={12} sm={2}>
            <Typography variant="body2">Province</Typography>
            <TextField
              variant="outlined"
              size="small"
              fullWidth
              value={editVendorOrder?.data?.province}
              onChange={(e) =>
                setEditVendorOrder({
                  ...editVendorOrder,
                  data: {
                    ...editVendorOrder.data,
                    province: e.target.value,
                  },
                })
              }
            />
          </Grid>
          <Grid item xs={12} sm={2}>
            <Typography variant="body2">Shipping Term</Typography>
            <TextField
              variant="outlined"
              size="small"
              fullWidth
              value={editVendorOrder?.data?.shippingTerm}
              onChange={(e) =>
                setEditVendorOrder({
                  ...editVendorOrder,
                  data: {
                    ...editVendorOrder.data,
                    shippingTerm: e.target.value,
                  },
                })
              }
            />
          </Grid>

          <Grid item xs={12} sm={2}>
            <Typography variant="body2">SAP PO Number</Typography>
            <TextField
              variant="outlined"
              size="small"
              fullWidth
              value={editVendorOrder?.data?.purchaseOrderNumber}
              onChange={(e) =>
                setEditVendorOrder({
                  ...editVendorOrder,
                  data: {
                    ...editVendorOrder.data,
                    purchaseOrderNumber: e.target.value,
                  },
                })
              }
            />
          </Grid>

          <Grid item xs={12} sm={2}>
            <Typography variant="body2">Record Status</Typography>
            <TextField
              variant="outlined"
              size="small"
              fullWidth
              id="outlined-select-status"
              select
              value={editVendorOrder?.data?.recordStatus}
              onChange={(e) =>
                setEditVendorOrder({
                  ...editVendorOrder,
                  data: {
                    ...editVendorOrder.data,
                    recordStatus: e.target.value,
                  },
                })
              }
            >
              {recordStatus.map((option) => (
                <MenuItem key={option.value} value={option.value}>
                  {option.label}
                </MenuItem>
              ))}
            </TextField>
          </Grid>
          <Grid item xs={12} sm={2}>
            <Typography variant="body2">Edit Status</Typography>
            <TextField
              variant="outlined"
              size="small"
              fullWidth
              select
              value={editVendorOrder?.data?.editingPermission}
              onChange={(e) =>
                setEditVendorOrder({
                  ...editVendorOrder,
                  data: {
                    ...editVendorOrder.data,
                    editingPermission: e.target.value,
                  },
                })
              }
            >
              {status.map((option) => (
                <MenuItem key={option.value} value={option.value}>
                  {option.label}
                </MenuItem>
              ))}
            </TextField>
          </Grid>
        </Grid>
        <Stack direction="row" spacing={1} mt={1}>
          <Button
            variant="outlined"
            onClick={() =>
              setEditVendorOrder({ visible: false, data: null, index: null })
            }
          >
            Cancel
          </Button>
          <Button
            variant="contained"
            onClick={() => {
              let updatedOrderData = { ...editVendorOrder.data };

              if (updatedOrderData.remainingQuantity > 0) {
                updatedOrderData = {
                  ...updatedOrderData,
                  orderStatus: "Open",
                };
              }else if (updatedOrderData.remainingQuantity <= 0) {
                updatedOrderData = {
                  ...updatedOrderData,
                  orderStatus: "Closed",
                };
              }

              updateOrderApi(updatedOrderData.id, updatedOrderData)
                .then((response) => {
                  toast.success("Purchase order edited successfully.");
                  window.location.reload();
                  setEditVendorOrder({
                    visible: false,
                    data: null,
                    index: null,
                  });
                })
                .catch((error) => {
                  toast.error("Something went wrong.");
                  console.log(error.response.data);
                });
            }}
          >
            Save
          </Button>
          <Button
            variant="contained"
            color="error"
            onClick={() => {
              editVendorOrder.data.recordStatus = "PASSIVE";
              updateOrderApi(editVendorOrder.data.id, editVendorOrder.data)
                .then((response) => {
                  toast.success("Purchase order deleted successfully.");
                  window.location.reload();
                  setEditVendorOrder({
                    visible: false,
                    data: null,
                    index: null,
                  });
                })
                .catch((error) => {
                  toast.error("Something went wrong.");
                  console.log(error.response.data);
                });
            }}
          >
            Delete
          </Button>
        </Stack>
      </Stack>
    </>
  );
};

export default EditVendorOrder;

import {
  Autocomplete,
  Box,
  Button,
  Chip,
  Grid,
  MenuItem,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import { format, parseISO } from "date-fns";
import React, { useState } from "react";
import { updateWarehouseApi } from "../../../components/api/WarehouseApiService";
import { companies, recordStatus, storagePricingBy, units } from "../../../utils/menu-items";
import { retrieveAllStakeholdersApi } from "../../../components/api/StakeholderApiService";
import toast from "react-hot-toast";

const EditWarehouse = (props) => {
  const { editWarehouse, setEditWarehouse } = props;
  const [vendors, setVendors] = useState([]);
  const [selectedVendor, setSelectedVendor] = useState(editWarehouse?.data?.contactPerson || null,);
  const [vendor, setVendor] = useState([]);

  function retrieveVendors() {
    retrieveAllStakeholdersApi("VENDOR")
      .then((response) => {
        setVendors(response?.data);
      })
      .catch((error) => console.log(error.response?.data));
  }

  return (
    <>
      <Stack direcion="column">
        <Typography variant="body1" mb={2}>
          Edit Warehouse Inventory Record
        </Typography>
        <Grid container item xs={12} spacing="8px">

        <Grid item xs={12} sm={2}>
            <Typography variant="body2">Name</Typography>
            <TextField
              variant="outlined"
              size="small"
              fullWidth
              value={editWarehouse?.data?.name}
              onChange={(e) =>
                setEditWarehouse({
                  ...editWarehouse,
                  data: {
                    ...editWarehouse.data,
                    name: e.target.value,
                  },
                })
              }
            />
          </Grid>
      
          <Grid item xs={12} sm={2}>
            <Typography variant="body2">Code</Typography>
            <TextField
              variant="outlined"
              size="small"
              fullWidth
              value={editWarehouse?.data?.code}
              onChange={(e) =>
                setEditWarehouse({
                  ...editWarehouse,
                  data: {
                    ...editWarehouse.data,
                    code: e.target.value,
                  },
                })
              }
            />
          </Grid>

          <Grid item xs={12} sm={2}>
            <Typography variant="body2">Contact Person</Typography>
            <Autocomplete
              options={vendors}
              getOptionLabel={(option) =>
                `${option?.id || ""} - ${option?.name || ""} - ${option?.contactPerson || ""}`
              }
              value={selectedVendor}
              onChange={(event, newValue) => {
                setSelectedVendor(newValue);
                setEditWarehouse({
                  ...editWarehouse,
                  data: {
                    ...editWarehouse.data,
                    contactPerson: newValue || {},
                  },
                });
              }}
              onOpen={retrieveVendors}
              isOptionEqualToValue={(option, value) => option.id === value.id}
              renderInput={(params) => (
                <TextField
                  {...params}
                  variant="outlined"
                  size="small"
                  fullWidth
                />
              )}
              renderTags={() => null}
            />
            {selectedVendor && (
              <Box mt={2}>
                <Chip
                  label={`${selectedVendor?.id || ""} - ${
                    selectedVendor?.name || ""
                  }`}
                  onDelete={() => {
                    setSelectedVendor(null);
                    setEditWarehouse({
                      ...editWarehouse,
                      data: {
                        ...editWarehouse.data,
                        contactPerson: {},
                      },
                    });
                  }}
                  style={{ marginBottom: "5px" }}
                  />
                    <br></br>
                    {selectedVendor?.contactPerson && (
                    <>
                      <Chip
                        label={`${selectedVendor.contactPerson}`}
                        style={{ marginBottom: "5px" }}
                      />
                      <br />
                    </>
                  )}
                </Box>
            )}
          </Grid>

          <Grid item xs={12} sm={2}>
            <Typography variant="body2">Storage Pricing By</Typography>
            <TextField
              variant="outlined"
              size="small"
              fullWidth
              id="outlined-select-storage-pricing-by"
              select
              value={editWarehouse?.data?.storagePricingBy}
              onChange={(e) =>
                setEditWarehouse({
                  ...editWarehouse,
                  data: {
                    ...editWarehouse.data,
                    storagePricingBy: e.target.value,
                  },
                })
              }
            >
              {storagePricingBy.map((option) => (
                <MenuItem key={option.value} value={option.value}>
                  {option.label}
                </MenuItem>
              ))}
            </TextField>
          </Grid>

          <Grid item xs={12} sm={2}>
            <Typography variant="body2">Record Status</Typography>
            <TextField
              variant="outlined"
              size="small"
              fullWidth
              id="outlined-select-status"
              select
              value={editWarehouse?.data?.recordStatus}
              onChange={(e) =>
                setEditWarehouse({
                  ...editWarehouse,
                  data: {
                    ...editWarehouse.data,
                    recordStatus: e.target.value,
                  },
                })
              }
            >
              {recordStatus.map((option) => (
                <MenuItem key={option.value} value={option.value}>
                  {option.label}
                </MenuItem>
              ))}
            </TextField>
          </Grid>
        </Grid>
        <Stack direction="row" spacing={1} mt={1}>
          <Button
            variant="outlined"
            onClick={() =>
              setEditWarehouse({ visible: false, data: null, index: null })
            }
          >
            Cancel
          </Button>
          <Button
            variant="contained"
            onClick={() => {
              updateWarehouseApi(editWarehouse.data.id, editWarehouse.data)
                .then((response) => {
                  toast.success("Warehouse edited succesfully.");
                  window.location.reload();
                  setEditWarehouse({ visible: false, data: null, index: null });
                })
                .catch((error) => {
                  toast.error(error?.response?.data?.message);
                  console.log(error.response.data);
                });
            }}
          >
            Save
          </Button>

          <Button
            variant="contained"
            color="error"
            onClick={() => {
              editWarehouse.data.recordStatus = "PASSIVE";
              updateWarehouseApi(editWarehouse.data.id, editWarehouse.data)
                .then((response) => {
                  window.location.reload();
                  setEditWarehouse({ visible: false, data: null, index: null });
                })
                .catch((error) => {
                  console.log(error.response.data);
                });
            }}
          >
            Delete
          </Button>
        </Stack>
      </Stack>
    </>
  );
};

export default EditWarehouse;

import { Button, Grid, MenuItem, TextField, Typography } from "@mui/material";
import { format, parseISO } from "date-fns";
import React, { useState } from "react";
import { currencies, packagingTypes, recordStatus, status } from "../../../utils/menu-items";

const CreateOrder = (props) => {
  const { setCreateOrder, createOrder } = props;
  console.log(createOrder);
  const [orderData, setOrderData] = useState({});

  return (
    <Grid container spacing="12px">
      <Grid item xs={12} sm={4}>
        <Typography variant="body2">Order Number</Typography>
        <TextField
          disabled
          variant="outlined"
          size="small"
          defaultValue={createOrder?.data?.orderGroupNumber}
          fullWidth
        />
      </Grid>
      <Grid item xs={12} sm={4}>
        <Typography variant="body2">SG Approval Status</Typography>
        <TextField
          variant="outlined"
          size="small"
          fullWidth
          id="outlined-select-approval-status"
          defaultValue={createOrder?.data?.approvalStatus}
          select
          onChange={(e) =>
            setOrderData({
              ...orderData,
              data: {
                ...orderData.data,
                approvalStatus: e.target.value,
              },
            })
          }
        >
          {status.map((option) => (
            <MenuItem key={option.value} value={option.value}>
              {option.label}
            </MenuItem>
          ))}
          {createOrder?.data?.approvalStatus &&
            !status.some(
              (option) => option.value === createOrder?.data?.approvalStatus,
            ) && (
              <MenuItem value={createOrder?.data?.approvalStatus}>
                {createOrder?.data?.approvalStatus}
              </MenuItem>
            )}
        </TextField>
      </Grid>

      <Grid item xs={12} sm={4}>
        <Typography variant="body2">Offer</Typography>
        <TextField
          variant="outlined"
          size="small"
          fullWidth
          id="outlined-select-status"
          defaultValue={createOrder?.data?.offer?.id}
          onChange={(e) =>
            setOrderData({
              ...orderData,
              data: {
                ...orderData.data,
                offerId: e.target.value,
              },
            })
          }
        />
      </Grid>

      <Grid item xs={12} sm={4}>
        <Typography variant="body2">Company</Typography>
        <TextField
          disabled
          variant="outlined"
          size="small"
          fullWidth
          id="outlined-select-status"
          defaultValue={createOrder?.data?.company}
          onChange={(e) =>
            setOrderData({
              ...orderData,
              data: {
                ...orderData.data,
                status: e.target.value,
              },
            })
          }
        />
      </Grid>

      <Grid item xs={12} sm={4}>
        <Typography variant="body2">Item ID</Typography>
        <TextField
          disabled
          variant="outlined"
          size="small"
          value={createOrder?.data?.offer?.item?.id}
          fullWidth
        />
      </Grid>

      <Grid item xs={12} sm={4}>
        <Typography variant="body2">Tobacco Code</Typography>
        <TextField
          disabled
          variant="outlined"
          size="small"
          value={createOrder?.data?.offer?.item?.tobaccoCode}
          fullWidth
        />
      </Grid>
      <Grid item xs={12} sm={4}>
        <Typography variant="body2">Item Buying Grade</Typography>
        <TextField
          disabled
          variant="outlined"
          size="small"
          value={createOrder?.data?.offer?.item?.buyingGrade}
          fullWidth
        />
      </Grid>
      <Grid item xs={12} sm={4}>
        <Typography variant="body2">Item Sales Grade</Typography>
        <TextField
          disabled
          variant="outlined"
          size="small"
          value={createOrder?.data?.offer?.item?.salesGrade}
          fullWidth
        />
      </Grid>
      <Grid item xs={12} sm={4}>
        <Typography variant="body2">Item Origin</Typography>
        <TextField
          disabled
          variant="outlined"
          size="small"
          value={createOrder?.data?.offer?.item?.origin}
          fullWidth
        />
      </Grid>
      <Grid item xs={12} sm={4}>
        <Typography variant="body2">Nicotine</Typography>
        <TextField
          disabled
          variant="outlined"
          size="small"
          value={createOrder?.data?.offer?.item?.nicotine}
          fullWidth
        />
      </Grid>
      <Grid item xs={12} sm={4}>
        <Typography variant="body2">Sugar</Typography>
        <TextField
          disabled
          variant="outlined"
          size="small"
          value={createOrder?.data?.offer?.item?.sugar}
          fullWidth
        />
      </Grid>
      <Grid item xs={12} sm={4}>
        <Typography variant="body2">Moisture</Typography>
        <TextField
          disabled
          variant="outlined"
          size="small"
          value={createOrder?.data?.offer?.item?.moisture}
          fullWidth
        />
      </Grid>

      <Grid item xs={12} sm={4}>
        <Typography variant="body2">Business Partner</Typography>
        <TextField
          disabled
          variant="outlined"
          size="small"
          value={createOrder?.data?.offer?.stakeholder?.name}
          fullWidth
        />
      </Grid>
      <Grid item xs={12} sm={4}>
        <Typography variant="body2">Business Partner SAP Code</Typography>
        <TextField
          disabled
          variant="outlined"
          size="small"
          value={createOrder?.data?.offer?.stakeholder?.code}
          fullWidth
        />
      </Grid>

      <Grid item xs={12} sm={4}>
        <Typography variant="body2">First Sample</Typography>
        <TextField
          disabled
          variant="outlined"
          size="small"
          defaultValue={createOrder?.data?.offer?.firstSample?.id}
          fullWidth
        />
      </Grid>

      <Grid item xs={12} sm={4}>
        <Typography variant="body2">First Sample Status</Typography>
        <TextField
          disabled
          variant="outlined"
          size="small"
          defaultValue={createOrder?.data?.offer?.firstSample?.status}
          fullWidth
        />
      </Grid>

      <Grid item xs={12} sm={4}>
        <Typography variant="body2">Second Sample</Typography>
        <TextField
          disabled
          variant="outlined"
          size="small"
          defaultValue={createOrder?.data?.offer?.secondSample?.id}
          fullWidth
        />
      </Grid>

      <Grid item xs={12} sm={4}>
        <Typography variant="body2">Second Sample Status</Typography>
        <TextField
          disabled
          variant="outlined"
          size="small"
          defaultValue={createOrder?.data?.offer?.secondSample?.status}
          fullWidth
        />
      </Grid>

      <Grid item xs={12} sm={4}>
        <Typography variant="body2">Volume</Typography>
        <TextField
          variant="outlined"
          size="small"
          defaultValue={createOrder?.data?.volume}
          fullWidth
          onChange={(e) =>
            setOrderData({ ...orderData, volume: e.target.value })
          }
        />
      </Grid>

      <Grid item xs={12} sm={4}>
        <Typography variant="body2">Volume Unit</Typography>
        <TextField
          variant="outlined"
          size="small"
          fullWidth
          id="outlined-select-volume"
          defaultValue={createOrder?.data?.volumeUnit}
          select
          onChange={(e) =>
            setOrderData({
              ...orderData,
              data: {
                ...orderData.data,
                volumeUnit: e.target.value,
              },
            })
          }
        >
          {status.map((option) => (
            <MenuItem key={option.value} value={option.value}>
              {option.label}
            </MenuItem>
          ))}
          {createOrder?.data?.volumeUnit &&
            !status.some(
              (option) => option.value === createOrder?.data?.volumeUnit,
            ) && (
              <MenuItem value={createOrder?.data?.volumeUnit}>
                {createOrder?.data?.volumeUnit}
              </MenuItem>
            )}
        </TextField>
      </Grid>

      <Grid item xs={12} sm={4}>
        <Typography variant="body2">Select Packaging Type</Typography>
        <TextField
          variant="outlined"
          size="small"
          fullWidth
          select
          defaultValue={createOrder?.data?.packaging}
          onChange={(e) =>
            setOrderData({ ...orderData, packaging: e.target.value })
          }
        >
          {packagingTypes.map((option) => (
            <MenuItem key={option.value} value={option.value}>
              {option.label}
            </MenuItem>
          ))}
        </TextField>
      </Grid>

      <Grid item xs={12} sm={4}>
        <Typography variant="body2">Enter Net Weight Per Carton</Typography>
        <TextField
          type="number"
          variant="outlined"
          size="small"
          fullWidth
          onChange={(e) =>
            setOrderData({ ...orderData, netWeightPerCase: e.target.value })
          }
        />
      </Grid>

      <Grid item xs={12} sm={4}>
        <Typography variant="body2">Enter Number Of Cartons</Typography>
        <TextField
          type="number"
          variant="outlined"
          size="small"
          fullWidth
          onChange={(e) =>
            setOrderData({ ...orderData, numberOfCases: e.target.value })
          }
        />
      </Grid>
      <Grid item xs={12} sm={4}>
        <Typography variant="body2">Enter Quantity (KG)</Typography>
        <TextField
          type="number"
          variant="outlined"
          size="small"
          fullWidth
          onChange={(e) =>
            setOrderData({ ...orderData, quantity: e.target.value })
          }
        />
      </Grid>
      <Grid item xs={12} sm={4}>
        <Typography variant="body2">Enter Final Price</Typography>
        <TextField
          type="number"
          variant="outlined"
          size="small"
          fullWidth
          onChange={(e) =>
            setOrderData({ ...orderData, finalPrice: e.target.value })
          }
        />
      </Grid>
      <Grid item xs={12} sm={4}>
        <Typography variant="body2">Select Final Price Currency</Typography>
        <TextField
          id="outlined-select-status"
          select
          variant="outlined"
          size="small"
          fullWidth
          onChange={(e) =>
            setOrderData({ ...orderData, finalPriceCurrency: e.target.value })
          }
        >
          {currencies.map((option) => (
            <MenuItem key={option.value} value={option.value}>
              {option.label}
            </MenuItem>
          ))}
        </TextField>
      </Grid>

      {/*<Grid item xs={12} sm={4}>
        <Typography variant="body2">Select Cost</Typography>
        <TextField
          variant="outlined"
          size="small"
          fullWidth
          onChange={(e) =>
            setOrderData({ ...orderData, costId: e.target.value })
          }
        />
        </Grid>*/}

      <Grid item xs={12} sm={4}>
        <Typography variant="body2">Select Contract Value Date</Typography>
        <TextField
          size="small"
          fullWidth
          variant="outlined"
          id="date"
          type="date"
          inputProps={{ min: "1999-01-01", max: "2999-01-01" }}
          InputLabelProps={{
            shrink: true,
          }}
          onKeyDown={(e) => e.preventDefault()}
          onChange={(e) =>
            setOrderData({ ...orderData, contractValueDate: e.target.value })
          }
        />
      </Grid>
      <Grid item xs={12} sm={4}>
        <Typography variant="body2">Select Issue Date</Typography>
        <TextField
          size="small"
          fullWidth
          variant="outlined"
          id="date"
          type="date"
          inputProps={{ min: "1999-01-01", max: "2999-01-01" }}
          InputLabelProps={{
            shrink: true,
          }}
          onKeyDown={(e) => e.preventDefault()}
          onChange={(e) =>
            setOrderData({ ...orderData, operationDate: e.target.value })
          }
        />
      </Grid>
      <Grid item xs={12} sm={4}>
        <Typography variant="body2">Select ETD</Typography>
        <TextField
          size="small"
          fullWidth
          variant="outlined"
          id="date"
          type="date"
          inputProps={{ min: "1999-01-01", max: "2999-01-01" }}
          InputLabelProps={{
            shrink: true,
          }}
          onChange={(e) =>
            setOrderData({
              ...orderData,
              estimatedTimeOfDeparture: e.target.value,
            })
          }
        />
      </Grid>
      <Grid item xs={12} sm={4}>
        <Typography variant="body2">Select ETA</Typography>
        <TextField
          size="small"
          fullWidth
          variant="outlined"
          id="date"
          type="date"
          inputProps={{ min: "1999-01-01", max: "2999-01-01" }}
          InputLabelProps={{
            shrink: true,
          }}
          onChange={(e) =>
            setOrderData({
              ...orderData,
              estimatedTimeOfArrival: e.target.value,
            })
          }
        />
      </Grid>

      <Grid item xs={12} sm={4}>
        <Typography variant="body2">Enter Province</Typography>
        <TextField
          variant="outlined"
          size="small"
          fullWidth
          onChange={(e) =>
            setOrderData({
              ...orderData,
              province: e.target.value,
            })
          }
        />
      </Grid>
      <Grid item xs={12} sm={4}>
        <Typography variant="body2">Enter Shipping Term</Typography>
        <TextField
          variant="outlined"
          size="small"
          fullWidth
          onChange={(e) =>
            setOrderData({
              ...orderData,
              shippingTerm: e.target.value,
            })
          }
        />
      </Grid>
      <Grid item xs={12} sm={4}>
        <Typography variant="body2">Select Record Status</Typography>
        <TextField
          id="outlined-select-status"
          select
          variant="outlined"
          size="small"
          fullWidth
          onChange={(e) =>
            setOrderData({
              ...orderData,
              recordStatus: e.target.value,
            })
          }
        >
          {recordStatus.map((option) => (
            <MenuItem key={option.value} value={option.value}>
              {option.label}
            </MenuItem>
          ))}
        </TextField>
      </Grid>
      <Button
        variant="outlined"
        size="small"
        sx={{ ml: 1.5, mt: 4, mr: 1 }}
        onClick={() => setCreateOrder({ visible: false, data: null })}
      >
        Cancel
      </Button>
      <Button
        variant="contained"
        size="small"
        sx={{ mt: 4 }}
        onClick={() =>
          setCreateOrder({ ...createOrder, data: orderData, visible: false })
        }
      >
        Create Sales Order
      </Button>
    </Grid>
  );
};

export default CreateOrder;

import { apiClient } from "./ApiClient";

export const retrieveAllWarehouseInventoryApi = () =>
  apiClient.get(`/warehouse-inventory-view/warehouse-inventory`);

export const createWarehouseInventoryApi = (warehouseInventory) =>
  apiClient.post(`/warehouse-inventory-view/add`, warehouseInventory);

export const updateWarehouseInventoryApi = (warehouseInventoryId, warehouseInventory) =>
  apiClient.put(`/warehouse-inventory-view/edit/${warehouseInventoryId}`, warehouseInventory);

export const updateMultipleWarehouseInventoryRecordsApi = (warehouseInventoryList) =>
  apiClient.put(`/warehouse-inventory-view/edit/warehouse-inventory-record-list`, warehouseInventoryList);

export const retrieveFilteredWarehouseInventoryRecordsApi = (warehouseInventory) =>
  apiClient.post(`/warehouse-inventory-view/warehouse-inventory/filter`, warehouseInventory);

import { Grid, MenuItem, Stack, TextField, Typography, IconButton } from "@mui/material";
import Button from "@mui/material/Button";
import { format, parseISO } from "date-fns";
import ExcelJS from "exceljs";
import { saveAs } from "file-saver";
import React, { useEffect, useState } from "react";
import toast from "react-hot-toast";
import eurLogo from "../../../../assets/images/logo/sanEurLogoJpeg.jpeg";
import intlLogo from "../../../../assets/images/logo/sanIntlLogoJpeg.jpeg";
import { companies, titles } from "../../../../utils/menu-items";
import { updateMultipleOrdersApi } from "../../../../components/api/OrderApiService";
import { ButtonGroup } from '@mui/material';
import { Delete as DeleteIcon } from '@mui/icons-material';


const convertImageToBase64 = (url, callback) => {
  const xhr = new XMLHttpRequest();
  xhr.onload = function () {
    const reader = new FileReader();
    reader.onloadend = function () {
      callback(reader.result);
    };
    reader.readAsDataURL(xhr.response);
  };
  xhr.open("GET", url);
  xhr.responseType = "blob";
  xhr.send();
};

const createExcelWithLogoAndData = async (shippingInstructionDetails) => {
  const workbook = new ExcelJS.Workbook();
  const worksheet = workbook.addWorksheet("My Sheet");
  const len = shippingInstructionDetails?.items.length;
  const documentsRequiredLen = shippingInstructionDetails?.entities.length;
  const specialInstructionsLen = shippingInstructionDetails?.specialInstructionsList.length;
  console.log("documentsRequiredLen", documentsRequiredLen, "specialInstructionsLen",specialInstructionsLen)
  const buyingCompanyHeader =
    shippingInstructionDetails?.buyingCompany === "SGE"
      ? "SanGroup Europe Kft."
      : "SANGROUP INTERNATIONAL PTE.LTD.";
  const buyingAddressOne =
    shippingInstructionDetails?.buyingCompany === "SGE"
      ? "Stefania ut 101-103"
      : "11 Irving Place #09-02";
  const buyingAddressTwo =
    shippingInstructionDetails?.buyingCompany === "SGE"
      ? "H-1143 Budapest, Hungary"
      : "Tai Seng Point Singapore 369551";
  const buyingAddressThree =
    shippingInstructionDetails?.buyingCompany === "SGE"
      ? "Registration no: 01-09-400244 VAT no: Hu27842588"
      : "Registration no: 201717395N";

  const totalNumberOfCases = shippingInstructionDetails?.items.reduce((total, item) => {
    return total + (item?.numberOfCases || 0);
  }, 0);

  const totalNetWeight = shippingInstructionDetails?.items.reduce((total, item) => {
    return total + (item?.shipmentQuantity || 0);
  }, 0);

  const totalTotalAmount = shippingInstructionDetails?.items.reduce((total, item) => {
    return total + (item?.totalAmount || 0);
  }, 0);

  const total20 = shippingInstructionDetails?.items.reduce((total, item) => {
    return total + (item?.toBeShippedBy20 || 0);
  }, 0);
  const total40 = shippingInstructionDetails?.items.reduce((total, item) => {
    return total + (item?.toBeShippedBy40 || 0);
  }, 0);
  const total40HC = shippingInstructionDetails?.items.reduce((total, item) => {
    return total + (item?.toBeShippedBy40HC || 0);
  }, 0);
  const totalFtl = shippingInstructionDetails?.items.reduce((total, item) => {
    return total + (item?.toBeShippedByFtl || 0);
  }, 0);

  var logo;
  // Convert and add the intlLogo
  if (shippingInstructionDetails.buyingCompany === "SGI") {
    logo = intlLogo;
  } else if (shippingInstructionDetails.buyingCompany === "SGE") {
    logo = eurLogo;
  } else {
    logo = intlLogo;
  }
  convertImageToBase64(logo, async (base64Image) => {
    const imageId = workbook.addImage({
      base64: base64Image,
      extension: "jpeg",
    });

    worksheet.addImage(imageId, "B2:C4");
    worksheet.pageSetup.printArea = `A1:I${82+len}`;

    worksheet.pageSetup.fitToPage = true;
    worksheet.pageSetup.fitToWidth = 1;
    worksheet.pageSetup.fitToHeight = 0;


    const startRow = 3;
    const headerRowNumber = startRow + 13; 

    const headerFill = {
      type: "pattern",
      pattern: "solid",
      fgColor: { argb: "FF4F81BD" }, 
    };

    const dataForExcel = [
      [],
      [],
      [],
      [,,,,"SHIPPING INSTRUCTIONS"],
      [],
      [,,"SI No:", shippingInstructionDetails?.siNumber|| "" ,,, "Date:",shippingInstructionDetails?.siDate
      ? format(
          parseISO(shippingInstructionDetails.siDate),
          "dd/MMM/yyyy",
        )
      : "",],
      [],
      [,,"PO Number:", shippingInstructionDetails?.poNumber|| "",,"To:", (shippingInstructionDetails?.siTo|| "")], [,,,,,,(shippingInstructionDetails?.vendor?.contactPerson|| "")],
      [,,,,,"Revision:", (shippingInstructionDetails?.revision|| "")],
      [],
      [,"Please find below the details for goods to be loaded:"],
      [],
      [,
        "Supplier Grade Mark",
        "Crop",
        "Date Of Loading",
        "# Of Cartons",
        "Net Weight",
        "Customer Grade Mark",
        "Carton Range",
      ],
      ...shippingInstructionDetails?.items.map((item, index) => [
        ,
        item?.supplierGradeMark, 
        item?.crop,
        item?.dateOfLoadingRemark,
        item?.numberOfCases,
        item?.shipmentQuantity? new Intl.NumberFormat('de-DE', { style: 'decimal', minimumFractionDigits: 2 }).format(item?.shipmentQuantity): '',
        item?.customerGradeMark,
        item?.cartonNumber,
      ]),
      ["",
        "",,
        "Totals:",
        totalNumberOfCases,
        totalNetWeight? new Intl.NumberFormat('de-DE', { style: 'decimal', minimumFractionDigits: 2 }).format(totalNetWeight)
        : '',
      ],
      [],
      [,
        "To be shipped by:",
        (total20 ? total20 + "x20\", " : "") + 
        (total40 ? total40 + "x40\", " : "") +
        (total40HC ? total40HC + "x40HC\", " : "")+
        (totalFtl ? totalFtl + "xFTL" : "")
      ],
      [],
      [,"Bill of Lading / CMR details:",,],
      [],
      [,"Shipper:",, (shippingInstructionDetails?.shipper?.name || "")],
      [,,,"on behalf of Sangroup International Pte. Ltd."],
      [],
      [,"Consignee:",, (shippingInstructionDetails?.consignee?.name|| "") , , , "Notify Party(ies):", (shippingInstructionDetails?.notify?.name|| "")],
      [],
      [],
      [],
      [,"Point of Loading:",, (shippingInstructionDetails?.pointOfLoading|| "") , , , "Point of unloading:", (shippingInstructionDetails?.pointOfUnloading|| "")],
      [],
      [,"Description of goods:" , ,shippingInstructionDetails?.description|| "" , ,],
      [,,,shippingInstructionDetails?.descriptionTwo|| ""],
      [,,,"(this is the description that should appear on the docs)"],
      [],
      [,"Marking Instructions:" , , "Centered on both short sides of the cartons"],
      [],
      [,,,"","","",],
      [,,,"",,""],
      [,,,"",,""],
      [,,,"","",""],
      [],
      [],
      [],
      [],
      [,"Documents Required:"],
      [,,,,,"Originals","Copies"],
      ...shippingInstructionDetails?.entities.map((entity, index) => [
        ,,,
        entity?.name,,
        entity?.originalAmount,
        entity?.copyAmount,
      ]),
      [],
      [,,,"Drafts should be approved before the originals are issued."],
      [],
      [,"Document Distribution:" , , ,],
      [,,,"Immediately after approval of the drafts, please send the requested originals to:"],
      [,,,"Ms. Verda Karabulut"],
      [,,,"90 530 108 7940"],
      [,,,"İsmet Kaptan mah. Şehit Nevres Bulvarı"],
      [,,,"Deren Plaza No:10 K: 1"],
      [,,,"35210 Alsancak– İzmir"],
      [,,,"verda.karabulut@sangroupintl.com"],
      [],
      [,"Contact details for transportation:",, shippingInstructionDetails?.transportationContact],
      [],
      [,"Invoicing Address :",,buyingCompanyHeader],
      [,,,buyingAddressOne],
      [,,,buyingAddressTwo],
      [,,,buyingAddressThree],
      [],
      [,"Special Instructions:"],
      ...(Array.isArray(shippingInstructionDetails?.specialInstructionsList) ? 
  shippingInstructionDetails.specialInstructionsList.map((entity, index) => [
        ,,,
        entity?.instruction || entity,
      ]) : []),
      [],
      [],
      [,"We trust that above mentioned instructions are clear enough, if not, please don't hesitate to contact us."],
      [],
      [,,,,"Best Regards"],[,,,,shippingInstructionDetails?.assignedUser?.name],
    ];

    // Example: Inserting a row of data
    dataForExcel.forEach((row, index) => {
      const rowIndex = startRow + index;
      const excelRow = worksheet.getRow(rowIndex);
      row.forEach((cell, cellIndex) => {
        const cellAddress = excelRow.getCell(cellIndex + 1);
        cellAddress.value = cell;
   
        if (rowIndex === headerRowNumber) {
          cellAddress.font = { bold: true, size: 8, };
          if (cellIndex === 1) {
            cellAddress.alignment = {
              vertical: "middle",
              wrapText: "true",
              horizontal:"center",
            };
            worksheet.getColumn(cellIndex+1).width = 12;
            worksheet.getColumn(cellIndex+2).width = 12;
            worksheet.getColumn(cellIndex+4).width = 12;
            worksheet.getColumn(cellIndex+6).width = 12;
            worksheet.getColumn(cellIndex+8).width = 12;
              worksheet.getColumn(cellIndex+3).width = 12;
              worksheet.getColumn(cellIndex+5).width = 12;
              worksheet.getColumn(cellIndex+7).width = 12;
            }else if (cellIndex === 6 || cellIndex === 7 ) {
              cellAddress.alignment = {
                  vertical: "middle",
                  wrapText: "true",
                  horizontal:"center",
                };
            }else{
            cellAddress.alignment = {
                vertical: "middle",
                horizontal:"center",
              };
          }
          cellAddress.font = {
            size: 8,
            bold: true,
          };
          cellAddress.border = {
            top: { style: "thin" },
            bottom: { style: "thin" },
          };
        } else if (rowIndex === 2) {
            cellAddress.border = {
                top: { style: "medium" },
              };
          } else if (rowIndex === 6) {
          cellAddress.font = {
            bold: true,
            underline: "double",
            size: 11,
            color: { argb: "002060" },
          };
        } else if(rowIndex === 8) {
          if (cellIndex === 2 || cellIndex === 6) {
            cellAddress.font = {
              size: 8,
              bold: true,
            };
          }else if(cellIndex === 3){
            cellAddress.font = {
                size: 8,
                bold: true,
                color: { argb: 'FFFF0000' },
              };
          }else{
            cellAddress.font = {
                size: 8,
              };
          }
        }
        else if (rowIndex > 16 && rowIndex <= 16 + len) {

          cellAddress.border = {
            top: { style: "thin", color: { argb: "6e6e6e" } },
            bottom: { style: "thin", color: { argb: "6e6e6e" } },
          };
          cellAddress.alignment = {
            wrapText: "true",
            vertical: "middle",
            horizontal: "center",
          };
          cellAddress.font = {
            size: 8,
            bold: true,
          };
        }
              else if (rowIndex === 10) {
          if (cellIndex === 5) {
            cellAddress.font = {
              size: 8,
              bold: true,
            };
          }else{
            cellAddress.font = {
                size: 8,
              };}
        } else if (rowIndex === 11) {
          if (cellIndex === 5) {
            cellAddress.font = {
              size: 8,
              bold: true,
            };
          }else{
            cellAddress.font = {
                size: 8,
              };}
        }else if (rowIndex === 12) {
            if (cellIndex === 5) {
              cellAddress.font = {
                size: 8,
                bold: true,
              };
            }else{
                cellAddress.font = {
                    size: 8,
                  };}
          }else if (rowIndex === 14) {
              cellAddress.font = {
                size: 8,
                bold: true,
              }; 
          }else if (rowIndex === 17 + len) {  
                cellAddress.font = {
                  size: 8,
                  bold: true,
              }
              cellAddress.alignment = {
                vertical: "middle",
                horizontal: "center",
              };
          }else if (rowIndex === 19 + len) {  
            if (cellIndex === 1) {
                cellAddress.font = {
                  size: 8,
                  bold: true,
                };
              }else{
                cellAddress.font = {
                    size: 8,
                  };}
          }else if (rowIndex === 21 + len) {  
            if (cellIndex === 1) {
                cellAddress.font = {
                  size: 8,
                  bold: true,
                };
              }else{
                cellAddress.font = {
                    size: 8,
                  };}
          }else if (rowIndex === 23 + len) {  
            if (cellIndex === 1) {
                cellAddress.font = {
                  size: 8,
                  bold: true,
                };
              }else if(cellIndex === 2 || cellIndex === 3 || cellIndex === 4){
                cellAddress.border = {
                    bottom: { style: 'dotted', color: { argb: 'FF000000' } },
                  };
              }else{
                cellAddress.font = {
                    size: 8,
                  };}
                }else if (rowIndex === 24 + len) {  
                    cellAddress.font = {
                      size: 8,
                    };
                }
          else if (rowIndex === 26 + len) {  
            if (cellIndex === 1 || cellIndex === 6) {
                cellAddress.font = {
                  size: 8,
                  bold: true,
                };
              }else{
                cellAddress.font = {
                    size: 8,
                  };}
          }else if (rowIndex === 30 + len) {  
            if (cellIndex === 1 || cellIndex === 6) {
                cellAddress.font = {
                  size: 8,
                  bold: true,
                };
              }else{
                cellAddress.font = {
                    size: 8,
                  };}
          }else if (rowIndex === 32 + len) {  
            if (cellIndex === 1) {
                cellAddress.font = {
                  size: 8,
                  bold: true,
                };
              }else{
                cellAddress.font = {
                    size: 8,
                  };}
          }else if (rowIndex === 34 + len) {  
            cellAddress.font = {
              size: 8,
          }
         }else if ((rowIndex === 36 + len)) {  
            if (cellIndex === 1 || cellIndex === 4) {
                cellAddress.font = {
                  size: 8,
                  bold: true,
                };
              }else{
                cellAddress.font = {
                    size: 8,
                  };}
            }else if ((rowIndex === 37 + len) || (rowIndex === 38 + len) || (rowIndex === 39 + len) || (rowIndex === 40 + len) || (rowIndex === 41 + len)) {  
            cellAddress.font = {
              size: 8,
              }
              if (cellIndex === 3) {
                cellAddress.border = {
                    left: { style: "medium", color: { argb: "6e6e6e" } },
                  };
                  if(rowIndex === 38 + len){
                    cellAddress.border = {
                        left: { style: "medium", color: { argb: "6e6e6e" } },
                        top: { style: "medium", color: { argb: "6e6e6e" } },
                      };
                  }
                  if(rowIndex === 41 + len){
                    cellAddress.border = {
                        left: { style: "medium", color: { argb: "6e6e6e" } },
                        bottom: { style: "medium", color: { argb: "6e6e6e" } },
                      };
                  }
              }else if(cellIndex === 4){
                if(rowIndex === 38 + len){
                    cellAddress.border = {
                        top: { style: "medium", color: { argb: "6e6e6e" } },
                      };
                  }
                  if(rowIndex === 41 + len){
                    cellAddress.border = {
                        bottom: { style: "medium", color: { argb: "6e6e6e" } },
                      };
                  }
              }else if(cellIndex === 5){
                if(rowIndex === 38 + len){
                    cellAddress.border = {
                        right: { style: "medium", color: { argb: "6e6e6e" } },
                        top: { style: "medium", color: { argb: "6e6e6e" } },
                      };
                  }
                  else if(rowIndex === 41 + len){
                    cellAddress.border = {
                        right: { style: "medium", color: { argb: "6e6e6e" } },
                        bottom: { style: "medium", color: { argb: "6e6e6e" } },
                      };
                  }
                  else{
                    cellAddress.border = {
                        right: { style: "medium", color: { argb: "6e6e6e" } },
                      };
                  }
              }
            }else if (rowIndex === 46 + len) {  
            if (cellIndex === 1) {
                cellAddress.font = {
                  size: 8,
                  bold: true,
                };
              }else{
                cellAddress.font = {
                    size: 8,
                  };}
          }else if (rowIndex > (46+len) && rowIndex <= (48 + len + documentsRequiredLen)) {  
            cellAddress.font = {
              size: 8,
              }
              if((cellIndex === 6) || (cellIndex === 5)){
                cellAddress.alignment = {
                  horizontal:"center",
                };
              }
            }else if (rowIndex === (49 + len + documentsRequiredLen)) {  
                cellAddress.font = {
                  size: 11,
                  bold: true,
                  color: { argb: 'FFFF0000' },
                };
          }else if (rowIndex === (51 + len + documentsRequiredLen)) {  
            cellAddress.font = {
              size: 8,
              bold: true,
            };
         }else if ((rowIndex === 52 + len + documentsRequiredLen) || (rowIndex === 53 + len + documentsRequiredLen) || (rowIndex === 54 + len + documentsRequiredLen)
         || (rowIndex === 55 + len + documentsRequiredLen) || (rowIndex === 56 + len + documentsRequiredLen)|| (rowIndex === 57 + len + documentsRequiredLen) ||
          (rowIndex === 58 + len + documentsRequiredLen)) {  
           cellAddress.font = {
             size: 8,
             }
           }else if (rowIndex === 60 + len + documentsRequiredLen) {  
            if (cellIndex === 1) {
                cellAddress.font = {
                  size: 8,
                  bold: true,
                };
              }else{
                cellAddress.font = {
                    size: 8,
                  };}
        }else if (rowIndex === 62 + len + documentsRequiredLen) {  
            if (cellIndex === 1) {
                cellAddress.font = {
                  size: 8,
                  bold: true,
                };
              }else{
                cellAddress.font = {
                    size: 8,
                  };}
         }else if (rowIndex === 63 + len + documentsRequiredLen) {  
          if (cellIndex === 1) {
              cellAddress.font = {
                size: 8,
                bold: true,
              };
            }else{
              cellAddress.font = {
                  size: 8,
                };}
       }
       else if (rowIndex === 64 + len + documentsRequiredLen) {  
        if (cellIndex === 1) {
            cellAddress.font = {
              size: 8,
              bold: true,
            };
          }else{
            cellAddress.font = {
                size: 8,
              };}
     }
     else if (rowIndex === 65 + len + documentsRequiredLen) {  
      if (cellIndex === 1) {
          cellAddress.font = {
            size: 8,
            bold: true,
          };
        }else{
          cellAddress.font = {
              size: 8,
            };}
   }else if (rowIndex === 67 + len + documentsRequiredLen) {  
            if (cellIndex === 1) {
                cellAddress.font = {
                  size: 8,
                  bold: true,
                };
              }else{
                cellAddress.font = {
                    size: 8,
                  };}
         }else if (rowIndex > (67+len+documentsRequiredLen) && rowIndex <= (67 + len + documentsRequiredLen + specialInstructionsLen)) {  
          cellAddress.font = {
            size: 8,
            }
          }
             else if ((rowIndex === 70 + len+ documentsRequiredLen + specialInstructionsLen)) {  
               cellAddress.font = {
                 size: 8,
                 }}
                 else if ((rowIndex === 72 + len+ documentsRequiredLen + specialInstructionsLen)) {  
                  cellAddress.font = {
                    size: 8,
                    }
                    cellAddress.alignment = {
                      vertical: "middle",
                      horizontal: "center",
                    };}
                    else if ((rowIndex === 73 + len+ documentsRequiredLen + specialInstructionsLen)) {  
                      cellAddress.font = {
                        size: 8,
                        }
                        cellAddress.alignment = {
                          vertical: "middle",
                          horizontal: "center",
                        };}
      });
      excelRow.commit();
    });

    // Adjust column widths
    /*worksheet.columns.forEach((column, i) => {
        column.width = 10; // Adjust based on your content
      });*/

    // Generate and save the Excel file
    const buffer = await workbook.xlsx.writeBuffer();
    const blob = new Blob([buffer], {
      type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    });
    saveAs(blob, "OfferWithLogo.xlsx");
  });
};


const ExportShippingInstructionOrder = (props) => {
  const { setExportSIPopup, exportSIPopup } = props;
  const [entities, setEntities] = useState([]);
  const [items, setItems] = useState(
    exportSIPopup && exportSIPopup.data
      ? exportSIPopup.data.map((shippingInstruction) => ({
          supplierGradeMark: shippingInstruction?.shipmentRequest?.purchaseOrder?.offer?.item?.buyingGrade || shippingInstruction?.shipmentRequest?.salesOrder?.offer?.item?.buyingGrade || "",
          crop: shippingInstruction?.shipmentRequest?.purchaseOrder?.offer?.item?.crop || shippingInstruction?.shipmentRequest?.salesOrder?.offer?.item?.crop || "",
          dateOfLoadingRemark: shippingInstruction?.dateOfLoadingRemark ||  "",
          numberOfCases: shippingInstruction?.shipmentRequest?.numberOfCases || "",
          shipmentQuantity: shippingInstruction?.shipmentRequest?.shipmentQuantity || "",
          customerGradeMark: shippingInstruction?.shipmentRequest?.customerGradeMark || "",
          cartonNumber: shippingInstruction?.cartonNumber || "",
          volume: shippingInstruction?.volume || "",
          price: shippingInstruction?.finalPrice || "",
          incoterm: shippingInstruction?.incoterm || "",
          estimatedTimeOfDeparture: shippingInstruction?.estimatedTimeOfDeparture
            ? new Date(shippingInstruction?.estimatedTimeOfDeparture).toLocaleDateString(
                "en-GB",
              )
            : "",
          finalPrice: shippingInstruction?.finalPrice || "",
          totalAmount: shippingInstruction?.totalAmount || "",
          toBeShippedBy20: shippingInstruction?.toBeShippedBy20 || "",
          toBeShippedBy40: shippingInstruction?.toBeShippedBy40 || "",
          toBeShippedBy40HC: shippingInstruction?.toBeShippedBy40HC || "",
          toBeShippedByFtl: shippingInstruction?.toBeShippedByFtl || "",
        }))
      : [],
  );
  
  const documentsRequiredList = exportSIPopup?.data[0]?.shipmentRequest?.salesOrder?.offer?.stakeholder?.documentsRequiredList || exportSIPopup?.data[0]?.shipmentRequest?.purchaseOrder?.offer?.stakeholder?.documentsRequiredList || [];

  const getDigit = (type) => {
    if (type === "HT" || type === "HD") {
      return 100;
    } else if (type === "ST" || type === "SD" || type === "B" || type === "SL" || type === "CT") {
      return 1000;
    } else {
      return 1000;
    }
  };

  useEffect(() => {
    setExportSIPopup(prevState => ({
      ...prevState,
      data: prevState.data.map(item => ({
        ...item,
        shippingInstructionStatus: 'PO Created', 
      })),
    }));
  }, []);
  
  useEffect(() => {
    if (entities.length === 0) {
      setEntities(documentsRequiredList);
    }
  }, [documentsRequiredList]);

  const updateStatusForAll = () => {
    updateMultipleOrdersApi(exportSIPopup?.data)
                .then((response) => {
                  console.log(response?.data);
                  toast.success("Purchase shippingInstruction(s) updated successfully.");
                })
                .catch((error) => {
                  console.log(error.response?.data);
                  toast.error("Purchase shippingInstruction(s) could not be deleted.");
                });
  };
  const [shippingInstructionDetails, setShippingInstructionDetails] = useState({
    siNumber: (exportSIPopup?.data[0]?.shippingInstructionType || "") + ((exportSIPopup?.data[0]?.shippingInstructionYear%10)*getDigit(exportSIPopup?.data[0]?.shippingInstructionType)+(exportSIPopup?.data[0]?.shippingInstructionNumber) || "") || "",
    poNumber: exportSIPopup?.data[0]?.shipmentRequest?.purchaseOrder?.purchaseOrderNumber || "",
    items: items,
    siDate: exportSIPopup?.data[0]?.shippingInstructionDate || "",
    specialInstructionsList: exportSIPopup?.data[0]?.shipmentRequest?.salesOrder?.offer?.stakeholder?.specialInstructionsList || "",
    documentsRequiredList: documentsRequiredList || "",
    vendor: exportSIPopup?.data[0]?.shipmentRequest?.purchaseOrder?.offer?.stakeholder || exportSIPopup?.data[0]?.shipmentRequest?.salesOrder?.offer?.stakeholder || "",
    siTo: exportSIPopup?.data[0]?.shipmentRequest?.shipmentRequestTo || "",
    assignedUser: exportSIPopup?.data[0]?.shipmentRequest?.assignedUser || "",
    toBeShippedBy: exportSIPopup?.data[0]?.shippedBy || "",
    toBeShippedBy20: exportSIPopup?.data[0]?.toBeShippedBy20 || "",
    toBeShippedBy40: exportSIPopup?.data[0]?.toBeShippedBy40 || "",
    toBeShippedBy40HC: exportSIPopup?.data[0]?.toBeShippedBy40HC || "",
    toBeShippedByFtl: exportSIPopup?.data[0]?.toBeShippedByFtl || "",
    shipper: exportSIPopup?.data[0]?.shipper || "",
    consignee: exportSIPopup?.data[0]?.consignee || "",
    notify: exportSIPopup?.data[0]?.notify || "",
    pointOfLoading: exportSIPopup?.data[0]?.pointOfLoading || "",
    pointOfUnloading: exportSIPopup?.data[0]?.pointOfUnloading || "",
    description: exportSIPopup?.data[0]?.description || "",
    descriptionTwo: "",
    documentsRequired:"",
    transportationContact: exportSIPopup?.data[0]?.transportationContact || "",
    buyingCompany: exportSIPopup?.data[0]?.shipmentRequest?.salesOrder?.company || exportSIPopup?.data[0]?.shipmentRequest?.purchaseOrder?.company||"",
    revision: exportSIPopup?.data[0]?.revision|| "",
    entities: documentsRequiredList,
  });

  const [showDocumentCounter, setShowDocumentCounter] = useState(false);
  const [showSpecialInstructions, setShowSpecialInstructions] = useState(false);

  const handleToggleDocuments = () => {
    setShowDocumentCounter(!showDocumentCounter);
  };

  const handleToggleSpecialInstructions = () => {
    setShowSpecialInstructions(!showSpecialInstructions);
  };

  const handleNameChange = (index, event) => {
    const newEntities = [...entities];
    newEntities[index].name = event.target.value;
    setEntities(newEntities);
  };

  const incrementCount1 = (index) => {
    const newEntities = [...entities];
    newEntities[index].originalAmount += 1;
    setEntities(newEntities);
  };

  const decrementCount1 = (index) => {
    const newEntities = [...entities];
    if (newEntities[index].originalAmount > 0) {
      newEntities[index].originalAmount -= 1;
      setEntities(newEntities);
    }
  };

  const incrementCount2 = (index) => {
    const newEntities = [...entities];
    newEntities[index].copyAmount += 1;
    setEntities(newEntities);
  };

  const decrementCount2 = (index) => {
    const newEntities = [...entities];
    if (newEntities[index].copyAmount > 0) {
      newEntities[index].copyAmount -= 1;
      setEntities(newEntities);
    }
  };

  const addEntity = () => {
    const newEntities = [...entities, { name: '', originalAmount: 0, copyAmount: 0 }];
    setEntities(newEntities);
    setShippingInstructionDetails((prevDetails) => ({
      ...prevDetails,
      entities: newEntities,
    }));
  };

  const deleteEntity = (index) => {
    const newEntities = entities.filter((_, idx) => idx !== index);
    setEntities(newEntities);
    setShippingInstructionDetails((prevDetails) => ({
      ...prevDetails,
      entities: newEntities,
    }));
  };

  const [specialInstructions, setSpecialInstructions] = useState([]);

  useEffect(() => {
    if (shippingInstructionDetails?.specialInstructionsList) {
      setSpecialInstructions(shippingInstructionDetails.specialInstructionsList);
    }
  }, [shippingInstructionDetails]);

  const handleInstructionChange = (index, event) => {
    const newInstructions = [...specialInstructions];
    newInstructions[index] = event.target.value;
    setSpecialInstructions(newInstructions);
  };

  const createNewList = () => {
    const newList = specialInstructions.filter(instruction => instruction.trim() !== '');
    if (shippingInstructionDetails) {
      shippingInstructionDetails.specialInstructionsList = newList;
    }
  };

  const handleRemoveInstruction = (index) => {
    const newInstructions = specialInstructions.filter((_, i) => i !== index);
    setSpecialInstructions(newInstructions);
  };

  const handleAddInstruction = () => {
    setSpecialInstructions([...specialInstructions, '']);
  };

  return (
    // Added return statement here
    <>
      <Stack direction="column">
        <Typography variant="body1" mb={2}>
          Selected Items
        </Typography>
        {exportSIPopup &&
          exportSIPopup.data &&
          exportSIPopup.data.map((shippingInstruction, index) => (
            <Grid container key={shippingInstruction.id} spacing={2}>
              <Grid item xs={12} sm={0.5}>
                <Typography variant="body2"> SI ID </Typography>
                <TextField
                  disabled
                  variant="outlined"
                  size="small"
                  fullWidth
                  value={shippingInstruction?.id || ""}
                />
              </Grid>
              <Grid item xs={12} sm={0.5}>
                <Typography variant="body2">SR Id</Typography>
                <TextField
                  disabled
                  variant="outlined"
                  size="small"
                  fullWidth
                  value={shippingInstruction?.shipmentRequest?.id || ""}
                />
              </Grid>
              <Grid item xs={12} sm={1.5}>
                <Typography variant="body2">Supplier Grade Mark</Typography>
                <TextField
                  disabled
                  variant="outlined"
                  size="small"
                  fullWidth
                  value={shippingInstruction?.shipmentRequest?.purchaseOrder?.offer?.item?.buyingGrade || shippingInstruction?.shipmentRequest?.salesOrder?.offer?.item?.buyingGrade || ""}
                />
              </Grid>
              <Grid item xs={12} sm={0.5}>
                <Typography variant="body2">Crop</Typography>
                <TextField
                  disabled
                  variant="outlined"
                  size="small"
                  fullWidth
                  value={shippingInstruction?.shipmentRequest?.purchaseOrder?.offer?.item?.crop || shippingInstruction?.shipmentRequest?.salesOrder?.offer?.item?.crop || ""}
                />
              </Grid>
              <Grid item xs={12} sm={1}>
                <Typography variant="body2">Date Of Loading</Typography>
                <TextField
                  disabled
                  variant="outlined"
                  size="small"
                  fullWidth
                  value={shippingInstruction?.dateOfLoadingRemark}
                />
              </Grid>
              <Grid item xs={12} sm={0.75}>
                <Typography variant="body2"># Of Cartons</Typography>
                <TextField
                  disabled
                  variant="outlined"
                  size="small"
                  fullWidth
                  value={shippingInstruction?.shipmentRequest?.numberOfCases || ""}
                />
              </Grid>
              <Grid item xs={12} sm={0.75}>
                <Typography variant="body2">Net Weight/Carton</Typography>
                <TextField
                  disabled
                  variant="outlined"
                  size="small"
                  fullWidth
                  value={shippingInstruction?.shipmentRequest?.netWeightPerCase || ""}
                />
              </Grid>
              <Grid item xs={12} sm={1}>
                <Typography variant="body2">Total Net Weight</Typography>
                <TextField
                  disabled
                  variant="outlined"
                  size="small"
                  fullWidth
                  value={shippingInstruction?.shipmentRequest?.shipmentQuantity || ""}
                />
              </Grid>
              <Grid item xs={12} sm={1.5}>
                <Typography variant="body2">Customer Grade Mark</Typography>
                <TextField
                  disabled
                  variant="outlined"
                  size="small"
                  fullWidth
                  value={shippingInstruction?.shipmentRequest?.customerGradeMark || ""}
                />
              </Grid>
              <Grid item xs={12} sm={1}>
                <Typography variant="body2">Carton Range</Typography>
                <TextField
                  disabled
                  variant="outlined"
                  size="small"
                  fullWidth
                  value={
                    shippingInstruction?.cartonNumber
                  }
                />
              </Grid>
              <Grid item xs={12} sm={0.5}>
                <Typography variant="body2">20"</Typography>
                <TextField
                  disabled
                  variant="outlined"
                  size="small"
                  fullWidth
                  value={
                    shippingInstruction?.toBeShippedBy20
                  }
                />
              </Grid>
              <Grid item xs={12} sm={0.5}>
                <Typography variant="body2">40"</Typography>
                <TextField
                  disabled
                  variant="outlined"
                  size="small"
                  fullWidth
                  value={
                    shippingInstruction?.toBeShippedBy40
                  }
                />
              </Grid>
              <Grid item xs={12} sm={0.5}>
                <Typography variant="body2">40HC</Typography>
                <TextField
                  disabled
                  variant="outlined"
                  size="small"
                  fullWidth
                  value={
                    shippingInstruction?.toBeShippedBy40HC
                  }
                />
              </Grid>
              <Grid item xs={12} sm={0.5}>
                <Typography variant="body2">FTL</Typography>
                <TextField
                  disabled
                  variant="outlined"
                  size="small"
                  fullWidth
                  value={
                    shippingInstruction?.toBeShippedByFtl
                  }
                />
              </Grid>
            </Grid>
          ))}
        <Typography variant="body1" mb={2} marginTop={5}>
          SI Details
        </Typography>
        <Grid container spacing={2} marginBottom={5}>

        <Grid item xs={12} sm={2}>
            <Typography variant="body2">PO Number</Typography>
            <TextField
              variant="outlined"
              size="small"
              fullWidth
              value={shippingInstructionDetails?.poNumber}
              disabled
            />
          </Grid>

          <Grid item xs={12} sm={2}>
            <Typography variant="body2">SI Number</Typography>
            <TextField
              variant="outlined"
              size="small"
              fullWidth
              value={shippingInstructionDetails?.siNumber}
              disabled
            />
          </Grid>

          <Grid item xs={12} sm={2}>
            <Typography variant="body2">SI Date</Typography>
            <TextField
              size="small"
              fullWidth
              variant="outlined"
              id="date"
              type="date"
              value={
                shippingInstructionDetails?.siDate
                  ? format(
                      parseISO(shippingInstructionDetails.siDate),
                      "yyyy-MM-dd",
                    )
                  : ""
              }
              inputProps={{ min: "1999-01-01", max: "2999-01-01" }}
              InputLabelProps={{
                shrink: true,
              }}
              onKeyDown={(e) => e.preventDefault()}
              disabled
            />
          </Grid>

          <Grid item xs={12} sm={1}>
            <Typography variant="body2">Company</Typography>
            <TextField
              variant="outlined"
              size="small"
              fullWidth
              id="outlined-select-title"
              select
              value={shippingInstructionDetails?.buyingCompany}
              onChange={(e) =>
                setShippingInstructionDetails({
                  ...shippingInstructionDetails,
                  buyingCompany: e.target.value,
                })
              }
            >
              {companies.map((option) => (
                <MenuItem key={option.value} value={option.value}>
                  {option.label}
                </MenuItem>
              ))}
            </TextField>
          </Grid>

          <Grid item xs={12} sm={5}></Grid>

          <Grid item xs={12} sm={2}>
            <Typography variant="body2">SI To</Typography>
            <TextField
              variant="outlined"
              size="small"
              fullWidth
              value={shippingInstructionDetails?.siTo}
              disabled
            />
          </Grid>

          <Grid item xs={12} sm={2}>
            <Typography variant="body2">Contact Person</Typography>
            <TextField
              variant="outlined"
              size="small"
              fullWidth
              value={shippingInstructionDetails?.vendor?.contactPerson}
              disabled
            />
          </Grid>

          <Grid item xs={12} sm={2}>
            <Typography variant="body2">Shipper</Typography>
            <TextField
              disabled
              variant="outlined"
              size="small"
              fullWidth
              value={shippingInstructionDetails?.shipper?.name}
            />
          </Grid>

          <Grid item xs={12} sm={2}>
            <Typography variant="body2">Consignee</Typography>
            <TextField
              disabled
              variant="outlined"
              size="small"
              fullWidth
              value={shippingInstructionDetails?.consignee?.name}
            />
          </Grid>

          <Grid item xs={12} sm={2}>
            <Typography variant="body2">Notify Parties</Typography>
            <TextField
              disabled
              variant="outlined"
              size="small"
              fullWidth
              value={shippingInstructionDetails?.notify?.name}
            />
          </Grid>
          <Grid item xs={12} sm={2}></Grid>
        <Grid item xs={12} sm={1.33}>
            <Typography variant="body2">
            Total 20"
            </Typography>
            <TextField
              variant="outlined"
              size="small"
              fullWidth
              disabled
              value={shippingInstructionDetails?.items.reduce((total, item) => {
                return total + (item?.toBeShippedBy20 || 0);
              }, 0)}
            />
          </Grid> 
          <Grid item xs={12} sm={1.33}>
            <Typography variant="body2">
            Total 40"
            </Typography>
            <TextField
              variant="outlined"
              size="small"
              fullWidth
              disabled
              value={shippingInstructionDetails?.items.reduce((total, item) => {
                return total + (item?.toBeShippedBy40 || 0);
              }, 0)}
            />
          </Grid> 
          <Grid item xs={12} sm={1.33}>
            <Typography variant="body2">
            Total 40HC"
            </Typography>
            <TextField
              variant="outlined"
              size="small"
              fullWidth
              disabled
              value={shippingInstructionDetails?.items.reduce((total, item) => {
                return total + (item?.toBeShippedBy40HC || 0);
              }, 0)}
            />
          </Grid> 
          <Grid item xs={12} sm={1.33}>
            <Typography variant="body2">
            Total FTL
            </Typography>
            <TextField
              variant="outlined"
              size="small"
              fullWidth
              disabled
              value={shippingInstructionDetails?.items.reduce((total, item) => {
                return total + (item?.toBeShippedByFtl || 0);
              }, 0)}
            />
          </Grid> 
          <Grid item xs={12} sm={4}></Grid>
          <Grid item xs={12} sm={5.5}>
            <Typography variant="body2">
              Point Of Loading
            </Typography>
            <TextField
              variant="outlined"
              size="small"
              fullWidth
              value={shippingInstructionDetails?.pointOfLoading || ""}
              disabled
              inputProps={{
                maxLength: 100,
              }}
            />
          </Grid>
          <Grid item xs={12} sm={5.5}>
            <Typography variant="body2">
            Point Of Unloading
            </Typography>
            <TextField
              variant="outlined"
              size="small"
              fullWidth
              value={shippingInstructionDetails?.pointOfUnloading || ""}
              disabled
              inputProps={{
                maxLength: 100,
              }}
            />
          </Grid>

          <Grid item xs={12} sm={5.5}>
            <Typography variant="body2">
            Revision
            </Typography>
            <TextField
              variant="outlined"
              size="small"
              fullWidth
              disabled
              value={shippingInstructionDetails?.revision}
            />
          </Grid> 

          <Grid item xs={12} sm={5.5}>
            <Typography variant="body2">
            Description
            </Typography>
            <TextField
              variant="outlined"
              size="small"
              fullWidth
              value={shippingInstructionDetails?.description}
              onChange={(e) => {
                setShippingInstructionDetails({
                  ...shippingInstructionDetails,
                  description: e.target.value,
                });
                if (e.target.value.length === 100) {
                  toast.error("Character limit (100) exceeded");
                }
              }}
              inputProps={{
                maxLength: 100,
              }}
            />
          </Grid> 
         
          <Grid item xs={12} sm={5.5}>
            <Typography variant="body2">
            Description Line 2
            </Typography>
            <TextField
              variant="outlined"
              size="small"
              fullWidth
              value={shippingInstructionDetails?.descriptionTwo}
              onChange={(e) => {
                setShippingInstructionDetails({
                  ...shippingInstructionDetails,
                  descriptionTwo: e.target.value,
                });
                if (e.target.value.length === 100) {
                  toast.error("Character limit (100) exceeded");
                }
              }}
              inputProps={{
                maxLength: 100,
              }}
            />
          </Grid> 

    {showDocumentCounter && entities.map((entity, index) => (
  <Grid container key={index} spacing={2} alignItems="center" marginLeft={5} marginTop={1}>
    <Grid item xs={12} sm={6}>
      <Typography variant="body2" component="div" sx={{ display: 'flex', alignItems: 'center' }}>
        Documents Required
      </Typography>
      <TextField
        variant="outlined"
        size="small"
        fullWidth
        placeholder="Document Name"
        value={entity?.name}
        onChange={(event) => handleNameChange(index, event)}
      />
    </Grid>
    <Grid item xs={12} sm={6} container spacing={1}>
      <Grid item>
        <Typography>Originals:</Typography>
      </Grid>
      <Grid item>
        <ButtonGroup variant="outlined">
          <Button onClick={() => decrementCount1(index)}>-</Button>
          <Button>{entity?.originalAmount}</Button>
          <Button onClick={() => incrementCount1(index)}>+</Button>
        </ButtonGroup>
      </Grid>
      <Grid item>
        <Typography>Copies:</Typography>
      </Grid>
      <Grid item>
        <ButtonGroup variant="outlined">
          <Button onClick={() => decrementCount2(index)}>-</Button>
          <Button>{entity?.copyAmount}</Button>
          <Button onClick={() => incrementCount2(index)}>+</Button>
        </ButtonGroup>
      </Grid>
      <Grid item>
        <Button variant="contained" color="error"  onClick={() => deleteEntity(index)}>
          Delete
        </Button>
      </Grid>
    </Grid>
  </Grid>
))}
<Grid item xs={12} sm={12}></Grid>
       {showDocumentCounter && <Grid container spacing={2} marginLeft={5} marginTop={0.5} marginBottom={1}>
          <Grid item xs={12} sm={12}>
            <Button variant="outlined" color="primary" onClick={addEntity}>
              Add New Document
            </Button>
          </Grid>
        </Grid>}

          <Grid item xs={12} sm={12}>
          <Button variant="contained" onClick={handleToggleDocuments}  color={showDocumentCounter ? 'success' : 'primary'}>
          {showDocumentCounter ? 'Save Documents Required' : 'Edit Documents Required'}
      </Button>
          </Grid> 

        <Grid item xs={12} sm={12}>
          <Button variant="contained" onClick={handleToggleSpecialInstructions} style={{ paddingRight: '20px', paddingLeft: '20px' }}>
          Edit Special Instructions
      </Button>
          </Grid> 

          {showSpecialInstructions &&  <Grid item xs={12} sm={12}>
        <Typography variant="body2">Special Instructions</Typography>
        {specialInstructions.map((instruction, index) => (
          <Grid container spacing={1} alignItems="center" key={index}>
            <Grid item xs={10}>
              <TextField
                variant="outlined"
                size="small"
                fullWidth
                value={instruction}
                onChange={(event) => handleInstructionChange(index, event)}
                margin="normal"
              />
            </Grid>
            <Grid item xs={2}>
              <IconButton
                onClick={() => handleRemoveInstruction(index)}
                style={{ color: 'red' }}
              >
                <DeleteIcon />
              </IconButton>
            </Grid>
          </Grid>
        ))}
        <Button
          variant="outlined"
          onClick={handleAddInstruction}
          style={{ marginTop: '10px' }}
        >
          Add New Instruction
        </Button>
        <Button
          variant="contained"
          color="success"
          onClick={() => {
            createNewList();
            handleToggleSpecialInstructions();
          }}
          style={{ marginTop: '10px', marginLeft: '10px' }}
        >
          Save Special Instructions List
        </Button>
      </Grid>}

<Grid item xs={12} sm={5.5}>
            <Typography variant="body2">
            Contact Details For Transportation
            </Typography>
            <TextField
              variant="outlined"
              size="small"
              fullWidth
              value={shippingInstructionDetails?.transportationContact}
              onChange={(e) => {
                setShippingInstructionDetails({
                  ...shippingInstructionDetails,
                  transportationContact: e.target.value,
                });
                if (e.target.value.length === 100) {
                  toast.error("Character limit (100) exceeded");
                }
              }}
              inputProps={{
                maxLength: 100,
              }}
            />
          </Grid> 
        
        </Grid>
        <Stack direction="row" spacing={1} mt={1}>
          <Button
            variant="outlined"
            onClick={() =>{
              setExportSIPopup({
                data: null,
                visible: false,
              })
            }}
          >
            Cancel
          </Button>
          <Button
            variant="contained"
            style={{ whiteSpace: "nowrap" }}
            onClick={() => {createExcelWithLogoAndData(shippingInstructionDetails)
            updateStatusForAll()}}
          >
            Create Shipping Instruction File
          </Button>
        </Stack>
      </Stack>
    </>
  );
};

export default ExportShippingInstructionOrder;

import { Autocomplete, Chip, MenuItem, Typography } from "@mui/material";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Checkbox from "@mui/material/Checkbox";
import FormControlLabel from "@mui/material/FormControlLabel";
import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import Switch from "@mui/material/Switch";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import TextField from "@mui/material/TextField";
import { format } from "date-fns";
import * as React from "react";
import { useEffect, useState } from "react";
import {
  retrieveAllWarehouseApi,
  retrieveFilteredWarehouseRecordsApi,
} from "../../../../components/api/WarehouseApiService";
import { recordStatus } from "../../../../utils/menu-items";
import HistoryButton from "../../WarehouseView/Dialogs/HistoryButton";
import AddStoragePrice from "../AddView/AddStoragePrice";
import EditStoragePrice from "../EditView/EditStoragePrice";
import { getComparator, stableSort } from "../utils";
import EnhancedTableHead from "./EnhancedStoragePriceTableHead";
import EnhancedTableToolbar from "./EnhancedStoragePriceTableToolbar";
import {
  retrieveAllStoragePriceApi,
  retrieveFilteredStoragePricesApi,
} from "../../../../components/api/StoragePriceApiService";

export default function StoragePrice() {
  const [order, setOrder] = useState("asc");
  const [orderBy, setOrderBy] = useState("calories");
  const [selected, setSelected] = useState([]);
  const [selectedData, setSelectedData] = useState([]);
  const [page, setPage] = useState(0);
  const [dense, setDense] = useState(true);
  const [rowsPerPage, setRowsPerPage] = useState(25);
  const [showFilters, setShowFilters] = useState(false);
  const [addView, setAddView] = useState(false);
  const [rows, setRows] = useState([]);
  const [selectedWarehouse, setSelectedWarehouse] = useState(null);
  const [warehouses, setWarehouses] = useState([]);

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const [filterStorages, setFilterStorages] = useState({
    recordStatusList: ["ACTIVE"],
    warehouseIdList: [],
  });

  const [componentsState, setComponentsState] = useState({
    warehouse: {
      dropdownOpen: false,
      inputValue: "",
    },
  });

  const updateComponentState = (componentKey, newState) => {
    setComponentsState((prevState) => ({
      ...prevState,
      [componentKey]: {
        ...prevState[componentKey],
        ...newState,
      },
    }));
  };

  useEffect(
    () =>
      retrieveFilteredStorages({
        recordStatusList: ["ACTIVE"],
        warehouseIdList: [],
      }),
    [],
  );

  function retrieveFilteredStorages(filterStorages) {
    retrieveFilteredStoragePricesApi(filterStorages)
      .then((response) => {
        setRows(response?.data);
        return response?.data || [];
      })
      .catch((error) => console.log(error.response?.data));
  }
  function retrieveWarehouse() {
    retrieveFilteredWarehouseRecordsApi({
      recordStatusList: ["ACTIVE"],
    })
      .then((response) => {
        setWarehouses(response?.data);
      })
      .catch((error) => console.log(error.response?.data));
  }

  const [editStorage, setEditStorage] = useState({
    visible: false,
    data: null,
    index: null,
  });

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelected = rows.map((n) => n.id);
      setSelected(newSelected);
      setSelectedData(rows);
      return;
    } else {
      setSelected([]);
      setSelectedData([]);
    }
  };

  const handleClick = (event, name, row) => {
    const selectedIndex = selected.indexOf(name);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1),
      );
    }

    const selectedDataIndex = selectedData.indexOf(row);
    let newSelectedData = [];

    if (selectedDataIndex === -1) {
      newSelectedData = newSelectedData.concat(selectedData, row);
    } else if (selectedDataIndex === 0) {
      newSelectedData = newSelectedData.concat(selectedData.slice(1));
    } else if (selectedDataIndex === selectedData.length - 1) {
      newSelectedData = newSelectedData.concat(selectedData.slice(0, -1));
    } else if (selectedDataIndex > 0) {
      newSelectedData = newSelectedData.concat(
        selectedData.slice(0, selectedDataIndex),
        selectedData.slice(selectedDataIndex + 1),
      );
    }
    setSelectedData(newSelectedData);

    setSelected(newSelected);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleChangeDense = (event) => {
    setDense(event.target.checked);
  };

  const isSelected = (name) => selected.indexOf(name) !== -1;
  const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - rows.length) : 0;

  const visibleRows = React.useMemo(
    () =>
      stableSort(rows, getComparator(order, orderBy)).slice(
        page * rowsPerPage,
        page * rowsPerPage + rowsPerPage,
      ),
    [order, orderBy, page, rowsPerPage, rows, editStorage],
  );

  return (
    <div>
      {addView ? (
        <AddStoragePrice
          rows={rows}
          setRows={setRows}
          setAddView={setAddView}
        />
      ) : editStorage.visible ? (
        <EditStoragePrice
          rows={rows}
          setRows={setRows}
          setAddView={setAddView}
          editStorage={editStorage}
          setEditStorage={setEditStorage}
        />
      ) : (
        <>
          {showFilters && (
            <Box
              component="form"
              sx={{
                "& .MuiTextField-root": { m: 1, width: "25ch" },
              }}
              noValidate
              autoComplete="off"
            >
              <Grid container spacing={1} justifyContent="flex-start" alignItems="center">
                  <Autocomplete
        multiple
        id="outlined-select-warehouse"
        disableClearable
        open={componentsState.warehouse.dropdownOpen}
        onOpen={() => updateComponentState("warehouse", { dropdownOpen: true })}
        inputValue={componentsState.warehouse.inputValue}
        onInputChange={(event, newInputValue, reason) => {
          if (reason === "input") {
            updateComponentState("warehouse", { inputValue: newInputValue });
          }
        }}
        options={[
          ...warehouses.filter((warehouse) =>
            filterStorages.warehouseIdList.includes(warehouse.id),
          ),
          ...warehouses.filter(
            (warehouse) => !filterStorages.warehouseIdList.includes(warehouse.id),
          ),
        ]}
        getOptionLabel={(option) =>
          `${option?.id || ""} - ${option?.code || ""} - ${
            option?.name || ""
          }`
        }
        value={warehouses.filter((warehouse) =>
          filterStorages.warehouseIdList.includes(warehouse.id),
        )}
        onChange={(event, newValue) => {
          setFilterStorages({
            ...filterStorages,
            warehouseIdList: newValue.map((warehouse) => warehouse.id),
          });
        }}
        renderOption={(props, option, { selected }) => (
          <li
            {...props}
            style={{
              ...props.style,
              backgroundColor: selected ? "lightblue" : undefined,
              fontWeight: selected ? 700 : 400,
            }}
          >
            {`${option?.id || ""} - ${option?.code || ""} - ${
              option?.name || ""
            }`}
          </li>
        )}
        renderInput={(params) => {
          const label =
            filterStorages?.warehouseIdList.length > 0
              ? `Warehouses Selected (${filterStorages?.warehouseIdList.length})`
              : "Warehouse";

          return (
            <TextField
              {...params}
              label={label}
              type="search"
              value={componentsState.warehouse.inputValue}
              onChange={(e) =>
                updateComponentState("warehouse", { inputValue: e.target.value })
              }
              onMouseDown={retrieveWarehouse}
              InputProps={{
                ...params.InputProps,
                style: { height: 42 },
              }}
              onBlur={() =>
                updateComponentState("warehouse", { dropdownOpen: false })
              }
            />
          );
        }}
        renderTags={() => null}
      />
 
        <TextField
        id="outlined-number"
        label="Year"
        value={filterStorages?.year || ""}
        type="number"
        onChange={(e) =>
          setFilterStorages({
            ...filterStorages,
            year: e.target.value,
          })
        }
      />
              </Grid>
              <Grid
                container
                spacing={2}
                justifyContent="flex-start"
                alignItems="center"
                sx={{ mt: 1 }}
              >
                <Grid item xs={3} sm={1}>
                  <Button
                    disableElevation
                    variant="contained"
                    fullWidth
                    onClick={() => {
                      retrieveFilteredStoragePricesApi(filterStorages)
                        .then((response) => {
                          setRows(response?.data);
                        })
                        .catch((error) => {
                          console.log(error?.response?.data);
                        });
                    }}
                  >
                    Search
                  </Button>
                </Grid>
                <Grid item xs={3} sm={1}>
                  <Button
                    disableElevation
                    variant="outlined"
                    fullWidth
                    onClick={() => {
                      setFilterStorages({
                        year: null,
                        warehouseIdList: [],
                        statusList: ["ACTIVE"],
                      });
                    }}
                  >
                    Clear Filters
                  </Button>
                </Grid>
              </Grid>
            </Box>
          )}
          <Box sx={{ width: "100%", mt: 2 }}>
            <Paper sx={{ width: "100%", mb: 2 }}>
              <EnhancedTableToolbar
                numSelected={selected.length}
                showFilters={showFilters}
                setShowFilters={setShowFilters}
                setAddView={setAddView}
                selected={selected}
                selectedData={selectedData}
              />
              <TableContainer style={{ maxHeight: 700 }}>
                <Table
                  stickyHeader
                  aria-label="sticky table"
                  sx={{
                    minWidth: 750,
                    "& .MuiTableCell-head": {
                      backgroundColor: "#20315d",
                      color: "white",
                    },
                  }}
                  aria-labelledby="tableTitle"
                  size={dense ? "small" : "medium"}
                >
                  <EnhancedTableHead
                    numSelected={selected.length}
                    order={order}
                    orderBy={orderBy}
                    onSelectAllClick={handleSelectAllClick}
                    onRequestSort={handleRequestSort}
                    rowCount={rows.length}
                  />
                  <TableBody>
                    {visibleRows.map((row, index) => {
                      const isItemSelected = isSelected(row.id);
                      const labelId = `enhanced-table-checkbox-${index}`;

                      return (
                        <TableRow
                          style={
                            index % 2
                              ? { background: "#f0f0f0" }
                              : { background: "white" }
                          }
                          hover
                          onClick={(event) => handleClick(event, row.id, row)}
                          role="checkbox"
                          aria-checked={isItemSelected}
                          tabIndex={-1}
                          key={row.id}
                          selected={isItemSelected}
                          sx={{ cursor: "pointer" }}
                        >
                          <TableCell padding="checkbox">
                            <Checkbox
                              color="primary"
                              checked={isItemSelected}
                              inputProps={{
                                "aria-labelledby": labelId,
                              }}
                            />
                          </TableCell>
                          <TableCell padding="normal">
                            <Button
                              onClick={(e) => {
                                setEditStorage({
                                  visible: true,
                                  data: row,
                                  index: index,
                                });
                                e.preventDefault();
                              }}
                            >
                              Edit
                            </Button>
                          </TableCell>
                          <TableCell align="center">{row?.id}</TableCell>
                          <TableCell align="center">
                            {row?.warehouse?.name}
                          </TableCell>
                          <TableCell align="center">{row?.year}</TableCell>
                          <TableCell align="center">{row?.currency}</TableCell>
                          <TableCell align="center">{row?.january}</TableCell>
                          <TableCell align="center">{row?.february}</TableCell>
                          <TableCell align="center">{row?.march}</TableCell>
                          <TableCell align="center">{row?.april}</TableCell>
                          <TableCell align="center">{row?.may}</TableCell>
                          <TableCell align="center">{row?.june}</TableCell>
                          <TableCell align="center">{row?.july}</TableCell>
                          <TableCell align="center">{row?.august}</TableCell>
                          <TableCell align="center">{row?.september}</TableCell>
                          <TableCell align="center">{row?.october}</TableCell>
                          <TableCell align="center">{row?.november}</TableCell>
                          <TableCell align="center">{row?.december}</TableCell>
                        </TableRow>
                      );
                    })}
                    {emptyRows > 0 && (
                      <TableRow
                        style={{
                          height: (dense ? 33 : 53) * emptyRows,
                        }}
                      >
                        <TableCell colSpan={6} />
                      </TableRow>
                    )}
                  </TableBody>
                </Table>
              </TableContainer>
              <TablePagination
                rowsPerPageOptions={[5, 10, 25, 50]}
                component="div"
                count={rows.length}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
              />
            </Paper>
            <FormControlLabel
              control={<Switch checked={dense} onChange={handleChangeDense} />}
              label="Dense padding"
            />
          </Box>
        </>
      )}
    </div>
  );
}

import {
  Autocomplete,
  Button,
  Checkbox,
  Chip,
  FormControlLabel,
  Grid,
  MenuItem,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from "@mui/material";
import Box from "@mui/material/Box";
import { format, parseISO } from "date-fns";
import React, { useEffect, useState } from "react";
import toast from "react-hot-toast";
import { useNavigate } from "react-router-dom";
import { retrieveFilteredOrdersApi } from "../../../components/api/OrderApiService";
import { retrieveQuantityByItemIdApi } from "../../../components/api/QuantityApiService";
import { createShipmentRequestApi, retrieveFilteredShipmentRequestsApi } from "../../../components/api/ShipmentRequestApiService";
import { status, units, portsOfDischarge, portsOfLoading } from "../../../utils/menu-items";
import WarehouseInventoryButton from "../ShipmentRequestView/Dialogs/WarehouseInventoryButton";
import { retrieveFilteredWarehouseRecordsApi } from "../../../components/api/WarehouseApiService";

const CreatePurchaseOrderShipment = (props) => {
  const {
    setCreatePurchaseOrderShipmentRequest,
    createPurchaseOrderShipmentRequest,
  } = props;

  const [shipmentRequestData, setShipmentRequestData] = useState({
    id: 0,
    shipmentRequestType: "TC",
    offerId: createPurchaseOrderShipmentRequest?.data?.id,
    purchaseOrderId: createPurchaseOrderShipmentRequest?.data?.id,
    portOfLoading: createPurchaseOrderShipmentRequest?.data?.portOfLoading || "",
    portOfDischarge: createPurchaseOrderShipmentRequest?.data?.portOfDischarge || "",
    shipmentRequestTo: createPurchaseOrderShipmentRequest?.data?.offer?.stakeholder?.name || "",
    netWeightPerCase: createPurchaseOrderShipmentRequest?.data?.netWeightPerCase || "",
    numberOfCases: createPurchaseOrderShipmentRequest?.data?.numberOfCases || "",
    customerGradeMark: createPurchaseOrderShipmentRequest?.data?.offer?.salesGrade || "",
    productionTobaccoCode: createPurchaseOrderShipmentRequest?.data?.offer?.item?.tobaccoCode || "",
    shipmentQuantity: createPurchaseOrderShipmentRequest?.data?.volume || "",
    shipmentQuantityUnit: createPurchaseOrderShipmentRequest?.data?.volumeUnit || "KG",
    shipmentEstimatedTimeOfDeparture: createPurchaseOrderShipmentRequest?.data?.estimatedTimeOfDeparture || "",
    approvalStatus:"Not Approved",
  });

  const [isHistoryVisible, setIsHistoryVisible] = useState(false);
  const [shipmentRequests, setShipmentRequests] = useState(null);

  const [warehouses, setWarehouses] = useState([]);
  const [selectedWarehouse, setSelectedWarehouse] = useState(null);

  const filterWarehouse = {
    recordStatusList: ["ACTIVE"],
  };

  function retrieveFilteredWarehouseRecords(filterWarehouse) {
    retrieveFilteredWarehouseRecordsApi(filterWarehouse)
      .then((response) => {
        setWarehouses(response?.data);
        return response?.data || [];
      })
      .catch((error) => console.log(error.response?.data));
  }

  function retrieveQuantity(itemId) {
    retrieveQuantityByItemIdApi(itemId)
      .then((response) => {
        console.log("QUANTITY: ", response?.data);
        setQuantity(response?.data);
      })
      .catch((error) => console.log(error.response?.data));
  }

  function retrieveFilteredOrders(orderDto) {
    retrieveFilteredOrdersApi(orderDto)
      .then((response) => {
        setOrders(response?.data);
        return response?.data || [];
      })
      .catch((error) => console.log(error.response?.data));
  }

  function retrieveFilteredShipmentRequests(filterShipmentRequest) {
    retrieveFilteredShipmentRequestsApi(filterShipmentRequest)
      .then((response) => {
        setShipmentRequests(response?.data);
        return response?.data || [];
      })
      .catch((error) => console.log(error.response?.data));
  }

  const handleHistoryClick = () => {
    const filterShipmentRequest = {purchaseOrderIdList: [createPurchaseOrderShipmentRequest?.data?.id], recordStatusList: ["ACTIVE"],}
    retrieveFilteredShipmentRequests(filterShipmentRequest);
    setIsHistoryVisible((prevVisibility) => !prevVisibility);
  };

  const orderDto = {
    orderType: "CUSTOMER",
    offerId: createPurchaseOrderShipmentRequest?.data?.offer?.id,
    offerIdList: [] || [],
    itemIdList: [createPurchaseOrderShipmentRequest?.data?.offer?.item?.id],
    stakeholderIdList: [],
    costIdList: [],
    companyList: [],
    approvalStatusList: ["Approved"],
    orderStatusList: [],
    customerList: [],
    firstSampleIdList: [],
    secondSampleIdList: [],
    firstSampleStatusList: [],
    secondSampleStatusList: [],
    paymentTermList: [],
    incotermList: [],
    packagingList: [],
    volumeUnitList: [],
    portOfLoadingList: [],
    portOfDischargeList: [],
    firstPriceCurrencyList: [],
    finalPriceCurrencyList: [],
    documentStatusList: [],
    originList: [],
    typeList: [],
    leafFormList: [],
    colorList: [],
    storageList: [],
    remainingQuantity:1,
    recordStatusList: ["ACTIVE"],
  };

  const [orders, setOrders] = useState([]);
  const [quantity, setQuantity] = useState([]);
  const [selectedPortOfLoading, setSelectedPortOfLoading] = useState(null);
  const [selectedPortOfDischarge, setSelectedPortOfDischarge] = useState(null);

  useEffect(() => {
    retrieveQuantity(
      createPurchaseOrderShipmentRequest?.data?.offer?.item?.id,
    );
  }, []);

  const [selectedSo, setSelectedSo] = useState(null);
  const [twSelected, setTwSelected] = useState(false);
  const [tpSelected, setTpSelected] = useState(false);
  const [tpSales, setTpSales] = useState(false);
  const handleCheckboxChange = (event) => {
    const { name, checked } = event.target;
    if (name === "tp") {
      setTpSelected(checked);
      setTwSelected(false);
      setTpSales(false);
      setShipmentRequestData({
        ...shipmentRequestData,
        shipmentRequestType: "FVTP",
        destination: "",
        warehouse: "",
        productionTobaccoCode: createPurchaseOrderShipmentRequest?.data?.offer?.item?.tobaccoCode || "",
      });
    } else if (name === "tw") {
      setTpSelected(false);
      setTwSelected(checked);
      setShipmentRequestData({
        ...shipmentRequestData,
        shipmentRequestType: "FVTW",
        destination: "",
        warehouse: "",
        productionTobaccoCode: "",
      });
    }
  };
  const tpOptions = [
    {
      value: true,
      label: "Sales",
    },
    {
      value: false,
      label: "Inward",
    },
  ];

  const navigate = useNavigate();

  return (
    <>
      <Grid container>
        <Grid item xs={12} style={{ position: "relative" }}>
          <Typography
            variant="h3"
            align="center"
            style={{
              color: "#777f87",
              position: "absolute",
              top: "3px",
              left: "50%",
              transform: "translateX(-50%)",
              backgroundColor: "#fafafb",
              padding: "0 10px",
            }}
          >
           PO Details {createPurchaseOrderShipmentRequest?.data?.id}
          </Typography>
        </Grid>
        <Grid
          container
          spacing="12px"
          style={{
            border: "1px solid #dbd9d9",
            borderRadius: "20px",
            marginTop: "20px",
            marginBottom: "10px",
            padding: "20px",
            boxShadow: "2px 4px 8px rgba(0, 0, 0, 0.2)",
          }}
        >
          <Grid item xs={12} sm={1}>
            <Typography variant="body2">PO Quantity</Typography>
            <TextField
              disabled
              variant="outlined"
              size="small"
              value={
                (createPurchaseOrderShipmentRequest?.data?.numberOfCases ||
                  "") + " cases"
              }
              fullWidth
            />
          </Grid>

          <Grid item xs={12} sm={1}>
            <Typography style={{ color: "#fafafb" }} variant="body2">
              PO Quantity
            </Typography>
            <TextField
              disabled
              variant="outlined"
              size="small"
              value={
                (createPurchaseOrderShipmentRequest?.data?.netWeightPerCase ||
                  "") +
                " " +
                (createPurchaseOrderShipmentRequest?.data?.volumeUnit || "") +
                " per case"
              }
              fullWidth
            />
          </Grid>

          <Grid item xs={12} sm={1}>
            <Typography style={{ color: "#fafafb" }} variant="body2">
              PO Quantity
            </Typography>
            <TextField
              disabled
              variant="outlined"
              size="small"
              value={
                (createPurchaseOrderShipmentRequest?.data?.volume || "") +
                " " +
                (createPurchaseOrderShipmentRequest?.data?.volumeUnit || "")
              }
              fullWidth
            />
          </Grid>

          <Grid item xs={12} sm={1}>
            <Typography variant="body2">Item ID</Typography>
            <TextField
              disabled
              variant="outlined"
              size="small"
              value={
                createPurchaseOrderShipmentRequest?.data?.offer?.item?.id || ""
              }
              fullWidth
            />
          </Grid>

          <Grid item xs={12} sm={1}>
            <Typography variant="body2">Origin</Typography>
            <TextField
              disabled
              variant="outlined"
              size="small"
              value={
                createPurchaseOrderShipmentRequest?.data?.offer?.item?.origin ||
                ""
              }
              fullWidth
            />
          </Grid>

          {(createPurchaseOrderShipmentRequest?.data?.offer?.item?.origin==="China" &&  <Grid item xs={12} sm={1}>
            <Typography variant="body2">Province</Typography>
            <TextField
              disabled
              variant="outlined"
              size="small"
              value={
                createPurchaseOrderShipmentRequest?.data?.offer?.item?.province ||
                ""
              }
              fullWidth
            />
          </Grid>)}

          <Grid item xs={12} sm={1}>
            <Typography variant="body2">Type</Typography>
            <TextField
              disabled
              variant="outlined"
              size="small"
              value={
                createPurchaseOrderShipmentRequest?.data?.offer?.item?.type ||
                ""
              }
              fullWidth
            />
          </Grid>
          <Grid item xs={12} sm={1}>
            <Typography variant="body2">Buying Grade</Typography>
            <TextField
              disabled
              variant="outlined"
              size="small"
              value={
                createPurchaseOrderShipmentRequest?.data?.offer?.item
                  ?.buyingGrade || ""
              }
              fullWidth
            />
          </Grid>

          {/*<Grid item xs={12} sm={1}>
            <Typography variant="body2">Sales Grade</Typography>
            <TextField
              disabled
              variant="outlined"
              size="small"
              value={
                createPurchaseOrderShipmentRequest?.data?.offer?.item
                  ?.salesGrade || ""
              }
              fullWidth
            />
          </Grid>
*/}
          <Grid item xs={12} sm={1}>
            <Typography variant="body2">Crop</Typography>
            <TextField
              disabled
              variant="outlined"
              size="small"
              value={
                createPurchaseOrderShipmentRequest?.data?.offer?.item?.crop ||
                ""
              }
              fullWidth
            />
          </Grid>

          <Grid item xs={12} sm={1}>
            <Typography variant="body2">Redrawn Status</Typography>
            <TextField
              disabled
              variant="outlined"
              size="small"
              value={
                createPurchaseOrderShipmentRequest?.data?.secondSampleStatus ||
                ""
              }
              fullWidth
            />
          </Grid>

          <Grid item xs={12} sm={1}>
            <Typography variant="body2">SAP PO Number</Typography>
            <TextField
              disabled
              variant="outlined"
              size="small"
              value={
                createPurchaseOrderShipmentRequest?.data?.purchaseOrderNumber ||
                ""
              }
              fullWidth
            />
          </Grid>

          <Grid item xs={12} sm={1}>
            <Typography variant="body2">Vendor</Typography>
            <TextField
              disabled
              variant="outlined"
              size="small"
              value={
                createPurchaseOrderShipmentRequest?.data?.offer?.stakeholder
                  ?.name || ""
              }
              fullWidth
            />
          </Grid>

          <Grid item xs={12} sm={1}>
            <Typography variant="body2">Current Location</Typography>
            <TextField
              disabled
              variant="outlined"
              size="small"
              value={
                createPurchaseOrderShipmentRequest?.data?.offer
                  ?.storage || ""
              }
              fullWidth
            />
          </Grid>

          <Grid item xs={12} sm={1}>
            <Typography variant="body2">ETD</Typography>
            <TextField
              disabled
              variant="outlined"
              size="small"
              defaultValue={
                createPurchaseOrderShipmentRequest?.data
                  ?.estimatedTimeOfDeparture
                  ? format(
                      parseISO(
                        createPurchaseOrderShipmentRequest.data
                          .estimatedTimeOfDeparture,
                      ),
                      "yyyy-MM-dd",
                    )
                  : ""
              }
              fullWidth
            />
          </Grid>

          <Grid item xs={12} sm={1}>
            <Typography variant="body2">Port Of Loading</Typography>
            <TextField
              disabled
              variant="outlined"
              size="small"
              value={
                createPurchaseOrderShipmentRequest?.data?.portOfLoading || ""
              }
              fullWidth
            />
          </Grid>

          <Grid item xs={12} sm={1}>
            <Typography variant="body2">Port Of Discharge</Typography>
            <TextField
              disabled
              variant="outlined"
              size="small"
              value={
                createPurchaseOrderShipmentRequest?.data?.portOfDischarge || ""
              }
              fullWidth
            />
          </Grid>

        </Grid>

        {tpSelected && tpSales && (
          <Grid item xs={12} style={{ position: "relative" }}>
            <Typography
              variant="h3"
              align="center"
              style={{
                color: "#777f87",
                position: "absolute",
                top: "3px",
                left: "50%",
                transform: "translateX(-50%)",
                backgroundColor: "#fafafb",
                padding: "0 10px",
              }}
            >
              SO Details
            </Typography>
          </Grid>
        )}
        {tpSelected && tpSales && (
          <Grid
            container
            spacing="12px"
            style={{
              border: "1px solid #dbd9d9",
              borderRadius: "20px",
              marginTop: "20px",
              marginBottom: "10px",
              padding: "20px",
              boxShadow: "2px 4px 8px rgba(0, 0, 0, 0.2)",
            }}
          >
            <Grid item xs={12} sm={2}>
              <Typography variant="body2">Select SAP SO Number Of Item</Typography>
              <Autocomplete
                options={orders || []}
                getOptionLabel={(option) =>
                  `ID: ${option?.id || ""}, SAP SO Number: ${
                    option?.salesOrderNumber || ""
                  }, Customer: ${option?.stakeholder?.name || ""}`
                }
                value={selectedSo}
                onChange={(event, newValue) => {
                  setSelectedSo(newValue);
                  setShipmentRequestData({
                    ...shipmentRequestData,
                    salesOrderId: newValue ? newValue.id : "",
                    customerGradeMark: newValue ? newValue?.offer?.salesGrade : "",
                  });
                }}
                onOpen={() => retrieveFilteredOrders(orderDto)}
                isOptionEqualToValue={(option, value) => option.id === value.id}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    variant="outlined"
                    size="small"
                    fullWidth
                  />
                )}
                renderTags={() => null}
              />
              {selectedSo && (
                <Box mt={2}>
                  <Chip
                    label={`ID: ${selectedSo?.id || ""}`}
                    onDelete={() => {
                      setSelectedSo(null);
                      setShipmentRequestData({
                        ...shipmentRequestData,
                        salesOrderId: "",
                      });
                    }}
                  />
                </Box>
              )}
            </Grid>

            <Grid item xs={12} sm={1}>
              <Typography variant="body2">SO Quantity</Typography>
              <TextField
                disabled
                variant="outlined"
                size="small"
                value={(selectedSo?.numberOfCases || "") + " cases"}
                fullWidth
              />
            </Grid>

            <Grid item xs={12} sm={1}>
              <Typography style={{ color: "#fafafb" }} variant="body2">
                PO Quantity
              </Typography>
              <TextField
                disabled
                variant="outlined"
                size="small"
                value={
                  (selectedSo?.netWeightPerCase || "") +
                  " " +
                  (selectedSo?.volumeUnit || "") +
                  " per case"
                }
                fullWidth
              />
            </Grid>

            <Grid item xs={12} sm={1}>
              <Typography style={{ color: "#fafafb" }} variant="body2">
                PO Quantity
              </Typography>
              <TextField
                disabled
                variant="outlined"
                size="small"
                value={
                  (selectedSo?.volume || "") +
                  " " +
                  (selectedSo?.volumeUnit || "")
                }
                fullWidth
              />
            </Grid>

            <Grid item xs={12} sm={1}>
              <Typography variant="body2">Supplier</Typography>
              <TextField
                disabled
                variant="outlined"
                size="small"
                value={selectedSo?.offer?.stakeholder?.name || ""}
                fullWidth
              />
            </Grid>

            <Grid item xs={12} sm={1}>
              <Typography variant="body2">Item ID</Typography>
              <TextField
                disabled
                variant="outlined"
                size="small"
                value={selectedSo?.offer?.item?.id || ""}
                fullWidth
              />
            </Grid>

            <Grid item xs={12} sm={1}>
              <Typography variant="body2">Origin</Typography>
              <TextField
                disabled
                variant="outlined"
                size="small"
                value={selectedSo?.offer?.item?.origin || ""}
                fullWidth
              />
            </Grid>

            <Grid item xs={12} sm={1}>
              <Typography variant="body2">Type</Typography>
              <TextField
                disabled
                variant="outlined"
                size="small"
                value={selectedSo?.offer?.item?.type || ""}
                fullWidth
              />
            </Grid>
            <Grid item xs={12} sm={1}>
              <Typography variant="body2">Buying Grade</Typography>
              <TextField
                disabled
                variant="outlined"
                size="small"
                value={selectedSo?.offer?.item?.buyingGrade || ""}
                fullWidth
              />
            </Grid>

            <Grid item xs={12} sm={1}>
              <Typography variant="body2">Sales Grade</Typography>
              <TextField
                disabled
                variant="outlined"
                size="small"
                value={selectedSo?.offer?.item?.salesGrade || ""}
                fullWidth
              />
            </Grid>

            <Grid item xs={12} sm={1}>
              <Typography variant="body2">Crop</Typography>
              <TextField
                disabled
                variant="outlined"
                size="small"
                value={selectedSo?.offer?.item?.crop || ""}
                fullWidth
              />
            </Grid>
          </Grid>
        )}

        <Grid item xs={12} style={{ position: "relative" }}>
          <Typography
            variant="h3"
            align="center"
            style={{
              color: "#777f87",
              position: "absolute",
              top: "3px",
              left: "50%",
              transform: "translateX(-50%)",
              backgroundColor: "#fafafb",
              padding: "0 10px",
            }}
          >
            Shipment Request Details
          </Typography>
        </Grid>
        <Grid
          container
          spacing="12px"
          style={{
            border: "1px solid #dbd9d9",
            borderRadius: "20px",
            marginTop: "20px",
            padding: "20px",
            boxShadow: "2px 4px 8px rgba(0, 0, 0, 0.2)",
          }}
        >
          <Grid item xs={12} sm={1.2}>
            <Typography variant="body1" mb={2}>
              From:
            </Typography>
          </Grid>
          <Grid item xs={12} sm={1.5}>
            <FormControlLabel
              control={<Checkbox checked={true} name="fv" />}
              label="Vendor"
            />
          </Grid>
          <Grid item xs={12} sm={1.5}></Grid>
          <Grid item xs={12} sm={7.8}></Grid>

          <Grid item xs={12} sm={1.2}>
            <Typography variant="body1" mb={2}>
              To:
            </Typography>
          </Grid>
          <Grid item xs={12} sm={1.5}>
            <FormControlLabel
              control={
                <Checkbox
                  checked={tpSelected}
                  onChange={handleCheckboxChange}
                  name="tp"
                />
              }
              label="Production"
            />
          </Grid>
          <Grid item xs={12} sm={1.5}>
            <FormControlLabel
              control={
                <Checkbox
                  checked={twSelected}
                  onChange={handleCheckboxChange}
                  name="tw"
                />
              }
              label="Warehouse"
            />
          </Grid>
          <Grid item xs={12} sm={7.8}></Grid>
          {tpSelected && (
            <Grid item xs={12} sm={2}>
              <Typography variant="body2">Production Type</Typography>
              <TextField
                variant="outlined"
                size="small"
                fullWidth
                id="outlined-select-incoterm"
                select
                onChange={(e) => {
                  const newValue = e.target.value;
                  setTpSales(newValue);
                  setShipmentRequestData({
                    ...shipmentRequestData,
                    shipmentRequestType: newValue ? "FVTIMP" : "FVTINW",
                  });
                }}
              >
                {tpOptions.map((option) => (
                  <MenuItem key={option.value} value={option.value}>
                    {option.label}
                  </MenuItem>
                ))}
              </TextField>
            </Grid>
          )}
          <Grid item xs={12} sm={12}></Grid>
          <Grid item xs={12} sm={2}>
            <Typography variant="body2">SI To</Typography>
            <TextField
              variant="outlined"
              size="small"
              fullWidth
              disabled
              value={shipmentRequestData?.shipmentRequestTo}
            />
          </Grid>
          {/*<Grid item xs={12} sm={1}>
            <Typography variant="body2">Total Item Quantity</Typography>
            <TextField
              variant="outlined"
              size="small"
              fullWidth
              disabled
              value={quantity?.quantityRemainingInNetKg ?  + quantity?.quantityRemainingInNetKg  + " KG":""}
            />
          </Grid>*/}
          <Grid item xs={12} sm={2}>
            <Typography variant="body2">Unshipped Quantity</Typography>
            <TextField
              variant="outlined"
              size="small"
              fullWidth
              disabled
              value={
                createPurchaseOrderShipmentRequest?.data?.remainingQuantity
                  ? `${new Intl.NumberFormat("de-DE", {
                      style: "decimal",
                      minimumFractionDigits: 2,
                    }).format(createPurchaseOrderShipmentRequest?.data?.remainingQuantity)} ${createPurchaseOrderShipmentRequest?.data?.volumeUnit || ""}`
                  : ""
              }
            />
          </Grid>

          {(tpSelected) && (
          <Grid item xs={12} sm={2}>
            <Typography variant="body2">Production Tobacco Code</Typography>
            <TextField
              variant="outlined"
              size="small"
              fullWidth
              value={shipmentRequestData?.productionTobaccoCode || ""}
              onChange={(e) =>
                setShipmentRequestData({
                  ...shipmentRequestData,
                  productionTobaccoCode: e.target.value,
                })
              }
            />
          </Grid>
          )}

          <Grid item xs={12} sm={1}>
            <Typography variant="body2">Customer Grade Mark</Typography>
            <TextField
              variant="outlined"
              size="small"
              fullWidth
              value={shipmentRequestData?.customerGradeMark || ""}
              onChange={(e) =>
                setShipmentRequestData({
                  ...shipmentRequestData,
                  customerGradeMark: e.target.value,
                })
              }
            />
          </Grid>

{(tpSelected || twSelected) && (
            <Grid item xs={12} sm={2}>
              <Typography variant="body2">Destination</Typography>
              <Autocomplete
                options={warehouses || []}
                getOptionLabel={(option) =>
                  `ID: ${option?.id || ""}, Code: ${
                    option?.code || ""
                  }, Name: ${option?.name || ""}`
                }
                value={selectedWarehouse}
                onChange={(event, newValue) => {
                  setSelectedWarehouse(newValue);
                  setShipmentRequestData({
                    ...shipmentRequestData,
                    destination: newValue ? newValue?.name : "",
                  });
                }}
                onOpen={() => retrieveFilteredWarehouseRecords(filterWarehouse)}
                isOptionEqualToValue={(option, value) => option.id === value.id}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    variant="outlined"
                    size="small"
                    fullWidth
                  />
                )}
                renderTags={() => null}
              />
              {selectedWarehouse && (
                <Box mt={2}>
                  <Chip
                    label={`ID: ${selectedWarehouse?.id || ""}`}
                    onDelete={() => {
                      setSelectedWarehouse(null);
                      setShipmentRequestData({ ...shipmentRequestData, destination: "" });
                    }}
                    style={{ marginBottom: "5px" }}
                  />
                  <br></br>
                  <Chip
                    label={`Code: ${selectedWarehouse?.code || ""}`}
                    style={{ marginBottom: "5px" }}
                  />
                  <br />
                  <Chip
                    label={`Name: ${selectedWarehouse?.name || ""}`}
                    style={{ marginBottom: "5px" }}
                  />
                  <br />
                  <Chip
                    label={`Contact: ${
                      selectedWarehouse?.contactPerson?.contactPerson || ""
                    }`}
                    style={{ marginBottom: "5px" }}
                  />
                  <br />
                  <Chip
                    label={`Storage Pricing By ${
                      selectedWarehouse?.storagePricingBy || ""
                    }`}
                    style={{ marginBottom: "5px" }}
                  />
                  <br />
                </Box>
              )}
            </Grid>
          )}

          <Grid item xs={12} sm={1.5}>
            <Typography variant="body2">Port Of Loading</Typography>
            <Autocomplete
              options={portsOfLoading}
              getOptionLabel={(option) => (option ? option.label : "")}
              value={selectedPortOfLoading}
              onChange={(event, newValue) => {
                setSelectedPortOfLoading(newValue);
                setShipmentRequestData({
                  ...shipmentRequestData,
                  portOfLoading: newValue ? newValue.value : "",
                });
              }}
              isOptionEqualToValue={(option, value) =>
                option.value === value.value
              }
              renderInput={(params) => (
                <TextField
                  {...params}
                  variant="outlined"
                  size="small"
                  label={shipmentRequestData?.portOfLoading}
                  fullWidth
                />
              )}
              renderTags={() => null}
            />
            {selectedPortOfLoading && (
              <Box mt={2}>
                <Chip
                  label={selectedPortOfLoading.label}
                  onDelete={() => {
                    setSelectedPortOfLoading(null);
                    setShipmentRequestData({
                      ...shipmentRequestData,
                      portOfLoading: "",
                    });
                  }}
                />
              </Box>
            )}
          </Grid>

          <Grid item xs={12} sm={1.5}>
            <Typography variant="body2">Port Of Discharge</Typography>
            <Autocomplete
              options={portsOfDischarge}
              getOptionLabel={(option) => (option ? option.label : "")}
              value={selectedPortOfDischarge}
              onChange={(event, newValue) => {
                setSelectedPortOfDischarge(newValue);
                setShipmentRequestData({
                  ...shipmentRequestData,
                  portOfDischarge: newValue ? newValue.value : "",
                });
              }}
              isOptionEqualToValue={(option, value) =>
                option.value === value.value
              }
              renderInput={(params) => (
                <TextField
                  {...params}
                  variant="outlined"
                  size="small"
                  label={shipmentRequestData?.portOfDischarge}
                  fullWidth
                />
              )}
              renderTags={() => null}
            />
            {selectedPortOfDischarge && (
              <Box mt={2}>
                <Chip
                  label={selectedPortOfDischarge.label}
                  onDelete={() => {
                    setSelectedPortOfDischarge(null);
                    setShipmentRequestData({
                      ...shipmentRequestData,
                      portOfDischarge: "",
                    });
                  }}
                />
              </Box>
            )}
          </Grid>

          <Grid item xs={12} sm={1}>
            <Typography variant="body2">Number Of Cartons</Typography>
            <TextField
              type="number"
              variant="outlined"
              size="small"
              fullWidth
              value={shipmentRequestData?.numberOfCases}
              onChange={(e) => {
                const numberOfCases = e.target.value; // Capture the new net weight per case from the event
                let calculatedShipmentQuantity =
                  numberOfCases * shipmentRequestData?.netWeightPerCase;

                // Ensure shipmentQuantity does not exceed createPurchaseOrderShipmentRequest.data.volume
                if (
                  createPurchaseOrderShipmentRequest?.data?.volume &&
                  calculatedShipmentQuantity >
                    createPurchaseOrderShipmentRequest.data.volume
                ) {
                  toast.error(
                    "Shipment quantity cannot be greater than SO Quantity!",
                  );
                  calculatedShipmentQuantity =
                    createPurchaseOrderShipmentRequest.data.volume;
                }

                setShipmentRequestData({
                  ...shipmentRequestData,
                  numberOfCases: numberOfCases,
                  shipmentQuantity: calculatedShipmentQuantity,
                });
              }}
            />
          </Grid>

          <Grid item xs={12} sm={1}>
            <Typography variant="body2">Net Weight Per Carton</Typography>
            <TextField
              type="number"
              variant="outlined"
              size="small"
              fullWidth
              value={shipmentRequestData?.netWeightPerCase}
              onChange={(e) => {
                const netWeightPerCase = e.target.value;
                let calculatedShipmentQuantity =
                  netWeightPerCase * shipmentRequestData?.numberOfCases;
                if (
                  createPurchaseOrderShipmentRequest?.data?.volume &&
                  calculatedShipmentQuantity >
                    createPurchaseOrderShipmentRequest.data.volume
                ) {
                  toast.error(
                    "Shipment quantity cannot be greater than SO Quantity!",
                  );
                  calculatedShipmentQuantity =
                    createPurchaseOrderShipmentRequest.data.volume;
                }

                setShipmentRequestData({
                  ...shipmentRequestData,
                  netWeightPerCase: netWeightPerCase,
                  shipmentQuantity: calculatedShipmentQuantity,
                });
              }}
            />
          </Grid>

          <Grid item xs={12} sm={1}>
            <Typography variant="body2">Shipment Quantity</Typography>
            <TextField
              type="number"
              variant="outlined"
              size="small"
              fullWidth
              value={shipmentRequestData?.shipmentQuantity}
              onChange={(e) => {
                if (
                  e.target.value >
                  createPurchaseOrderShipmentRequest?.data?.volume
                ) {
                  toast.error(
                    "Shipment quantity cannot be greater than SO Quantity!",
                  );
                } else {
                  setShipmentRequestData({
                    ...shipmentRequestData,
                    shipmentQuantity: e.target.value,
                  });
                }
              }}
            />
          </Grid>

          <Grid item xs={12} sm={1}>
            <Typography variant="body2">Unit</Typography>
            <TextField
              variant="outlined"
              size="small"
              fullWidth
              defaultValue={"KG"}
              id="outlined-select-shipment-quantity-unit"
              select
              onChange={(e) =>
                setShipmentRequestData({
                  ...shipmentRequestData,
                  shipmentQuantityUnit: e.target.value,
                })
              }
            >
              {units.map((option) => (
                <MenuItem key={option.value} value={option.value}>
                  {option.label}
                </MenuItem>
              ))}
            </TextField>
          </Grid>

          <Grid item xs={12} sm={1}>
            <Typography variant="body2">Cartons/Cont</Typography>
            <TextField
              type="number"
              variant="outlined"
              size="small"
              fullWidth
              value={shipmentRequestData?.cartonsPerContainer}
              onChange={(e) =>
                setShipmentRequestData({
                  ...shipmentRequestData,
                  cartonsPerContainer: e.target.value,
                })
              }
            />
          </Grid>

          <Grid item xs={12} sm={1}>
            <Typography variant="body2">Shipment ETD</Typography>
            <TextField
              size="small"
              fullWidth
              variant="outlined"
              id="date"
              type="date"
              defaultValue={
                shipmentRequestData
                  ?.shipmentEstimatedTimeOfDeparture
                  ? format(
                      parseISO(
                        shipmentRequestData
                          .shipmentEstimatedTimeOfDeparture,
                      ),
                      "yyyy-MM-dd",
                    )
                  : ""
              }
              inputProps={{ min: "1999-01-01", max: "2999-01-01" }}
              InputLabelProps={{
                shrink: true,
              }}
              onKeyDown={(e) => e.preventDefault()}
              onChange={(e) =>
                setShipmentRequestData({
                  ...shipmentRequestData,
                  shipmentEstimatedTimeOfDeparture: e.target.value,
                })
              }
            />
          </Grid>

          {/*<Grid item xs={12} sm={2}>
            <Typography variant="body2">Status</Typography>
            <TextField
              variant="outlined"
              size="small"
              fullWidth
              id="outlined-select-status"
              select
              onChange={(e) =>
                setShipmentRequestData({
                  ...shipmentRequestData,
                  status: e.target.value,
                })
              }
            >
              {status.map((option) => (
                <MenuItem key={option.value} value={option.value}>
                  {option.label}
                </MenuItem>
              ))}
            </TextField>
          </Grid>*/}

          {/*<Grid item xs={12} sm={2}>
            <Typography variant="body2">Payment Status</Typography>
            <TextField
              variant="outlined"
              size="small"
              fullWidth
              id="outlined-select-payment-status"
              select
              onChange={(e) =>
                setShipmentRequestData({
                  ...shipmentRequestData,
                  paymentStatus: e.target.value,
                })
              }
            >
              {status.map((option) => (
                <MenuItem key={option.value} value={option.value}>
                  {option.label}
                </MenuItem>
              ))}
            </TextField>
          </Grid>*/}

          <Grid item xs={12} sm={5.5}>
            <Typography variant="body2">Loading Remark</Typography>
            <TextField
              variant="outlined"
              size="small"
              fullWidth
              value={shipmentRequestData?.loadingRemark}
              onChange={(e) =>
                setShipmentRequestData({
                  ...shipmentRequestData,
                  loadingRemark: e.target.value,
                })
              }
            />
          </Grid>
          <Grid item xs={12} sm={5.5}>
            <Typography variant="body2">Remark</Typography>
            <TextField
              variant="outlined"
              size="small"
              fullWidth
              value={shipmentRequestData?.remark}
              onChange={(e) =>
                setShipmentRequestData({
                  ...shipmentRequestData,
                  remark: e.target.value,
                })
              }
            />
          </Grid>
          {((tpSelected) || twSelected) && (<Grid item xs={12} sm={6.5}></Grid>)}
              <Grid item xs={12} sm={2}>
              <WarehouseInventoryButton 
                warehouse={shipmentRequestData?.destination}
                itemIdList={[
                  createPurchaseOrderShipmentRequest?.data?.offer?.item?.id || 0,
                  createPurchaseOrderShipmentRequest?.data?.offer?.item?.id || 0,
                ]}
              />
          </Grid>
          <Grid item xs={12} sm={10}></Grid>
          <Grid item xs={12} sm={5.5}>
            <Typography variant="body2" onClick={handleHistoryClick} 
            style={{ cursor: "pointer", fontSize:"14px", color: "blue", textDecoration: "underline" }}>History (Shipment Requests Connected To Po {createPurchaseOrderShipmentRequest?.data?.id})</Typography>
          </Grid>
          {isHistoryVisible && (
           <Grid item xs={12} sm={12}>
             <Table>
               <TableHead>
                 <TableRow>
                   <TableCell align="center">Shipment Request ID</TableCell>
                   <TableCell align="center">Type</TableCell>
                   <TableCell align="center">PO</TableCell>
                   <TableCell align="center">SO</TableCell>
                   <TableCell align="center">Creation Time</TableCell>
                   <TableCell align="center">ETD</TableCell>
                   <TableCell align="center"># Of Cartons</TableCell>
                   <TableCell align="center">Shipment Quantity</TableCell>
                 </TableRow>
               </TableHead>
               <TableBody>
              {shipmentRequests?.map((request, index) => (
                <TableRow key={index}>
                  <TableCell align="center">{request?.id}</TableCell>
                  <TableCell align="center">{request?.shipmentRequestType}</TableCell>
                  <TableCell align="center">{request?.purchaseOrder?.id}-{request?.purchaseOrder?.purchaseOrderNumber}</TableCell>
                  <TableCell align="center">{request?.salesOrder?.id}-{request?.salesOrder?.salesOrderNumber}</TableCell>
                  <TableCell align="center">{request?.timestamp ? format(parseISO(request?.timestamp), 'dd-MMM-yyyy') : ''}</TableCell>
                  <TableCell align="center">{request?.shipmentEstimatedTimeOfDeparture ? format(parseISO(request?.shipmentEstimatedTimeOfDeparture), 'dd-MMM-yyyy') : ''}</TableCell>
                  <TableCell align="center">
                    {request?.numberOfCases
                      ? `${request?.numberOfCases}`
                      : ''}
                  </TableCell>
                  <TableCell align="center">
                    {request?.shipmentQuantity
                      ? `${request?.shipmentQuantity} ${request?.shipmentQuantityUnit || ''}`
                      : ''}
                  </TableCell>
                </TableRow>
              ))}
                <TableRow>
                <TableCell></TableCell>
                <TableCell></TableCell>
                <TableCell></TableCell>
                <TableCell></TableCell>
                <TableCell></TableCell>
          <TableCell align="center"><strong>Total Shipment Quantity:</strong></TableCell>
          <TableCell align="center">
            <strong>
            {shipmentRequests?.reduce((total, request) => total + (request?.numberOfCases || 0), 0)}
            </strong>
          </TableCell>
          <TableCell align="center">
            <strong>
            {shipmentRequests?.reduce((total, request) => total + (request?.shipmentQuantity || 0), 0)}
            </strong>
          </TableCell>
        </TableRow>
        <TableRow>
         <TableCell></TableCell>
         <TableCell></TableCell>
         <TableCell></TableCell>
          <TableCell></TableCell>
          <TableCell></TableCell>
          <TableCell align="center"><strong>Remaining PO Quantity:</strong></TableCell>
          <TableCell align="center">
                       <strong>
                 {createPurchaseOrderShipmentRequest?.data?.numberOfCases
                   ? createPurchaseOrderShipmentRequest.data.numberOfCases -
                     shipmentRequests?.reduce((total, request) => total + (request?.numberOfCases || 0), 0)
                   : 0}
               </strong>
          </TableCell>
          <TableCell align="center">
                       <strong>
                 {createPurchaseOrderShipmentRequest?.data?.volume
                   ? createPurchaseOrderShipmentRequest.data.volume -
                     shipmentRequests?.reduce((total, request) => total + (request?.shipmentQuantity || 0), 0)
                   : 0}
               </strong>
          </TableCell>
        </TableRow>
            </TableBody>
          </Table>
        </Grid>
      )}
        </Grid>
        <Button
          variant="outlined"
          size="small"
          sx={{ ml: 1.5, mt: 4, mr: 1 }}
          onClick={() => {
            setShipmentRequestData({ data: null });
            setCreatePurchaseOrderShipmentRequest({ visible: false });
          }}
        >
          Cancel
        </Button>
        <Button
          variant="contained"
          disabled={
            (!tpSelected && !twSelected) ||
            (tpSelected &&
              shipmentRequestData.shipmentRequestType !== "FVTIMP" &&
              shipmentRequestData.shipmentRequestType !== "FVTINW")
          }
          style={!tpSelected && !twSelected ? { whiteSpace: "nowrap" } : {}}
          size="small"
          sx={{ mt: 4 }}
          onClick={async () => {
            if (quantity?.quantityRemainingInVendorInNetKg <= 0) {
              alert(`
                Selected item's:
        
                Remaining quantity in vendor: ${quantity?.quantityRemainingInVendorInNetKg || "N/A"} KG
                Remaining quantity in warehouse: ${quantity?.quantityRemainingInWarehouseInNetKg || "N/A"} KG
                Remaining quantity in production: ${quantity?.quantityRemainingInProductionInNetKg || "N/A"} KG
                Remaining net quantity: ${quantity?.quantityRemainingInNetKg} KG
        
                Shipment request could not be created due to missing quantity.
              `);
              return;
            }
        
            try {   
              await createShipmentRequestApi(shipmentRequestData);
              toast.success("ShipmentRequest added successfully.");

              window.location.reload();
              navigate("/purchase-order-view");
            } catch (error) {
              console.error(error?.response?.data || error);
              toast.error("Something went wrong. Please try again.");
            }
            setCreatePurchaseOrderShipmentRequest({
              ...createPurchaseOrderShipmentRequest,
              data: shipmentRequestData,
              visible: false,
            });
          }}
        >
          Create
        </Button>
      </Grid>
      <br></br>
      {!tpSelected && !twSelected && (
        <Typography variant="body2" color="error">
          Please select one of 'To Production', or 'To Warehouse'.
        </Typography>
      )}
      {tpSelected &&
        shipmentRequestData.shipmentRequestType !== "FVTIMP" &&
        shipmentRequestData.shipmentRequestType !== "FVTINW" && (
          <Typography variant="body2" color="error">
            Please select a production type.
          </Typography>
        )}
    </>
  );
};

export default CreatePurchaseOrderShipment;

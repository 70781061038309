import {
  Button,
  Grid,
  MenuItem,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import { format, parseISO } from "date-fns";
import React from "react";
import { updateWarehouseApi } from "../../../components/api/WarehouseApiService";
import { companies, recordStatus, units } from "../../../utils/menu-items";

const EditWarehouseInventory = (props) => {
  const { editWarehouseInventory, setEditWarehouseInventory } = props;
  return (
    <>
      <Stack direcion="column">
        <Typography variant="body1" mb={2}>
          Edit Warehouse Inventory Record
        </Typography>
        <Grid container item xs={12} spacing="8px">
        <Grid item xs={12} sm={2}>
            <Typography variant="body2">Name</Typography>
            <TextField
              variant="outlined"
              size="small"
              fullWidth
              value={editWarehouseInventory?.data?.name}
              onChange={(e) =>
                setEditWarehouseInventory({
                  ...editWarehouseInventory,
                  data: {
                    ...editWarehouseInventory.data,
                    name: e.target.value,
                  },
                })
              }
            />
          </Grid>
          <Grid item xs={12} sm={2}>
            <Typography variant="body2">Item</Typography>
            <TextField
              variant="outlined"
              size="small"
              fullWidth
              type="number"
              value={editWarehouseInventory?.data?.item?.id || ""}
              onChange={(e) =>
                setEditWarehouseInventory({
                  ...editWarehouseInventory,
                  data: {
                    ...editWarehouseInventory.data,
                    item: {
                      ...(editWarehouseInventory.data.item || {}),
                      id:
                        e.target.value !== ""
                          ? parseInt(e.target.value, 10)
                          : null,
                    },
                  },
                })
              }
            />
          </Grid>
          <Grid item xs={12} sm={2}>
            <Typography variant="body2">Shipped Amount</Typography>
            <TextField
              variant="outlined"
              size="small"
              fullWidth
              type="number"
              value={editWarehouseInventory?.data?.shippedAmount}
              onChange={(e) =>
                setEditWarehouseInventory({
                  ...editWarehouseInventory,
                  data: {
                    ...editWarehouseInventory.data,
                    shippedAmount: e.target.value,
                  },
                })
              }
            />
          </Grid>
          <Grid item xs={12} sm={2}>
            <Typography variant="body2">Shipped Amount Unit</Typography>
            <TextField
              variant="outlined"
              size="small"
              fullWidth
              select
              value={editWarehouseInventory?.data?.shippedAmountUnit}
              onChange={(e) =>
                setEditWarehouseInventory({
                  ...editWarehouseInventory,
                  data: {
                    ...editWarehouseInventory.data,
                    shippedAmountUnit: e.target.value,
                  },
                })
              }
            >
              {units.map((option) => (
                <MenuItem key={option.value} value={option.value}>
                  {option.label}
                </MenuItem>
              ))}
            </TextField>
          </Grid>
          <Grid item xs={12} sm={2}>
            <Typography variant="body2">Unshipped Amount</Typography>
            <TextField
              variant="outlined"
              size="small"
              fullWidth
              type="number"
              value={editWarehouseInventory?.data?.unshippedAmount}
              onChange={(e) =>
                setEditWarehouseInventory({
                  ...editWarehouseInventory,
                  data: {
                    ...editWarehouseInventory.data,
                    unshippedAmount: e.target.value,
                  },
                })
              }
            />
          </Grid>
          <Grid item xs={12} sm={2}>
            <Typography variant="body2">Unshipped Amount Unit</Typography>
            <TextField
              variant="outlined"
              size="small"
              fullWidth
              select
              value={editWarehouseInventory?.data?.unshippedAmountUnit}
              onChange={(e) =>
                setEditWarehouseInventory({
                  ...editWarehouseInventory,
                  data: {
                    ...editWarehouseInventory.data,
                    unshippedAmountUnit: e.target.value,
                  },
                })
              }
            >
              {units.map((option) => (
                <MenuItem key={option.value} value={option.value}>
                  {option.label}
                </MenuItem>
              ))}
            </TextField>
          </Grid>
          <Grid item xs={12} sm={2}>
            <Typography variant="body2">Record Status</Typography>
            <TextField
              variant="outlined"
              size="small"
              fullWidth
              id="outlined-select-status"
              select
              value={editWarehouseInventory?.data?.recordStatus}
              onChange={(e) =>
                setEditWarehouseInventory({
                  ...editWarehouseInventory,
                  data: {
                    ...editWarehouseInventory.data,
                    recordStatus: e.target.value,
                  },
                })
              }
            >
              {recordStatus.map((option) => (
                <MenuItem key={option.value} value={option.value}>
                  {option.label}
                </MenuItem>
              ))}
            </TextField>
          </Grid>
        </Grid>
        <Stack direction="row" spacing={1} mt={1}>
          <Button
            variant="outlined"
            onClick={() =>
              setEditWarehouseInventory({ visible: false, data: null, index: null })
            }
          >
            Cancel
          </Button>
          <Button
            variant="contained"
            onClick={() => {
              updateWarehouseApi(editWarehouseInventory.data.id, editWarehouseInventory.data)
                .then((response) => {
                  window.location.reload();

                  setEditWarehouseInventory({ visible: false, data: null, index: null });
                })
                .catch((error) => {
                  console.log(error.response.data);
                });
            }}
          >
            Save
          </Button>
          <Button
            variant="contained"
            color="error"
            onClick={() => {
              editWarehouseInventory.data.recordStatus = "PASSIVE";
              updateWarehouseApi(editWarehouseInventory.data.id, editWarehouseInventory.data)
                .then((response) => {
                  window.location.reload();
                  setEditWarehouseInventory({ visible: false, data: null, index: null });
                })
                .catch((error) => {
                  console.log(error.response.data);
                });
            }}
          >
            Delete
          </Button>
        </Stack>
      </Stack>
    </>
  );
};

export default EditWarehouseInventory;

import React, { useState } from 'react';
import { Button } from '@mui/material';
import toast from "react-hot-toast";
import { retrieveFilteredWarehouseRecordsApi, updateMultipleWarehouseRecordsApi } from '../../../../components/api/WarehouseApiService';
import { updateShippingInstructionApi } from '../../../../components/api/ShippingInstructionApiService';
import { updateMultipleOrdersApi } from '../../../../components/api/OrderApiService';
import { ClipLoader } from 'react-spinners';
import { retrieveFilteredWarehouseInventoryRecordsApi } from '../../../../components/api/WarehouseInventoryApiService';
import { updateCostApi } from '../../../../components/api/CostApiService';

function CloseShippingInstructionButton({ invoiceData, onSuccessfulClose }) {
  const [closeDialogOpen, setCloseDialogOpen] = useState(false);
  const [loading, setLoading] = useState(false);

  const handleCloseClick = async () => {
    if (
      !invoiceData?.actualTimeOfArrival
    ) {
      toast.error("Please fill in all required fields to close the SI.");
      return;
    }

        function isSalesOrderQuantityValid(updatedSalesOrder) {
          const isSalesOrderValid = updatedSalesOrder?.remainingQuantity 
              ? updatedSalesOrder.remainingQuantity >= 0 
              : true;
      
          return isSalesOrderValid;
      }

      function isPurchaseOrderQuantityValid(updatedPurchaseOrder) {
        const isPurchaseOrderValid = updatedPurchaseOrder?.remainingQuantity 
            ? updatedPurchaseOrder.remainingQuantity >= 0 
            : true;
    
        return isPurchaseOrderValid;
    }

      function isWarehouseQuantityValid(updatedWarehouseRecords) {
        if (
          invoiceData?.shipmentRequest?.shipmentRequestType === "FWTC" ||
          invoiceData?.shipmentRequest?.shipmentRequestType === "FWTIMP" ||
          invoiceData?.shipmentRequest?.shipmentRequestType === "FWTINW" ||
          invoiceData?.shipmentRequest?.shipmentRequestType === "FWTW" ||
          invoiceData?.shipmentRequest?.shipmentRequestType === "FVTW"
        ) {
          return updatedWarehouseRecords
            ? updatedWarehouseRecords.every(record => record?.shippedAmount >= 0)
            : false;
        }
        return true;
      }

        const updatedInvoiceData = {
          ...invoiceData,
          status: "Closed",
          operation: "Closing",
        };

        const updatedPurchaseOrder = {
          ...invoiceData?.shipmentRequest?.purchaseOrder,
          remainingQuantity: invoiceData?.shipmentRequest?.purchaseOrder ? ((invoiceData?.shipmentRequest?.purchaseOrder?.remainingQuantity || 0) - (invoiceData?.shipmentRequest?.shipmentQuantity || 0)) : invoiceData?.shipmentRequest?.purchaseOrder?.remainingQuantity,
          orderStatus: invoiceData?.shipmentRequest?.purchaseOrder ? (((invoiceData?.shipmentRequest?.purchaseOrder?.remainingQuantity || 0) - (invoiceData?.shipmentRequest?.shipmentQuantity || 0)) <= 0 ? "Closed" : invoiceData?.shipmentRequest?.purchaseOrder?.status) : invoiceData?.shipmentRequest?.purchaseOrder?.status,
        };

        const updatedSalesOrder = {
          ...invoiceData?.shipmentRequest?.salesOrder,
          remainingQuantity: invoiceData?.shipmentRequest?.salesOrder ? ((invoiceData?.shipmentRequest?.salesOrder?.remainingQuantity || 0) - (invoiceData?.shipmentRequest?.shipmentQuantity || 0)) : invoiceData?.shipmentRequest?.salesOrder?.remainingQuantity,
          orderStatus: invoiceData?.shipmentRequest?.salesOrder ? (((invoiceData?.shipmentRequest?.salesOrder?.remainingQuantity || 0) - (invoiceData?.shipmentRequest?.shipmentQuantity || 0)) <= 0 ? "Closed" : invoiceData?.shipmentRequest?.salesOrder?.status) : invoiceData?.shipmentRequest?.purchaseOrder?.status,
        };

        const updatedOrders = [updatedPurchaseOrder, updatedSalesOrder];
        const shipmentType = invoiceData?.shipmentRequest?.shipmentRequestType;

        const retrieveFilteredWarehouseRecord = {
          id: 0,
          warehouseList: [invoiceData?.warehouse],
          itemIdList: [
            invoiceData?.shipmentRequest?.purchaseOrder?.offer?.item?.id || 0,
            invoiceData?.shipmentRequest?.salesOrder?.offer?.item?.id || 0,
          ],
          recordStatusList: ["ACTIVE"],
        };

        const retrieveFilteredDestinationRecord = {
          id: 0,
          warehouseList: [invoiceData?.destination],
          itemIdList: [
            invoiceData?.shipmentRequest?.purchaseOrder?.offer?.item?.id || 0,
            invoiceData?.shipmentRequest?.salesOrder?.offer?.item?.id || 0,
          ],
          recordStatusList: ["ACTIVE"],
        };

        setLoading(true);

    try {

      /*const destinationResponse = await retrieveFilteredWarehouseRecordsApi(retrieveFilteredDestinationRecord);
      const destinationRecords = destinationResponse?.data || [];

      const destinationInventoryResponse = await retrieveFilteredWarehouseInventoryRecordsApi(retrieveFilteredDestinationRecord);
      const destinationInventoryRecords = destinationInventoryResponse?.data || [];

      let updatedDestinationRecords = [];

      if (shipmentType === "FVTW" || shipmentType === "FWTW" ||
          shipmentType === "FVTIMP" || shipmentType === "FWTIMP" ||
          shipmentType === "FVTINW" || shipmentType === "FWTINW") {
        
        updatedDestinationRecords = destinationRecords.map((destinationRecord) => ({
          ...destinationRecord,
          shippedAmount: (destinationRecord?.shippedAmount || 0) +
            (invoiceData?.shipmentRequest?.shipmentQuantity || 0),
          unshippedAmount: (destinationRecord?.unshippedAmount || 0) -
            (invoiceData?.shipmentRequest?.shipmentQuantity || 0),
        }));


        if (!isOrderQuantityValid(updatedPurchaseOrder, updatedSalesOrder) || !isWarehouseQuantityValid(updatedDestinationRecords)) {
          toast.error("Unshipped quantities after SI cannot be less than zero for the warehouse or PO/SO.");
          return;
        }
      }*/

      const warehouseInventoryResponse = await retrieveFilteredWarehouseInventoryRecordsApi(retrieveFilteredWarehouseRecord);
      const warehouseInventoryRecords = warehouseInventoryResponse?.data || [];

      const totalIncoming = warehouseInventoryRecords
      .filter(record => record?.type === "Incoming")
      .reduce((sum, record) => sum + (record?.shippedAmount || 0), 0);

      const totalOutgoing = warehouseInventoryRecords
        .filter(record => record?.type === "Outgoing")
        .reduce((sum, record) => sum + (record?.shippingInstruction?.shipmentRequest?.shipmentQuantity || 0), 0);

      const finalTotalAmount = totalIncoming - totalOutgoing;
      const isValid = finalTotalAmount >= 0;

      if (invoiceData?.shipmentRequest?.shipmentRequestType === "FWTW" || invoiceData?.shipmentRequest?.shipmentRequestType === "FWTIMP" ||
        invoiceData?.shipmentRequest?.shipmentRequestType === "FWTINW") {

        if (!isValid) {
          toast.error("Unshipped quantities after SI cannot be less than zero for the warehouse.");
          return;
        }
      }else if(invoiceData?.shipmentRequest?.shipmentRequestType === "FWTC"){
        if (!isSalesOrderQuantityValid(updatedSalesOrder) || !isValid) {
          toast.error("Unshipped quantities after SI cannot be less than zero for the warehouse or SO.");
          return;
        }
      }else if(invoiceData?.shipmentRequest?.shipmentRequestType === "FVTW" || invoiceData?.shipmentRequest?.shipmentRequestType === "FVTIMP" ||
        invoiceData?.shipmentRequest?.shipmentRequestType === "FVTINW"){
        if (!isPurchaseOrderQuantityValid(updatedPurchaseOrder)) {
          toast.error("Unshipped quantities after SI cannot be less than zero for the PO.");
          return;
        }
      }else if(invoiceData?.shipmentRequest?.shipmentRequestType === "FVTC"){
        if (!isPurchaseOrderQuantityValid(updatedPurchaseOrder) || !isSalesOrderQuantityValid(updatedSalesOrder)) {
          toast.error("Unshipped quantities after SI cannot be less than zero for the PO or SO.");
          return;
        }
      }

      await Promise.all([
        updateShippingInstructionApi(updatedInvoiceData?.id, updatedInvoiceData),
        shipmentType === "FVTC"
          ? updateMultipleOrdersApi(updatedOrders)
          : shipmentType === "FVTW" || shipmentType === "FVTIMP" || shipmentType === "FVTINW"
          ? updateMultipleOrdersApi([updatedPurchaseOrder])
          : shipmentType === "FWTC"
          ? updateMultipleOrdersApi([updatedSalesOrder])
          : Promise.resolve(),
      ]);

      toast.success("All updates were successful.");
      setCloseDialogOpen(false);
      if (onSuccessfulClose) onSuccessfulClose();
    } catch (error) {
       console.error("An error occurred:", error);
       toast.error("Something went wrong during one of the updates.");
    }finally {
      setLoading(false);
    }
    };

  return (
    <>
    {loading ? (
      <ClipLoader color="#000000" loading={loading} size={35} />
    ) : (
      <Button
        disabled={invoiceData?.status === "Closed"}
        onClick={handleCloseClick}
        color="primary"
      >
        Close SI
      </Button>
    )}
  </>
  );
}

export default CloseShippingInstructionButton;

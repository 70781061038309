import {
  Autocomplete,
  Button,
  Chip,
  Grid,
  MenuItem,
  TextField,
  Typography,
} from "@mui/material";
import Box from "@mui/material/Box";
import { format, parseISO } from "date-fns";
import React, { useEffect, useState } from "react";
import { retrieveAllCostsApi } from "../../../../components/api/CostApiService";
import { createOfferApi } from "../../../../components/api/OfferApiService";
import { retrievePurposeFilteredSamplesApi } from "../../../../components/api/SampleApiService";
import { retrieveAllStakeholdersApi } from "../../../../components/api/StakeholderApiService";
import {
  companies,
  currencies,
  incoterms,
  offerStatus,
  packagingTypes,
  paymentTerm,
  portsOfDischarge,
  sampleStatus,
  status,
  units,
} from "../../../../utils/menu-items";

const CreateCustomerOffer = (props) => {
  const { setCreateCustomerOffer, createCustomerOffer } = props;
  const [orderData, setOrderData] = useState({});

  useEffect(() => {
    setOrderData({
      ...orderData,
      offerType: "CUSTOMER",
      itemId: createCustomerOffer?.data?.item?.id,
      estimatedCostId: createCustomerOffer?.data?.estimatedCost?.id,
      referenceSanOfferId: createCustomerOffer?.data?.id,
      company: createCustomerOffer?.data?.company,
      packaging: createCustomerOffer?.data?.packaging,
      paymentTerm: createCustomerOffer?.data?.paymentTerm,
      incoterm: createCustomerOffer?.data?.incoterm,
      portOfLoading: createCustomerOffer?.data?.portOfLoading,
    });
  }, []);

  function retrieveCustomers() {
    retrieveAllStakeholdersApi("CUSTOMER")
      .then((response) => {
        setCustomers(response?.data);
      })
      .catch((error) => console.log(error.response?.data));
  }
  function retrieveCosts() {
    retrieveAllCostsApi()
      .then((response) => {
        setCosts(response?.data);
      })
      .catch((error) => console.log(error.response?.data));
  }

  const [selectedFirstSample, setSelectedFirstSample] = useState(null);
  const [selectedSecondSample, setSelectedSecondSample] = useState(null);
  const [selectedCost, setSelectedCost] = useState(null);
  const [customers, setCustomers] = useState([]);
  const [costs, setCosts] = useState([]);
  const [filteredSamples, setFilteredSamples] = useState([]);
  const [selectedPortOfDischarge, setSelectedPortOfDischarge] = useState(null);
  function retrieveFilteredSamples(samplePurpose) {
    retrievePurposeFilteredSamplesApi(samplePurpose)
      .then((response) => {
        setFilteredSamples(response?.data);
      })
      .catch((error) => console.log(error.response?.data));
  }

  return (
    <Grid container spacing="12px">
      <Grid item xs={12} sm={4}>
        <Typography variant="body2">Vendor Offer Number</Typography>
        <TextField
          variant="outlined"
          size="small"
          value={createCustomerOffer?.data?.offerGroupNumber}
          fullWidth
          disabled
        />
      </Grid>

      <Grid item xs={12} sm={4}>
        <Typography variant="body2">Item Id</Typography>
        <TextField
          variant="outlined"
          size="small"
          value={createCustomerOffer?.data?.item?.id}
          fullWidth
          disabled
        />
      </Grid>

      <Grid item xs={12} sm={4}>
        <Typography variant="body2">Item Buying Grade</Typography>
        <TextField
          disabled
          variant="outlined"
          size="small"
          value={createCustomerOffer?.data?.item?.buyingGrade || ""}
          fullWidth
        />
      </Grid>
      <Grid item xs={12} sm={4}>
        <Typography variant="body2">Nicotine</Typography>
        <TextField
          disabled
          type="number"
          variant="outlined"
          size="small"
          value={createCustomerOffer?.data?.item?.nicotine || ""}
          fullWidth
        />
      </Grid>
      <Grid item xs={12} sm={4}>
        <Typography variant="body2">Sugar</Typography>
        <TextField
          disabled
          type="number"
          variant="outlined"
          size="small"
          value={createCustomerOffer?.data?.item?.sugar || ""}
          fullWidth
        />
      </Grid>
      <Grid item xs={12} sm={4}>
        <Typography variant="body2">Moisture</Typography>
        <TextField
          disabled
          type="number"
          variant="outlined"
          size="small"
          value={createCustomerOffer?.data?.item?.moisture || ""}
          fullWidth
        />
      </Grid>
      <Grid item xs={12} sm={4}>
        <Typography variant="body2">Item Origin</Typography>
        <TextField
          disabled
          variant="outlined"
          size="small"
          value={createCustomerOffer?.data?.item?.origin || ""}
          fullWidth
        />
      </Grid>
      {( createCustomerOffer?.data?.item?.origin === "China" && <Grid item xs={12} sm={4}>
        <Typography variant="body2">Item Province</Typography>
        <TextField
          disabled
          variant="outlined"
          size="small"
          value={createCustomerOffer?.data?.item?.province || ""}
          fullWidth
        />
      </Grid>)}
      <Grid item xs={12} sm={4}>
        <Typography variant="body2">Item Type</Typography>
        <TextField
          disabled
          variant="outlined"
          size="small"
          value={createCustomerOffer?.data?.item?.type || ""}
          fullWidth
        />
      </Grid>
      <Grid item xs={12} sm={4}>
        <Typography variant="body2">Selling Company</Typography>
        <TextField
          variant="outlined"
          size="small"
          value={orderData?.company}
          fullWidth
          select
          onChange={(e) =>
            setOrderData({
              ...orderData,
              company: e.target.value,
            })
          }
        >
          {companies.map((option) => (
            <MenuItem key={option.value} value={option.value}>
              {option.label}
            </MenuItem>
          ))}
        </TextField>
      </Grid>

      <Grid item xs={12} sm={4}>
        <Typography variant="body2">SG Approval Status</Typography>
        <TextField
          variant="outlined"
          size="small"
          fullWidth
          id="outlined-select-approval-status"
          value={orderData?.approvalStatus}
          select
          onChange={(e) =>
            setOrderData({
              ...orderData,
              approvalStatus: e.target.value,
            })
          }
        >
          {status.map((option) => (
            <MenuItem key={option.value} value={option.value}>
              {option.label}
            </MenuItem>
          ))}
        </TextField>
      </Grid>

      <Grid item xs={12} sm={4}>
        <Typography variant="body2">Status</Typography>
        <TextField
          variant="outlined"
          size="small"
          fullWidth
          id="outlined-select-status"
          select
          onChange={(e) =>
            setOrderData({
              ...orderData,
              status: e.target.value,
            })
          }
        >
          {offerStatus.map((option) => (
            <MenuItem key={option.value} value={option.value}>
              {option.label}
            </MenuItem>
          ))}
        </TextField>
      </Grid>

      <Grid item xs={12} sm={4}>
        <Typography variant="body2">Status Deadline</Typography>
        <TextField
          id="date"
          type="date"
          inputProps={{ min: "1999-01-01", max: "2999-01-01" }}
          InputLabelProps={{
            shrink: true,
          }}
          variant="outlined"
          size="small"
          fullWidth
          onChange={(e) =>
            setOrderData({
              ...orderData,
              statusDeadline: e.target.value,
            })
          }
        />
      </Grid>

      <Grid item xs={12} sm={4}>
        <Typography variant="body2">Customer</Typography>
        <TextField
          variant="outlined"
          size="small"
          value={orderData?.stakeholder?.id}
          fullWidth
          select
          onChange={(e) =>
            setOrderData({
              ...orderData,
              stakeholderId:
                e.target.value !== "" ? parseInt(e.target.value, 10) : null,
            })
          }
        >
          <MenuItem>-</MenuItem>
          <Box display="flex" justifyContent="center">
            <Button onClick={retrieveCustomers}>
              {" "}
              Click To Retrieve Customers{" "}
            </Button>
          </Box>
          <MenuItem>-</MenuItem>
          {customers.map((option) => (
            <MenuItem key={option.id} value={option.id}>
              {option.id} - {option.name}
            </MenuItem>
          ))}
        </TextField>
      </Grid>

      {/*  <Grid item xs={12} sm={4}>
        <Typography variant="body2">Purchase Cost</Typography>
        <TextField
          disabled
          variant="outlined"
          size="small"
          value={createCustomerOffer?.data?.estimatedCost?.totalCost}
          fullWidth
        />
        </Grid>*/}

      <Grid item xs={12} sm={4}>
        <Typography variant="body2">Sales Cost</Typography>
        <Autocomplete
          options={costs}
          getOptionLabel={(option) =>
            `Remark: ${option?.remark || ""}, Total Cost: ${
              option?.totalCost || ""
            }`
          }
          value={selectedCost}
          onChange={(event, newValue) => {
            setSelectedCost(newValue);
            setOrderData({
              ...orderData,
              estimatedCostId: newValue ? newValue.id : "",
            });
          }}
          onOpen={retrieveCosts}
          isOptionEqualToValue={(option, value) => option.id === value.id}
          renderInput={(params) => (
            <TextField {...params} variant="outlined" size="small" fullWidth />
          )}
          renderTags={() => null}
        />
        {selectedCost && (
          <Box mt={2}>
            <Chip
              label={`Remark: ${selectedCost?.remark || ""}, Total Cost: ${
                selectedCost?.totalCost || ""
              }`}
              onDelete={() => {
                setSelectedCost(null);
                setOrderData({
                  ...orderData,
                  costId: "",
                });
              }}
            />
          </Box>
        )}
      </Grid>

      <Grid item xs={12} sm={4}>
        <Typography variant="body2">Vendor Offer Final Price Per Kg</Typography>
        <TextField
          variant="outlined"
          size="small"
          value={createCustomerOffer?.data?.finalOfferPrice}
          fullWidth
          disabled
        />
      </Grid>

      <Grid item xs={12} sm={4}>
        <Typography variant="body2">
          Vendor Offer Final Price Per Kg Currency
        </Typography>
        <TextField
          variant="outlined"
          size="small"
          value={createCustomerOffer?.data?.finalOfferPriceCurrency}
          fullWidth
          disabled
        />
      </Grid>
      <Grid item xs={12} sm={4}>
        <Typography variant="body2">First Offer Price</Typography>
        <TextField
          variant="outlined"
          type="number"
          size="small"
          fullWidth
          onChange={(e) =>
            setOrderData({
              ...orderData,
              firstOfferPrice: e.target.value,
            })
          }
        />
      </Grid>

      <Grid item xs={12} sm={4}>
        <Typography variant="body2">First Offer Price Currency</Typography>
        <TextField
          variant="outlined"
          size="small"
          value={orderData?.firstOfferPriceCurrency}
          fullWidth
          select
          onChange={(e) =>
            setOrderData({
              ...orderData,
              firstOfferPriceCurrency: e.target.value,
            })
          }
        >
          {currencies.map((option) => (
            <MenuItem key={option.value} value={option.value}>
              {option.label}
            </MenuItem>
          ))}
        </TextField>
      </Grid>

      <Grid item xs={12} sm={4}>
        <Typography variant="body2">Final Offer Price</Typography>
        <TextField
          variant="outlined"
          type="number"
          size="small"
          fullWidth
          onChange={(e) =>
            setOrderData({
              ...orderData,
              finalOfferPrice: e.target.value,
            })
          }
        />
      </Grid>

      <Grid item xs={12} sm={4}>
        <Typography variant="body2">Final Offer Currency</Typography>
        <TextField
          variant="outlined"
          size="small"
          value={orderData?.finalOfferPriceCurrency}
          fullWidth
          select
          onChange={(e) =>
            setOrderData({
              ...orderData,
              finalOfferPriceCurrency: e.target.value,
            })
          }
        >
          {currencies.map((option) => (
            <MenuItem key={option.value} value={option.value}>
              {option.label}
            </MenuItem>
          ))}
        </TextField>
      </Grid>

      <Grid item xs={12} sm={4}>
        <Typography variant="body2">Select Offer Sample</Typography>
        <Autocomplete
          options={filteredSamples}
          getOptionLabel={(option) =>
            `ID: ${option?.id || ""}, Remark: ${
              option?.remark || ""
            }, Buying Grade: ${option?.item?.buyingGrade || ""}`
          }
          value={selectedFirstSample}
          onChange={(event, newValue) => {
            setSelectedFirstSample(newValue);
            setOrderData({
              ...orderData,
              firstSampleId: newValue ? newValue.id : "",
            });
          }}
          onOpen={() => retrieveFilteredSamples("Offer Sample")}
          isOptionEqualToValue={(option, value) => option.id === value.id}
          renderInput={(params) => (
            <TextField {...params} variant="outlined" size="small" fullWidth />
          )}
          renderTags={() => null}
        />
        {selectedFirstSample && (
          <Box mt={2}>
            <Chip
              label={`ID: ${selectedFirstSample?.id || ""}, Remark: ${
                selectedFirstSample?.remark || ""
              }, Buying Grade: ${selectedFirstSample?.item?.buyingGrade || ""}`}
              onDelete={() => setSelectedFirstSample(null)}
            />
          </Box>
        )}
      </Grid>

      <Grid item xs={12} sm={4}>
        <Typography variant="body2">Offer Sample Date</Typography>
        <TextField
          disabled
          variant="outlined"
          size="small"
          value={
            selectedFirstSample?.deliveryDate
              ? format(parseISO(selectedFirstSample.deliveryDate), "yyyy-MM-dd")
              : ""
          }
          fullWidth
        />
      </Grid>

      <Grid item xs={12} sm={4}>
        <Typography variant="body2">Offer Sample Status</Typography>
        <TextField
          variant="outlined"
          size="small"
          value={orderData?.firstSampleStatus}
          fullWidth
          select
          onChange={(e) =>
            setOrderData({
              ...orderData,
              firstSampleStatus: e.target.value,
            })
          }
        >
          {sampleStatus.map((option) => (
            <MenuItem key={option.value} value={option.value}>
              {option.label}
            </MenuItem>
          ))}
        </TextField>
      </Grid>

      <Grid item xs={12} sm={4}>
        <Typography variant="body2">Select Trial Sample</Typography>
        <Autocomplete
          options={filteredSamples}
          getOptionLabel={(option) =>
            `ID: ${option?.id || ""}, Remark: ${
              option?.remark || ""
            }, Buying Grade: ${option?.item?.buyingGrade || ""}`
          }
          value={selectedSecondSample}
          onChange={(event, newValue) => {
            setSelectedSecondSample(newValue);
            setOrderData({
              ...orderData,
              secondSampleId: newValue ? newValue.id : "",
            });
          }}
          onOpen={() => retrieveFilteredSamples("Trial Sample")}
          isOptionEqualToValue={(option, value) => option.id === value.id}
          renderInput={(params) => (
            <TextField {...params} variant="outlined" size="small" fullWidth />
          )}
          renderTags={() => null}
        />
        {selectedSecondSample && (
          <Box mt={2}>
            <Chip
              label={`ID: ${selectedSecondSample?.id || ""}, Remark: ${
                selectedSecondSample?.remark || ""
              }, Buying Grade: ${
                selectedSecondSample?.item?.buyingGrade || ""
              }`}
              onDelete={() => setSelectedSecondSample(null)}
            />
          </Box>
        )}
      </Grid>

      <Grid item xs={12} sm={4}>
        <Typography variant="body2">Trial Sample Date</Typography>
        <TextField
          disabled
          variant="outlined"
          size="small"
          value={
            selectedSecondSample?.deliveryDate
              ? format(
                  parseISO(selectedSecondSample?.deliveryDate),
                  "yyyy-MM-dd",
                )
              : ""
          }
          fullWidth
        />
      </Grid>

      <Grid item xs={12} sm={4}>
        <Typography variant="body2">Trial Sample Status</Typography>
        <TextField
          variant="outlined"
          size="small"
          value={orderData?.secondSampleStatus}
          fullWidth
          select
          onChange={(e) =>
            setOrderData({
              ...orderData,
              secondSampleStatus: e.target.value,
            })
          }
        >
          {sampleStatus.map((option) => (
            <MenuItem key={option.value} value={option.value}>
              {option.label}
            </MenuItem>
          ))}
        </TextField>
      </Grid>

      <Grid item xs={12} sm={4}>
        <Typography variant="body2">Select Packaging</Typography>
        <TextField
          variant="outlined"
          size="small"
          fullWidth
          select
          onChange={(e) =>
            setOrderData({
              ...orderData,
              packaging: e.target.value,
            })
          }
        >
          {packagingTypes.map((option) => (
            <MenuItem key={option.value} value={option.value}>
              {option.label}
            </MenuItem>
          ))}
        </TextField>
      </Grid>

      <Grid item xs={12} sm={4}>
        <Typography variant="body2">Payment Term</Typography>
        <TextField
          variant="outlined"
          size="small"
          defaultValue={createCustomerOffer?.data?.paymentTerm}
          fullWidth
          select
          onChange={(e) =>
            setOrderData({
              ...orderData,
              paymentTerm: e.target.value,
            })
          }
        >
          {paymentTerm.map((option) => (
            <MenuItem key={option.value} value={option.value}>
              {option.label}
            </MenuItem>
          ))}
        </TextField>
      </Grid>

      <Grid item xs={12} sm={4}>
        <Typography variant="body2">Incoterm</Typography>
        <TextField
          variant="outlined"
          size="small"
          defaultValue={createCustomerOffer?.data?.incoterm}
          fullWidth
          select
          onChange={(e) =>
            setOrderData({
              ...orderData,
              incoterm: e.target.value,
            })
          }
        >
          {incoterms.map((option) => (
            <MenuItem key={option.value} value={option.value}>
              {option.label}
            </MenuItem>
          ))}
        </TextField>
      </Grid>

      <Grid item xs={12} sm={4}>
        <Typography variant="body2">Number Of Cartons</Typography>
        <TextField
          variant="outlined"
          size="small"
          type="number"
          value={orderData?.numberOfCases}
          fullWidth
          onChange={(e) =>
            setOrderData({
              ...orderData,
              numberOfCases: e.target.value,
              volume: e.target.value * orderData?.netWeightPerCase,
            })
          }
        />
      </Grid>

      <Grid item xs={12} sm={4}>
        <Typography variant="body2">Net Weight Per Carton</Typography>
        <TextField
          variant="outlined"
          size="small"
          type="number"
          value={orderData?.netWeightPerCase}
          fullWidth
          onChange={(e) =>
            setOrderData({
              ...orderData,
              netWeightPerCase: e.target.value,
              volume: e.target.value * orderData?.numberOfCases,
            })
          }
        />
      </Grid>

      <Grid item xs={12} sm={4}>
        <Typography variant="body2">Volume</Typography>
        <TextField
          variant="outlined"
          size="small"
          type="number"
          value={orderData?.volume}
          fullWidth
          onChange={(e) =>
            setOrderData({
              ...orderData,
              volume: e.target.value,
            })
          }
        />
      </Grid>

      <Grid item xs={12} sm={4}>
        <Typography variant="body2">Volume Unit</Typography>
        <TextField
          variant="outlined"
          size="small"
          select
          value={orderData?.volumeUnit}
          fullWidth
          onChange={(e) =>
            setOrderData({
              ...orderData,
              volumeUnit: e.target.value,
            })
          }
        >
          {units.map((option) => (
            <MenuItem key={option.value} value={option.value}>
              {option.label}
            </MenuItem>
          ))}
        </TextField>
      </Grid>

      <Grid item xs={12} sm={4}>
        <Typography variant="body2">Total Amount</Typography>
        <TextField
          variant="outlined"
          size="small"
          type="number"
          value={orderData?.totalAmount}
          fullWidth
          onChange={(e) =>
            setOrderData({
              ...orderData,
              totalAmount: e.target.value,
            })
          }
        />
      </Grid>

      <Grid item xs={12} sm={4}>
        <Typography variant="body2">Total Amount Currency</Typography>
        <TextField
          variant="outlined"
          size="small"
          value={orderData?.totalAmountCurrency}
          fullWidth
          select
          onChange={(e) =>
            setOrderData({
              ...orderData,
              totalAmountCurrency: e.target.value,
            })
          }
        >
          {currencies.map((option) => (
            <MenuItem key={option.value} value={option.value}>
              {option.label}
            </MenuItem>
          ))}
        </TextField>
      </Grid>

      <Grid item xs={12} sm={4}>
        <Typography variant="body2">Offer Date</Typography>
        <TextField
          id="date"
          type="date"
          inputProps={{ min: "1999-01-01", max: "2999-01-01" }}
          InputLabelProps={{
            shrink: true,
          }}
          onKeyDown={(e) => e.preventDefault()}
          variant="outlined"
          size="small"
          fullWidth
          onChange={(e) =>
            setOrderData({
              ...orderData,
              offerDate: e.target.value,
            })
          }
        />
      </Grid>

      <Grid item xs={12} sm={4}>
        <Typography variant="body2">Offer Validity Date</Typography>
        <TextField
          id="date"
          type="date"
          inputProps={{ min: "1999-01-01", max: "2999-01-01" }}
          InputLabelProps={{
            shrink: true,
          }}
          onKeyDown={(e) => e.preventDefault()}
          variant="outlined"
          size="small"
          fullWidth
          onChange={(e) =>
            setOrderData({
              ...orderData,
              validityDate: e.target.value,
            })
          }
        />
      </Grid>

      <Grid item xs={12} sm={4}>
        <Typography variant="body2">Port Of Loading</Typography>
        <TextField
          variant="outlined"
          size="small"
          value={createCustomerOffer?.data?.portOfLoading}
          fullWidth
          disabled
        />
      </Grid>

      <Grid item xs={12} sm={4}>
        <Typography variant="body2">Port Of Discharge</Typography>
        <Autocomplete
          options={portsOfDischarge}
          value={selectedPortOfDischarge}
          onChange={(event, newValue) => {
            const newTerm = newValue ? newValue.value : "";
            setSelectedPortOfDischarge(newTerm);
            setOrderData({
              ...orderData,
              portOfDischarge: newValue ? newValue.value : "",
            });
          }}
          isOptionEqualToValue={(option, value) => option.value === value.value}
          renderInput={(params) => (
            <TextField {...params} variant="outlined" size="small" fullWidth />
          )}
          renderTags={() => null}
        />
        {selectedPortOfDischarge && (
          <Box mt={2}>
            <Chip
              label={
                portsOfDischarge.find(
                  (term) => term.value === selectedPortOfDischarge,
                )?.label || ""
              }
              onDelete={() => setSelectedPortOfDischarge("")}
            />
          </Box>
        )}
      </Grid>

      <Grid item xs={12} sm={4}>
        <Typography variant="body2">Remark</Typography>
        <TextField
          variant="outlined"
          size="small"
          fullWidth
          onChange={(e) =>
            setOrderData({
              ...orderData,
              remark: e.target.value,
            })
          }
        />
      </Grid>

      <Grid item xs={12} sm={4}>
        <Typography variant="body2">Comment</Typography>
        <TextField
          variant="outlined"
          size="small"
          fullWidth
          onChange={(e) =>
            setOrderData({
              ...orderData,
              comment: e.target.value,
            })
          }
        />
      </Grid>

      <Grid item xs={12} sm={4}>
        <Typography variant="body2">Offer Link</Typography>
        <TextField
          variant="outlined"
          size="small"
          value={orderData?.offerLink}
          fullWidth
          onChange={(e) =>
            setOrderData({
              ...orderData,
              offerLink: e.target.value,
            })
          }
        />
      </Grid>
      <Grid item xs={12} sm={4}>
        <Typography variant="body2">Document Status</Typography>
        <TextField
          variant="outlined"
          size="small"
          value={orderData?.documentStatus}
          fullWidth
          select
          onChange={(e) =>
            setOrderData({
              ...orderData,
              documentStatus: e.target.value,
            })
          }
        >
          {status.map((option) => (
            <MenuItem key={option.value} value={option.value}>
              {option.label}
            </MenuItem>
          ))}
        </TextField>
      </Grid>

      <Button
        variant="outlined"
        size="small"
        sx={{ ml: 1.5, mt: 4, mr: 1 }}
        onClick={() => setCreateCustomerOffer({ visible: false, data: null })}
      >
        Cancel
      </Button>
      <Button
        variant="contained"
        size="small"
        sx={{ mt: 4 }}
        onClick={() => {
          setCreateCustomerOffer({
            ...createCustomerOffer,
            data: orderData,
            visible: false,
          });
          createOfferApi(orderData)
            .then((response) => {
              window.location.reload();
            })
            .catch((error) => {
              console.log(error.response.data);
            });
        }}
      >
        Create
      </Button>
    </Grid>
  );
};

export default CreateCustomerOffer;

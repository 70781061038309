import { apiClient } from "./ApiClient";

export const retrieveAllStoragePriceApi = () =>
  apiClient.get(`/storage-price-view/storage-price`);

export const createStoragePriceApi = (storagePrice) =>
  apiClient.post(`/storage-price-view/add`, storagePrice);

export const updateStoragePriceApi = (storagePriceId, storagePrice) =>
  apiClient.put(`/storage-price-view/edit/${storagePriceId}`, storagePrice);

export const updateMultipleStoragePricesApi = (storagePriceList) =>
  apiClient.put(
    `/storage-price-view/edit/storage-price-list`,
    storagePriceList,
  );

export const retrieveFilteredStoragePricesApi = (storagePrice) =>
  apiClient.post(`/storage-price-view/storage-price/filter`, storagePrice);
